import { encode } from '@nem035/gpt-3-encoder';

// Determine the base URL for WebSocket connections based on the current protocol
const baseUrl = (window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host;

// Initialize a WebSocket connection to the specified path
const initializeWebSocket = path => new WebSocket(`${baseUrl}/ws${path}`);

// Send a command to the WebSocket server once the connection is open
const sendWebSocketCommand = (ws, command, data) => {
	ws.onopen = () => {
		ws.send(JSON.stringify({ command, ...data }));
	};
};

// Create a promise that handles WebSocket responses, errors, and closure
const createWebSocketPromise = (ws, resolve, reject) => {
	ws.onmessage = event => {
		try {
			// Attempt to parse the response data and resolve the promise
			resolve(JSON.parse(event.data));
		} catch (error) {
			// Reject the promise if parsing fails
			reject(error);
		} finally {
			// Close the WebSocket connection
			ws.close();
		}
	};

	ws.onerror = error => {
		// Reject the promise on error and close the connection
		reject(error);
		ws.close();
	};

	ws.onclose = event => {
		// If the connection closes unexpectedly, reject the promise
		if (!event.wasClean) {
			reject(new Error('WebSocket closed with unexpected response'));
		}
	};
};

// Make a WebSocket request to a specified endpoint with a command and data, and return a promise
const callWebSocketEndpoint = (path, command, data) => {
	return new Promise((resolve, reject) => {
		const ws = initializeWebSocket(path);
		sendWebSocketCommand(ws, command, data);
		createWebSocketPromise(ws, resolve, reject);
	});
};

// Send a note to the server to get a modified version, using GPT-3 encoder for token length calculation
export const getModifiedNote = note => {
	const data = {
		messages: note,
		temperature: 0.4, // Temperature parameter for AI response variability
		max_tokens: 4096 - encode(note).length, // Max tokens allowed for the AI response
	};
	return callWebSocketEndpoint('/chat-gpt/', 'chat-completion', data);
};

// Chatbot support commands
export const callSupport = (command, data) => callWebSocketEndpoint(`/support/`, command, data);

// Show support chat interface
export const showSupport = () => callSupport('show');

// Post a message to support chat
export const postSupport = message => callSupport('post', { message });

// End the support chat session
export const endSupport = () => callSupport('quit');

// AI Note Plus feature commands
export const callAIPlus = (noteTypeId, command, data) =>
	callWebSocketEndpoint(`/ai-note/${noteTypeId}/`, command, data);

// Show AI Note Plus interface for a specific note type
export const showAIPlus = noteTypeId => callAIPlus(noteTypeId, 'show');

// Post a message to AI Note Plus for a specific note type
export const postAIPlus = (noteTypeId, message) => callAIPlus(noteTypeId, 'post', { message });

// End the AI Note Plus session for a specific note type
export const endAIPlus = noteTypeId => callAIPlus(noteTypeId, 'quit');
