import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const tabsState = atom({
	key: 'tabsState',
	default: [],
});

export const subTabsState = atom({
	key: 'subTabsState',
	default: [],
});

export const sectionsState = atom({
	key: 'sectionsState',
	default: [],
});

export const selectedStatementsState = atom({
	key: 'selectedStatementsState',
	default: [],
});

export const bulkStatementState = atom({
	key: 'bulkStatementState',
	default: [],
});

export const isInBukModeState = atom({
	key: 'isInBukModeState',
	default: false,
});

export const filtersState = atom({
	key: 'filtersState',
	default: {
		showLiked: false,
		showHidden: false,
	},
});

export const headingsState = atom({
	key: 'headingsState',
	default: [],
});

export const tourState = atom({
	key: 'tourState',
	default: {
		splash: true,
		mainPage: true,
		designer: true,
	},
	effects_UNSTABLE: [persistAtom],
});

export const isDesignPageState = atom({
	key: 'isDesignPageState',
	default: false,
});

export const stemsState = atom({
	key: 'stemsState',
	default: [],
});

export const notificationsState = atom({
	key: 'notificationsState',
	default: [],
});

export const statementsState = atom({
	key: 'statementsState ',
	default: [],
});
export const activeTabId = atom({
	key: 'activeSubTab ',
	default: 'headings',
});

export const selectorActiveTabId = selector({
	key: 'selectorActiveTabId',
	get: ({ get }) => {
		return get(activeTabId);
	},
	set: ({ set }, activeTabIdNew) => {
		set(activeTabId, activeTabIdNew);
	},
});

export const statementsStateSelector = selector({
	key: 'statementsStateSelector',
	get: ({ get }) => {
		return get(statementsState);
	},
	set: ({ set }, updatedSentence) => {
		set(statementsState, prevStatements => {
			const pStatements = _.clone(prevStatements),
				statementIndex = pStatements?.findIndex(emp => emp.id === updatedSentence.id);
			if (updatedSentence.remove) {
				pStatements.splice(statementIndex, 1);
			} else if (statementIndex > -1) {
				// updating a statement
				pStatements.splice(statementIndex, 1, updatedSentence);
			} else {
				// adding a statement
				pStatements.push(updatedSentence);
			}
			return [...pStatements];
		});
	},
});

export const filteredStatementState = atom({
	key: 'filteredStatementState',
	default: [],
});

export const outputStatementSelector = selector({
	key: 'outputStatementSelector',
	get: ({ get }) => {
		const list = get(statementsState);
		return list;
	},
});

export const filteredStatementSelector = selector({
	key: 'filteredStatementSelector',
	get: ({ get }) => {
		const filters = get(filtersState);
		const list = get(statementsState);
		if (filters.showHidden && filters.showLiked) {
			return list.filter(st => st.hidden && st.likes);
		} else if (filters.showHidden) {
			return list.filter(st => st.hidden);
		} else if (filters.showLiked) {
			return list.filter(st => st.likes && !st.hidden);
		}
		return list.filter(st => !st.hidden);
	},
});

export const sectionStatementsSelector = selectorFamily({
	key: 'sectionStatementsSelector',
	get:
		sectionId =>
		({ get }) => {
			return get(filteredStatementSelector).filter(statement => statement.section === sectionId);
		},
});

export const selectedListStatementState = atom({
	key: 'selectedListStatementState',
	default: null,
	set:
		statement =>
		({ set }) => {
			// Update the selectedListStatementId state
			set(selectedListStatementState, statement);
		},
});

export const imagesState = atom({
	key: 'imagesState ',
	default: [],
});

export const selectedListStatementsState = atom({
	key: 'selectedListStatementsState',
	default: {},
});

export const manualInputStatementState = atom({
	key: 'manualInputStatementState',
	default: [],
});

export const editImageState = atom({
	key: 'editImageState',
	default: '',
});

export const editOutputState = atom({
	key: 'editOutputState',
	default: false,
});

export const introCommentsState = atom({
	key: 'introCommentsState',
	default: '',
});

export const closingCommentsState = atom({
	key: 'closingCommentsState',
	default: '',
});

export const chatbotConversationState = atom({
	key: 'chatbotConversationState',
	default: [],
});

export const tourStepState = atom({
	key: 'tourStepState',
	default: 0,
});

export const designTourState = atom({
	key: 'designTourState',
	default: false,
});

export const acceptedConsentState = atom({
	key: 'acceptedConsentState',
	default: false,
});
