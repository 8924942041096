import Tippy from '@tippyjs/react';
import React from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { deleteImage } from 'services/images';
import styled from 'styled-components';
import { green, red, yellow } from 'styles/colors';

import { editImageState, imagesState } from '@note/atoms';

const ActionButtons = ({ image, isDesignPage, openEdit, isBlank, hideDelete }) => {
	const [images, setImages] = useRecoilState(imagesState);
	const setImageEdit = useSetRecoilState(editImageState);

	const handleOpenEdit = () => {
		setImageEdit(image);
		openEdit();
	};
	const handleDelete = e => {
		image.id && image.isLocal !== true && deleteImage(image.id);
		const newImagesState = images.filter(img => img.id !== image.id);
		setImages(newImagesState);
	};

	const toggleImageSelection = () => {
		setImages(prevImages => {
			return prevImages.map(item => {
				if (item.id === image.id) {
					return {
						...item,
						isSelected: !item.isSelected,
						width: undefined,
						height: undefined,
					};
				}
				return item;
			});
		});
	};

	return (
		<ActionButtonsContainer>
			{!isDesignPage && (
				<>
					{!isBlank && (
						<Tippy content="Add to note">
							<ActionBtn color={green} onClick={toggleImageSelection}>
								{image.isSelected ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
							</ActionBtn>
						</Tippy>
					)}

					<Tippy content="Edit the image">
						<ActionBtn color={yellow} onClick={handleOpenEdit}>
							<FaPencilAlt />
						</ActionBtn>
					</Tippy>
				</>
			)}
			{!isBlank && !hideDelete && (
				<Tippy content="Delete the image">
					<ActionBtn color={red} onClick={handleDelete}>
						<FaTrashAlt />
					</ActionBtn>
				</Tippy>
			)}
		</ActionButtonsContainer>
	);
};

export default ActionButtons;

const ActionButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const ActionBtn = styled.button`
	position: relative;
	border: none;
	background: ${props => props.color};
	color: white;
	padding: 0 6px 3px 6px;
	border-radius: 3px;
	&:hover {
		transform: scale(1.1);
	}
`;
