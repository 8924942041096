import React from 'react'
import { Tab } from 'react-bootstrap';
import StatementList from './StatementList';

const NoteTypeTabContent = ({ noteTypes, activeNoteType, user }) => {

    return (
        <Tab.Content>
            {
                noteTypes.map((noteType, index) => (
                    noteType.id === activeNoteType &&
                    <Tab.Pane key={index} eventKey={noteType.id}>
                        <StatementList noteType={noteType} isMyNoteType={noteType.user === user.id} />
                    </Tab.Pane>
                ))
            }
        </Tab.Content>
    )
}

export default NoteTypeTabContent