import React from 'react';
import { Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

import { ThemeButton } from 'components/ThemeButton';

const SignatureDeleteConfirmationModal = ({ deleteSignature, show, handleClose }) => {

	const handleYes = async () => {
		await deleteSignature();
		handleClose();
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Confirmation</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					<b>
						Are you sure you want to delete your signature? This deletion is permanent and cannot be undone. OK to delete?
					</b>
				</p>
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label="Yes" size="lg" color="red" onClick={handleYes} />
				<ThemeButton label="Cancel" icon={FaTimes} size="lg" color="grey" onClick={handleClose} />
			</Modal.Footer>
		</Modal>
	);
};

export default SignatureDeleteConfirmationModal;
