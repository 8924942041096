import background from 'assets/canvas.jpeg';
import React, { useCallback, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getImages } from 'services/images';
import styled from 'styled-components';
import { blue } from 'styles/colors';

import { editImageState, imagesState, isDesignPageState } from '@note/atoms';

import ActionButtons from './ActionButtons';
import ImageEditModal from './imageEdit/ImageEditModal';
import ImagesUploadModal from './ImagesModal';

const Images = () => {
	const [showImagesModal, setShowImagesModal] = useState(false);
	const [showImageEditModal, setShowImageEditModal] = useState(false);
	const [images, setImages] = useRecoilState(imagesState);
	const isDesignPage = useRecoilValue(isDesignPageState);
	const setImageEdit = useSetRecoilState(editImageState);

	const openImagesModal = () => setShowImagesModal(true);
	const closeImagesModal = () => setShowImagesModal(false);

	const openImageEditModal = () => setShowImageEditModal(true);
	const closeImageEditModal = () => setShowImageEditModal(false);

	const blankImage = {
		file: background,
		id: '',
	};

	const handleOpenEdit = img => {
		setImageEdit(img);
		openImageEditModal();
	};

	const fetchImages = useCallback(async () => {
		const fetchedImages = await getImages();
		const modifiedImages =
			fetchedImages?.data?.map((img, index) => ({
				...img,
				order: index,
				isSaved: true,
			})) || [];
		setImages(modifiedImages);
	}, [setImages]);

	useEffect(() => {
		fetchImages();
	}, [fetchImages]);

	const handleEdit = editedDataUrl => {
		const maxOrder = Math.max(...images?.map(img => img.order), 0);
		const newImage = {
			file: editedDataUrl,
			id: Math.random(),
			isLocal: true,
			order: maxOrder + 1,
		};
		setImages([...images, newImage]);
	};

	const sortedImages = [...images].sort((a, b) => a.order - b.order);

	const renderImageWidget = (img, index, isStored) => {
		const slashIndex = img.file.lastIndexOf('/');
		const filename = img.file.substr(slashIndex + 1);
		const hideDelete = isStored && !isDesignPage;
		return (
			<ImageItemContainer key={`image-${index}`}>
				<ImageWidget>
					<Image src={img.file} alt={filename} />
					<ActionButtonsContainer>
						<ActionButtons
							image={img}
							isDesignPage={isDesignPage}
							openEdit={openImageEditModal}
							hideDelete={hideDelete}
						/>
					</ActionButtonsContainer>
				</ImageWidget>
			</ImageItemContainer>
		);
	};

	return (
		<>
			<Alert>
				{isDesignPage
					? 'You can store up to 5 images that you wish to have available to bring into your notes. **Do NOT store confidential information (e.g., Private Health Information), as these images will not be encrypted.'
					: 'Here you can temporarily upload one or more images (or draw your own image) to be displayed at the end of your note. Once uploaded, you can click the pencil icon on the image to edit it before adding it to your note. [If you wish to upload and store a particular image for future use, you need to click the button above to Design Your Note.]'}
			</Alert>
			<ButtonsContainer>
				{!isDesignPage && (
					<ImagesModalButton onClick={() => handleOpenEdit(blankImage)}>
						<FaPlus />
						Sketch
					</ImagesModalButton>
				)}
				<ImagesModalButton
					onClick={openImagesModal}
					disabled={isDesignPage && sortedImages.filter(image => !image.isLocal && !image.default).length >= 5}
				>
					<FaPlus />
					{isDesignPage ? 'Add Images' : 'Add temporary images'}
				</ImagesModalButton>
			</ButtonsContainer>
			<ImagesArea>
				{!isDesignPage && images.filter(image => image.default).length > 0 && (
					<ImagesContainer>
						Note Designer's images
						<ImageList>
							{images &&
								Array.isArray(images) &&
								sortedImages?.map((image, index) => image.default && renderImageWidget(image, index, true))}
						</ImageList>
					</ImagesContainer>
				)}
				{sortedImages.filter(image => !image.isLocal && !image.default).length > 0 && (
					<ImagesContainer>
						Your stored images
						<ImageList>
							{images &&
								Array.isArray(images) &&
								sortedImages?.map(
									(image, index) => !image.isLocal && !image.default && renderImageWidget(image, index, true)
								)}
						</ImageList>
					</ImagesContainer>
				)}
				{!isDesignPage && images.filter(image => image.isLocal).length > 0 && (
					<ImagesContainer>
						Temporary Images
						<ImageList>
							{images &&
								Array.isArray(images) &&
								sortedImages?.map((image, index) => image.isLocal && renderImageWidget(image, index))}
						</ImageList>
					</ImagesContainer>
				)}
			</ImagesArea>
			<ImageEditModal show={showImageEditModal} handleClose={closeImageEditModal} handleEdit={handleEdit} />
			<ImagesUploadModal show={showImagesModal} handleClose={closeImagesModal} isDesignPage={isDesignPage} />
		</>
	);
};

export default Images;

const Image = styled.img`
	border: 1px solid black;
`;

const ImagesContainer = styled.div`
	padding: 1rem 1rem 2rem;
`;

const ImagesArea = styled.div`
	${ImagesContainer}:nth-child(even) {
		background: #f9f9f9;
	}
`;

const ImageList = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 1rem;
`;

const ImageItemContainer = styled.div`
	max-width: 30%;
	display: flex;
	flex-direction: column;
	&:last-child {
		justify-self: end;
	}
`;

const ImageWidget = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	margin-top: 1.5rem;

	img {
		max-height: 25vh;
		max-width: 100%;
	}
`;

const ImagesModalButton = styled.button`
	border: none;
	margin-top: 10px;
	padding: 4px 12px;
	font-size: 14px;
	border-radius: 5px;
	color: white;
	display: flex;
	align-items: center;
	gap: 5px;
	background: ${blue};
	svg {
		font-size: 16px;
	}
	&:disabled {
		background: grey;
	}
`;

const ButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 2rem;
`;

const ActionButtonsContainer = styled.div`
	position: absolute;
	z-index: 50;
	top: 5px;
	right: 2px;
`;
