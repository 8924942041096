import React from 'react';
import styled from 'styled-components';

const ReadMode = ({ section, sectionHref = 'section', toggleEditMode }) => (
	<SectionNameContainer>
		<SectionName id={sectionHref + section.id} onClick={toggleEditMode}>
			{section.name}
		</SectionName>
	</SectionNameContainer>
);

export default ReadMode;

const SectionName = styled.h6`
	margin-top: 20px;
	margin-bottom: 15px;
	font-weight: 600;
	font-style: italic;
	opacity: 0.8;
`;

const SectionNameContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;
