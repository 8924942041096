import Tippy from '@tippyjs/react';
import { usePreference } from 'contexts/PreferenceContext';
import React, { useContext } from 'react';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { useSetRecoilState } from 'recoil';
import { green, blue } from 'styles/colors';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';

import { statementsStateSelector } from '@note/atoms';
import { AuthContext } from '@note/contexts/AuthContext';

import { ActionBtn } from './styles';

const ActionButtons = ({ statement, resetInputs, updatedValues }) => {
	const { isOldSafari } = useContext(AuthContext);
	const setStatement = useSetRecoilState(statementsStateSelector);
	const { globalPreferences } = usePreference();

	// Select the prefilled
	const toggleContent = e => {
		setStatement({
			...statement,
			isSelected: !statement.isSelected,
			editedPrefilledText: updatedValues.editedPrefilledText,
			editedName: updatedValues.editedName,
		});
		!isOldSafari &&
			globalPreferences.SoundEffects === 'Enabled' &&
			soundPlayer(!statement.isSelected ? soundKeys.TOGGLEON : soundKeys.TOGGLEOFF); //opposite logic because of how state get updated
		e.preventDefault();
		e.stopPropagation();
	};

	// Rset the unsaved changes on the statement back to the latest saved version
	const handleReset = e => {
		resetInputs();
		setStatement({
			...statement,
			editedPrefilledText: statement.prefilled_text,
			editedName: statement.name,
		});
		e.preventDefault();
		e.stopPropagation();
	};
	return (
		<>
			<Tippy content="Clear all edits and revert to stored widget content">
				<ActionBtn color={blue} onClick={handleReset}>
					<BsArrowCounterclockwise />
				</ActionBtn>
			</Tippy>
			<Tippy content="Display">
				<ActionBtn onClick={toggleContent} color={green}>
					{statement.isSelected ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
				</ActionBtn>
			</Tippy>
		</>
	);
};

export default ActionButtons;
