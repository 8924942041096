import { fetch } from 'utils/fetch';

const getStatementsByNoteType = id =>
	fetch({
		endpoint: `/notes/statements/?note_type=${id}&is_library=false`,
		method: 'get',
		errorLabel: 'getStatementsByNoteType',
	});

const getUserStatementsForDefaultNoteType = id =>
	fetch({
		endpoint: `/notes/statements/?note_type=${id}&is_library=false&user_only=1`,
		method: 'get',
		errorLabel: 'getStatementsByNoteType',
	});

const getUserStatementsForUserNoteType = id =>
	fetch({
		endpoint: `/notes/statements/?note_type=${id}&is_library=false&was_default=0`,
		method: 'get',
		errorLabel: 'getStatementsByNoteType',
	});

const getLibraryStatementsByNoteType = id =>
	fetch({
		endpoint: `/notes/statements/getlibrary/${id}/`,
		method: 'get',
		errorLabel: 'getLibraryStatementsByNoteType',
	});

const likeStatement = id =>
	fetch({
		endpoint: `/notes/like/${id}/`,
		method: 'get',
		errorLabel: 'likeStatement',
	});

const hideStatement = id =>
	fetch({
		endpoint: `/notes/hide/${id}/`,
		method: 'get',
		errorLabel: 'hideStatement',
	});

const createStatement = statement =>
	fetch({
		endpoint: `/notes/statements/`,
		method: 'post',
		payload: statement,
		errorLabel: 'createStatement',
	});

const updateStatement = statement =>
	fetch({
		endpoint: `/notes/statements/${statement.id}/`,
		method: 'patch',
		payload: statement,
		errorLabel: 'updateStatement',
	});

const reorderStatements = orders =>
	fetch({
		endpoint: `/notes/statements/reorder/`,
		method: 'post',
		payload: orders,
		errorLabel: 'reorderStatements',
	});

const deleteStatement = id =>
	fetch({
		endpoint: `/notes/statements/${id}/`,
		method: 'delete',
		errorLabel: 'deleteStatement',
	});

export {
	getStatementsByNoteType,
	getUserStatementsForUserNoteType,
	getUserStatementsForDefaultNoteType,
	getLibraryStatementsByNoteType,
	likeStatement,
	hideStatement,
	createStatement,
	updateStatement,
	reorderStatements,
	deleteStatement,
};
