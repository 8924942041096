import { useNotifications } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, ListGroup } from 'react-bootstrap';

const NotificationStatus = {
	READ: true,
	UNREAD: false,
};

const statusList = [
	{
		name: 'All',
		value: null,
	},
	{
		name: 'Read',
		value: NotificationStatus.READ,
	},
	{
		name: 'Unread',
		value: NotificationStatus.UNREAD,
	},
];

const NotificationList = ({ handleNotificationClick }) => {
	const [status, setStatus] = useState(null);
	const { notifications } = useNotifications();
	const [filteredNotifications, setFilteredNotification] = useState(notifications);

	useEffect(() => {
		if (notifications) {
			if (status !== null) {
				setFilteredNotification(notifications.filter(ntf => ntf.is_read.me === status));
			} else {
				setFilteredNotification(notifications);
			}
		}
	}, [status, notifications]);

	const handleStatusClick = selectedStatus => {
		setStatus(selectedStatus.value);
	};

	return (
		<div>
			<ButtonGroup className="mb-2" size="sm">
				{statusList.map((statusItem, index) => (
					<Button
						onClick={() => handleStatusClick(statusItem)}
						variant={statusItem.value === status ? 'primary' : 'light'}
						key={`notification-btn-${index}`}
					>
						{statusItem.name}
					</Button>
				))}
			</ButtonGroup>
			<ListGroup defaultActiveKey="#link1">
				{filteredNotifications && filteredNotifications.length > 0 ? (
					filteredNotifications.map((notification, index) => (
						<ListGroup.Item
							key={`${notification.title}-${index}`}
							onClick={() => handleNotificationClick(notification)}
							action
							dangerouslySetInnerHTML={{
								__html: notification.is_read.me ? notification.title : `<b>${notification.title}</b>`,
							}}
						/>
					))
				) : (
					<p className="text-center mt-3">No notifications found</p>
				)}
			</ListGroup>
		</div>
	);
};

export default NotificationList;
