import styled from 'styled-components';
import ReactQuill from 'react-quill';

export const TextArea = styled(ReactQuill)`
	width: 100%;
	margin-top: 1rem;
	.ql-container {
		width: 100%;
		height: 100%;
		border: none;
	}
	.ql-editor {
		padding: 1rem !important;
		background-color: white;
		width: 100%;
		padding: 5px;
		border-radius: 5px;
		border: 2px solid grey;
		overflow: auto;
		${props => props.isbold && 'font-weight: bold'}

		h1 {
			font-size: calc(1.375rem + 1.5vw);
		}
		h2 {
			font-size: calc(1.325rem + 0.9vw);
		}
		h3 {
			font-size: calc(1.3rem + 0.6vw);
		}
	}
	.ql-toolbar {
		background-color: white;
		border-radius: 5px;
		border: 2px solid grey;
		margin-bottom: 0.5rem;
	}
	.ql-align {
		margin-top: -5px;
	}
`;
