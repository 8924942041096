import React, { useCallback, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './DatePicker.css';

const DatePicker = props => {
	const datePickerRef = useRef();

	// Function to handle the click on the calendar icon
	const handleDateIconClick = useCallback(() => {
		if (datePickerRef.current) {
			datePickerRef.current.setOpen(true); // Opens the date picker when the icon is clicked
		}
	}, []);

	return (
		<div className="custom-date-picker-wrapper">
			<ReactDatePicker
				ref={datePickerRef}
				showPopperArrow={false}
				placeholderText="yyyy-mm-dd"
				dateFormat="yyyy-MM-dd"
				{...props}
			/>
			<button onClick={handleDateIconClick}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentColor"
					strokeWidth="2.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
					<line x1="16" y1="2" x2="16" y2="6"></line>
					<line x1="8" y1="2" x2="8" y2="6"></line>
					<line x1="3" y1="10" x2="21" y2="10"></line>
				</svg>
			</button>
		</div>
	);
};

export default DatePicker;
