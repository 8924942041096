import React, { useState } from 'react';
import styled from 'styled-components';
import { VscOpenPreview } from 'react-icons/vsc';

import { blue, white } from 'styles/colors';
import DesignerInfo from '../DesignerInfo';
import DesignerTips from '../DesignerTips';
import NotePreview from './NotePreview';
import WidgetTypePreview from './widget-type-preview';

const PreviewScreen = ({ subTab }) => {
	const [showInfo, setShowInfo] = useState(true);

	return (
		<PreviewScreenContainer>
			<PreviewScreenWrapper>
				{subTab.isNew ? (
					subTab.type !== null ? (
						<WidgetTypePreview subTab={subTab} />
					) : (
						<PreviewMessage>
							<VscOpenPreview />
							<h4>Select a widget to see preview</h4>
						</PreviewMessage>
					)
				) : (
					<NotePreview subTab={subTab} />
				)}
				{showInfo && (
					<InfoPopover key={showInfo}>
						<p>
							Depending on your browser, some changes you make may be visible only once you leave this Design Space.
						</p>
						<p>Use the green button at the top left to leave the Design Space and view your customized note.</p>
					</InfoPopover>
				)}
				<ButtonsRow>
					<DesignerInfo toggleInfo={() => setShowInfo(!showInfo)} />
					<DesignerTips subTab={subTab} />
				</ButtonsRow>
			</PreviewScreenWrapper>
		</PreviewScreenContainer>
	);
};

export default PreviewScreen;

const PreviewScreenContainer = styled.div`
	width: 40%;
	height: 100%;
	display: inline-block;
	padding: 10px 10px 10px 5px;
`;

const PreviewScreenWrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow: auto;
	background: white;
	border-radius: 10px;
	position: relative;
`;

const PreviewMessage = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 10px;
	& > svg {
		font-size: 50px;
	}
`;

const ButtonsRow = styled.div`
	width: 100%;
	position: absolute;
	bottom: 0;
	padding: 1rem;
	display: flex;
	justify-content: space-between;
`;

const InfoPopover = styled.div`
	position: absolute;
	bottom: 50px;
	width: 90%;
	background-color: ${white};
	border: 2px solid ${blue};
	border-radius: 20px;
	padding: 10px;
	margin: 5%;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	z-index: 10;
`;
