import React from 'react';
import { Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

import { ThemeButton } from 'components/ThemeButton';

export const FinalNoteConfirmationModal = ({ show, handleClose, closeFinalEditModal }) => {
	const handleConfirm = () => {
		closeFinalEditModal();
		handleClose();
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Note</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				If you close this Final Edit space without exporting or copying, any changes you made will not be saved. CANCEL and return to Final
				Edit space or CLOSE now?
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label="Close" size="lg" color="yellow" onClick={handleConfirm} />
				<ThemeButton label="Cancel" icon={FaTimes} size="lg" color="grey" onClick={handleClose} />
			</Modal.Footer>
		</Modal>
	);
};
