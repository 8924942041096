// These functions are used to format the response from the AI edit feature

// Function to format text by removing unnecessary span tags and attributes
export const formatText = text => {
	let counter = 0;
	let tagToRemove = 0;

	// Replace span tags conditionally
	let intermediateText = text?.replace(/<\/?span[^>]*>/g, function (match) {
		if (/style=/.test(match)) {
			counter++;
			return match;
		} else if (/<\/span>/.test(match) && counter > 0) {
			counter--;
			return match;
		} else if (/^<span/.test(match)) {
			tagToRemove++;
		} else if (/<\/span>/.test(match) && tagToRemove > 0) {
			tagToRemove--;
		}
		return '';
	});

	// Remove id attributes from remaining span tags
	return intermediateText?.replace(/<span([^>]*)>/g, function (match, attrs) {
		return '<span' + attrs?.replace(/ id="[^"]*"/, '') + '>';
	});
};

// Function to replace specific HTML tags with placeholders
export const replaceTags = html => {
	let stringWithSpaces = html?.replace(/\n/g, ' '); // Replace newlines with spaces
	let modifiedHtml = stringWithSpaces?.replace(/<div><br><\/div>/g, '\n\n'); // Replace empty divs with newlines
	const container = document?.createElement('div');
	container.innerHTML = modifiedHtml;

	// Replace spans with specific styles with placeholders
	const spans = container?.querySelectorAll('span[style="font-weight: 700;"]');
	spans.forEach(span => {
		const replacementText = `$$%%${span.innerHTML}%%$$`;
		const textNode = document?.createTextNode(replacementText);
		span.parentNode?.replaceChild(textNode, span);
	});

	return container.textContent; // Return the text content of the container
};

// Function to revert placeholders back to HTML tags
export const revertTags = html => {
	let modifiedHtml = html?.replace(/\n-/g, '<br />-'); // Replace newlines before dashes with <br />- tags

	modifiedHtml = modifiedHtml?.replace(/(\n\n|\n)/g, '<div><br></div>'); // Replace newlines with empty divs

	const container = document?.createElement('div');
	container.innerHTML = modifiedHtml;

	// Process each node to replace placeholders with spans
	const processNode = node => {
		if (node.nodeType === Node.TEXT_NODE) {
			processTextNode(node);
		} else if (node.nodeType === Node.ELEMENT_NODE) {
			Array.from(node.childNodes).forEach(processNode);
		}
	};

	// Process text nodes to replace placeholders with spans
	const processTextNode = node => {
		let nodeValue = node.nodeValue;
		const regex = /(?:\$\$%%|\$%%)(.*?)(?:%%\$\$|%%\$)(\s*:?)/g;
		let replacementPattern = `<span style="font-weight: 700;">$1$2</span>`;

		let processedHTML = nodeValue?.replace(regex, (match, p1, p2) => {
			return replacementPattern?.replace('$1', p1).replace('$2', p2 || '');
		});

		if (processedHTML !== nodeValue) {
			const tempDiv = document?.createElement('div');
			tempDiv.innerHTML = processedHTML;
			let parentNode = node.parentNode;
			while (tempDiv.firstChild) {
				parentNode?.insertBefore(tempDiv.firstChild, node);
			}
			if (node.parentNode && node.parentNode === parentNode) {
				parentNode?.removeChild(node);
			}
		}
	};

	processNode(container); // Start processing nodes from the container

	return container.innerHTML; // Return the HTML content of the container
};
