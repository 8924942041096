const designTourSteps = [
	{
		selector: '.designer-container',
		content: () => (
			<>
				<p>
					Welcome to this tour of our Design Space, where you can do some advanced customization of your note templates
				</p>
			</>
		),
		position: 'Center',
		tourStart: true,
	},
	{
		selector: '.headings-content',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Headings</h4>
				<p>
					Here you can do advanced editing of your headings. You can change the name of a heading, or alter the type of
					heading field you need (e.g., text, date field, or drop down menu).
				</p>
				<p>
					Try <b>clicking a pencil</b> (at the right of one of the headings) to see the editing options available. Then{' '}
					<b>click 'next'</b>.
				</p>
			</>
		),
		position: [window.innerWidth * 0.6, window.innerHeight * 0.15],
	},
	{
		selector: '.headings-content',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Headings</h4>
				<p>
					When in Design space, the order of your headings can also be changed by dragging and dropping your selections.
					You can try this now by <b>clicking down on a heading title and dragging it</b>.
				</p>
			</>
		),
		position: [window.innerWidth * 0.6, window.innerHeight * 0.15],
	},
	{
		selector: '.tab-nav',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Tabs</h4>
				<p>You can move the TABS to put them in the order you prefer.</p>
				<p>
					<b>Click and hold</b> down the Subjective tab now and <b>drag it</b> over to another position.
				</p>
			</>
		),
		position: 'bottom',
	},
	{
		selector: '.subjective-tab',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Tabs</h4>
				<p>Now let’s look within the Subjective Tab:</p>
				<p>
					<b>Click on the Subjective Tab</b> to select it.
				</p>
			</>
		),
		position: 'bottom',
		type: 'click',
	},
	{
		selector: '.subtab-navpane',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Subtabs</h4>
				<p>You can also change the order of the subtabs within a tab.</p>
				<p>
					<b>Click and hold down</b> the “Reported Affective State” and <b>drag it down</b> to another position.
				</p>
			</>
		),
		position: [window.innerWidth * 0.3, window.innerHeight * 0.3],
	},
	{
		selector: '.ras-subtab',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Subtabs</h4>
				<p>
					Now let’s look within a subtab: <b>Click on “Reported Affective State”</b>.
				</p>
			</>
		),
		type: 'click',
	},
	{
		selector: '.sub-tab-content',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Statements</h4>
				<p>Using the same drag-and-drop method, you can also move and change the order of any statement in a subtab.</p>
				<p>
					<b>Click down on any of the affect terms</b> in the Reported Affective State subtab and <b>move it</b> where
					you like.
				</p>
			</>
		),
		position: [window.innerWidth * 0.6, window.innerHeight * 0.15],
	},
	{
		selector: '.add-tab-btn',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Tabs</h4>
				<p>If you want to create your own Tab you can do that here, by clicking the +Add tab button.</p>
			</>
		),
		position: 'bottom',
		disableInteraction: true,
	},
	{
		selector: '.new-tab',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Tabs</h4>
				<p>
					<b>Click on "New Tab"</b> to see what a newly created Tab looks like
				</p>
			</>
		),
		type: 'click',
	},
	{
		selector: '.tab-content',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Subtabs</h4>
				<p>
					You will then be prompted to build the type of subtab you need. There are several different styles of subtab
					to choose from. You can make a list, or a textbox, or a multiple-choice question for example. Take a moment to
					click a few options on this list to see examples at the right (all based on the idea of “ordering from a
					restaurant”). Then <b>click next</b>
				</p>
			</>
		),
	},
	{
		selector: '.new-subtab',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Subtabs</h4>
				<p>
					If you would prefer to simply move a Subtab to another Tab or to another Note type you can do this by using
					the cloning function. Hover over a Subtab now, and you’ll see the clone icon appear. Clicking on this will
					allow you to duplicate the subtab to another place. <b>Click Next to continue.</b>
				</p>
			</>
		),
	},
	{
		selector: '.leave-design-btn',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Exiting Design Space</h4>
				<p>
					When you are finished building your custom note in Design Space you click the “Leave this Design page and back
					to note” button to being using your custom note. <b>Click Next to continue.</b>
				</p>
			</>
		),
		disableInteraction: true,
	},
	{
		selector: '.tab-content',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Support</h4>
				<p>
					If you want to watch a video about the Design Space you can find that in the Support menu at the top of the
					page.
				</p>
				<p>
					As always, if you have any questions about using the Design Your Note feature, you can ask Sophie the chatbot
					or send us an email at info@notedesigner.com.
				</p>
			</>
		),
	},
];

export default designTourSteps;
