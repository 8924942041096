import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { InputType } from 'utils/enum';

import DatePicker from './DatePicker';

const HeadingDate = ({ heading, onChange }) => {
	const [value, setValue] = useState(null);

	const handleChange = date => {
		setValue(date ?? null); // Update the state with the selected date or null if date is cleared
		onChange(date ? dayjs(date).format('YYYY-MM-DD') : null); // Format the date and pass it to the onChange handler
	};

	// NOTE: Clear DatePicker value if user clears note content.
	useEffect(() => {
		if (heading.type === InputType.DATE && !heading.content) {
			setValue(null); // Reset the value to null if the heading content is cleared
		}
	}, [heading]);

	return <DatePicker selected={value} onChange={handleChange} />;
};

export default HeadingDate;
