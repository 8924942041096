import { ThemeButton } from 'components/ThemeButton';
import dayjs from 'dayjs';
import { useNotifications } from 'hooks';
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { readNotification } from 'services/notification';
import styled from 'styled-components';

// Component to display the selected notification details
const SelectedNotification = ({ selectedNotification, handleBack }) => {
	const { notifications, setNotifications } = useNotifications();

	// Mark the notification as read when the component is mounted
	useEffect(() => {
		(async () => {
			try {
				await readNotification(selectedNotification.pk); // API call to mark the notification as read
				setNotifications(
					notifications.map(nt => (nt.pk === selectedNotification.pk ? { ...nt, is_read: { me: true } } : nt))
				); // Update the local state to reflect the read status
			} catch (err) {
				console.error(err);
			}
		})();
	}, [setNotifications, selectedNotification.pk, notifications]);

	return (
		<div>
			<ThemeButton label="Back" icon={FaArrowLeft} size="sm" color="yellow" onClick={handleBack} />
			<Card className="mt-2">
				<Card.Body>
					<CardTitle dangerouslySetInnerHTML={{ __html: selectedNotification.title }} />

					{selectedNotification.dt_created && (
						<CardSubtitle>{dayjs(selectedNotification.dt_created).format('h:mm A, DD MMMM, YYYY')}</CardSubtitle>
					)}
					<CardText dangerouslySetInnerHTML={{ __html: selectedNotification.content }} />
				</Card.Body>
			</Card>
		</div>
	);
};

export default SelectedNotification;

const CardTitle = styled(Card.Title)`
	font-size: 17px;
	margin-bottom: 10px;
`;

const CardSubtitle = styled(Card.Subtitle)`
	font-size: 14px;
	opacity: 0.6;
	font-style: italic;
	margin-bottom: 5px;
`;

const CardText = styled(Card.Text)`
	font-size: 15px;
	white-space: pre-wrap;
`;
