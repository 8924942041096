import React from 'react';
import { Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

import { ThemeButton } from 'components/ThemeButton';

const DisableStemModal = ({ show, handleClose, toggleStem }) => {
	const handleYes = async () => {
		await toggleStem();
		handleClose();
	};

	// To disable a stem in a selected tab
	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Confirmation</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<b>Are you sure that you want to disable the stem?</b>
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label="Yes" size="lg" color="red" onClick={handleYes} />
				<ThemeButton label="Cancel" icon={FaTimes} size="lg" color="grey" onClick={handleClose} />
			</Modal.Footer>
		</Modal>
	);
};

export default DisableStemModal;
