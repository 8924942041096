import { fetch } from 'utils/fetch';

const getSubTabsByNoteType = id =>
	fetch({
		endpoint: `/notes/subtabs/?note_type=${id}`,
		method: 'get',
		errorLabel: 'getSubTabsByNoteType',
	});

const deleteSubTab = id =>
	fetch({
		endpoint: `/notes/subtabs/${id}/`,
		method: 'delete',
		errorLabel: 'deleteSubTab',
	});

const updateSubTabSettings = (id, settings) =>
	fetch({
		endpoint: `/notes/subtabs/${id}/`,
		method: 'patch',
		payload: { settings },
		errorLabel: 'updateSubTabSettings',
	});

const updateSubTabStemStatus = (id, is_stem_disabled) =>
	fetch({
		endpoint: `/notes/subtabs/${id}/`,
		method: 'patch',
		payload: { is_stem_disabled },
		errorLabel: 'updateSubTabStemStatus',
	});

const reorderSubTabs = orders =>
	fetch({
		endpoint: `/notes/subtabs/reorder/`,
		method: 'post',
		payload: orders,
		errorLabel: 'reorderSubTabs',
	});

const updateSubTab = (id, subTab) =>
	fetch({
		endpoint: `/notes/subtabs/${id}/`,
		method: 'patch',
		payload: subTab,
		errorLabel: 'updateSubTab',
	});

const updateSubTabName = (id, name) =>
	fetch({
		endpoint: `/notes/subtabs/${id}/`,
		method: 'patch',
		payload: { name },
		errorLabel: 'updateSubTabName',
	});

const createSubTab = subTab =>
	fetch({
		endpoint: `/notes/subtabs/`,
		method: 'post',
		payload: subTab,
		errorLabel: 'createSubTab',
	});

const duplicateSubtab = (id, tabsIds) =>
	fetch({
		endpoint: `/subtabs/${id}/duplicate/`,
		method: 'post',
		payload: { ids: tabsIds },
		errorLabel: 'createTab',
	});

export {
	getSubTabsByNoteType,
	deleteSubTab,
	updateSubTabSettings,
	updateSubTabStemStatus,
	reorderSubTabs,
	updateSubTab,
	updateSubTabName,
	createSubTab,
	duplicateSubtab,
};
