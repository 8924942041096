import { ThemeButton } from 'components/ThemeButton';
import { usePreference } from 'contexts/PreferenceContext';
import React, { useCallback, useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { toast } from 'react-toastify';
import { postQuestion } from 'services/user';
import styled from 'styled-components';
import { green, red } from 'styles/colors';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';

import { AuthContext } from '@note/contexts/AuthContext';

import './Question-modal.scss';

const QuestionModal = ({ show, handleCloseQuestionModal }) => {
	const { user, isOldSafari } = useContext(AuthContext);
	const [error, setError] = useState();
	const [question, setQuestion] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [disableButton, setDisableButton] = useState(false);
	const [submitSuccess, setSubmitSuccess] = useState(false);
	const { globalPreferences } = usePreference();

	const handleSubmitQuestion = useCallback(
		async event => {
			event.preventDefault();
			setIsLoading(true);
			setDisableButton(true);

			try {
				await postQuestion(user.id, question);
				setIsLoading(false);
				setSubmitSuccess(true);
				!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.SUCCESS);
				toast.success('Feedback submitted successfully', { hideProgressBar: true });
				setTimeout(() => {
					setDisableButton(false); // Re-enable the submit button after a short delay
				}, 3000);
			} catch (error) {
				setError('Error, please try again later');
				setIsLoading(false);
			}
		},
		[globalPreferences.SoundEffects, question, user.id]
	);

	return (
		<Modal size="md" show={show} onHide={handleCloseQuestionModal} backdropClassName="backdrop-blur">
			<Modal.Header closeButton>
				<Modal.Title>Send us your question</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ minHeight: '40vh', width: '90%', margin: 'auto', marginBottom: '1.5rem' }}>
				<div className="feedback-modal-container">
					<Form onSubmit={handleSubmitQuestion}>
						<InputGroup>
							<Form.Control
								style={{ minHeight: '35vh', margin: 'auto', marginBottom: '1.5rem' }}
								as="textarea"
								aria-label="With textarea"
								value={question}
								onChange={e => setQuestion(e.target.value)}
							/>
						</InputGroup>
						{error && <ErrorMessage>{error}</ErrorMessage>}
						{submitSuccess && <SuccessMessage>Question submitted successfully</SuccessMessage>}
						<ThemeButton
							disabled={disableButton}
							type="submit"
							fullWidth={true}
							label={isLoading ? 'Submiting...' : 'Submit'}
							size="lg"
							color="navy"
						/>
					</Form>
					<ContactInfo>
						<div>
							<>Email:</>
							<b style={{ marginLeft: '1rem' }}>info@notedesigner.com</b>
						</div>
						<div style={{ marginTop: '1rem' }}>
							<>Phone:</>
							<span style={{ marginLeft: '1rem' }}>514-465-6275</span>
						</div>
						<div style={{ marginTop: '1rem', displa: 'flex', flexDirection: 'row' }}>
							<>Mail:</>
							<span
								style={{
									marginTop: '-1.5rem',
									marginLeft: '1rem',
									display: 'flex',
									flexDirection: 'column',
									textAlign: 'center',
								}}
							>
								<span>Note Designer Inc.</span>
								<span>180 Northfield Drive West, Unit 4, 1st Floor,</span>
								<span>Waterloo, Ontario, N2L 0C7, Canada</span>
							</span>
						</div>
					</ContactInfo>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default QuestionModal;

const ErrorMessage = styled.p`
	color: ${red};
	text-align: center;
	font-weight: 500;
`;

const SuccessMessage = styled.p`
	color: ${green};
	text-align: center;
	font-weight: 500;
`;

const ContactInfo = styled.p`
	text-align: left;
	margin-top: 2rem;
	padding: 1rem;
`;
