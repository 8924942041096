import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getVideos } from 'services/user';
import styled from 'styled-components';

import './videos.scss';

import VideosSection from './VideosSection';

// Accessed via the help and support menu
const VideosModal = ({ show, handleClose }) => {
	const [videoIds, setVideoIds] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const res = await getVideos();
			setVideoIds(res.data);
		};
		fetchData();
	}, [show]);

	return (
		<Modal
			show={show}
			onHide={handleClose}
			centered
			size="xl"
			className="h-100"
			scrollable
			backdropClassName="backdrop-blur"
		>
			<Modal.Header closeButton>
				<Modal.Title>Videos</Modal.Title>
			</Modal.Header>
			<ModalBody style={{ padding: 'unset' }}>
				<VideosSection videoIds={videoIds} />
			</ModalBody>
		</Modal>
	);
};

export default VideosModal;

const ModalBody = styled(Modal.Body)`
	@media (max-width: 991px) {
		overflow-y: scroll;
	}
`;
