import React, { useCallback } from 'react';
import { ThemeButton } from 'components/ThemeButton';
import { Modal } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Confirm modal shown when clicking "Customize this note"
export const DesignNoteConfirm = ({ show, handleClose }) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const id = searchParams.get('id');

	const handleAccept = useCallback(() => {
		handleClose();
		navigate('/design-note?id=' + id);
	}, [handleClose, id, navigate]);

	return (
		<>
			<Modal show={show} onHide={handleClose} backdropClassName="backdrop-blur">
				<Modal.Header closeButton>
					<Modal.Title>Customize This Note</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>CAUTION</p>
					<p>
						If you proceed, you will lose any inputs and statement selections you have made in your current note. [click
						"Cancel" to return to your current note instead]
					</p>
				</Modal.Body>
				<Modal.Footer>
					<ThemeButton size="sm" onClick={handleAccept} label="Proceed" />
					<ThemeButton size="sm" onClick={handleClose} label="Cancel" color="grey" />
				</Modal.Footer>
			</Modal>
		</>
	);
};
