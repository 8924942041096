import { ThemeButton } from 'components/ThemeButton';
import React, { useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa';
import { useRecoilState } from 'recoil';
import { createSection } from 'services/section';
import { createStatement } from 'services/statement';
import { createStem } from 'services/stem';
import { updateSubTab } from 'services/subTab';
import styled from 'styled-components';
import { Defaults, WidgetType } from 'utils/enum';

import { sectionsState, statementsState, stemsState, subTabsState } from '@note/atoms';

import {
	listSelectData,
	listSelectStems,
	manualInputData,
	multiChoiceData,
	multiChoiceOptionsData,
	prefilledInputData,
	psoData,
	threeChoiceStems,
	twoChoiceStems,
} from './example-data';

const WidgetSelector = ({ subTab }) => {
	const [isValid, setIsValid] = useState(true);
	const [subTabs, setSubTabs] = useRecoilState(subTabsState);
	const [sections, setSections] = useRecoilState(sectionsState);
	const [statements, setStatements] = useRecoilState(statementsState);
	const [stems, setStems] = useRecoilState(stemsState);

	// Function to get settings based on the widget type
	const getSettings = type => {
		if (type === WidgetType.LIST_SELECT || type === WidgetType.LIST_MULTI_SELECT) {
			return { listSelect: { isListDoubleStacked: false, isListLinesSingle: false } };
		} else if (type === WidgetType.MULTIPLE_CHOICE) {
			return { multipleChoice: { options: multiChoiceOptionsData } };
		}
		return {};
	};

	// Handle the widget type selection
	const handleClick = type => {
		setSubTabs(
			subTabs.map(st => {
				if (st.id === subTab.id) {
					return st.type === type ? { ...st, type: null } : { ...st, type: type, settings: getSettings(type) };
				}
				return st;
			})
		);
	};

	// Handle the Next button click to save the selected widget type and default data
	const onNextClick = async e => {
		if (subTab.type === null) {
			setIsValid(false);
			return;
		}

		try {
			const res = await Promise.all([
				updateSubTab(subTab.id, { type: subTab.type, settings: subTab.settings }),
				createSection({
					order_index: Defaults.UNCATEGORIZED_SECTION_ORDER,
					name: Defaults.UNCATEGORIZED_SECTION_NAME,
					subtab: subTab.id,
				}),
			]);
			await saveDefaultStems();
			await saveDefaultStatements(res[1].data);
			setSections([...sections, res[1].data]);
			setSubTabs(
				subTabs.map(st => (st.id === subTab.id ? { ...st, isNew: false, top_label: res[0].data.top_label } : st))
			);
		} catch (error) {
			console.error('🚀 ~ file: WidgetSelector.js ~ line 72 ~ onNextClick ~ error', error);
		}
	};

	// Save the default statements based on the selected widget type
	const saveDefaultStatements = async section => {
		const defaultStatements = getDefaultStatements(section);
		const promises = defaultStatements.map(st => createStatement(st));
		try {
			const results = await Promise.all(promises);
			const newStatements = results.map(re => ({
				...re.data,
				isSelected: false,
				selectedType: null,
				content: '',
				selectedOption: null,
				editedName: re.data.name,
				editedPrefilledText: re.data.prefilled_text,
			}));

			setStatements([...statements, ...newStatements].sort((a, b) => a.order_index - b.order_index));
		} catch (error) {
			console.error('🚀 ~ file: WidgetSelector.js ~ line 84 ~ saveDefaultStatements ~ error', error);
		}
	};

	// Save the default stems based on the selected widget type
	const saveDefaultStems = async () => {
		const defaultStems = getDefaultStems();
		const promises = defaultStems.map(st => createStem(st));
		try {
			const results = await Promise.all(promises);
			const newStems = results.map(re => re.data);
			setStems([...stems, ...newStems]);
		} catch (error) {
			console.error('🚀 ~ file: WidgetSelector.js ~ line 96 ~ saveDefaultStems ~ error', error);
		}
	};

	// Get default statements based on the widget type
	const getDefaultStatements = section => {
		let defaultStatements = [];
		const type = subTab.type;

		if (type === WidgetType.LIST_SELECT || type === WidgetType.LIST_MULTI_SELECT) {
			defaultStatements = listSelectData;
		} else if (type === WidgetType.PS || type === WidgetType.PSO) {
			defaultStatements = psoData;
		} else if (type === WidgetType.MANUAL_INPUT) {
			defaultStatements = manualInputData;
		} else if (type === WidgetType.MULTIPLE_CHOICE) {
			defaultStatements = multiChoiceData;
		} else if (type === WidgetType.PREFILLED_INPUT) {
			defaultStatements = prefilledInputData;
		}

		return defaultStatements.map((st, index) => {
			return {
				...st,
				order_index: index,
				section: section.id,
				placeholder: type === WidgetType.MANUAL_INPUT ? 'Add a prompt here if you wish' : '',
			};
		});
	};

	// Get default stems based on the widget type
	const getDefaultStems = () => {
		let stems = [];
		const type = subTab.type;
		if (type === WidgetType.LIST_SELECT || type === WidgetType.LIST_MULTI_SELECT) {
			stems = listSelectStems;
		} else if (type === WidgetType.PS) {
			stems = twoChoiceStems;
		} else if (type === WidgetType.PSO) {
			stems = threeChoiceStems;
		}
		return stems.map((st, index) => {
			return {
				key: st.key,
				order_index: index,
				value: st.value,
				subtab: subTab.id,
			};
		});
	};

	return (
		<WidgetsContainer>
			<WidgetsBody>
				<h5>The content for your subtab is handled by a widget.</h5>
				<p>
					Pick the type of widget you wish to use from the options below{' '}
					<i>
						(To help you choose, you can click each one to see an example at the right of your screen. Then click the
						NEXT button below)
					</i>
				</p>

				{!isValid && <Alert variant="danger">Please select one of the below to proceed.</Alert>}

				<RadioItem>
					<input
						value={WidgetType.MANUAL_INPUT}
						name="wiget-group"
						onClick={() => handleClick(WidgetType.MANUAL_INPUT)}
						type="radio"
					/>
					Simple Text Boxes
				</RadioItem>
				<RadioItem>
					<input
						value={WidgetType.PREFILLED_INPUT}
						name="wiget-group"
						onClick={() => handleClick(WidgetType.PREFILLED_INPUT)}
						type="radio"
					/>
					Prefilled Text Boxes
				</RadioItem>
				<RadioItem>
					<input
						value={WidgetType.LIST_MULTI_SELECT}
						name="wiget-group"
						onClick={() => handleClick(WidgetType.LIST_MULTI_SELECT)}
						type="radio"
					/>
					Select<b> ONE OR MORE </b>choices from a list
				</RadioItem>
				<RadioItem>
					<input
						value={WidgetType.LIST_SELECT}
						name="wiget-group"
						onClick={() => handleClick(WidgetType.LIST_SELECT)}
						type="radio"
					/>
					Select<b> ONLY ONE</b> choice from a list
				</RadioItem>
				<RadioItem>
					<input value={WidgetType.PS} name="wiget-group" onClick={() => handleClick(WidgetType.PS)} type="radio" />
					Select phrases from a list to create TWO sentences
				</RadioItem>
				<RadioItem>
					<input value={WidgetType.PSO} name="wiget-group" onClick={() => handleClick(WidgetType.PSO)} type="radio" />
					Select phrases from a list to create THREE sentences
				</RadioItem>
				<RadioItem>
					<input
						value={WidgetType.MULTIPLE_CHOICE}
						name="wiget-group"
						onClick={() => handleClick(WidgetType.MULTIPLE_CHOICE)}
						type="radio"
					/>
					Mutiple Choice Selection
				</RadioItem>
			</WidgetsBody>
			<Footer>
				<ThemeButton label="Next" color="blue" onClick={onNextClick} size="lg" icon={FaChevronRight} />
			</Footer>
		</WidgetsContainer>
	);
};

export default WidgetSelector;

const WidgetsContainer = styled.div`
	height: 100%;
	padding: 10px;
`;

const WidgetsBody = styled.div`
	padding: 0px;
	margin: 0;
	height: calc(100% - 40px);
	overflow: auto;
`;

const Footer = styled.div`
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 10px;
`;

const RadioItem = styled(Form.Label)`
	width: 100%;
	display: block;
	padding: 10px 10px;
	border: 1px solid #e5e5e5;
	cursor: pointer;
	border-radius: 5px;
	input {
		margin-left: 0 !important;
		margin-right: 10px;
	}
`;
