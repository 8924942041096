// debounce is a utility function that delays the execution of a given function until after a specified delay has passed since the last time the debounce function was called.
// This is useful for optimizing performance in scenarios where a function is called frequently, such as during window resizing or input changes.

const debounce = (func, delay) => {
	let inDebounce; // Variable to store the timeout ID
	return function (...args) {
		const context = this;
		clearTimeout(inDebounce); // Clear any existing timeout to reset the delay
		// Set a new timeout that will call the function after the specified delay
		inDebounce = setTimeout(() => func.apply(context, args), delay);
	};
};

export default debounce;
