/* eslint-disable react-hooks/exhaustive-deps */
import { usePreference } from 'contexts/PreferenceContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { navy, red } from 'styles/colors';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';

import { statementsStateSelector } from '@note/atoms';
import { AuthContext } from '@note/contexts/AuthContext';

export const MultipleChoice = ({ statement, settings, editMode, setStatementText }) => {
	const options = settings?.multipleChoice.options;
	const { isOldSafari } = useContext(AuthContext);
	const [start, setStart] = useState(statement.name.split('$')[0]);
	const [end, setEnd] = useState(statement.name.split('$')[1]);
	const setStatement = useSetRecoilState(statementsStateSelector);
	const { globalPreferences } = usePreference();

	useEffect(() => {
		setStatementText([start, end].join('$'));
	}, [start, end]);

	const toggleStatement = option => {
		!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.TOGGLEON);
		setStatement({ ...statement, selectedOption: option });
	};

	const removeStatement = () => {
		!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.TOGGLEOFF);
		setStatement({ ...statement, selectedOption: null });
	};

	return (
		<WidgetContaier>
			{editMode ? (
				<>
					<InputText onChange={e => setStart(e.target.value)} value={start} type="text" />
					<InputText onChange={e => setEnd(e.target.value)} value={end} type="text" />
				</>
			) : (
				<>
					<Line>{start}</Line>
					<div>
						{options?.map((option, index) => (
							<ListItem
								key={index}
								isSelected={option === statement.selectedOption}
								onClick={() => toggleStatement(option)}
							>
								{option}
							</ListItem>
						))}
						{statement.selectedOption && (
							<ListItemCancel onClick={() => removeStatement()}>
								<FaTimes />
							</ListItemCancel>
						)}
					</div>
					<Line>{end}</Line>
				</>
			)}
		</WidgetContaier>
	);
};

const ListItem = styled.div`
	padding: 7px 15px;
	background: ${props => (props.isSelected ? navy : '#eee')};
	color: ${props => (props.isSelected ? '#fff' : '#000')};
	margin-right: 5px;
	margin-bottom: 5px;
	border-radius: 5px;
	cursor: pointer;
	display: inline-block;
	&:hover {
		background: ${navy};
		color: #fff;
	}
`;

const ListItemCancel = styled.li`
	padding: 7px 15px;
	background: ${red};
	margin-right: 5px;
	border-radius: 5px;
	cursor: pointer;
	color: #fff;
	display: inline-block;
`;

const Line = styled.p`
	margin: 0;
	font-weight: bold;
`;

const WidgetContaier = styled.div`
	background: #fff;
	padding: 10px;
	width: 100%;
	position: relative;
	&:hover #buttons {
		display: block;
	}
`;
const InputText = styled.input`
	width: 100%;
	font-weight: bold;
	height: 30px;
`;
