import React from 'react';
import { Form } from 'react-bootstrap';

const HeadingTime = ({ heading, onChange }) => {
	const handleChange = e => {
		onChange(e.target.value);
	};

	return <Form.Control type="time" value={heading.content} onChange={handleChange} placeholder={'Enter ' + heading.name} />;
};

export default HeadingTime;
