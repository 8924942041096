import { fetch } from 'utils/fetch';

const getImages = id =>
	fetch({
		endpoint: `/users/imagewidget/`,
		method: 'get',
		errorLabel: 'getImages',
	});

const saveImage = (image, id) => {
	const formData = new FormData();
	formData.append('file', image);
	formData.append('user', id);

	return fetch({
		endpoint: `/users/imagewidget/`,
		method: 'post',
		payload: formData,
		errorLabel: 'saveImage',
	});
};

const deleteImage = id =>
	fetch({
		endpoint: `/users/imagewidget/${id}/`,
		method: 'delete',
		errorLabel: 'deleteImage',
	});

export { getImages, saveImage, deleteImage };
