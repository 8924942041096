const getVideoMetadata = async (id) => {
    const response = await fetch(`https://noembed.com/embed?url=https://www.youtube.com/watch?v=${id}&format=json`);
    if (!response.ok) {
      throw new Error("Failed to fetch video metadata");
    }
    const data = await response.json();
    if (!data.title || !data.thumbnail_url) {
      throw new Error("Invalid video metadata");
    }
    return {
      id: id,
      title: data.title,
      thumbnail: data.thumbnail_url
    };
  };
  
  export { getVideoMetadata };
  