import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { blue, darkGreen, green, grey, navy, red, violet, yellow, purple, loginGreen } from 'styles/colors';

// Define font sizes for different button sizes
const fontSizes = {
	sm: '12px',
	md: '14px',
	lg: '16px',
};

// Define colors for different button themes
const colors = {
	red: { bgColor: red, textColor: 'white' },
	navy: { bgColor: navy, textColor: 'white' },
	green: { bgColor: green, textColor: 'white' },
	darkgreen: { bgColor: darkGreen, textColor: 'white' },
	yellow: { bgColor: yellow, textColor: navy },
	grey: { bgColor: grey, textColor: 'white' },
	blue: { bgColor: blue, textColor: 'white' },
	violet: { bgColor: violet, textColor: 'white' },
	purple: { bgColor: purple, textColor: 'white' },
	loginGreen: { bgColor: loginGreen, textColor: 'white' },
};

// ThemeButton component
export const ThemeButton = forwardRef(
	(
		{
			label, // Text label of the button
			icon: Icon, // Optional icon component
			type = 'button', // Button type
			onClick, // Click handler
			fullWidth = false, // Whether the button should take full width
			color = 'green', // Button color theme
			size = 'md', // Button size
			disabled = false, // Disabled state
			inline = false, // Inline display
			inverted = false, // Inverted color scheme
			tourAnchor, // Optional class for tour anchor
		},
		ref
	) => {
		return (
			<>
				{disabled ? (
					<DisabledBtn ref={ref} fullWidth={fullWidth} color={color} size={size} disabled inverted={inverted}>
						{Icon && (
							<IconWrapper disabled={disabled}>
								<Icon />
							</IconWrapper>
						)}
						{label}
					</DisabledBtn>
				) : (
					<Btn
						ref={ref}
						type={type}
						onClick={onClick}
						fullWidth={fullWidth}
						color={color}
						size={size}
						inline={inline}
						className={tourAnchor || ''}
						inverted={inverted}
					>
						{Icon && (
							<IconWrapper disabled={disabled}>
								<Icon />
							</IconWrapper>
						)}
						{label}
					</Btn>
				)}
			</>
		);
	}
);

// Styled component for enabled button
const Btn = styled.button`
	display: ${props => (props.inline ? 'inline-block' : 'flex')};
	width: ${props => (props.fullWidth ? '100%' : 'auto')};
	align-items: center;
	justify-content: center;
	font-size: ${props => fontSizes[props.size]};
	border: ${props => (props.inverted ? `2px solid ${colors[props.color].bgColor}` : 'none')};
	background: ${props => (props.inverted ? colors[props.color].textColor : colors[props.color].bgColor)};
	color: ${props => (props.inverted ? colors[props.color].bgColor : colors[props.color].textColor)};
	padding: 5px 15px;
	border-radius: 5px;
	position: relative;
	&:after {
		content: '';
		color: ${props => (props.inverted ? colors[props.color].bgColor : colors[props.color].textColor)};
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.2);
		opacity: 0;
		transition: all 0.2s;
	}
	&:hover:after {
		opacity: 1;
	}
`;

// Styled component for disabled button
const DisabledBtn = styled.button`
	display: flex;
	width: ${props => (props.fullWidth ? '100%' : 'auto')};
	align-items: center;
	justify-content: center;
	font-size: ${props => fontSizes[props.size]};
	border: none;
	background: ${props => colors[props.color].bgColor};
	color: ${props => colors[props.color].textColor};
	padding: 5px 15px;
	border-radius: 5px;
	position: relative;
	opacity: 0.7;
`;

// Styled component for icon wrapper
const IconWrapper = styled.span`
	padding-right: 5px;
	display: inline-flex;

	svg {
		fill: ${props => (props.disabled ? 'white' : 'currentColor')};
	}
`;
