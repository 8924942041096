import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import { getTip } from 'services/user';
import { orange } from 'styles/colors';

const Tips = ({ close }) => {
	const [currentTip, setCurrentTip] = useState('');

	const getNewTip = async () => {
		const res = await getTip();
		setCurrentTip(res.data.text);
	};

	useEffect(() => {
		getNewTip();
	}, []);

	return (
		<TipsContainer>
			<CloseButton onClick={close}>
				<FaTimes />
			</CloseButton>
			{currentTip}
			<MoreButton onClick={() => getNewTip()}>More Tips</MoreButton>
		</TipsContainer>
	);
};

export default Tips;

const TipsContainer = styled.div`
	padding: 2rem 1rem;
	position: relative;
	border: 2px solid ${orange};
	border-radius: 0.75rem;
	color: ${orange};
`;

const CloseButton = styled.button`
	background-color: transparent;
	border: none;
	position: absolute;
	top: 0.3rem;
	right: 0.3rem;
	font-size: 1rem;
`;

const MoreButton = styled.button`
	background-color: transparent;
	border: none;
	position: absolute;
	bottom: 0.3rem;
	right: 0.3rem;
	font-size: 1rem;
	color: ${orange};
`;
