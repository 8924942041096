import CommunityModal from 'features/community/CommunityModal';
import NotificationModal from 'features/notifications/NotificationModal';
import PreferenceModal from 'features/preferences/global-preferences/PreferenceModal';
import NotePreferenceModal from 'features/preferences/note-preferences/NotePreferenceModal';
import { useNotifications } from 'hooks';
import { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { FaBell, FaCoffee, FaCogs } from 'react-icons/fa';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { red } from 'styles/colors';
import { isDesignPageState } from '@note/atoms';
import SupportMenu from './support-menu/SupportMenu';

const NavLinks = noteTypeName => {
	const [showPreference, setShowPreference] = useState(false);
	const [showNotePreference, setShowNotePreference] = useState(false);

	const [showCommunity, setShowCommunity] = useState(false);
	const [showNotifications, setShowNotifications] = useState(false);
	const isDesignPage = useRecoilValue(isDesignPageState);
	const { hasUnRead, notifications } = useNotifications();

	const openPreferenceModal = () => setShowPreference(true);
	const closePreferenceModal = () => setShowPreference(false);

	const openNotePreferenceModal = () => setShowNotePreference(true);
	const closeNotePreferenceModal = () => setShowNotePreference(false);

	const openCommunityModal = () => setShowCommunity(true);
	const closeCommunityModal = () => setShowCommunity(false);

	const openNotifications = () => setShowNotifications(true);
	const closeNotifications = () => setShowNotifications(false);

	const urgentNotification = notifications?.find(notif => notif.is_urgent && !notif.is_read.me);

	useEffect(() => {
		urgentNotification && setShowNotifications(true);
	}, []);

	return (
		<>
			<Nav className="h-100">
				<NoteTypeWrapper className="note-type-wrapper">
					<NoteTypeTitle>Note type: </NoteTypeTitle>
					<ActiveNoteType>{noteTypeName.noteTypeName}</ActiveNoteType>
				</NoteTypeWrapper>
				<NavLink onClick={openNotifications}>
					<MenuIcon>
						<FaBell />
					</MenuIcon>
					<NotificationsText>Notifications</NotificationsText>
					{hasUnRead && <NotificationDot />}
				</NavLink>
				<NavLink onClick={openCommunityModal}>
					<MenuIcon>
						<FaCoffee />
					</MenuIcon>
					<span>Community</span>
				</NavLink>
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
					<SupportMenu />
				</div>
				{isDesignPage ? (
					<NavLink onClick={openNotePreferenceModal}>
						<MenuIcon>
							<FaCogs />
						</MenuIcon>
						<span>Note Settings</span>
					</NavLink>
				) : (
					<NavLink onClick={openPreferenceModal} className="preferences-navlink">
						<MenuIcon>
							<FaCogs />
						</MenuIcon>
						<span>Preferences</span>
					</NavLink>
				)}
			</Nav>
			<PreferenceModal show={showPreference} handleClose={closePreferenceModal} />
			<NotePreferenceModal show={showNotePreference} handleClose={closeNotePreferenceModal} />
			<CommunityModal show={showCommunity} handleClose={closeCommunityModal} />
			<NotificationModal
				show={showNotifications}
				handleClose={closeNotifications}
				urgentNotification={urgentNotification}
			/>
		</>
	);
};

export default NavLinks;

const NavLink = styled.a`
	color: #a7a8a9;
	position: relative;
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	padding-left: 20px;
	cursor: pointer;
	& > svg {
		font-size: 18px;
		margin-right: 5px;
	}
	&:hover {
		color: white;
	}
	@media (max-width: 991px) {
		color: #1e2125;
		&:hover {
			color: #1e2125;
			background: #e9ecef;
		}
	}
	@media (min-width: 992px) and (max-width: 1051px) {
		& > span {
			display: none;
		}
	}
`;

const ActiveNoteType = styled.div`
	color: #a7a8a9;
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 20px;
	padding-right: 15px;
	border-right: 1px solid #ffffff50;
	font-weight: 600;

	@media (max-width: 991px) {
		color: #1e2125;
		font-weight: unset;
	}
`;

const NotificationDot = styled.div`
	background: ${red};
	height: 10px;
	width: 10px;
	border-radius: 50%;
	position: absolute;
	right: 2.5px;
	top: 5px;
	border: 1px solid #212529;
	@media (max-width: 991px) {
		left: 6px;
		top: 7px;
	}
`;

const NoteTypeWrapper = styled.div`
	display: flex;
	@media (max-width: 991px) {
		display: none;
	}
`;

const NoteTypeTitle = styled.div`
	margin-left: 20px;
	@media (min-width: 992px) {
		display: none;
	}
`;

const MenuIcon = styled.div`
	margin-right: 5px;
	margin-top: -2px;
	@media (max-width: 991px) {
		display: none;
	}
`;

const NotificationsText = styled.div`
	@media (min-width: 992px) {
		display: none;
	}
`;
