export const green = '#048a2d';
export const darkGreen = '#196229';
export const navy = '#0e386a';
export const yellow = '#ffc107';
export const red = '#dc3545';
export const grey = '#6c757d';
export const blue = '#2773f5';
export const white = '#ffffff';
export const black = '#000000';
export const orange = '#FF7400';
export const violet = '#b277ed';
export const purple = '#7c1ddc';
export const loginGreen = '#22b14c';
