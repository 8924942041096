import { axiosClient } from 'index';

export const fetch = async ({ endpoint, method, payload, errorLabel, options }) => {
	try {
		const response = await axiosClient[method](endpoint, payload, options);
		return response;
	} catch (error) {
		let customEvent;
		if (error.response?.status === 400) {
			customEvent = new CustomEvent('bad-credentials-error', { detail: error });
		} else if (error.response?.status === 401) {
			customEvent = new CustomEvent('unauthorized-error', { detail: error });
		} else if (error.response?.status === 402) {
			customEvent = new CustomEvent('unsubscibed-error', { detail: error });
		} else {
			customEvent = new CustomEvent('general-error', { detail: error });
		}

		window.dispatchEvent(customEvent);
	}
};
