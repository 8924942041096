import React from 'react';
import { ThemeButton } from 'components/ThemeButton';
import { archive } from 'services/noteType';
import { useNote } from 'contexts/NoteContext';

const ArchiveNoteType = ({ noteType }) => {
	const { noteTypes, setNoteTypes } = useNote();

	// Handles the archiving or unarchiving of a note type
	const handleArchive = async () => {
		try {
			// Toggle the archived status of the note type and update the state
			const res = await archive(noteType.id, !noteType.is_archived);
			setNoteTypes(
				noteTypes.map(nt => {
					return nt.id === noteType.id ? { ...nt, is_archived: res.data.is_archived } : nt;
				})
			);
		} catch (err) {
			console.error(err);
		}
	};

	return <ThemeButton onClick={handleArchive} color="green" label={noteType.is_archived ? 'Unarchive' : 'Archive'} />;
};

export default ArchiveNoteType;
