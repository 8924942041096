import React from 'react';
import styled from 'styled-components';

const darknessTypes = {
	light: 0.1,
	moderate: 0.3,
	dark: 0.5,
};

export const Separator = ({ darkness = 'light' }) => <SeparatorLine opacity={darknessTypes[darkness]} />;

const SeparatorLine = styled.hr`
	opacity: ${props => props.opacity};
`;
