import { useNote } from 'contexts/NoteContext';
import NoteTypeTab from 'layouts/my-customizations/NoteTypeTab';
import NoteTypeTabContent from 'layouts/my-customizations/NoteTypeTabContent';
import React, { useContext, useEffect, useState } from 'react';
import { CloseButton, Container, Tab } from 'react-bootstrap';
import { AuthContext } from '@note/contexts/AuthContext';

const MyCustomizations = ({ show }) => {
	const { noteTypes } = useNote();
	const { user } = useContext(AuthContext);
	const [activeNoteType, setActiveNoteType] = useState();

	// Set the initial active note type when noteTypes are loaded
	useEffect(() => {
		if (noteTypes) {
			setActiveNoteType(noteTypes[0]?.id);
		}
	}, [noteTypes]);

	return (
		<>
			<div className="right-pane-wrapper">
				<Container fluid>
					<div className="py-3 d-flex justify-content-between align-items-center">
						<h3>My custom statements</h3>
						<CloseButton onClick={() => show(false)} />
					</div>
					{user && noteTypes && activeNoteType && (
						<Tab.Container defaultActiveKey={activeNoteType}>
							<NoteTypeTab setActiveNoteType={setActiveNoteType} user={user} noteTypes={noteTypes} />
							<NoteTypeTabContent user={user} activeNoteType={activeNoteType} noteTypes={noteTypes} />
						</Tab.Container>
					)}
				</Container>
			</div>
		</>
	);
};

export default MyCustomizations;
