import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Tab as BootstrapTab } from 'react-bootstrap';
import styled from 'styled-components';

import LibrarySubTab from '../subtab/LibrarySubTab';
import LibrarySubTabNav from '../subtab/LibrarySubTabNav';

const LibraryTab = ({
	tab,
	librarySubTabs,
	librarySections,
	libraryStatements,
	libraryStems,
	setLibraryStatements,
	statements,
}) => {
	const [activeSubTab, setActiveSubTab] = useState();
	const [filteredSubTabs, setFilteredSubTabs] = useState([]);
	const [activeLibrarySection, setActiveLibrarySection] = useState();

	useEffect(() => {
		const newSubTabs = librarySubTabs.filter(st => st.tab === tab.id);
		setFilteredSubTabs(newSubTabs);
		if (!activeSubTab) setActiveSubTab(newSubTabs[0]);
	}, [tab, librarySubTabs, activeSubTab]);

	return (
		filteredSubTabs.length > 0 && (
			<BootstrapTab.Container activeKey={activeSubTab ? activeSubTab.name : filteredSubTabs[0].name}>
				<SubmenuContainer>
					<LibrarySubTabNav
						librarySubTabs={librarySubTabs}
						librarySections={librarySections}
						activeSubTab={activeSubTab}
						activeLibrarySection={activeLibrarySection}
						setActiveSubTab={setActiveSubTab}
						setActiveLibrarySection={setActiveLibrarySection}
						tab={tab}
					/>
					<TabContent>
						<AnimatePresence mode="wait">
							{filteredSubTabs.map(
								(subTab, index) =>
									activeSubTab.name === subTab.name && (
										<motion.div
											style={{ height: '100%' }}
											key={index}
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											exit={{ opacity: 0 }}
											transition={{ duration: 0.2 }}
										>
											<TabPane key={index} eventKey={subTab.name}>
												<LibrarySubTab
													subTab={subTab}
													librarySections={librarySections}
													libraryStatements={libraryStatements}
													setLibraryStatements={setLibraryStatements}
													libraryStems={libraryStems}
													statements={statements}
												/>
											</TabPane>
										</motion.div>
									)
							)}
						</AnimatePresence>
					</TabContent>
				</SubmenuContainer>
			</BootstrapTab.Container>
		)
	);
};

export default React.memo(LibraryTab);

const SubmenuContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
`;

const TabContent = styled(BootstrapTab.Content)`
	width: calc(100% - 225px);
	display: inline-block;
	height: 100%;
	background: #f9f9f9;
	padding: 0 5px;
	border-radius: 10px;
	overflow: auto;
`;

const TabPane = styled(BootstrapTab.Pane)`
	padding: 10px;
`;
