import { fetch } from 'utils/fetch';

const getNoteTypes = () =>
	fetch({
		endpoint: `/notes/notetype/`,
		method: 'get',
		errorLabel: 'getNoteTypes',
	});

const getNoteTypeGroups = () =>
	fetch({
		endpoint: `/notes/note-groups/`,
		method: 'get',
		errorLabel: 'getNoteGroups',
	});

const getNoteTypeById = id =>
	fetch({
		endpoint: `/notes/notetype/${id}/`,
		method: 'get',
		errorLabel: 'getNoteTypeById',
	});

const copyNote = (id, name) =>
	fetch({
		endpoint: `/notes/copy-note/${id}/`,
		method: 'post',
		payload: { name: name },
		errorLabel: 'copyNote',
	});

const updateName = (id, name) =>
	fetch({
		endpoint: `/notes/notetype/${id}/`,
		method: 'patch',
		payload: { name: name },
		errorLabel: 'updateNoteType',
	});

const archive = (id, isArchived) =>
	fetch({
		endpoint: `/notes/notetype/${id}/`,
		method: 'patch',
		payload: { is_archived: isArchived },
		errorLabel: 'updateNoteType',
	});

const isNoteReady = id =>
	fetch({
		endpoint: `/notes/is-note-ready/${id}/?${new Date().getTime()}`,
		method: 'get',
		errorLabel: 'isNoteReady',
	});

const pin = id =>
	fetch({
		endpoint: `/notes/notetype/${id}/pin/`,
		method: 'get',
		errorLabel: 'pin',
	});

const deleteNoteType = id =>
	fetch({
		endpoint: `/notes/notetype/${id}/`,
		method: 'delete',
		errorLabel: 'deleteNoteType',
	});

export {
	getNoteTypes,
	getNoteTypeById,
	copyNote,
	isNoteReady,
	pin,
	updateName,
	archive,
	deleteNoteType,
	getNoteTypeGroups,
};
