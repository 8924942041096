import { Draggable } from '@hello-pangea/dnd';
import BulkCheckBox from 'features/bulk-action/BulkCheckBox';
import { useBulkStatements } from 'hooks';
import DeleteConfirmationModal from 'modals/DeleteConfirmationModal';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { WidgetType } from 'utils/enum';

import { isDesignPageState } from '@note/atoms';

import WidgetWrapper from './WidgetWrapper';

// Outtermost element of a statement component

const Statement = ({
	statement,
	index,
	deleteStatement,
	subTabType,
	subTabSettings,
	optionsSpacing,
	removeNewStatement,
	filteredStems,
	saveStatement,
	filteredSections,
}) => {
	useEffect(() => {}, [statement]);
	const isDesignPage = useRecoilValue(isDesignPageState);
	const { isInBulkMode } = useBulkStatements();
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const openDeleteModal = useCallback(() => setShowDeleteModal(true), []);
	const closeDeleteModal = useCallback(() => setShowDeleteModal(false), []);

	const renderWidgetWrapper = () => {
		return (
			<>
				{isInBulkMode && <BulkCheckBox statement={statement} />}
				<WidgetWrapper
					key={statement.id}
					filteredStems={filteredStems}
					statement={statement}
					subTabType={subTabType}
					filteredSections={filteredSections}
					isListLinesSingle={subTabSettings?.listSelect?.isListLinesSingle}
					removeNewStatement={removeNewStatement}
					saveStatement={saveStatement}
					deleteStatement={openDeleteModal}
					subTabSettings={subTabSettings}
				/>
			</>
		);
	};

	return (
		<>
			{statement &&
				(isDesignPage ? (
					<Draggable
						key={statement.id}
						draggableId={statement.id.toString()}
						index={index}
						isDragDisabled={!isDesignPage}
					>
						{(provided, snapshot) => (
							<StatementWrapper
								key={index}
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
								padding={optionsSpacing * 2 - 1 + 'px'}
								marginBottom={optionsSpacing * 2 + 'px'}
							>
								{renderWidgetWrapper()}
							</StatementWrapper>
						)}
					</Draggable>
				) : (
					<StatementWrapper
						key={index}
						padding={optionsSpacing * 2 - 1 + 'px'}
						marginBottom={optionsSpacing * 2 + 'px'}
					>
						{renderWidgetWrapper()}
					</StatementWrapper>
				))}
			{showDeleteModal && (
				<DeleteConfirmationModal
					entityType="statement"
					deleteEntity={deleteStatement}
					show={showDeleteModal}
					id={statement.id}
					name={subTabType === WidgetType.PREFILLED_INPUT ? 'Prefilled form' : statement.name}
					handleClose={closeDeleteModal}
				/>
			)}
		</>
	);
};

export default Statement;

const StatementWrapper = styled.div`
	display: flex;
	width: 100%;
	gap: 5px;
	align-items: center;
	margin-bottom: ${props => props.marginBottom};
	padding: ${props => props.padding} 0;
`;
