import { usePreference } from 'contexts/PreferenceContext';
import { convert } from 'html-to-text';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { PreferenceTypes } from 'utils/enum';

import { editOutputState } from '@note/atoms';

import StatementLine from '../StatementLine';

// Here we build the output paragraphs from the selected statements
const FilteredStatements = ({
	filteredStatements,
	isStemDisabled = false,
	stem,
	subTab,
	getStemContent,
	getStatementName,
	filteredStems,
}) => {
	const selectedStem = filteredStems.find(stem => stem.isSelected);

	const outputStem = getStemContent(stem.key !== (null || '') ? stem : selectedStem);
	const editOutput = useRecoilValue(editOutputState);
	const [editedText, setEditedText] = useState('');
	const { getPreferenceValueBySubTab } = usePreference();

	// Set the initial edited text when outputStem changes
	useEffect(() => {
		outputStem && setEditedText(outputStem);
	}, [outputStem]);

	const handleChange = useCallback(event => setEditedText(event.target.value), []);

	// Get punctuation preferences by subTab
	const punctuation = useMemo(() => {
		return {
			OnePhraseSuffix: getPreferenceValueBySubTab(PreferenceTypes.PUNCTUATION_ONE_PHRASE_STEM_SUFFIX, subTab),
			TwoPhraseSuffix: getPreferenceValueBySubTab(PreferenceTypes.PUNCTUATION_TWO_PHRASE_STEM_SUFFIX, subTab),
			TwoPhraseBeforeFinalItem: getPreferenceValueBySubTab(PreferenceTypes.PUNCTUATION_TWO_BEFORE_FINAL_ITEM, subTab),
			ThreePhraseSuffix: getPreferenceValueBySubTab(PreferenceTypes.PUNCTUATION_THREE_PHRASE_STEM_SUFFIX, subTab),
			ThreePhraseSeparation: getPreferenceValueBySubTab(PreferenceTypes.PUNCTUATION_THREE_PHRASE_SEPARATION, subTab),
			ThreePhraseBeforeFinalItem: getPreferenceValueBySubTab(
				PreferenceTypes.PUNCTUATION_THREE_BEFORE_FINAL_ITEM,
				subTab
			),
		};
	}, [getPreferenceValueBySubTab, subTab]);

	// Render the stem content, either as editable or plain text
	const renderStem = () => {
		return editOutput ? (
			<Editable
				tagName="span"
				id={`${subTab.id}-stem`}
				html={editedText}
				onChange={handleChange}
				className="contentEditable"
			/>
		) : (
			<span id={`${subTab.id}-stem`}>{convert(editedText)}</span>
		);
	};

	return (
		<>
			{/* Render the stem if not disabled */}
			{!isStemDisabled && renderStem()}

			{/* Map through the filtered statements and render each statement line */}
			{filteredStatements.map((statement, index) => (
				<StatementLine
					key={statement.id}
					punctuation={punctuation}
					statementName={getStatementName(statement)}
					length={filteredStatements.length}
					index={index}
					hasPunctuations={!isStemDisabled}
					isTemporary={statement.isTemporary ? true : false}
				/>
			))}
		</>
	);
};

export default FilteredStatements;

const Editable = styled(ContentEditable)`
	&:focus-visible {
		outline: 1px solid #37a4fa;
		border-radius: 3px;
	}
`;
