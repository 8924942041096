import { useNote } from 'contexts/NoteContext';
import { usePreference } from 'contexts/PreferenceContext';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

import RadioInput from '../RadioInput';

// Options for the prompt builder
const promptOptionsList = {
	formatoptions: {
		name: 'formatoptions',
		label: 'Note format',
		values: [
			{
				key: 'narrative',
				text: ' or bullet points. Use the form of a narrative',
			},
			{
				key: 'bullet-points',
				text: '. Use the form of a bullet-points',
			},
		],
	},
	length: {
		name: 'length',
		label: 'Length',
		values: [
			{
				key: 'short / concise',
				text: 'relatively short and concise.',
			},
			{
				key: 'medium',
				text: 'of moderate length.',
			},
			{
				key: 'long / comprehensive',
				text: 'relatively long and comprehensive.',
			},
		],
	},
	style: {
		name: 'style',
		label: 'Note style (Not necessary for physical therapy notes.)',
		values: [
			{
				key: 'SOAP',
				text: 'SOAP note (with headings Subjective; Objective; Assessment; Plan). Be sure to include the interventions used and response to the interventions in the Assessment section and the planned interventions in the Plan section.',
			},
			{
				key: 'BIRP',
				text: 'BIRP note (with headings Behavior; Intervention; Response; Plan) instead of SOAP.',
			},
			{
				key: 'DAP',
				text: 'DAP note (with headings Data; Assessment; Plan) instead of SOAP. Be sure to include the interventions used and the response to interventions in the Data section and the planned interventions in the Plan section.',
			},
			{
				key: 'SIRP',
				text: 'SIRP note (with headings Situation; Intervention; Response; Plan) instead of SOAP.  Be sure to include all assessment inputs in the Situation section.',
			},
			{
				key: 'GIRP',
				text: 'GIRP note (with headings Goals; Intervention; Response; Plan) instead of SOAP.',
			},
			{
				key: 'Couples Therapy note (in development)',
				text: 'Couples Therapy note.',
			},
		],
	},
	pronouns: {
		name: 'pronouns',
		label: 'Pronouns',
		values: [
			{
				key: 'they / them',
				text: 'they/them/theirs pronouns to refer to the client,',
			},
			{
				key: 'she / her',
				text: 'she/her/hers pronouns to refer to the female client, ',
			},
			{
				key: 'he / him',
				text: 'he/him/his pronouns to refer to the male client,',
			},
		],
	},
	terminology: {
		name: 'terminology',
		label: 'Terminology',
		values: [
			{
				key: 'client',
				text: 'client',
			},
			{
				key: 'patient',
				text: 'patient',
			},
			{
				key: 'student',
				text: 'student',
			},
			{
				key: 'person',
				text: 'person',
			},
			{
				key: 'youth',
				text: 'youth',
			},
			{
				key: 'Veteran',
				text: 'Veteran',
			},
			{
				key: 'member',
				text: 'member',
			},
			{
				key: 'service member',
				text: 'service member',
			},
			{
				key: 'couple',
				text: 'couple',
			},
		],
	},
	language: {
		name: 'language',
		label: 'Language',
		values: [
			{
				key: 'english',
				text: 'English',
			},
			{
				key: 'french',
				text: 'French',
			},
			{
				key: 'spanish',
				text: 'Spanish',
			},
			{
				key: 'portuguese',
				text: 'Portuguese',
			},
		],
	},
};

export const PromptBuilder = () => {
	const { setFilledPrompt } = useNote();
	const { multiLevelPreferences } = usePreference();

	// State to manage the selected prompt options
	const [promptOptions, setPromptOptions] = useState({
		formatoptions: { value: ' or bullet points. Use the form of a narrative' },
		style: { value: 'SOAP note (with headings Subjective; Objective; Assessment; Plan)' },
		pronouns: { value: 'they/them/theirs pronouns to refer to the client,' },
		terminology: { value: 'client' },
		length: { value: 'of moderate length.' },
		language: { value: 'English' },
	});

	// Extract the selected values into strings
	const formatString = promptOptions?.formatoptions?.value ? promptOptions.formatoptions.value : '';
	const styleString = promptOptions?.style?.value ? promptOptions.style.value : '';
	const pronounString = promptOptions?.pronouns?.value ? promptOptions.pronouns.value : '';
	const terminologyString = promptOptions?.terminology?.value ? promptOptions.terminology.value : '';
	const lengthString = promptOptions?.length?.value ? promptOptions.length.value : '';
	const languageString = promptOptions?.language?.value ? promptOptions.language.value : '';

	const bulletExp = formatString === '. Use the form of a bullet-points' ? 'Use <li> tags for the bullet-points.' : '';
	const languageExp =
		languageString !== 'English'
			? `Very importantly, as a final step, please output the entire note only after you have translated it into ${languageString} rather than English:`
			: '';

	// Handle changes to the prompt options
	const handleChange = (param, value) => {
		setPromptOptions(prevOptions => ({
			...prevOptions,
			[param]: { value },
		}));
	};

	// Update the filled prompt whenever any of the selected values change
	useEffect(() => {
		const newFilledPrompt = `Rewrite and improve the following psychotherapy SOAP note without using names${formatString} ${styleString} ${bulletExp} Do not include name, date, or other headings at the top of the note, but do place any other headings in html strong tags with html <br /> tags before and after. Make the note ${lengthString} Importantly, be sure to occasionally use ${pronounString} use the term “${terminologyString}” instead of “${multiLevelPreferences.clientTerminology}” occasionally as well.  Be sure to include verbatim the estimated level of risk to self (or other) if it is submitted. Integrate all the following information without adding any new information. ${languageExp}`;

		setFilledPrompt(newFilledPrompt);
	}, [
		styleString,
		pronounString,
		setFilledPrompt,
		formatString,
		terminologyString,
		lengthString,
		languageExp,
		bulletExp,
		multiLevelPreferences.clientTerminology,
	]);

	// Render the choices for a given parameter
	const renderChoices = param => {
		const { label, values } = promptOptionsList[param];
		return (
			<Form.Group style={{ marginBottom: '1rem' }}>
				<h5>{label}</h5>
				{values.map(option => (
					<RadioInput
						key={option.key}
						value={option.text}
						label={option.key}
						currentValue={promptOptions[param]?.value}
						name={option.key}
						handleChange={() => handleChange(param, option.text)}
						checked={promptOptions[param]?.value === option.text}
					/>
				))}
			</Form.Group>
		);
	};

	return (
		<PromptBuilderContainer>
			<Form>
				{renderChoices('formatoptions')}
				{renderChoices('style')}
				{renderChoices('length')}
				{renderChoices('pronouns')}
				{renderChoices('terminology')}
			</Form>
		</PromptBuilderContainer>
	);
};

export default PromptBuilder;

// Styled component for the prompt builder container
const PromptBuilderContainer = styled.div`
	overflow-y: auto;
`;
