export const WidgetType = {
	MANUAL_INPUT: 0,
	LIST_SELECT: 1,
	LIST_MULTI_SELECT: 2,
	PS: 3,
	PSO: 4,
	MULTIPLE_CHOICE: 5,
	PREFILLED_INPUT: 6,
};

export const PSOType = {
	PRIMARY: 0,
	SECONDARY: 1,
	OBJECTIVE: 2,
};

export const InputType = {
	TEXT: 0,
	TEXT_AREA: 1,
	NUMBER: 2,
	DATE: 3,
	SELECT: 4,
	TIME: 5,
};

export const PreferenceTypes = {
	COLOR_OPACITY: 'preferences-color-opacity-selected-statements',
	OPTION_SPACING: 'preferences-spacing-between-options',
	DATE_FORMAT: 'preferences-date-format',
	PRINT_FONT_STYLE: 'preferences-printed-note-font-style',
	PRINT_FONT_SIZE: 'preferences-printed-note-font-size',
	OPENING_HEADING: 'preferences-opening-heading',
	TERMINOLOGY_BEHAVIOR: 'preferences-terminology-behavior',
	TERMINOLOGY_PSYCHODYNAMIC: 'preferences-terminology-psychodynamic',
	TERMINOLOGY_CLIENT: 'preferences-terminology-client',
	BUTTON_SIZE: 'preferences-button-size',
	NOTE_TITLE: 'preferences-note-title',
	NOTE_FORMAT: 'preferences-note-format',
	NOTE_TITLE_VALUE: 'preferences-note-title-value',
	TAB_BREAK: 'preferences-tab-break',
	TAB_HEADING: 'preferences-tab-heading',
	SUBTAB_HEADING: 'preferences-subtab-heading',
	SUBTAB_BREAK: 'preferences-subtab-break',
	PUNCTUATION_ONE_PHRASE_STEM_SUFFIX: 'punctuation-one-phrase-stem-suffix',
	PUNCTUATION_TWO_PHRASE_STEM_SUFFIX: 'punctuation-two-phrase-stem-suffix',
	PUNCTUATION_TWO_BEFORE_FINAL_ITEM: 'punctuation-two-before-final-item',
	PUNCTUATION_THREE_PHRASE_STEM_SUFFIX: 'punctuation-three-phrase-stem-suffix',
	PUNCTUATION_THREE_PHRASE_SEPARATION: 'punctuation-three-phrase-sepation',
	PUNCTUATION_THREE_BEFORE_FINAL_ITEM: 'punctuation-three-before-final-item',
	SIGNATURE_DATE_FORMAT: 'preferences-signature-date-format',
	SIGNATURE_DATE: 'preferences-signature-date',
	SIGNATURE_TIME: 'preferences-signature-time',
	SIGNATURE_START_LINE: 'preferences-signature-start-line',
	SIGNATURE_END_LINE: 'preferences-signature-end-line',
	USE_IMAGES: 'preferences-use-images',
	SOUND_EFFECTS: 'preferences-sound-effects',
	AI_REWRITE: 'preferences-ai-rewrite',
	COLLAPSE_SUBTABS: 'preferences-collapse-subtabs-menu',
	TABS_COLORS: 'preferences-tabs-colors',
	TIPS: 'preferences-tips',
};

export const Defaults = {
	UNCATEGORIZED_SECTION_NAME: 'Other',
	UNCATEGORIZED_SECTION_ORDER: 99999,
	TREATMENT_PLAN_ID: 3,
};

export const DateFormats = {
	'September 23, 1939': 'MMMM DD, YYYY',
	'09/23/1939': 'MM/DD/YYYY',
	'23/09/1939': 'DD/MM/YYYY',
	'1939-09-23': 'YYYY-MM-DD',
	'Saturday, 23 September, 1939': 'dddd, DD MMMM, YYYY',
	'Saturday, September 23, 1939': 'dddd, MMMM DD, YYYY',
	'23 September, 1939': 'DD MMMM, YYYY',
};
