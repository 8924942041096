import { bulkStatementState, isInBukModeState, notificationsState } from 'atoms';
import { useRecoilState } from 'recoil';

export const useBulkStatements = () => {
	const [bulkStatements, setBulkStatements] = useRecoilState(bulkStatementState);
	const [isInBulkMode, setIsInBulkMode] = useRecoilState(isInBukModeState);
	const addStatementToBulk = statement => setBulkStatements([...bulkStatements, statement]);
	const removeBulkStatement = statement => setBulkStatements(bulkStatements.filter(st => st.id === statement.id));
	const clearBulkStatement = () => setBulkStatements([]);
	const toggleBulkMode = () => {
		if (isInBulkMode) clearBulkStatement();
		setIsInBulkMode(!isInBulkMode);
	};

	return {
		bulkStatements,
		addStatementToBulk,
		removeBulkStatement,
		clearBulkStatement,
		toggleBulkMode,
		isInBulkMode,
	};
};

export const useNotifications = () => {
	const [notifications, setNotifications] = useRecoilState(notificationsState);
	const hasUnRead = notifications?.some(nt => !nt.is_read.me);
	return { notifications, setNotifications, hasUnRead };
};
