import React from 'react';
import styled from 'styled-components';

import NoteTypeListItem from './NoteTypeListItem';

const NoteTypeListGroup = ({
	label,
	groupedNoteTypes,
	noteTypeGroups,
	setSelectedNoteType,
	message,
	selectedNoteType,
	canDelete,
}) => {
	const renderlistWithGroups = () => {
		return noteTypeGroups?.map(group => {
			const hasElements = element => element?.group === group?.id;
			return (
				<div key={`${group?.name}-${group.id}`}>
					{groupedNoteTypes.some(hasElements) && <b>{group.name}</b>}
					<div style={{ padding: '1rem' }}>
						{groupedNoteTypes.map(
							(notetype, index) =>
								notetype?.group === group?.id && (
									<NoteTypeListItem
										key={index}
										setSelectedNoteType={setSelectedNoteType}
										selectedNoteType={selectedNoteType}
										canDelete={canDelete}
										notetype={notetype}
									/>
								)
						)}
					</div>
				</div>
			);
		});
	};

	const renderlist = () => {
		return groupedNoteTypes.map((notetype, index) => (
			<NoteTypeListItem
				key={index}
				setSelectedNoteType={setSelectedNoteType}
				selectedNoteType={selectedNoteType}
				canDelete={canDelete}
				notetype={notetype}
			/>
		));
	};
	return (
		<>
			<NoteTypeGroupHeading className="mt-3">{label}</NoteTypeGroupHeading>
			<div style={{ padding: '1rem' }}>
				{groupedNoteTypes && groupedNoteTypes.length > 0 ? (
					noteTypeGroups ? (
						renderlistWithGroups()
					) : (
						renderlist()
					)
				) : (
					<Error>{message}</Error>
				)}
			</div>
		</>
	);
};

export default NoteTypeListGroup;

const NoteTypeGroupHeading = styled.h6`
	opacity: 0.6;
`;

const Error = styled.p`
	font-size: 13px;
	text-align: center;
	background: #f5f5f5;
	padding: 2px 0px;
	border-radius: 3px;
`;
