import React, { useEffect, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { ThemeButton } from 'components/ThemeButton';
import DeleteConfirmationModal from 'modals/DeleteConfirmationModal';
import { createSubTab, deleteSubTab, updateSubTabName } from 'services/subTab';
import { useRecoilState } from 'recoil';
import { subTabsState } from '@note/atoms';

const SubTabModal = ({ show, handleClose, setActiveSubTab, filteredSubTabs, tab, selectedSubTab }) => {
	const [isValid, setIsValid] = useState(true);
	const [name, setName] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [subTabs, setSubTabs] = useRecoilState(subTabsState);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const openDeleteModal = () => setShowDeleteModal(true);
	const closeDeleteModal = () => setShowDeleteModal(false);

	const inputRef = useRef();

	// Automatically focus on the input field when the modal is opened
	useEffect(() => {
		inputRef.current.focus();
	}, []);

	useEffect(() => {
		setIsValid(true);
		if (selectedSubTab) setName(selectedSubTab.name); // Pre-fill with the selected sub-tab name
		else setName(''); // Clear the input if no sub-tab is selected
	}, [selectedSubTab, show]);

	// Function to handle saving the sub-tab (create new or update existing)
	const saveSubTab = async () => {
		if (name.length === 0) {
			setIsValid(false); // Set validation error if the name is empty
			return;
		}
		if (selectedSubTab) {
			await update(); // Update existing sub-tab
		} else {
			await createNew(); // Create new sub-tab
		}
	};

	// Function to delete the selected sub-tab
	const deleteEntity = async () => {
		try {
			await deleteSubTab(selectedSubTab.id); // Delete the sub-tab
			setSubTabs(subTabs.filter(st => st.id !== selectedSubTab.id)); // Remove it from state
			if (filteredSubTabs && filteredSubTabs.length > 0) {
				setActiveSubTab(filteredSubTabs[0]); // Set active sub-tab to the first in the filtered list
			}
			handleClose(); // Close the modal
		} catch (error) {
			console.error('🚀 ~ file: SubTabModal.js ~ line 51 ~ deleteSubTab ~ error', error);
		}
	};

	// Function to create a new sub-tab
	const createNew = async () => {
		try {
			setIsLoading(true); // Set loading state
			const res = await createSubTab({
				order_index:
					filteredSubTabs.length > 0 ? Math.max(...filteredSubTabs.map(subTab => subTab.order_index)) + 1 : 0, // Calculate order index
				name: name,
				tab: tab.id,
			});
			setSubTabs([...subTabs, { ...res.data, isNew: true }]); // Add new sub-tab to state
			setActiveSubTab(res.data); // Set the new sub-tab as active
			setIsLoading(false); // Clear loading state
			handleClose(); // Close the modal
		} catch (error) {
			console.error('🚀 ~ file: SubTabModal.js ~ line 71 ~ createNew ~ error', error);
		}
	};

	// Function to update the selected sub-tab
	const update = async () => {
		try {
			setIsLoading(true); // Set loading state
			const res = await updateSubTabName(selectedSubTab.id, name); // Update sub-tab name
			setSubTabs(subTabs.map(st => (st.id === selectedSubTab.id ? { ...st, name: res.data.name } : st))); // Update state
			setActiveSubTab(res.data); // Set the updated sub-tab as active
			setIsLoading(false); // Clear loading state
			handleClose(); // Close the modal
		} catch (error) {
			console.error('🚀 ~ file: SubTabModal.js ~ line 84 ~ update ~ error', error);
		}
	};

	// Function to handle the delete button click
	const handleDelete = () => {
		openDeleteModal(); // Show the delete confirmation modal
	};

	return showDeleteModal ? (
		selectedSubTab && (
			<DeleteConfirmationModal
				entityType="sub tab"
				deleteEntity={deleteEntity}
				show={showDeleteModal}
				id={selectedSubTab.id}
				name={selectedSubTab.name}
				handleClose={closeDeleteModal}
			/>
		)
	) : (
		<Modal show={show} onHide={handleClose} centered>
			<Modal.Header closeButton>
				<Modal.Title>{selectedSubTab ? 'Edit' : 'New'} Sub Tab</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3">
						<Form.Label>Name your Subtab</Form.Label>
						<Form.Control
							ref={inputRef}
							value={name}
							isInvalid={!isValid} // Show validation error if the name is invalid
							required
							type="text"
							placeholder="Type a Name for your Subtab here (you can change it later)"
							onChange={e => setName(e.target.value)}
							onKeyDown={e => {
								if (e.key === 'Enter') e.preventDefault(); // Prevent form submission on Enter key press
							}}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{selectedSubTab && <ThemeButton label="Delete" size="lg" color="red" onClick={handleDelete} />}
				<ThemeButton
					label={isLoading ? 'Saving...' : selectedSubTab ? 'Update' : 'Create'}
					size="lg"
					color="blue"
					onClick={saveSubTab}
				/>
				<ThemeButton label="Close" icon={FaTimes} size="lg" color="grey" onClick={handleClose} />
			</Modal.Footer>
		</Modal>
	);
};

export default SubTabModal;
