import React from 'react';
import { Modal } from 'react-bootstrap';
import { ThemeButton } from 'components/ThemeButton';

const LearnMoreModal = ({ show, handleClose }) => {

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>About the upgrade</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <p>
                    We are delighted to launch our new upgrade of the online version of Note Designer.
                    This upgrade will include:
                </p>
                <ul>
                    <li>new note-types (e.g., BIRP, DAP, Risk Assessment) </li>
                    <li>new customization tools (e.g., drag-and-drop of phrases and tabs; ability to
                        create entirely new custom sections of content)</li>
                    <li>
                        other new features (e.g., liking and hiding of statements; a signature
                        function)
                    </li>
                </ul>

                <p>
                    This upgrade is free to existing users. There’s nothing you need to do except
                    start a note, and you’ll find that the layout of the program is very familiar so it
                    should not be a major transition. If you wish to keep using the older version for the
                    time being, you can access that via your dashboard. (At some point in the coming
                    month or two we anticipate transferring everyone to the new upgrade.)
                    If you have made customizations before, for example to Basic Note or SOAP note,
                    you will find that these have been transferred over to the new upgraded app. This
                    transfer was done as of December 19, 2022 when we launched the upgrade. If you
                    make customizations going forward they will be specific to the version you are using
                    (for example, customizations in the upgraded version stay in the upgraded version).
                    We have created a number of videos, that demonstrate the new features and
                    provide some tips for using all the new tools. You can find these on the new help
                    page, here: <a href='https://notedesigner.com/app-help-menu'>https://notedesigner.com/app-help-menu</a>
                </p>
                <p>
                    If you have any questions about the transition to this new upgraded program,
                    please contact us at info@notedesigner.com. We hope you enjoy the upgrade!
                    Your Note Designer Team
                </p>

            </Modal.Body>
            <Modal.Footer>
                <ThemeButton label='Close' color='grey' onClick={handleClose} />
            </Modal.Footer>
        </Modal>
    );
};

export default LearnMoreModal;
