import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getFAQ } from 'services/user';
import styled from 'styled-components';

import './faq.scss';

import FAQSection from './FAQSection';

// Frequently asked questions accessed from the help and support menu
const FAQModal = ({ show, handleClose }) => {
	const [FAQItems, setFAQItems] = useState([]); // State to store FAQ items
	const [hideModal, setHideModal] = useState(false); // State to control modal visibility

	// Fetch FAQ data when the modal is shown
	useEffect(() => {
		const fetchData = async () => {
			const res = await getFAQ();
			setFAQItems(res.data);
		};
		fetchData();
	}, [show]);

	return (
		<Modal
			show={show}
			onHide={handleClose}
			centered
			size="xl"
			className={`h-100 ${hideModal ? 'hideModal' : ''}`}
			scrollable
			backdropClassName="backdrop-blur"
		>
			<Modal.Header closeButton>
				<Modal.Title>FAQ</Modal.Title>
			</Modal.Header>
			<ModalBody style={{ padding: 'unset' }}>
				<FAQSection FAQItems={FAQItems} backdropBlur={setHideModal} />
			</ModalBody>
		</Modal>
	);
};

export default FAQModal;

const ModalBody = styled(Modal.Body)`
	@media (max-width: 991px) {
		overflow-y: scroll;
	}
`;
