import { usePreference } from 'contexts/PreferenceContext';
import React, { useCallback, useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { editOutputState } from '@note/atoms';

const TabNoteContent = ({ children, name, id, index, tab }) => {
	const editOutput = useRecoilValue(editOutputState);
	const { multiLevelPreferences } = usePreference();
	const { tabBreak, tabTitle, subTabBreak } = multiLevelPreferences;

	const [isEmpty, setIsEmpty] = useState(true);
	const [editedText, setEditedText] = useState(name);

	// Observe changes to the tab content to update the isEmpty state
	useEffect(() => {
		const targetNode = document.getElementById(name + id);
		const config = { attributes: true, childList: true, subtree: true };

		const callback = mutationList => {
			for (const mutation of mutationList) {
				setIsEmpty(mutation?.target?.childNodes.length === 0);
			}
		};

		const observer = new MutationObserver(callback);
		observer.observe(targetNode, config);
		return () => observer.disconnect();
	}, [id, isEmpty, name]);

	// Handle changes to the edited text
	const handleChange = useCallback(event => setEditedText(event.target.value), []);

	// Render the tab title, allowing for editing if editOutput is enabled
	const renderTitle = () => {
		return editOutput ? (
			<Editable
				tagName="span"
				style={{ fontWeight: '700' }}
				id={name + id + '-title'}
				html={editedText}
				onChange={handleChange}
				className="contentEditable"
			/>
		) : (
			<span style={{ fontWeight: '700' }} id={name + id + '-title'}>
				{editedText}
			</span>
		);
	};

	return (
		<>
			{!isEmpty && (
				<>
					{index !== 0 && tabBreak === 'Show breaks' && subTabBreak !== 'Show breaks' && (
						<div>
							<br />
						</div>
					)}
					{tabTitle === 'Show title' && name && (
						<>
							{renderTitle()}
							<span>: </span>
						</>
					)}
					{tabBreak === 'Show breaks' && tabTitle === 'Show title' && (
						<div>
							<br />
						</div>
					)}
				</>
			)}
			<span id={name + id}>{children}</span>
		</>
	);
};

export default TabNoteContent;

const Editable = styled(ContentEditable)`
	&:focus-visible {
		outline: 1px solid #37a4fa;
		border-radius: 3px;
	}
`;
