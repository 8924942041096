import { ThemeButton } from 'components/ThemeButton';
import { useNote } from 'contexts/NoteContext';
import { usePreference } from 'contexts/PreferenceContext';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useContext, useState, useCallback } from 'react';
import { BsChevronCompactDown } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa';
import styled from 'styled-components';
import { blue } from 'styles/colors';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';

import { AuthContext } from '@note/contexts/AuthContext';

import NoteTypeList from './note-type-list';

const NewNoteMainPageModal = ({ show, handleClose, openManageNoteTypeModal, clearNote }) => {
	const { isOldSafari } = useContext(AuthContext);
	const { globalPreferences } = usePreference();
	const [selectedNoteType, setSelectedNoteType] = useState();
	const { activeNoteType, noteTypes } = useNote();
	const navigate = useNavigate();
	const location = useLocation();
	const tourNote = noteTypes?.find(note => note.is_tour === true && note.user === null);

	// Handles the creation of a new note. It also clears the current note if the selected note type is the same as the active one.
	const handleClick = () => {
		if (activeNoteType?.id === parseInt(selectedNoteType)) {
			clearNote();
		}

		!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.NEWNOTE);

		// Navigate to the new note page with a slight delay to allow for the sound to play
		setTimeout(() => {
			handleClose(); // Close the modal
			window.onbeforeunload = undefined;
			window.location.href = `/main-app?id=${selectedNoteType}`;
		}, 500);
	};

	// Opens a new browser tab with the new note, keeping the current note active
	const handleNewTabClick = () => {
		handleClose(); // Close the modal
		window.open(`/main-app?id=${selectedNoteType}`, '_blank');
	};

	const handleManage = () => {
		handleClose();
		openManageNoteTypeModal();
	};

	// Handles navigation to the guided tour note type
	const onTourClick = useCallback(() => {
		const desiredPath = `/main-app?id=${tourNote.id}`;

		handleClose();
		setSelectedNoteType(tourNote);

		// If the current path is already the tour note, refresh the page
		if (location.pathname + location.search === desiredPath) {
			navigate('/refresh');

			setTimeout(() => navigate(desiredPath), 0);
		} else {
			navigate(desiredPath);
		}
	}, [handleClose, navigate, location, tourNote]);

	return (
		<ModalContainer show={show} onHide={handleClose} centered backdropClassName="backdrop-blur">
			<Modal.Header closeButton>
				<HeaderRow>
					<Modal.Title as="selector-modal-title">
						<>
							Select note-type:
							<div style={{ fontSize: '18px' }}>Scroll down for more</div>
							{tourNote && (
								<span style={{ fontSize: '12px', display: 'inline', lineHeight: 'normal !important' }}>
									{`If you're new here, try out the `}
									<TourButton onClick={() => onTourClick()}>guided tour</TourButton>
									{` to learn about all the Note Designer features`}
								</span>
							)}
						</>
					</Modal.Title>
					<HeaderButton>
						<ThemeButton
							label="Manage your note-types"
							size="md"
							fullWidth={true}
							color="blue"
							onClick={handleManage}
						/>
					</HeaderButton>
				</HeaderRow>
			</Modal.Header>
			<ModalBody>
				<NoteTypeList selectedNoteType={selectedNoteType} setSelectedNoteType={setSelectedNoteType} />
			</ModalBody>
			<Modal.Footer style={{ position: 'relative' }}>
				<BsChevronCompactDown
					fontSize={50}
					style={{ position: 'absolute', left: '50%', top: '-2rem', transform: 'translateX(-50%)' }}
				/>
				<ThemeButton
					disabled={!selectedNoteType}
					label="Discard current note and start new note now"
					icon={FaChevronRight}
					fullWidth={true}
					size="lg"
					color="green"
					onClick={handleClick}
				/>
				<ThemeButton
					disabled={!selectedNoteType}
					label="Keep current note active and start a new note in a new tab"
					fullWidth={true}
					size="lg"
					color="blue"
					onClick={handleNewTabClick}
				/>
				<hr />
			</Modal.Footer>
		</ModalContainer>
	);
};

export default NewNoteMainPageModal;

const ModalContainer = styled(Modal)`
	div.modal-content {
		max-height: 90vh;
	}
`;

const HeaderRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const ModalBody = styled(Modal.Body)`
	overflow-y: auto;
`;

const HeaderButton = styled.div`
	margin-top: 1rem;
	margin-right: 2rem;
	width: fit-content;
`;

const TourButton = styled.div`
	background-color: transparent;
	border: none;
	color: ${blue};
	text-decoration: underline;
	display: inline;
	height: fit-content;
	padding: unset;
	cursor: pointer;
`;
