import React, { useState, useRef } from 'react';
import { Card, Alert } from 'react-bootstrap';
import { ThemeButton } from 'components/ThemeButton';
import ImageCropper from 'components/ImageCropper';
import SignatureImagePreview from './SignatureImagePreview';

const SignatureUpload = ({ setImageDataURL }) => {
	const [imageToCrop, setImageToCrop] = useState(undefined);
	const [croppedImage, setCroppedImage] = useState(undefined);
	const fileInputRef = useRef();

	const onUploadFile = event => {
		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				const image = reader.result;

				setImageToCrop(image);
			});

			reader.readAsDataURL(event.target.files[0]);
		}
	};

	const clear = () => {
		setImageToCrop(undefined);
		setCroppedImage(undefined);
		setImageDataURL(undefined);
		fileInputRef.current.value = null;
	};

	return (
		<div>
			<Alert>
				To upload a signature:
				<ol className="mb-0">
					<li>Choose the image file from your computer.</li>
					<li>Move the crop lines as needed to focus on the signature.</li>
					<li>Scroll down to see a preview.</li>
					<li>Click Save.</li>
				</ol>
			</Alert>
			<Card className="mt-2">
				<Card.Body>
					<input type="file" accept="image/*" ref={fileInputRef} onChange={onUploadFile} />
					<div className="mt-2">
						<ImageCropper
							imageToCrop={imageToCrop}
							setImageDataURL={setImageDataURL}
							onImageCropped={croppedImage => setCroppedImage(croppedImage)}
						/>
					</div>
				</Card.Body>
				{imageToCrop && (
					<Card.Footer className="d-flex justify-content-end">
						<ThemeButton label="Clear above input" size="lg" color="red" onClick={clear} />
					</Card.Footer>
				)}
			</Card>
			<Card className="my-2">
				<Card.Header>Current signature</Card.Header>
				<Card.Body>
					<SignatureImagePreview />
				</Card.Body>
			</Card>

			<Card>
				<Card.Header>Preview of new signature</Card.Header>
				<Card.Body>
					{croppedImage ? (
						<img alt="Cropped Img" src={croppedImage} />
					) : (
						<p style={{ textAlign: 'center', margin: '0' }}>Upload an image to see the preview</p>
					)}
				</Card.Body>
			</Card>
		</div>
	);
};

export default SignatureUpload;
