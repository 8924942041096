import React, { useEffect, useState } from 'react';
import { Tab as BootstrapTab } from 'react-bootstrap';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { subTabsState } from '@note/atoms';
import SubTab from '../subtab';
import SubTabNavPane from '../subtab/subtab-nav/SubTabNavPane';

const Tab = ({ tab }) => {
	const subTabs = useRecoilValue(subTabsState);
	const [activeSubTab, setActiveSubTab] = useState();
	const [activeSection, setActiveSection] = useState();
	const [filteredSubTabs, setFilteredSubTabs] = useState([]);

	// Filter sub-tabs for the selected tab and set the first sub-tab as active if none is active
	useEffect(() => {
		const newSubTabs = subTabs.filter(st => st.tab === tab.id);
		setFilteredSubTabs(newSubTabs);
		if (!activeSubTab) setActiveSubTab(newSubTabs[0]); // Set the first sub-tab as the active one
	}, [tab, subTabs, activeSubTab]);

	// If there are no sub-tabs, return null (render nothing)
	if (filteredSubTabs.length <= 0) {
		return null;
	}

	return (
		<BootstrapTab.Container activeKey={activeSubTab ? activeSubTab.id : filteredSubTabs[0].id}>
			<SubmenuContainer>
				<SubTabNavPane
					activeSubTab={activeSubTab}
					activeSection={activeSection}
					setActiveSubTab={setActiveSubTab}
					setActiveSection={setActiveSection}
					tab={tab}
				/>
				<TabContent>
					{filteredSubTabs.map(
						(subTab, index) =>
							activeSubTab.id === subTab.id && ( // Render content only for the active sub-tab
								<TabPane key={index} eventKey={subTab.id}>
									<SubTab subTab={subTab} />
								</TabPane>
							)
					)}
				</TabContent>
			</SubmenuContainer>
		</BootstrapTab.Container>
	);
};

export default React.memo(Tab);

const SubmenuContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
`;

const TabContent = styled(BootstrapTab.Content)`
	width: calc(100% - 225px);
	display: inline-block;
	height: 100%;
	background: #eaeaea;
	padding: 0 5px;
	border-radius: 10px;
`;

const TabPane = styled(BootstrapTab.Pane)`
	width: 100%;
	padding: 10px;
	padding-right: unset;
`;
