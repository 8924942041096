import { PreferenceTypes } from 'utils/enum';

export const defaultGlobalPreferences = {
	'preferences-color-opacity-selected-statements': '0.3',
	'preferences-spacing-between-options': '1',
	'preferences-date-format': '23 September, 1939',
	'preferences-printed-note-font-style': 'Arial',
	'preferences-printed-note-font-size': '14',
	'preferences-opening-heading': 'Session Note',
	'preferences-terminology-behavior': 'behavior',
	'preferences-terminology-psychodynamic': 'psychodynamic',
	'preferences-terminology-client': 'client',
	'preferences-tab-breaks': 'No tab breaks',
	[PreferenceTypes.NOTE_FORMAT]: 'Narrative',
	[PreferenceTypes.TAB_BREAK]: 'Show breaks',
	[PreferenceTypes.TAB_HEADING]: 'Show title',
	[PreferenceTypes.SUBTAB_HEADING]: 'Hide title',
	[PreferenceTypes.SUBTAB_BREAK]: 'Hide breaks',
	[PreferenceTypes.USE_IMAGES]: 'Hide images',
	[PreferenceTypes.PUNCTUATION_ONE_PHRASE_STEM_SUFFIX]: '',
	[PreferenceTypes.PUNCTUATION_TWO_PHRASE_STEM_SUFFIX]: '',
	[PreferenceTypes.PUNCTUATION_TWO_BEFORE_FINAL_ITEM]: 'and',
	[PreferenceTypes.PUNCTUATION_THREE_PHRASE_STEM_SUFFIX]: ':',
	[PreferenceTypes.PUNCTUATION_THREE_PHRASE_SEPARATION]: ';',
	[PreferenceTypes.PUNCTUATION_THREE_BEFORE_FINAL_ITEM]: '; and',
	[PreferenceTypes.NOTE_TITLE]: 'Hide title',
	[PreferenceTypes.NOTE_TITLE_VALUE]: '',
	[PreferenceTypes.BUTTON_SIZE]: 'Larger',
	[PreferenceTypes.SIGNATURE_DATE]: 'Show date',
	[PreferenceTypes.SIGNATURE_TIME]: 'Show time',
	[PreferenceTypes.SIGNATURE_DATE_FORMAT]: '23 September, 1939',
	[PreferenceTypes.SIGNATURE_START_LINE]: '',
	[PreferenceTypes.SIGNATURE_END_LINE]: '',
	[PreferenceTypes.AI_REWRITE]: 'Disabled',
	[PreferenceTypes.SOUND_EFFECTS]: 'Disabled',
	[PreferenceTypes.TIPS]: 'Show',
	[PreferenceTypes.TABS_COLORS]: 'Purple',
	[PreferenceTypes.COLLAPSE_SUBTABS]: 'Off',
};
