import { useNote } from 'contexts/NoteContext';
import React, { useContext, useMemo } from 'react';
import { AuthContext } from '@note/contexts/AuthContext';
import { green } from 'styles/colors';
import NoteTypeListGroup from './NoteTypeListGroup';

const NoteTypeList = ({ selectedNoteType, setSelectedNoteType, isArchivedVisible = false, isManage = false }) => {
	const { noteTypes, noteTypeGroups } = useNote();
	const { user } = useContext(AuthContext);

	// Filtering the note types to get the ones created by the current user, that are not pinned or archived
	const myNoteTypes = useMemo(() => {
		if (noteTypes && noteTypes.length > 0) {
			return noteTypes.filter(nt => nt.user === user?.id && !nt.is_pinned_by.includes(user?.id) && !nt.is_archived);
		}
		return [];
	}, [noteTypes, user]);

	// Filtering the note types to get those not created by the user, not pinned, not part of the tour, and not archived
	const otherNoteTypes = useMemo(() => {
		if (noteTypes && noteTypes.length > 0) {
			return noteTypes.filter(
				nt => nt.user !== user?.id && nt.is_tour === false && !nt.is_pinned_by.includes(user?.id) && !nt.is_archived
			);
		}
		return [];
	}, [noteTypes, user]);

	// Filtering the note types to get those pinned by the current user and not archived
	const pinnedNoteTypes = useMemo(() => {
		if (noteTypes && noteTypes.length > 0) {
			return noteTypes.filter(nt => nt.is_pinned_by.includes(user?.id) && !nt.is_archived);
		}
		return [];
	}, [noteTypes, user]);

	// Filtering the note types to get the ones that are archived
	const archivedNoteTypes = useMemo(() => {
		if (noteTypes && noteTypes.length > 0) {
			return noteTypes.filter(nt => nt.is_archived);
		}
		return [];
	}, [noteTypes]);

	return (
		<>
			<div style={{ textAlign: 'center', width: '100%' }}>
				<i style={{ color: green, padding: 'unset' }}>AI Rewrite is now available in every note-type!</i>
			</div>
			<NoteTypeListGroup
				isManage={isManage}
				label="Pinned note-types"
				message="Your pinned note-types will show here"
				groupedNoteTypes={pinnedNoteTypes}
				setSelectedNoteType={setSelectedNoteType}
				selectedNoteType={selectedNoteType}
			/>
			<NoteTypeListGroup
				isManage={isManage}
				label="Default note-types"
				groupedNoteTypes={otherNoteTypes}
				message="No note-types found"
				setSelectedNoteType={setSelectedNoteType}
				selectedNoteType={selectedNoteType}
				noteTypeGroups={noteTypeGroups}
			/>
			<NoteTypeListGroup
				isManage={isManage}
				label="My note-types"
				message="No note-types found"
				groupedNoteTypes={myNoteTypes}
				setSelectedNoteType={setSelectedNoteType}
				selectedNoteType={selectedNoteType}
			/>
			{isArchivedVisible && (
				<NoteTypeListGroup
					label="Archived note-types"
					message="No note-types found"
					groupedNoteTypes={archivedNoteTypes}
					setSelectedNoteType={setSelectedNoteType}
					selectedNoteType={selectedNoteType}
					canDelete={true}
				/>
			)}
		</>
	);
};

export default NoteTypeList;
