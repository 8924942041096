import React from 'react'
import { Modal } from 'react-bootstrap';
import { ThemeButton } from 'components/ThemeButton';

const LibraryComingSoonModal = ({ show, handleClose }) => {
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Library</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                If you wish to import additional statements from the library, please first do this while in the standard Note Designer note-type, and THEN start designing your own custom note.
            </Modal.Body>
            <Modal.Footer>
				<ThemeButton label="Close" onClick={handleClose} size="lg" color="grey" />
			</Modal.Footer>
        </Modal>
    )
}

export default LibraryComingSoonModal;

