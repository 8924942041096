import { usePreference } from 'contexts/PreferenceContext';
import React, { useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { subTabsState, tabsState } from '@note/atoms';

import Comment from './Comment';
import HeadingOutput from './HeadingOutput';
import NoteTitle from './NoteTitle';
import OpeningHeading from './OpeningHeading';
import SubTabNoteContent from './SubTabNoteContent';
import TabNoteContent from './TabNoteContent';

const NoteOutput = ({
	isAiNote,
	introComment: introCommentProp,
	setIntroComment,
	closingComment: closingCommentProp,
	setClosingComment,
}) => {
	const tabs = useRecoilValue(tabsState);
	const subTabs = useRecoilValue(subTabsState);
	const noteBody = useRef(null);
	const { multiLevelPreferences } = usePreference();
	const { subTabBreak } = multiLevelPreferences;

	return (
		<>
			<div className="note-content-body">
				<NoteTitle />
				<HeadingOutput />
				<OpeningHeading />
				{!isAiNote && (
					<Comment
						placeholder="[Add Introductory comments here if desired]"
						value={introCommentProp}
						setValue={setIntroComment}
					/>
				)}
				<br />
				{/* Using Section tab below since first-of-type selector cannot be applied to class and there are
				multiple divs in the parent hence for differenciation had to use section */}
				<section ref={noteBody} className="note-body" id="note-body">
					{tabs?.map(({ id, name }, index) => {
						return (
							<TabNoteContent key={id} name={name} id={id} index={index}>
								{subTabs
									.filter(subTab => subTab.tab === id)
									?.map(subTab => (
										<SubTabNoteContent key={subTab.id} subTab={subTab} />
									))}
							</TabNoteContent>
						);
					})}
				</section>

				{subTabBreak === 'Hide breaks' && (
					<div>
						<br />
					</div>
				)}
				{subTabBreak === 'Hide breaks' && (
					<div>
						<br />
					</div>
				)}
				{!isAiNote && (
					<Comment
						placeholder="[Add closing comments here if desired]"
						value={closingCommentProp}
						setValue={setClosingComment}
					/>
				)}
			</div>
		</>
	);
};

export default NoteOutput;
