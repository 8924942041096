import { ThemeButton } from 'components/ThemeButton';
import { usePreference } from 'contexts/PreferenceContext';
import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import { Alert, Modal, Tabs, Tab } from 'react-bootstrap';
import { FaSave, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { createLetterhead, deleteLetterhead, getMyInfo } from 'services/user';
import styled from 'styled-components';
import { blue } from 'styles/colors';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';
import { TextArea } from './styles';

import { AuthContext } from '@note/contexts/AuthContext';

import { blankLetterhead } from './blankLetterhead';
import LetterheadForm from './LetterheadForm';

// Register the image resize module for Quill
Quill.register('modules/imageResize', ImageResize);

const LetterheadModal = ({ show, handleClose }) => {
	const { user, setUser, setShowLetterhead, isOldSafari } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [rtfLetterhead, setRtfLetterhead] = useState(
		user?.letterhead?.type === 'freeform' ? user.letterhead : { type: 'freeform', value: '' }
	);

	const [activeTab, setActiveTab] = useState('formfields');
	const { globalPreferences } = usePreference();

	const initialLetterhead = JSON.parse(JSON.stringify(blankLetterhead));
	const [letterhead, setLetterhead] = useState(
		user?.letterhead && (user.letterhead.type === 'formfields' || !user.letterhead.type)
			? user.letterhead
			: initialLetterhead
	);

	// Set the active tab to 'formfields' when the modal is shown
	useEffect(() => {
		setActiveTab('formfields');
	}, [show]);

	const handleTabSelect = key => {
		setActiveTab(key);
	};

	// Handle changes to the RTF letterhead content
	const handleRtfChange = content => {
		setRtfLetterhead({ type: 'freeform', value: content });
	};

	// Handle saving the letterhead
	const handleSave = useCallback(async () => {
		let saveData;

		if (activeTab === 'formfields') {
			saveData = letterhead;
		} else if (activeTab === 'freeform') {
			saveData = rtfLetterhead;
		}

		if (saveData) {
			setIsLoading(true);
			if (activeTab === 'formfields' && Object.values(letterhead).every(param => param.value === '')) {
				// Delete letterhead if all form fields are empty
				await deleteLetterhead(user);
				const res = await getMyInfo();
				res && setUser({ ...res.data[0], letterhead: null });
				setLetterhead(blankLetterhead);
				setShowLetterhead(false);
			} else {
				// Create or update letterhead
				await createLetterhead(user, saveData);
				const res = await getMyInfo();
				if (res) {
					const userData = res.data[0];
					if (userData.letterhead) {
						if (userData.letterhead.type === 'freeform') {
							setRtfLetterhead(userData.letterhead);
							setLetterhead(initialLetterhead);
							setUser({ ...res.data[0], letterhead: userData.letterhead });
						} else if (userData.letterhead.type === 'formfields' || !userData.letterhead.type) {
							setLetterhead(userData.letterhead);
							setRtfLetterhead({ type: 'freeform', value: '' });
							setUser({ ...res.data[0], letterhead: userData.letterhead });
						}
					} else {
						setLetterhead(initialLetterhead);
						setRtfLetterhead({ type: 'freeform', value: '' });
					}
				}
			}
			setIsLoading(false);
			handleClose();
			!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.SUCCESS);
			toast.success('Letterhead was updated', { hideProgressBar: true });
		}
	}, [
		activeTab,
		letterhead,
		rtfLetterhead,
		handleClose,
		isOldSafari,
		globalPreferences.SoundEffects,
		user,
		setUser,
		setShowLetterhead,
		initialLetterhead,
	]);

	// Handle deleting the letterhead
	const handleDelete = useCallback(async () => {
		if (letterhead) {
			setIsLoading(true);
			await deleteLetterhead(user);
			const res = await getMyInfo();
			res && setUser({ ...res.data[0], letterhead: null });
			setLetterhead(blankLetterhead);
			setRtfLetterhead('');
			setShowLetterhead(false);
			setShowDelete(false);
			setIsLoading(false);
			!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.SUCCESS);
			toast.success('Letterhead was deleted', { hideProgressBar: true });
		}
	}, [letterhead, user, setUser, setShowLetterhead, isOldSafari, globalPreferences.SoundEffects]);

	// Component to render the delete button and confirmation alert
	const DeleteButton = () => {
		return (
			<>
				<Alert show={showDelete} variant="primary" onClose={() => setShowDelete(false)} dismissible>
					<Alert.Heading>Deletion</Alert.Heading>
					<p>Are you certain that you wish to delete your saved letterhead?</p>
					<hr />
					<div className="d-flex justify-content-start">
						<ThemeButton
							type="button"
							label="Delete Letterhead"
							size="md"
							color="blue"
							onClick={() => handleDelete()}
						/>
					</div>
				</Alert>

				{!showDelete && (
					<ThemeButton type="button" label="Delete" size="md" color="blue" onClick={() => setShowDelete(true)} />
				)}
			</>
		);
	};

	const TextModules = {
		toolbar: [
			[{ header: [1, 2, 3, false] }],
			['bold', 'italic', 'underline', 'image'],
			[{ align: ['', 'center', 'right', 'justify'] }],
		],
		imageResize: {
			parchment: Quill.import('parchment'),
			modules: ['Resize', 'DisplaySize'],
		},
	};

	return (
		<FullHeightModal show={show} onHide={handleClose} size="lg" scrollable centered backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>Manage letterhead</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<CustomTabs defaultActiveKey={activeTab} className="mb-3" justify onSelect={handleTabSelect}>
					<FullHeightTab eventKey="formfields" title="Form fields">
						<LetterheadForm letterhead={letterhead} setLetterhead={setLetterhead} />
					</FullHeightTab>
					<FullHeightTab eventKey="freeform" title="Free form">
						<TextArea
							theme="snow"
							value={rtfLetterhead?.value || ''}
							onChange={handleRtfChange}
							modules={TextModules}
						/>
					</FullHeightTab>
				</CustomTabs>
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-between">
				<DeleteButton />
				<div style={{ display: 'flex' }}>
					<ThemeButton label="Close" icon={FaTimes} size="lg" color="grey" onClick={handleClose} />
					<div style={{ marginLeft: '1rem' }}>
						<ThemeButton
							label={isLoading ? 'Saving...' : 'Save letterhead'}
							disabled={isLoading}
							icon={FaSave}
							size="lg"
							onClick={handleSave}
						/>
					</div>
				</div>
			</Modal.Footer>
		</FullHeightModal>
	);
};

export default LetterheadModal;

const FullHeightModal = styled(Modal)`
	div.modal-content,
	div.tab-content {
		height: 100%;
	}
	& .tab-content {
		padding: 0;
	}
`;

const FullHeightTab = styled(Tab)`
	height: 100%;
`;

const CustomTabs = styled(Tabs)`
	flex-direction: row;
	& > li > button {
		padding-right: 10px !important;
		padding-left: 10px !important;
		color: black !important;
	}
	& > li button.active {
		background: ${blue} !important;
		color: white !important;
	}
`;
