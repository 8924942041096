const FontSizeChoice = ({ fontSizes, selectedFontSizes, onFontSizeChange }) => {
    const handleFontSizeChange = (font) => {
      onFontSizeChange(font);
    };
  
    return (
      <div className="choice-format-fonts">
        {fontSizes.map((fontSize) => (
          <button
            key={fontSize}
            className={selectedFontSizes === fontSize ? "active" : ""}
            type="button"
            onClick={() => handleFontSizeChange(fontSize)}
          >
            {fontSize}
          </button>
        ))}
      </div>
    );
  };
  
  export default FontSizeChoice;
  
  