import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';

import NoteReadyModal from './NoteReadyModal';
import SaveAs from './SaveAs';

// To save the customized note accessd via the top right menu in note customization
const SaveAsModal = ({ show, handleClose }) => {
	const [newNote, setNewNote] = useState(null);
	const [showModal, setShowModal] = useState();

	const closeModal = useCallback(() => setShowModal(false), []);

	return (
		<>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Save As</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<SaveAs handleClose={handleClose} setNewNote={setNewNote} setShowModal={setShowModal} />
				</Modal.Body>
			</Modal>
			{showModal && <NoteReadyModal newNote={newNote} show={showModal} handleClose={closeModal} />}
		</>
	);
};

export default SaveAsModal;
