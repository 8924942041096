import { fetch } from 'utils/fetch';

const login = (email, password) =>
	fetch({
		endpoint: `/users/signin/`,
		method: 'post',
		payload: { email, password },
		errorLabel: 'login',
	});

const getQuote = () =>
	fetch({
		endpoint: `/users/quote/`,
		method: 'get',
		errorLabel: 'getQuote',
	});

const logout = () =>
	fetch({
		endpoint: `/users/signout/`,
		method: 'get',
		errorLabel: 'logout',
	});

const getMyInfo = () =>
	fetch({
		endpoint: `/users/user/`,
		method: 'get',
		errorLabel: 'getMyInfo',
	});

const register = (email, password) =>
	fetch({
		endpoint: `/users/signup/`,
		method: 'post',
		payload: { email, password },
		errorLabel: 'register',
	});

const acceptTerms = id =>
	fetch({
		endpoint: `/users/user/${id}/`,
		method: 'patch',
		payload: { has_accepted_terms: true },
		errorLabel: 'acceptTerms',
	});

const getFAQ = () =>
	fetch({
		endpoint: `/users/faq/`,
		method: 'get',
		errorLabel: 'getFAQ',
	});

const getVideos = () =>
	fetch({
		endpoint: `/users/communityvideos/`,
		method: 'get',
		errorLabel: 'getVideos',
	});

const getFeed = () =>
	fetch({
		endpoint: `users/communityfeed/`,
		method: 'get',
		errorLabel: 'getFeed',
	});

const getBlogs = () =>
	fetch({
		endpoint: `/users/communityblog/`,
		method: 'get',
		errorLabel: 'getBlogs',
	});

const createLetterhead = (user, letterhead) => {
	return fetch({
		endpoint: `/users/user/${user.pk}/`,
		method: 'patch',
		payload: { letterhead: letterhead },
		errorLabel: 'createLetterhead',
	});
};

const deleteLetterhead = user => {
	return fetch({
		endpoint: `/users/user/${user.pk}/`,
		payload: { ...user, letterhead: null },
		method: 'patch',
		errorLabel: 'deleteLetterhead',
	});
};

const postFeedback = (id, feedback) =>
	fetch({
		endpoint: `/users/user/${id}/create_feedback/`,
		method: 'post',
		payload: { feedback: feedback },
		errorLabel: 'postFeedback',
	});

const postQuestion = (id, question) =>
	fetch({
		endpoint: `/users/user/${id}/send_question_by_email/`,
		method: 'post',
		payload: { question: question },
		errorLabel: 'postQuestion',
	});

const acceptGlobalAIConsent = id =>
	fetch({
		endpoint: `/users/user/${id}/`,
		method: 'patch',
		payload: { has_accepted_ai_terms: true },
		errorLabel: 'acceptGlobalAITerms',
	});

const acceptAINoteConsent = id =>
	fetch({
		endpoint: `/users/user/${id}/`,
		method: 'patch',
		payload: { has_accepted_gpt_terms: true },
		errorLabel: 'acceptAINoteTerms',
	});

const changeAIConsent = (id, consentValue) =>
	fetch({
		endpoint: `/users/user/${id}/`,
		method: 'patch',
		payload: { has_accepted_ai_terms: consentValue },
		errorLabel: 'acceptAITerms',
	});

const createAvatar = (user, avatarFile) => {
	const formData = new FormData();
	formData.append('avatar', avatarFile);

	return fetch({
		endpoint: `/users/user/${user?.pk}/`,
		method: 'patch',
		payload: formData,
		options: { headers: { 'Content-Type': 'multipart/form-data' } },
		errorLabel: 'createAvatar',
	});
};

const deleteAvatar = user => {
	return fetch({
		endpoint: `/users/user/${user?.pk}/`,
		payload: { ...user, avatar: null },
		method: 'patch',
		errorLabel: 'deleteLetterhead',
	});
};
const getSignature = () =>
	fetch({
		endpoint: `/users/getsignature/`,
		method: 'get',
		errorLabel: 'getSignature',
	});

const createSignature = (userId, signature_image, signature_json) => {
	const formData = new FormData();

	if (signature_image) {
		formData.append('signature_image', signature_image);
	} else if (signature_json) {
		formData.append('signature_json', JSON.stringify(signature_json));
	} else {
		return;
	}

	return fetch({
		endpoint: `/users/signature/${userId}/`,
		method: 'put',
		payload: formData,
		options: {},
		errorLabel: 'createSignature',
	});
};

const deleteSignature = userId => {
	return fetch({
		endpoint: `/users/signature/${userId}/`,
		method: 'delete',
		errorLabel: 'deleteSignature',
	});
};

const getTip = () =>
	fetch({
		endpoint: `/users/tip/random/`,
		method: 'get',
		errorLabel: 'getTip',
	});

export {
	login,
	logout,
	getQuote,
	getSignature,
	getMyInfo,
	register,
	createSignature,
	deleteSignature,
	acceptTerms,
	acceptGlobalAIConsent,
	changeAIConsent,
	createLetterhead,
	deleteLetterhead,
	postFeedback,
	getFAQ,
	postQuestion,
	getVideos,
	getBlogs,
	getFeed,
	acceptAINoteConsent,
	createAvatar,
	deleteAvatar,
	getTip,
};
