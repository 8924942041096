import { ThemeButton } from 'components/ThemeButton';
import { useNote } from 'contexts/NoteContext';
import React, { useCallback, useState } from 'react';
import { BsChevronCompactDown } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { FaChevronRight, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { blue } from 'styles/colors';

import NoteTypeList from './note-type-list';

const NewNoteModal = ({ show, handleClose, openManageNoteTypeModal }) => {
	const { noteTypes } = useNote();
	const [selectedNoteType, setSelectedNoteType] = useState();
	const navigate = useNavigate();

	const handleClick = useCallback(() => {
		if (selectedNoteType) {
			handleClose();
			navigate(`/main-app?id=${selectedNoteType}`);
		}
	}, [handleClose, selectedNoteType, navigate]);

	const handleManage = () => {
		handleClose();
		openManageNoteTypeModal();
	};
	const tourNote = noteTypes?.find(note => note.is_tour === true && note.user === null);

	const onTourClick = useCallback(() => {
		setSelectedNoteType(tourNote);

		handleClose();
		navigate(`/main-app?id=${tourNote.id}`);
	}, [handleClose, navigate, tourNote]);

	return (
		<Modal show={show} onHide={handleClose} centered scrollable className="splash-new-note-modal">
			<Modal.Header closeButton>
				<Modal.Title as="selector-modal-title">
					<>
						<h4>Select note-type:</h4>
						<h3 style={{ fontSize: '18px' }}>Scroll down to see all note-types</h3>
						{tourNote && (
							<span style={{ fontSize: '12px', display: 'inline', lineHeight: 'normal !important' }}>
								{`If you're new here, try out the `}
								<TourButton onClick={() => onTourClick()}>guided tour</TourButton>
								{` to learn about all the Note Designer features`}
							</span>
						)}
					</>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="h-100">
				<NoteTypeList selectedNoteType={selectedNoteType} setSelectedNoteType={setSelectedNoteType} />
			</Modal.Body>
			<Modal.Footer style={{ position: 'relative' }}>
				<BsChevronCompactDown
					fontSize={50}
					style={{ position: 'absolute', left: '50%', top: '-2rem', transform: 'translateX(-50%)' }}
				/>
				{noteTypes && (
					<ThemeButton
						disabled={!selectedNoteType}
						fullWidth={true}
						label="Start the note now"
						icon={FaChevronRight}
						size="lg"
						color="green"
						onClick={handleClick}
					/>
				)}
				<ThemeButton
					label="Manage your note-type list"
					size="lg"
					fullWidth={true}
					color="blue"
					onClick={handleManage}
				/>
				<ThemeButton fullWidth={true} label="Close" icon={FaTimes} size="lg" color="grey" onClick={handleClose} />
			</Modal.Footer>
		</Modal>
	);
};

export default NewNoteModal;

const TourButton = styled.div`
	background-color: transparent;
	border: none;
	color: ${blue};
	text-decoration: underline;
	display: inline;
	height: fit-content;
	padding: unset;
	cursor: pointer;
`;
