import SubTabBreak from 'layouts/note-output/SubTabBreak';
import SubTabTitle from 'layouts/note-output/SubTabTitle';
import { flatten } from 'lodash';
import React, { Fragment, useMemo } from 'react';

import PrefilledStatement from './prefilledStatement';

const PrefilledInputNoteContent = ({ subTab, filteredSections, subTabStatements }) => {
	// Filter statements based on sections
	const filteredStatements = useMemo(
		() => flatten(filteredSections.map(section => subTabStatements.filter(st => st.section === section.id))),
		[filteredSections, subTabStatements]
	);

	// Check if there are any non-empty prefilled text statements
	const isNotEmpty = useMemo(
		() => filteredStatements.some(st => st?.prefilled_text && st.prefilled_text.length > 0),
		[filteredStatements]
	);

	// Filter selected statements
	const selectedStatements = filteredStatements.filter(st => st?.isSelected);

	return selectedStatements.length > 0 ? (
		<div className="prefilled-content">
			<SubTabBreak isVisible={isNotEmpty} />
			<SubTabTitle isVisible={isNotEmpty} title={subTab.name} />
			{selectedStatements.map((statement, index) => (
				<Fragment key={`index-${statement.id}`}>
					<PrefilledStatement statement={statement} isNotEmpty={isNotEmpty} />
				</Fragment>
			))}
		</div>
	) : null;
};

export default PrefilledInputNoteContent;
