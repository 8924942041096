import { fetch } from 'utils/fetch';

const getSectionsByNoteType = id =>
	fetch({
		endpoint: `/notes/sections/?note_type=${id}`,
		method: 'get',
		errorLabel: 'getSectionsByNoteType',
	});

const updateSectionName = (id, name) =>
	fetch({
		endpoint: `/notes/sections/${id}/`,
		method: 'patch',
		payload: { name },
		errorLabel: 'updateSectionName',
	});

const reorderSectionOrder = orders =>
	fetch({
		endpoint: `/notes/sections/reorder/`,
		method: 'post',
		payload: orders,
		errorLabel: 'reorderSectionOrder',
	});

const deleteSection = id =>
	fetch({
		endpoint: `/notes/sections/${id}/`,
		method: 'delete',
		errorLabel: 'deleteSection',
	});

const createSection = section =>
	fetch({
		endpoint: `/notes/sections/`,
		method: 'post',
		payload: section,
		errorLabel: 'createSection',
	});

export { getSectionsByNoteType, updateSectionName, reorderSectionOrder, deleteSection, createSection };
