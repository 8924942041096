import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { ThemeButton } from 'components/ThemeButton';
import React, { useCallback, useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { useRecoilState, useRecoilValue } from 'recoil';
import { reorderSubTabs } from 'services/subTab';
import styled from 'styled-components';
import { updateAndGetNewOrder } from 'utils/dragDropHelper';

import { isDesignPageState, subTabsState } from '@note/atoms';

import SubTabModal from '../SubTabModal';
import SubTabNavItem from './SubTabNavItem';

const SubTabNavPane = ({ tab, activeSubTab, setActiveSubTab, activeSection, setActiveSection }) => {
	const isDesignPage = useRecoilValue(isDesignPageState);
	const [subTabs, setSubTabs] = useRecoilState(subTabsState);
	const [showModal, setShowModal] = useState(false);
	const [selectedSubTab, setSelectedSubTab] = useState(false);
	const [filteredSubTabs, setFilteredSubTabs] = useState([]);

	// Opens the modal to add or edit a sub-tab
	const openModal = useCallback(() => setShowModal(true), []);

	// Filters sub-tabs based on the selected tab
	useEffect(() => {
		setFilteredSubTabs(subTabs.filter(st => st.tab === tab.id));
	}, [tab, subTabs]);

	// Closes the modal and resets the selected sub-tab
	const closeModal = useCallback(() => {
		setSelectedSubTab(null);
		setShowModal(false);
	}, []);

	// Handles adding or editing a sub-tab by opening the modal
	const handleAdd = useCallback(
		subTab => {
			setSelectedSubTab(subTab);
			openModal();
		},
		[openModal]
	);

	// Saves the order of sub-tabs after a drag-and-drop operation
	const saveOrder = useCallback(
		async changedSubTabs => {
			if (changedSubTabs.length === 0) return;
			try {
				await reorderSubTabs({ orders: changedSubTabs });
				setSubTabs(
					subTabs
						.map(st => {
							const updatedSubTab = changedSubTabs.find(changedSt => changedSt.id === st.id);
							return updatedSubTab && updatedSubTab !== -1 ? { ...st, order_index: updatedSubTab.order } : st;
						})
						.sort((a, b) => a.order_index - b.order_index)
				);
			} catch (error) {
				console.error('🚀 ~ file: SubTabNavPane.js ~ line 57 ~ saveOrder ~ error', error);
			}
		},
		[setSubTabs, subTabs]
	);

	// Handles the end of a drag-and-drop operation to update the order of sub-tabs
	const handleDragEnd = useCallback(
		result => {
			const changedSubTabs = updateAndGetNewOrder({ values: filteredSubTabs, setValues: setFilteredSubTabs, result });
			saveOrder(changedSubTabs);
		},
		[filteredSubTabs, saveOrder]
	);

	// Renders the list of sub-tabs
	const renderSubNavItemList = () => {
		return filteredSubTabs.map((subTab, index) => (
			<SubTabNavItem
				key={subTab.id}
				subTab={subTab}
				index={index}
				handleAdd={handleAdd}
				activeSubTab={activeSubTab}
				setActiveSubTab={setActiveSubTab}
				activeSection={activeSection}
				setActiveSection={setActiveSection}
			/>
		));
	};

	return (
		<SubTab variant="pills" className="subtab-navpane">
			{isDesignPage && (
				<DragDropContext onDragEnd={handleDragEnd}>
					<Droppable droppableId="subtab-droppable" direction="vertical">
						{(provided, snapshot) => (
							<span ref={provided.innerRef} {...provided.droppableProps}>
								{renderSubNavItemList()}
								{provided.placeholder}
							</span>
						)}
					</Droppable>
				</DragDropContext>
			)}
			{!isDesignPage && renderSubNavItemList()}
			{isDesignPage && (
				<>
					<AddSubTabBtn>
						<ThemeButton
							label="Add subtab"
							onClick={() => handleAdd(null)}
							icon={FaPlus}
							fullWidth
							size="lg"
							tourAnchor="add-subtab"
						/>
					</AddSubTabBtn>
					{showModal && (
						<SubTabModal
							show={showModal}
							handleClose={closeModal}
							activeSubTab={activeSubTab}
							setActiveSubTab={setActiveSubTab}
							filteredSubTabs={filteredSubTabs}
							selectedSubTab={selectedSubTab}
							tab={tab}
						/>
					)}
				</>
			)}
		</SubTab>
	);
};

export default React.memo(SubTabNavPane);

const SubTab = styled(Nav)`
	width: 225px;
	display: inline-block;
	height: 100%;
	overflow: auto;
`;

const AddSubTabBtn = styled(Nav.Item)`
	margin-top: 5px;
`;
