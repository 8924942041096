import React from 'react';
import styled from 'styled-components';

import EditMode from './EditMode';
import ReadMode from './ReadMode';

export const PrefilledInput = ({
	statement,
	editMode,
	prefilledText,
	statementText,
	setPrefilledText,
	setStatementText,
}) => {
	return (
		<WidgetContainer>
			{editMode ? (
				<EditMode
					editMode={editMode}
					prefilledText={prefilledText}
					setPrefilledText={setPrefilledText}
					statementText={statementText}
					setStatementText={setStatementText}
				/>
			) : (
				<ReadMode statement={statement} />
			)}
		</WidgetContainer>
	);
};

const WidgetContainer = styled.div`
	width: 100%;
`;
