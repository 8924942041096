import React from 'react';
import { Modal, ButtonGroup, ToggleButton, Alert } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import { ThemeButton } from 'components/ThemeButton';
import { navy } from 'styles/colors';
import { useRecoilState } from 'recoil';
import { filtersState } from '@note/atoms';

//Filters to show only liked statements and show/hide the "hidden" statements
const FilterModal = ({ show, handleClose }) => {
	const [filters, setFilters] = useRecoilState(filtersState);

	const toggleFilter = key => {
		setFilters({ ...filters, [key]: !filters[key] });
	};

	return (
		<Modal show={show} onHide={handleClose} centered size="md" className="h-100" scrollable>
			<Modal.Header closeButton>
				<Modal.Title>Filter</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert>
					Here you can choose to display only your liked statements. You can also choose to display only your hidden
					statements in case you wish to recover them.
				</Alert>
				<div>
					<h6>Liked Statements</h6>
					<CustomButtonGroup>
						<ToggleButton
							type="checkbox"
							variant={filters.showLiked === false ? 'success' : 'light'}
							onClick={() => toggleFilter('showLiked')}
						>
							All
						</ToggleButton>
						<ToggleButton
							type="checkbox"
							variant={filters.showLiked === true ? 'success' : 'light'}
							onClick={() => toggleFilter('showLiked')}
						>
							Liked
						</ToggleButton>
					</CustomButtonGroup>
				</div>
				<hr />
				<div>
					<h6>Hidden Statements</h6>
					<CustomButtonGroup>
						<ToggleButton
							type="checkbox"
							variant={filters.showHidden === true ? 'success' : 'light'}
							onClick={() => toggleFilter('showHidden')}
						>
							Hidden
						</ToggleButton>
						<ToggleButton
							type="checkbox"
							variant={filters.showHidden === false ? 'success' : 'light'}
							onClick={() => toggleFilter('showHidden')}
						>
							Unhidden
						</ToggleButton>
					</CustomButtonGroup>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label="Close" icon={FaTimes} size="lg" color="grey" onClick={handleClose} />
			</Modal.Footer>
		</Modal>
	);
};

export default FilterModal;

const CustomButtonGroup = styled(ButtonGroup)`
	display: block;
	& > label.btn-success {
		background: ${navy};
	}
	& > label {
		margin-bottom: 5px;
	}
`;
