import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FaLightbulb } from 'react-icons/fa';
import styled from 'styled-components';

import { navy, yellow } from 'styles/colors';

const Tips = ({ content: Content }) => {
	const [showPopup, setShowPopup] = useState(false);

	const togglePopup = () => setShowPopup(!showPopup);

	return (
		<TipsContainer>
			<OverlayTrigger
				trigger="click"
				show={showPopup}
				placement="top-end"
				overlay={
					<TipsPopover>
						<Popover.Body>
							<Content />
						</Popover.Body>
					</TipsPopover>
				}
			>
				<TipButton onClick={togglePopup}>
					<FaLightbulb /> Tips
				</TipButton>
			</OverlayTrigger>
		</TipsContainer>
	);
};

export default Tips;

const TipsContainer = styled.div`
	position: relative;
`;

const TipsPopover = styled(Popover)`
	max-width: 400px;
	border: 1px solid #ffecb5;
	background: #fff3cd;
	color: #664d03;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), 0 3px 5px rgba(0, 0, 0, 0.1);
	div.popover-arrow::after {
		border-top-color: #fff3cd;
	}
	div.popover-arrow::before {
		border-top-color: #ffecb5;
	}
`;
const TipButton = styled.button`
	position: relative;
	display: inline-block;
	padding: 5px 15px;
	border-radius: 20px;
	align-items: center;
	background: ${yellow};
	color: ${navy};
	cursor: pointer;
	box-shadow: 1px 1px 5px #00000020;
	& svg {
		margin-right: 5px;
	}
	border: none;
`;
