import React, { useState, useEffect } from 'react';
import { FaMicrophone, FaMicrophoneSlash, FaStop } from 'react-icons/fa';
import styled from 'styled-components';
import { grey, red, blue } from 'styles/colors';

const SpeechToText = ({ setter }) => {
	const [isRecording, setIsRecording] = useState(false);
	const [recognition, setRecognition] = useState(null);
	const [isDisabled, setIsDisabled] = useState(false);

	const handleSpeechToggle = () => {
		if (!isRecording) {
			const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
			const recognitionInstance = new SpeechRecognition();
			recognitionInstance.continuous = false;
			recognitionInstance.lang = 'en-US';
			recognitionInstance.interimResults = false;
			recognitionInstance.maxAlternatives = 1;

			recognitionInstance.onresult = event => {
				const transcriptResult = event.results[0][0].transcript;
				setter(transcriptResult);
			};

			recognitionInstance.onerror = event => {
				console.error('Speech recognition error:', event.error);
			};

			recognitionInstance.onend = () => {
				setIsRecording(false);
			};

			recognitionInstance.start();
			setRecognition(recognitionInstance);
			setIsRecording(true);
		} else {
			recognition && recognition.stop();
			setIsRecording(false);
		}
	};

	useEffect(() => {
		//setIsDisabled(true);
	}, []);
	const renderButton = () => {
		if (isDisabled) {
			return (
				<RecordButton color={grey} disabled={true}>
					<FaMicrophoneSlash />
				</RecordButton>
			);
		} else if (isRecording) {
			return (
				<RecordButton color={red} onClick={handleSpeechToggle}>
					<FaStop style={{ fontSize: 9, marginRight: -9, marginTop: -4 }} />
				</RecordButton>
			);
		}
		return (
			<RecordButton color={blue} onClick={handleSpeechToggle} disabled={isDisabled}>
				<FaMicrophone />
			</RecordButton>
		);
	};

	return <div>{renderButton()}</div>;
};

export default SpeechToText;

const RecordButton = styled.button`
	width: 18px;
	height: 18px;
	//padding: 5px;
	border-radius: 50%;
	background: transparent;
	line-height: unset;
	border: ${props => `2px solid ${props.color}`};
	color: ${props => props.color};

	& > svg {
		transform: translate(-50%, -50%);
		margin-right: -11px;
		margin-top: -2px;
		font-size: 11px;
	}
`;
