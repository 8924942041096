import React, { useState } from 'react';

import EditMode from './design-page/EditMode';
import StemSelector from './StemSelector';

const Stem = ({ filteredStems, subTab }) => {
	const [editMode, setEditMode] = useState(false);

	const toggleEditMode = () => {
		setEditMode(!editMode);
	};

	return editMode ? (
		<EditMode subTab={subTab} filteredStems={filteredStems} toggleEditMode={toggleEditMode} />
	) : (
		<StemSelector subTab={subTab} filteredStems={filteredStems} toggleEditMode={toggleEditMode} />
	);
};

export default Stem;
