import { fetch } from 'utils/fetch';

const getTabsByNoteId = id =>
	fetch({
		endpoint: `/notes/tabs/?note_type=${id}`,
		method: 'get',
		errorLabel: 'getTabsByNoteId',
	});

const reorderTabs = orders =>
	fetch({
		endpoint: `notes/tabs/reorder/`,
		method: 'post',
		payload: orders,
		errorLabel: 'reorderTabs',
	});

const deleteTab = id =>
	fetch({
		endpoint: `notes/tabs/${id}/`,
		method: 'delete',
		payload: id,
		errorLabel: 'deleteTab',
	});

const createTab = tab =>
	fetch({
		endpoint: '/notes/tabs/',
		method: 'post',
		payload: tab,
		errorLabel: 'createTab',
	});

const updateTabName = (id, name) =>
	fetch({
		endpoint: `/notes/tabs/${id}/`,
		method: 'patch',
		payload: { name },
		errorLabel: 'updateTabName',
	});

const duplicateTab = (id, noteIds) =>
	fetch({
		endpoint: `/tabs/${id}/duplicate/`,
		method: 'post',
		payload: { ids: noteIds },
		errorLabel: 'createTab',
	});
export { getTabsByNoteId, reorderTabs, deleteTab, createTab, updateTabName, duplicateTab };
