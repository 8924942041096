import React from 'react';
import styled from 'styled-components';

import { WidgetType } from 'utils/enum';

import { green, navy } from 'styles/colors';

const PSOPreview = ({ subTab }) => {
	const data = [
		{
			name: 'Green Salad',
			isSelected: 'a',
		},
		{
			name: 'Vegetable Soup',
			isSelected: null,
		},
		{
			name: 'Fresh fish',
			isSelected: 'm',
		},
		{
			name: 'Cheese Plate',
			isSelected: null,
		},
		{
			name: 'Pie',
			isSelected: 'd',
		},
		{
			name: 'Chicken Fingers',
			isSelected: null,
		},
		{
			name: 'Nut Cake',
			isSelected: null,
		},
	];
	return (
		<>
			<StemContainer>
				<Stem>A - For the appetizer the customer chose: </Stem>
				<Stem>M - For the main course the customer chose:</Stem>
				{subTab.type === WidgetType.PSO && <Stem>D - For the Dessert the customer chose: </Stem>}
			</StemContainer>

			{data.map((item, index) => (
				<StatementContainer key={index}>
					<Btn isSelected={item.isSelected === 'a'}>A</Btn>
					<Btn isSelected={item.isSelected === 'm'}>M</Btn>
					{subTab.type === WidgetType.PSO ? (
						<>
							<Btn isSelected={item.isSelected === 'd'}>D</Btn>
							<Statement selected={item.isSelected}>{item.name}</Statement>
						</>
					) : (
						<Statement selected={item.isSelected && item.isSelected !== 'd'}>{item.name}</Statement>
					)}
				</StatementContainer>
			))}
		</>
	);
};

export default PSOPreview;

const Btn = styled.button`
	border: none;
	padding: 7px 12px;
	margin-right: 5px;
	border-radius: 2px;
	background: ${props => (props.isSelected ? green : '#eee')};
	color: ${props => (props.isSelected ? 'white' : 'black')};
	height: 40px;
`;

const Statement = styled.div`
	width: 100%;
	height: 40px;
	background: ${props => (props.selected ? 'rgba(141, 184, 252, .1)' : '#eee')};
	padding: 7px 12px;
	border: 1px solid ${props => (props.selected ? navy + '70' : '#eee')};
	color: ${props => (props.selected ? navy : 'black')};
	border-radius: 5px;
	margin-bottom: 5px;
`;

const StatementContainer = styled.div`
	display: inline-flex;
	width: 100%;
	height: 45px;
	margin-bottom: 10px;
`;

const Stem = styled.div`
	padding: 0px 5px;
	width: 100%;
	font-weight: bold;
`;

const StemContainer = styled.div`
	margin-bottom: 10px;
`;
