import React from 'react';
import { usePreference } from 'contexts/PreferenceContext';

const SubTabBreak = ({ isVisible }) => {
	const { multiLevelPreferences } = usePreference();
	const { subTabBreak } = multiLevelPreferences;

	return (
		<>
			{isVisible && subTabBreak === 'Show breaks' && (
				<div>
					<br />
				</div>
			)}
		</>
	);
};

export default SubTabBreak;
