import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { FaPencilAlt } from 'react-icons/fa';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { blue } from 'styles/colors';
import { WidgetType } from 'utils/enum';

import { isDesignPageState, stemsState } from '@note/atoms';

import { sortedFilteredStems } from './service';

const StemSelector = ({ subTab, filteredStems, isListMode, toggleEditMode }) => {
	const isDesignPage = useRecoilValue(isDesignPageState);
	const [stems, setStems] = useRecoilState(stemsState);
	const [selectedStemId, setSelectedStemId] = useState(null);

	// Check if the widget type is PS or PSO
	const isPSO = WidgetType.PS === subTab.type || WidgetType.PSO === subTab.type;

	// Get all stems associated with this subTab
	const stemsForThisSubTab = stems.filter(stem => stem.subtab === subTab.id);

	// Find the first stem with this subTab ID
	const firstStemWithSubTabId = stemsForThisSubTab[0];
	// Find the selected stem for this subTab
	const selectedStemForSubTab = stemsForThisSubTab.find(stem => stem.isSelected);

	// Initialize the selected stem when component mounts or when stems change
	useEffect(() => {
		if (!stems) return;

		// If there's a stem with this subTab ID and no selected stem, select the first one
		if (firstStemWithSubTabId && !selectedStemForSubTab) {
			setStems(
				stems.map(stem =>
					stem.id === firstStemWithSubTabId.id
						? { ...stem, isSelected: true }
						: stem.subtab === subTab.id
						? { ...stem, isSelected: false }
						: stem
				)
			);
			setSelectedStemId(firstStemWithSubTabId.id);
		}
	}, [stems, subTab.id, setStems]);

	// Handle changes to stem input fields
	const handleChange = (e, selectedStem) => {
		if (!selectedStem) return;

		const value = e.target.value;

		setStems(
			stems.map(stem => {
				if (stem.id === selectedStem.id) {
					return { ...stem, editedValue: value };
				}
				return stem;
			})
		);
	};

	// Revert edited values of stems back to their original values
	const handleRevert = () => {
		setStems(
			stems.map(stem => {
				return { ...stem, editedValue: stem.value };
			})
		);
	};

	// Handle selection of a stem (for PSO)
	const handleCheckChange = selectedStem => {
		setSelectedStemId(selectedStem.id);

		setStems(
			stems.filter(stem => stem.isSelected).length === 0
				? stems[0]
				: stems.map(stem => {
						if (stem.id === selectedStem.id) {
							return { ...stem, isSelected: true };
						} else if (stem?.subtab && stem.id !== selectedStem.id && stem?.subtab === subTab.id) {
							return { ...stem, isSelected: false };
						}
						return stem;
				  })
		);
	};

	// Render dropdown for stem selection
	const renderStemDropdown = () => {
		return (
			<StyledDropdown
				value={selectedStemForSubTab ? selectedStemForSubTab.id : filteredStems[0].id}
				options={filteredStems.sort((a, b) => a.order_index - b.order_index)}
				optionLabel={option => option.editedValue || option.value}
				optionValue="id"
				editable
				filter
				onChange={e => {
					const selectedStem = filteredStems.find(stem => stem.id === e.value);
					const stemToEdit = selectedStem || filteredStems.find(stem => stem.id === selectedStemId);
					if (stemToEdit) {
						handleChange(e, stemToEdit);
					}
					if (selectedStem) {
						handleCheckChange(selectedStem);
					}
				}}
				className="w-full md:w-14rem"
			/>
		);
	};

	// Render stem selector depending on the mode and stems available
	const renderStemsSelector = () => {
		return filteredStems.length < 1 && isDesignPage ? (
			<StemCard>
				<Card.Body>
					<>
						<ButtonContainer id="stem-btn" className="btn-container">
							<ActionBtn color={blue} onClick={toggleEditMode}>
								<FaPencilAlt />
							</ActionBtn>
						</ButtonContainer>

						{subTab.is_stem_disabled && (
							<p>
								<b>
									Stem is currently disabled. Click (<FaPencilAlt />) and "Enable stem" button to enable it.
								</b>
							</p>
						)}
					</>

					<NoStem>There are currently no stems assigned to this Subtab</NoStem>
				</Card.Body>
			</StemCard>
		) : (
			<StemCard>
				<Card.Body>
					{isDesignPage && (
						<>
							<ButtonContainer id="stem-btn" className="btn-container">
								<ActionBtn color={blue} onClick={toggleEditMode}>
									<FaPencilAlt />
								</ActionBtn>
							</ButtonContainer>

							{subTab.is_stem_disabled && (
								<p>
									<b>
										Stem is currently disabled. Click (<FaPencilAlt />) and "Enable stem" button to enable it.
									</b>
								</p>
							)}
						</>
					)}

					<StemsContainer style={{ marginTop: 'unset' }} isPSO={isPSO}>
						{!isPSO && filteredStems.length > 0 && !subTab.is_stem_disabled && (
							<>
								{renderStemDropdown()}
								<ActionBtn color={blue} onClick={handleRevert}>
									<BsArrowCounterclockwise />
								</ActionBtn>
							</>
						)}
						{isPSO &&
							sortedFilteredStems(filteredStems)
								?.filter(stem => stem.parent === null || stem.parent === undefined)
								.map((stem, index) => (
									<InputContainer key={index}>
										{(WidgetType.PS === subTab.type || WidgetType.PSO === subTab.type) && <span>{stem.key} </span>}
										{isListMode && (
											<Form.Check
												type="radio"
												name={`stemradio-${subTab.id}`}
												id={`usestem-${subTab.id}-${index}`}
												onChange={() => handleCheckChange(stem)}
												checked={stem.isSelected}
											/>
										)}
										<Form.Control
											type="text"
											name="value"
											value={stem.editedValue || stem.value}
											onChange={e => handleChange(e, stem)}
											placeholder="enter stem"
										/>
									</InputContainer>
								))}
					</StemsContainer>
				</Card.Body>
			</StemCard>
		);
	};

	return !isDesignPage && (subTab.is_stem_disabled || filteredStems.length < 1) ? null : renderStemsSelector();
};

export default StemSelector;

const StemCard = styled(Card)`
	position: relative;
	margin-bottom: 10px;
`;

const StyledDropdown = styled(Dropdown)`
	width: 100%;
	margin-right: 0.5rem;
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0.75rem;
`;

const NoStem = styled.div`
	width: 100%;
	margin-right: 0.5rem;
	text-align: center;
`;

const ActionBtn = styled.button`
	position: relative;
	border: none;
	background: ${blue};
	color: white;
	padding: 0 5px 3px;
	border-radius: 3px;
	&:hover {
		transform: scale(1.1);
	}
`;

const StemsContainer = styled.div`
	display: ${props => (!props.isPSO ? 'flex' : 'block')};
`;

const InputContainer = styled.div`
	width: 100%;
	height: 35px;
	margin-top: 5px;
	margin-bottom: 5px;
	display: flex;
	gap: 5px;
	align-items: center;
	position: relative;
	&:hover {
		div#stem-btn {
			display: block;
		}
	}
`;
