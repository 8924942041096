import React, { useCallback } from 'react';
import styled from 'styled-components';

const LibrarySectionList = ({ subTab, filteredSections, setActiveSubTab, activeLibrarySection, setActiveLibrarySection }) => {
	const handleSectionClick = useCallback(
		section => {
			setActiveSubTab(subTab);
			setActiveLibrarySection(section);
		},
		[setActiveLibrarySection, setActiveSubTab, subTab]
	);

	if (filteredSections.length <= 1) {
		return null;
	}

	return (
		<List>
			{filteredSections.map((section, index) => (
				<SectionItem key={index} title={section.name}>
					<SectionLink
						selected={activeLibrarySection?.id === section.id}
						title={section.name}
						href={'#library-section' + section.id}
						onClick={() => handleSectionClick(section)}
					>
						{section.name}
					</SectionLink>
				</SectionItem>
			))}
		</List>
	);
};

export default React.memo(LibrarySectionList);

const List = styled.ul`
	padding: 0;
	margin: 5px 0;
	list-style: none;
	background: white;
`;
const SectionItem = styled.li`
	margin-top: 5px;
`;
const SectionLink = styled.a`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
	text-decoration: none;
	color: black;
	font-weight: 400;
	font-size: 14px;
	padding-left: 13px;
	margin-left: 7px;
	${props =>
		props.selected &&
		`
        border-left: 5px solid black;
        font-weight: bold;
        opacity: 0.9;
        background: #f3f4f7;
      `}
`;
