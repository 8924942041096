/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import styled from 'styled-components';

import { defaultGlobalPreferences } from 'constants';

import { blue } from 'styles/colors';

// Component to display and handle preference options
const Preference = ({
	isDisabled = false, // Whether the preference buttons should be disabled
	preferenceOptions, // The options for the preference
	preferenceKey, // The key for the preference
	filteredPreferences, // The current state of filtered preferences
	setFilteredPreferences, // Function to update the filtered preferences
}) => {
	// Get the current value of the preference
	const preferenceValue = useMemo(() => {
		const preference = filteredPreferences?.find(
			pref =>
				pref.key === preferenceKey &&
				pref.note_type === null &&
				pref.subtab === null &&
				pref.section === null &&
				pref.tab === null
		);
		return preference && preference !== -1 ? preference.value : defaultGlobalPreferences[preferenceKey];
	}, [filteredPreferences, preferenceKey]);

	// Handle the click event for preference options
	const handleClick = prefOption => {
		const changedPreference = filteredPreferences?.find(
			pref =>
				pref.key === preferenceKey &&
				pref.note_type === null &&
				pref.subtab === null &&
				pref.section === null &&
				pref.tab === null
		);
		if (changedPreference && changedPreference !== -1) {
			if (!changedPreference.isNew) {
				setFilteredPreferences(
					filteredPreferences.map(pref =>
						pref.key === preferenceKey &&
						pref.note_type === null &&
						pref.tab === null &&
						pref.subtab === null &&
						pref.section === null
							? { ...pref, value: prefOption, isChanged: true }
							: pref
					)
				);
			} else {
				setFilteredPreferences(
					filteredPreferences.map(pref =>
						pref.key === preferenceKey &&
						pref.note_type === null &&
						pref.tab === null &&
						pref.subtab === null &&
						pref.section === null
							? { ...pref, value: prefOption }
							: pref
					)
				);
			}
		} else {
			setFilteredPreferences([
				...filteredPreferences,
				{
					key: preferenceKey,
					value: prefOption,
					note_type: null,
					tab: null,
					subtab: null,
					section: null,
					isNew: true,
					isChanged: true,
				},
			]);
		}
	};

	return (
		<CustomButtonGroup>
			{preferenceOptions.map((prefOption, index) => (
				<ToggleButton
					key={index}
					disabled={isDisabled}
					type="checkbox"
					variant={prefOption === preferenceValue ? 'success' : 'light'}
					onClick={() => handleClick(prefOption)}
				>
					{prefOption}
				</ToggleButton>
			))}
		</CustomButtonGroup>
	);
};

export default Preference;

const CustomButtonGroup = styled(ButtonGroup)`
	display: block;
	& > label.btn-success {
		background: ${blue};
	}
	& > label {
		margin-bottom: 5px;
	}
`;
