import { navy } from 'styles/colors';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import { Modal } from 'react-bootstrap';

export const CustomModal = styled(({ blurredbackground, ...rest }) => <Modal {...rest} />)`
	div.modal-content {
		height: 100%;
		filter: ${props => (props.blurredbackground ? 'blur(2px)' : 'unset')};
	}
`;

export const SpinnerContainer = styled.div`
	min-width: 60%;
	padding: 1rem;
	margin: 0;
	border: 2px solid grey;
	border-radius: 5px;
	background: white;
	opacity: 85%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 100;
`;

export const SettingsContainer = styled.div`
	height: 100%;
`;

export const AiContentContainer = styled.div`
	height: 100%;
`;

export const NoteBody = styled.div`
	& > p {
		margin: 0;
		padding: 0;
	}
`;

export const ExportContainer = styled.div`
	margin: 0 1rem;
	& > button {
		height: 100%;
	}
`;

export const InstructionsPannel = styled.div`
	border: 2px solid ${navy};
	flex-grow: 1;
	padding: 4rem 1rem 0 1rem;
	border-radius: 5px;
	margin-bottom: 1rem;
`;

export const InstructionElement = styled.li`
	margin-bottom: 1rem;
`;

export const TextArea = styled(ReactQuill)`
	width: 100%;
	margin-top: 1rem;
	.ql-container {
		width: 100%;
		height: 100%;
		border: none;
	}
	.ql-editor {
		padding: 1rem !important;
		background-color: white;
		width: 100%;
		padding: 5px;
		border-radius: 5px;
		border: 2px solid grey;
		overflow: auto;
		${props => props.isbold && 'font-weight: bold'}

		h1 {
			font-size: calc(1.375rem + 1.5vw);
		}
		h2 {
			font-size: calc(1.325rem + 0.9vw);
		}
		h3 {
			font-size: calc(1.3rem + 0.6vw);
		}
	}
	.ql-toolbar {
		background-color: white;
		border-radius: 5px;
		border: 2px solid grey;
		margin-bottom: 0.5rem;
	}
	.ql-align {
		margin-top: -5px;
	}
`;
