import React from 'react';
import styled from 'styled-components';

import { manualInputData } from '../../example-data';

const ManualInputPreview = () => {
	return (
		<>
			{manualInputData.map((item, index) => (
				<div key={index}>
					<label>{item.name}</label>
					<TextInput placeholder="Mango salad"/>
				</div>
			))}
		</>
	);
};

export default ManualInputPreview;

const TextInput = styled.textarea`
	width: 100%;
	outline: none;
	border: 1px solid #00000020;
`;
