// This function replaces specific terminologies within a given text based on the provided terminology mapping.
export const replaceTerminologies = (text, terminology) => {
	let replacedText = text.replace(' ', ' '); // Initializes the replaced text. The replace operation here doesn't change the text.

	// Destructure the different types of terminologies from the provided object
	const { clientTerminology, behaviorTerminology, psychodynamicTerminology } = terminology;

	// Temporarily replace certain terms with placeholder text to avoid conflicts during replacement
	replacedText = replacedText.replace(/outpatient\b/g, 'SPECIALTEXT01');
	replacedText = replacedText.replace(/outpatients\b/g, 'SPECIALTEXT02');
	replacedText = replacedText.replace(/out-patient\b/g, 'SPECIALTEXT03');
	replacedText = replacedText.replace(/out-patients\b/g, 'SPECIALTEXT04');
	replacedText = replacedText.replace(/inpatient\b/g, 'SPECIALTEXT05');
	replacedText = replacedText.replace(/inpatients\b/g, 'SPECIALTEXT06');
	replacedText = replacedText.replace(/in-patient\b/g, 'SPECIALTEXT07');
	replacedText = replacedText.replace(/in-patients\b/g, 'SPECIALTEXT08');
	replacedText = replacedText.replace(/impatient\b/g, 'SPECIALTEXT09');
	replacedText = replacedText.replace(/in-person\b/g, 'SPECIALTEXT10');
	replacedText = replacedText.replace(/with respect to person\b/g, 'SPECIALTEXT11');

	// Replace occurrences of client-related terminologies with the specified client terminology
	if (clientTerminology !== 'client') {
		replacedText = replacedText.replace(/client\b/g, clientTerminology);
		replacedText = replacedText.replace(/clients\b/g, clientTerminology + 's');
	}
	if (clientTerminology !== 'patient') {
		replacedText = replacedText.replace(/patient\b/g, clientTerminology);
		replacedText = replacedText.replace(/patients\b/g, clientTerminology + 's');
	}
	if (clientTerminology !== 'person') {
		replacedText = replacedText.replace(/person\b/g, clientTerminology);
		replacedText = replacedText.replace(/persons\b/g, clientTerminology + 's');
	}
	if (clientTerminology !== 'student') {
		replacedText = replacedText.replace(/student\b/g, clientTerminology);
		replacedText = replacedText.replace(/students\b/g, clientTerminology + 's');
	}
	if (clientTerminology !== 'youth') {
		replacedText = replacedText.replace(/youth\b/g, clientTerminology);
		replacedText = replacedText.replace(/youths\b/g, clientTerminology + 's');
	}
	if (clientTerminology !== 'Veteran') {
		replacedText = replacedText.replace(/Veteran\b/g, clientTerminology);
		replacedText = replacedText.replace(/Veterans\b/g, clientTerminology + 's');
	}
	if (clientTerminology !== 'service member') {
		replacedText = replacedText.replace(/service member\b/g, clientTerminology);
		replacedText = replacedText.replace(/service members\b/g, clientTerminology + 's');
	}

	// Replace occurrences of psychodynamic/psychoanalytic terminologies with the specified terminology
	if (psychodynamicTerminology !== 'psychodynamic') {
		replacedText = replacedText.replace(/psychodynamic\b/g, psychodynamicTerminology);
	}
	if (psychodynamicTerminology !== 'psychoanalytic') {
		replacedText = replacedText.replace(/psychoanalytic\b/g, psychodynamicTerminology);
	}

	// Replace occurrences of behavior/behaviour terminologies with the specified behavior terminology
	if (behaviorTerminology !== 'behaviour') {
		replacedText = replacedText.replace(/behaviour/g, behaviorTerminology);
		replacedText = replacedText.replace(/behaviours/g, behaviorTerminology + 's');
	}
	if (behaviorTerminology !== 'behavior') {
		replacedText = replacedText.replace(/behavior/g, behaviorTerminology);
		replacedText = replacedText.replace(/behaviors/g, behaviorTerminology + 's');
	}

	// Replace the temporary placeholders back to their original terms
	replacedText = replacedText.replace(/SPECIALTEXT01\b/g, 'outpatient');
	replacedText = replacedText.replace(/SPECIALTEXT02\b/g, 'outpatients');
	replacedText = replacedText.replace(/SPECIALTEXT03\b/g, 'out-patient');
	replacedText = replacedText.replace(/SPECIALTEXT04\b/g, 'out-patients');
	replacedText = replacedText.replace(/SPECIALTEXT05\b/g, 'inpatient');
	replacedText = replacedText.replace(/SPECIALTEXT06\b/g, 'inpatients');
	replacedText = replacedText.replace(/SPECIALTEXT07\b/g, 'in-patient');
	replacedText = replacedText.replace(/SPECIALTEXT08\b/g, 'in-patients');
	replacedText = replacedText.replace(/SPECIALTEXT09\b/g, 'impatient');
	replacedText = replacedText.replace(/SPECIALTEXT10\b/g, 'in-person');
	replacedText = replacedText.replace(/SPECIALTEXT11\b/g, 'with respect to person');

	return replacedText;
};
