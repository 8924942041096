import React from 'react';
import { ToggleButton, ButtonGroup } from 'react-bootstrap';
import { blue } from 'styles/colors';
import styled from 'styled-components';

const PreferenceOptions = ({ preferenceOptions, handleClick, handleReset, selectedPreference, hasReset = false }) => {
	return (
		<CustomButtonGroup>
			{preferenceOptions.map((prefOption, index) => (
				<ToggleButton
					key={index}
					type="checkbox"
					variant={prefOption === selectedPreference?.value ? 'success' : 'light'}
					onClick={() => handleClick(prefOption)}
				>
					{prefOption}
				</ToggleButton>
			))}
			{hasReset && selectedPreference && (
				<ResetButton variant="danger" onClick={() => handleReset(selectedPreference)}>
					Reset
				</ResetButton>
			)}
		</CustomButtonGroup>
	);
};

export default PreferenceOptions;

const CustomButtonGroup = styled(ButtonGroup)`
	display: block;
	& > label.btn-success {
		background: ${blue};
	}
	& > label {
		margin-bottom: 5px;
	}
`;

const ResetButton = styled(ToggleButton)`
	border-top-left-radius: 0.25rem !important;
	border-bottom-left-radius: 0.25rem !important;
	margin-left: 5px !important;
`;
