const tourSteps = [
	{
		selector: '.headings-tab',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Tabs</h4>
				<p>
					Each note is made up of a series of Tabs, across the top of the screen. Let’s start with note Headings: Just{' '}
					<b>click the Headings tab</b> now.
				</p>
			</>
		),
		type: 'click',
	},
	{
		selector: '.tour-heading-input',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Headings</h4>
				<p>
					Here you can fill in whatever information is relevant. E.g.,{' '}
					<b>start by filling in the name of a hypothetical client</b> now.
				</p>
			</>
		),
		type: 'input',
	},
	{
		selector: '.note-headings',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Headings</h4>
				<p>
					As you enter text into the Headings tab, your note gets built here on the right. <b>Click next</b> to continue
					our tour.
				</p>
			</>
		),
		disableInteraction: true,
	},
	{
		selector: '.headings-dropdown-toggle',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Headings</h4>
				<p>
					Some Headings will have a dropdown menu like this.{' '}
					<b>Click on the menu and pick whatever item(s) capture what you want to say.</b>
				</p>
			</>
		),
		type: 'click',
	},
	{
		selector: '.headings-dropdown-menu',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Headings</h4>
				<p>
					<b>Select any item</b> now, to continue.
				</p>
			</>
		),
		type: 'select',
	},
	{
		selector: '.subjective-tab',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Tabs</h4>
				<p>
					Now you can start describing the session. Just <b>click the Subjective tab</b> now.
				</p>
			</>
		),
		type: 'click',
	},
	{
		selector: '.subtab-tour',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Subtabs</h4>
				<p>
					Within each Tab, content for the note is organized into Subtabs, such as Affect, Themes Discussed, etc. You
					can complete Tabs and Subtabs in any order. <b>Choose the Themes Discussed subtab</b> now.
				</p>
			</>
		),
		type: 'click',
	},
	{
		selector: '.trauma-section',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Statements</h4>
				<p>
					Within a subtab like Themes Discussed you can pick whatever content describes your session. Choose a few
					statements to see your note get written. <b>Click next</b> when you’re ready to continue.
				</p>
			</>
		),
		position: [window.innerWidth * 0.75, 0],
	},
	{
		selector: '.tour-add-statement',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Statements</h4>
				<p>
					If you’d like to add statements of your own you can do that either temporarily, for just one note, or you can
					save it to your account for use in future notes. <b>Click next</b> to continue
				</p>
			</>
		),
		disableInteraction: true,
	},
	{
		selector: '.navtabs',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Tabs and Subtabs</h4>
				<p>
					Maybe take a moment to look through all the Tabs and Subtabs to get a feel for the note. (Currently the tour
					is exploring a SOAP note template.) Then <b>click Next</b> to continue the tour.
				</p>
			</>
		),
	},
	{
		selector: '.preferences-navlink',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Preferences</h4>
				<p>
					To adjust various settings, such as preferred terminology, date format, or whether to show Tab titles, please
					use the preferences menu. <b>Click “Preferences”</b> (here on the right) now.
				</p>
			</>
		),
		type: 'click',
	},
	{
		selector: '.modal-content',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Preferences</h4>
				<p>
					Scroll through this list of preferences to see the options you can choose from, make a few choices if you
					wish, then <b>click either 'SAVE" or "Discard and Close"</b> to continue
				</p>
			</>
		),
		type: 'hide-controls',
		position: [window.innerWidth * 0.75, 0],
	},
	{
		selector: '.signature-controls',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Signature and Letterhead</h4>
				<p>
					Once you’ve collected the statements you want to include, you’re almost done. You can use these buttons here
					to create and store a digital signature, or some letterhead, which you then can add to your note.{' '}
					<b>Click Next</b> to continue.
				</p>
			</>
		),
		disableInteraction: true,
	},
	{
		selector: '.tour-copy',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Copy</h4>
				<p>
					If your note is the way you want it, you can COPY the note so you can then paste it into a blank Word document
					or into your Electronic Health Records system. <b>Click Next</b> to continue.
				</p>
			</>
		),
		disableInteraction: true,
	},
	{
		selector: '.tour-export',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Export</h4>
				<p>Or you can EXPORT the note so you can either print it out or store it as a pdf on your computer.</p>
			</>
		),
		disableInteraction: true,
	},
	{
		selector: '.tour-finaledit',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Final Edit</h4>
				<p>If you want to do some final editing to the note before exporting, you can use the Final Edit button.</p>
			</>
		),
		disableInteraction: true,
	},
	{
		selector: '.tour-aiedit',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>AI Rewrite</h4>
				<p>
					Or, if the note-type you are using includes the AI-Rewrite function, you can click this button to send the
					non-identified information to be rewritten by AI.
				</p>
			</>
		),
		disableInteraction: true,
	},
	{
		selector: '.tour-clear',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Clear</h4>
				You can Clear all the selections and inputs when you are ready to start over. <b>Try that now</b>.
			</>
		),
		type: 'click',
	},
	{
		selector: '.modal-content',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Clear</h4>
				<p>
					<b>Click yes</b> to clear the note.
				</p>
			</>
		),

		type: 'hide-controls',
	},
	{
		selector: '.chatbot-toggle-button',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Help & Support</h4>
				<p>
					If you have questions at any time you can ask our chatbot Sophie by clicking the orange question mark at the
					bottom right of the screen. You can also use the Support menu at the top of the screen to watch a tutorial, or
					email us your question using the contact menu item.
				</p>
			</>
		),
	},
	{
		selector: '.main-body',
		content: () => (
			<>
				<p>
					Congratulations, you have finished the first part of the tour! The next part of the tour is an introduction to
					the advanced customization features. You may continue with that now, or return to that later by simply going
					through the tour again.
				</p>
				<p>
					New users may prefer to end the tour here and explore the note template on your own. Go ahead and check out
					the functionality of each of the buttons and when you’re ready to start an actual note, click the green + New
					Note button at the top left to get started.
				</p>
			</>
		),
		disableInteraction: true,
		customControl: true,
	},
	{
		selector: '.tour-design',
		content: () => (
			<>
				<h4 style={{ marginTop: '-1.5rem' }}>Advanced Customization</h4>
				<p>
					To customize the default notes or to build your own custom note templates, you click the Customize This
					Note button. This gives you access to our advanced Design Space where you can do things like moving
					statements, moving and editing tabs and subtabs, and creating new tabs, subtabs, and templates.{' '}
					<b>Click the Customize this Note button and then continue with your tour.</b>
				</p>
			</>
		),
		type: 'click',
	},
];

export default tourSteps;
