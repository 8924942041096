import { flatten } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';

import SubTabBreak from '../SubTabBreak';
import SubTabTitle from '../SubTabTitle';
import StatementLine from './StatementLine';

const MultipleChoiceNoteContent = ({ subTab, filteredSections, subTabStatements, getStatementName }) => {
	// Filter statements based on selected options and their section IDs
	const filteredStatements = useMemo(() => {
		return flatten(
			filteredSections.map(section => subTabStatements.filter(st => st.section === section.id && st.selectedOption))
		);
	}, [filteredSections, subTabStatements]);

	// Construct the statement name for multiple choice statements
	const getMultiChoiceStatementName = useCallback(
		statement => {
			const name = getStatementName(statement);
			const statementSplit = name.split('$');
			return statementSplit[0] + ' ' + statement.selectedOption + ' ' + statementSplit[1];
		},
		[getStatementName]
	);

	return (
		<>
			<SubTabTitle isVisible={filteredStatements.length > 0} title={subTab.name} />
			{filteredStatements.map((statement, index) => (
				<Fragment key={index}>
					<StatementLine key={index} hasPunctuations={false} statementName={getMultiChoiceStatementName(statement)} />
					{index === filteredStatements.length - 1 && <SubTabBreak isVisible={true} />}
				</Fragment>
			))}
		</>
	);
};

export default MultipleChoiceNoteContent;
