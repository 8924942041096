import { usePreference } from 'contexts/PreferenceContext';
import dayjs from 'dayjs';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { DateFormats, InputType } from 'utils/enum';

import { headingsState } from '@note/atoms';

// Component to render the headings in the output
const HeadingOutput = () => {
	const headings = useRecoilValue(headingsState);
	const { globalPreferences } = usePreference();

	// Determine the date format based on global preferences
	const dateFormat = DateFormats[globalPreferences.DateFormat]
		? DateFormats[globalPreferences.DateFormat]
		: 'DD MMMM, YYYY';
	let customParseFormat = require('dayjs/plugin/customParseFormat');
	dayjs.extend(customParseFormat);

	// Function to format the value based on its type
	const renderFormattedValue = value => {
		if (value.type === InputType.DATE) return dayjs(value.content, 'YYYY-MM-DD').format(dateFormat);
		else if (value.type === InputType.TIME) return dayjs(value.content, 'hh:mm').format('h:mm A');
		return value.content;
	};

	return (
		<>
			{headings && (
				<div className="note-headings">
					{headings.map(
						(heading, index) =>
							heading.content &&
							heading.content.length > 0 && (
								<p
									style={{ margin: 0 }}
									key={index}
									className={index === 0 ? 'first-output-heading' : ''}
									contentEditable
								>
									<b>{heading.name}: </b>
									<span>{renderFormattedValue(heading)}</span>
								</p>
							)
					)}
					<br />
				</div>
			)}
		</>
	);
};

export default React.memo(HeadingOutput);
