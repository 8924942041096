import React from 'react';
import { Modal } from 'react-bootstrap';

import { ThemeButton } from 'components/ThemeButton';

const VideoModal = ({ show, handleClose, videoType }) => {
	const handleClick = () => {
		window.open(`https://www.youtube.com/watch?v=${videoType === 'welcome' ? 'wqOb-NFPWf0' : 'Oa709eKqbOk'}`, '_blank');
	};

	return (
		<Modal show={show} onHide={handleClose} size="lg" style={{ zIndex: '1300' }}>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<iframe
					width="100%"
					height="315"
					src={`https://www.youtube-nocookie.com/embed/${videoType === 'welcome' ? 'wqOb-NFPWf0' : 'Oa709eKqbOk'}`}
					title="YouTube video player"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				></iframe>
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label="Open video in new tab" size="lg" color="red" onClick={handleClick} />
				<ThemeButton label="Close" size="lg" color="grey" onClick={handleClose} />
			</Modal.Footer>
		</Modal>
	);
};

export default VideoModal;
