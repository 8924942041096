import React, { useEffect } from 'react';
import { Navigate, Routes as ReactRouterRoutes, Route, useNavigate } from 'react-router-dom';
import SignMeUp from '@note/pages/SignMeUp';
import DesignNote from '@note/pages/DesignNote';
import Login from '@note/pages/Login';
import MainApp from '@note/pages/MainApp';
import Splash from '@note/pages/Splash';

const RedirectToLogin = () => <Navigate to="/login" replace />;

export const Routes = () => {
	const navigate = useNavigate();
	const hostname = window.location.origin;
	const isHomePage = window.location.href === `${hostname}/`;
	const user = localStorage.getItem('user');

	useEffect(() => {
		// Redirects to the login page is the stored user data gets dumped
		const handleStorageChange = e => {
			if (e.key === 'user' && e.newValue === null) {
				isHomePage && navigate('/login');
			}
		};
		window.addEventListener('storage', handleStorageChange);
		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, [isHomePage, navigate]);

	return (
		<ReactRouterRoutes>
			<Route path="/sign-me-up" element={<SignMeUp />} />
			<Route path="/login" element={<Login />} />
			<Route path="/" element={!user ? <RedirectToLogin /> : <Splash />} />
			<Route path="/main-app" element={<MainApp />} />
			<Route path="/design-note" element={<DesignNote />} />
		</ReactRouterRoutes>
	);
};
