import { ThemeButton } from 'components/ThemeButton';
import { useNote } from 'contexts/NoteContext';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';

import ImagesUpload from './ImagesUpload';

// Modal called when the user wants to upload an image
const ImagesUploadModal = ({ show, handleClose, isDesignPage }) => {
	const { noteImagesUpload, setNoteImagesUpload } = useNote();
	const [hasInvalidFiles, setHasInvalidFiles] = useState(false);
	const [clearAll, setClearAll] = useState(() => () => {});
	const [uploadAll, setUploadAll] = useState(() => () => {});

	const updateClearAll = newClearAll => {
		setClearAll(() => newClearAll);
	};

	const updateUploadAll = newUploadAll => {
		setUploadAll(() => newUploadAll);
	};

	const closeModal = () => {
		setNoteImagesUpload([]);
		handleClose();
		setHasInvalidFiles(false);
	};

	return (
		<FullHeightModal show={show} onHide={closeModal} size="md" scrollable centered backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>Upload images</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{hasInvalidFiles && (
					<div style={{ color: 'red' }}>You cannot upload non-image files or unsupported images types.</div>
				)}
				<ImagesUpload
					updateClearAll={updateClearAll}
					updateUploadAll={updateUploadAll}
					isDesignPage={isDesignPage}
					onInvalidFileType={setHasInvalidFiles}
				/>
			</Modal.Body>
			<Modal.Footer>
				{noteImagesUpload?.length > 0 && (
					<>
						<ThemeButton label="Upload" size="lg" color="green" onClick={uploadAll} disabled={hasInvalidFiles} />
						<ThemeButton label="Clear above images" size="lg" color="red" onClick={clearAll} />
					</>
				)}
				<ThemeButton label="Close" icon={FaTimes} size="lg" color="grey" onClick={closeModal} />
			</Modal.Footer>
		</FullHeightModal>
	);
};

export default ImagesUploadModal;

const FullHeightModal = styled(Modal)`
	div.modal-content,
	div.tab-content {
		height: 100%;
	}
	& .tab-content {
		padding: 0;
	}
`;
