import SubTabBreak from 'layouts/note-output/SubTabBreak';
import SubTabTitle from 'layouts/note-output/SubTabTitle';
import { flatten } from 'lodash';
import React, { useMemo } from 'react';

import FilteredStatements from './FilteredStatements';

const ListNoteContent = ({
	subTab,
	subTabStatements,
	filteredStems,
	filteredSections,
	getStemContent,
	getStatementName,
}) => {
	// Check if the subTab contains statements with the given stemId
	const doesSubTabContain = stemId => subTabStatements.some(st => st.stem === stemId);

	// Memoize the filtered statements to optimize performance
	const filteredStatements = useMemo(() => {
		const stemValues = filteredStems.map(stem => stem.id);
		return flatten(
			filteredSections.map(section =>
				subTabStatements.filter(st => st.section === section.id && stemValues.includes(st.stem))
			)
		);
	}, [filteredSections, subTabStatements, filteredStems]);

	// Sort stems based on order_index
	const sortedStems = filteredStems.sort((a, b) => {
		return a.order_index - b.order_index;
	});

	return (
		<>
			{/* Render the subTab title if there are filtered statements */}
			<SubTabTitle isVisible={filteredStatements.length > 0} title={subTab.name} />
			{/* Render filtered stems and their corresponding statements */}
			{filteredStems &&
				sortedStems.map(
					(stem, index) =>
						doesSubTabContain(stem.id) && (
							<React.Fragment key={stem.id}>
								<FilteredStatements
									key={index}
									subTab={subTab}
									stem={stem}
									filteredStems={filteredStems}
									isStemDisabled={subTab.is_stem_disabled}
									filteredStatements={filteredStatements.filter(st => st.stem === stem.id)}
									getStemContent={getStemContent}
									getStatementName={getStatementName}
								/>
								<SubTabBreak isVisible={true} />
							</React.Fragment>
						)
				)}
		</>
	);
};

export default ListNoteContent;
