import { isNoteReady, copyNote } from 'services/noteType';

const checkIsNoteReady = async noteId => {
	let intervalId;
	return await new Promise((resolve, reject) => {
		// Set an interval to repeatedly check if the note is ready
		intervalId = setInterval(async () => {
			try {
				const res = await isNoteReady(noteId); // Make a request to check if the note is ready
				if (res.data.is_ready) {
					resolve();
					clearInterval(intervalId);
				}
			} catch (error) {
				reject();
				console.error('🚀 ~ file: save-as.js ~ line 9 ~ checkIsNoteReady ~ error', error);
			}
		}, 3000); // Check every 3 seconds
	});
};

// Function to save a note type by copying it and checking if it's ready
export const saveNoteType = async (noteTypeId, name) => {
	try {
		const response = await copyNote(noteTypeId, name);
		const noteId = response.data.id;
		await checkIsNoteReady(noteId);
		return response.data;
	} catch (error) {
		console.error('🚀 ~ file: SaveAs.js ~ line 38 ~ handleSave ~ error', error);
	}
};
