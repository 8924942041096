import styled from 'styled-components';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { FaRegLightbulb } from 'react-icons/fa';
import PromptBuilder from 'components/prompt-builder/PromptBuilder';
import AiPlusPromptBuilder from 'components/prompt-builder/AIPlusPromtBuilder';
import GlobalAiPromtBuilder from 'components/prompt-builder/GlobalAiPromtBuilder';
import { useNote } from 'contexts/NoteContext';

//Displayed in the ai settings tab
const AISettings = () => {
	const { activeNoteType } = useNote();
	return (
		<>
			<SettingsContainer>
				<div>
					{activeNoteType?.is_ai_plus_note ? (
						<AiPlusPromptBuilder />
					) : activeNoteType?.is_ai_note ? (
						<PromptBuilder />
					) : (
						<GlobalAiPromtBuilder />
					)}
				</div>
			</SettingsContainer>
			<Alert variant="warning" style={{ marginTop: 10 }}>
				<div style={{ color: 'black', fontSize: 12 }}>
					<b>
						<span>
							<FaRegLightbulb style={{ transform: 'translateY(-2px)' }} />
						</span>{' '}
						Important
					</b>
					<p style={{ marginTop: 10 }}>
						AI Language models may sometimes produce unexpected, incomplete or inaccurate outputs. You can check your
						note for accuracy and make edits before copying or exporting.
					</p>
				</div>
			</Alert>
		</>
	);
};

export default AISettings;

const SettingsContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;
