import { ThemeButton } from 'components/ThemeButton';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Card, Form } from 'react-bootstrap';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { useRecoilState } from 'recoil';
import { createStem, deleteStem, updateStem } from 'services/stem';
import { updateSubTabStemStatus } from 'services/subTab';
import styled from 'styled-components';
import { red } from 'styles/colors';
import { WidgetType } from 'utils/enum';

import { stemsState, subTabsState } from '@note/atoms';

import { sortedFilteredStems } from '../service';
import StemFooter from './StemFooter';

const EditMode = ({ subTab, toggleEditMode, filteredStems }) => {
	const [stemList, setStemList] = useState([]);
	const [subTabs, setSubTabs] = useRecoilState(subTabsState);
	const [stems, setStems] = useRecoilState(stemsState);
	const isPSO = WidgetType.PS === subTab.type || WidgetType.PSO === subTab.type;
	const newStemRef = useRef(null);

	useEffect(() => {
		if (filteredStems) {
			// Initialize stemList with filteredStems and set default values
			const updatedStemList = filteredStems.map((stem, index) => ({
				...stem,
				tempValue: stem.value,
				editedKey: stem.key,
				order_index: stem.order_index !== null ? stem.order_index : index + 1,
			}));
			setStemList(updatedStemList);
		}
	}, [filteredStems]);

	// Check if any stem has an empty tempValue
	const emptyStemExists = useMemo(() => {
		return stemList.some(stem => stem.tempValue === '');
	}, [stemList]);

	// Handle input change for stems
	const handleChange = (e, selectedStem) => {
		setStemList(currentStems =>
			currentStems.map(stem => (stem === selectedStem ? { ...stem, [e.target.name]: e.target.value } : stem))
		);
	};

	// Cancel edits and reset stemList to its original state
	const handleCancel = () => {
		setStemList(filteredStems.map(stem => ({ ...stem, tempValue: stem.value, editedKey: stem.key })));
		toggleEditMode();
	};

	// Add a new stem to the list
	const handleAddStem = () => {
		const maxOrderIndex = Math.max(...stemList.map(stem => (stem.order_index !== null ? stem.order_index : 0)));

		const newStem = {
			editedKey: '',
			id: null,
			key: '',
			old_id: null,
			order_index: maxOrderIndex + 1, // Assign next order index
			subtab: subTab.id,
			tempValue: '',
			user: null,
			value: '',
			was_default: false,
			isAdded: true,
		};

		setStemList(currentStemList => [...currentStemList, newStem]);

		setTimeout(() => {
			newStemRef.current && newStemRef.current.focus(); // Focus on the new stem input field
		}, 0);
	};

	// Save the changes made to the stems
	const handleSave = async () => {
		let changedStems = stemList.filter(stem => {
			return stem.tempValue !== stem.value || stem.key !== stem.editedKey;
		});

		if (changedStems && changedStems?.length > 0) {
			changedStems = changedStems.map(stem => ({ ...stem, value: stem.tempValue, key: stem.editedKey }));
			const promises = changedStems.map(stem => (stem.id ? updateStem(stem.id, stem) : createStem(stem)));

			const res = await Promise.all(promises);
			const results = res.map(r => r.data);

			const updatedStems = stems.map(stem => {
				const changedStem = results.find(resultItem => resultItem.id === stem.id);
				if (changedStem && changedStem !== -1) {
					return changedStem;
				}
				return stem;
			});

			const newStems = results.filter(resultItem => !updatedStems.some(stem => stem.id === resultItem.id));
			setStems([...updatedStems, ...newStems]);
		}
		toggleEditMode();
	};

	// Delete a stem from the list
	const handleDelete = async stemToDelete => {
		if (stemList.length === 1) {
			toggleStem(); // Disable stems if only one is left and it's deleted
		} else {
			if (stemToDelete.id) {
				await deleteStem(stemToDelete.id); // Delete stem from server if it exists
			}

			const updatedStemList = stemList.filter(stem => stem !== stemToDelete);

			setStemList(updatedStemList);

			if (updatedStemList.length < 1) {
				toggleStem(); // Disable stems if no stems remain
				return;
			}

			if (stemToDelete.id) {
				setStems(prevStems => prevStems.filter(stem => stem.id !== stemToDelete.id));
			}
		}
	};

	// Toggle the stem enable/disable status
	const toggleStem = useCallback(async () => {
		const is_stem_disabled = !subTab.is_stem_disabled;

		try {
			setSubTabs(
				subTabs.map(st => {
					if (st.id === subTab.id) {
						return { ...st, is_stem_disabled };
					}
					return st;
				})
			);

			await updateSubTabStemStatus(subTab.id, is_stem_disabled);
		} catch (error) {
			console.error('🚀 ~ file: ToggleSwitch.js ~ line 34 ~ toggleStem ~ error', error);
		}
	}, [setSubTabs, subTab, subTabs]);

	const renderInput = (stem, index) => {
		return (
			<>
				{isPSO && (
					<KeyInput
						disabled={subTab.is_stem_disabled}
						name="editedKey"
						value={stem.editedKey}
						isInvalid={!stem.editedKey && stem.editedKey?.length <= 0}
						maxLength="1"
						onChange={e => handleChange(e, stem)}
						type="text"
					/>
				)}

				<Form.Control
					key={`stemid-${index}`}
					disabled={subTab.is_stem_disabled}
					type="text"
					name="tempValue"
					isInvalid={!stem.tempValue && stem?.tempValue?.length <= 0}
					value={stem.tempValue}
					ref={stem.isAdded ? newStemRef : null}
					onChange={e => handleChange(e, stem)}
					placeholder="enter stem"
				/>

				{!isPSO && (
					<BtnContainer>
						<ActionBtn valid={true} onClick={() => handleDelete(stem)} style={{ color: red }}>
							<FaTrashAlt />
						</ActionBtn>
					</BtnContainer>
				)}
			</>
		);
	};

	return (
		<StemCard>
			<Card.Body>
				{subTab.is_stem_disabled && (
					<p>
						<b>Stem is currently disabled. If you want to enable it, click on the "Enable stem" button</b>
					</p>
				)}
				{!subTab.is_stem_disabled &&
					sortedFilteredStems(stemList).map((stem, index) => (
						<InputContainer key={`stem-${index}`}>{renderInput(stem, index)}</InputContainer>
					))}
				{!subTab.is_stem_disabled && stemList.length === 1 && (
					<Alert show={true} variant="primary">
						<p>
							If you do not wish to have a stem, use the Disable Stem button below. Deleting the last stem will
							automatically disable the stems feature. You will be able to enable it by clicking the "Enable Stem"
							button.
						</p>
					</Alert>
				)}
				<ButtonContainer>
					{!isPSO && !subTab.is_stem_disabled && <ThemeButton onClick={handleAddStem} label="Add Stem" icon={FaPlus} />}
				</ButtonContainer>
			</Card.Body>
			<Card.Footer>
				{emptyStemExists && (
					<AlertMEssage>
						<p>You cannot save an empty stem.</p>
					</AlertMEssage>
				)}
				<StemFooter
					handleCancel={handleCancel}
					subTab={subTab}
					handleSave={handleSave}
					toggleStem={toggleStem}
					disableSave={emptyStemExists}
				/>
			</Card.Footer>
		</StemCard>
	);
};

export default EditMode;

const StemCard = styled(Card)`
	position: relative;
	margin-bottom: 10px;
	&:hover {
		div#stem-btn {
			display: block;
		}
	}
`;
const InputContainer = styled.div`
	width: 100%;
	height: 35px;
	margin-top: 5px;
	margin-bottom: 5px;
	display: flex;
	gap: 5px;
	position: relative;
`;

const KeyInput = styled(Form.Control)`
	width: 40px;
	height: 100%;
	text-align: center;
	outline: none;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: 10px 0;
`;

const BtnContainer = styled.div`
	position: absolute;
	right: 0;
	top: 5px;
	z-index: 999;
`;

const ActionBtn = styled.button`
	background: white;
	margin-right: 10px;
	padding: 0 4px 3px 4px;
	font-size: 12px;
	border: 2px solid;
	border-radius: 1rem;
	&:hover {
		transform: scale(1.1);
	}
`;

const AlertMEssage = styled.div`
	background: transparent;
	text-align: center;
	color: red;
	width: 100%;
`;
