import { ThemeButton } from 'components/ThemeButton';
import { useNote } from 'contexts/NoteContext';
import React, { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { FaArrowRight, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const NoteReadyModal = ({ show, handleClose, newNote }) => {
	const navigate = useNavigate();
	const { setActiveNoteType } = useNote();

	const handleClick = useCallback(() => {
		handleClose();
		newNote && setActiveNoteType(newNote);
		newNote && navigate('/design-note?id=' + newNote.id);
	}, [handleClose, navigate, newNote, setActiveNoteType]);

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Success</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Your note is saved and ready to edit. Click on "Design your Note" to start editing your new note!
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label="Design your Note" size="lg" color="green" icon={FaArrowRight} onClick={handleClick} />
				<ThemeButton label="Cancel" icon={FaTimes} size="lg" color="grey" onClick={handleClose} />
			</Modal.Footer>
		</Modal>
	);
};

export default NoteReadyModal;
