import React, { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

import LibrarySection from '../section/LibrarySection';

const LibrarySubTab = ({
	subTab,
	librarySections,
	libraryStatements,
	libraryStems,
	setLibraryStatements,
	statements,
}) => {
	const [searchValue, setSearchValue] = useState();

	const filteredSections = useMemo(() => {
		return librarySections.filter(section => section.subtab === subTab.id);
	}, [subTab, librarySections]);

	const filteredStems = useMemo(() => {
		return libraryStems.filter(stem => stem.subtab === subTab.id);
	}, [subTab, libraryStems]);

	return (
		<>
			<SearchContainer>
				<SearchInput placeholder="Search statements" onChange={e => setSearchValue(e.target.value)} />
			</SearchContainer>
			{filteredSections.map((section, index) => (
				<LibrarySection
					key={index}
					libraryStatements={libraryStatements}
					setLibraryStatements={setLibraryStatements}
					isSectionNameVisible={filteredSections.length > 1}
					searchValue={searchValue}
					section={section}
					filteredSections={filteredSections}
					filteredStems={filteredStems}
					subTab={subTab}
					statements={statements}
				/>
			))}
		</>
	);
};

export default React.memo(LibrarySubTab);

const SearchInput = styled(Form.Control)`
	width: 100%;
	margin-bottom: 10px;
`;

const SearchContainer = styled.div`
	display: flex;
	gap: 5px;
`;
