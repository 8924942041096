import React, { useContext } from 'react';
import { AuthContext } from '@note/contexts/AuthContext';

const SignatureImagePreview = () => {
	const { signatureImage } = useContext(AuthContext);

	return signatureImage ? (
		<img src={signatureImage} alt="Click unsign, sign to refresh" width="auto" height={45} />
	) : (
		<span>No signature found</span>
	);
};

export default SignatureImagePreview;
