import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import styled from 'styled-components';

import LibrarySubTabNavItem from './LibrarySubTabNavItem';

const LibrarySubTabNav = ({ tab, librarySubTabs, librarySections, setActiveSubTab, activeLibrarySection, setActiveLibrarySection }) => {
	const [filteredSubTabs, setFilteredSubTabs] = useState([]);

	useEffect(() => {
		setFilteredSubTabs(librarySubTabs.filter(st => st.tab === tab.id));
	}, [tab, librarySubTabs]);

	return (
		<SubTab variant="pills" className="subtab-navpane">
			{filteredSubTabs.map((subTab, index) => (
				<LibrarySubTabNavItem
					key={index}
					librarySections={librarySections}
					subTab={subTab}
					index={index}
					setActiveSubTab={setActiveSubTab}
					activeLibrarySection={activeLibrarySection}
					setActiveLibrarySection={setActiveLibrarySection}
				/>
			))}
		</SubTab>
	);
};

export default LibrarySubTabNav;

const SubTab = styled(Nav)`
	width: 225px;
	display: inline-block;
	height: 100%;
	padding-right: 10px;
	overflow: auto;
`;
