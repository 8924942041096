import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ThemeButton } from 'components/ThemeButton';
import { getBlogs, getFeed } from 'services/user';
import styled from 'styled-components';

import './community.scss';

import BlogSection from './blog/BlogSection';
import DailyThought from './daily-thought/DailyThought';
import FeedCard from './FeedCard';

// The community modal accessed from the top bar
const CommunityModal = ({ show, handleClose }) => {
	const [activeSection, setActiveSection] = useState('community'); // State to track the active section (community or blog)
	const [blogItems, setBlogItems] = useState([]); // State to store blog items
	const [feedItems, setFeedItems] = useState([]); // State to store feed items
	const [hideModal, setHideModal] = useState(false); // State to hide the modal

	// Function to handle section click and set the active section
	const handleSectionClick = section => {
		setActiveSection(section);
	};

	// Function to get the modal title based on the active section
	const getModalTitle = () => {
		if (activeSection === 'blog') {
			return "Community - Patricia's blog";
		}
		return 'Community';
	};

	// Fetch data when the active section changes
	useEffect(() => {
		if (activeSection === 'blog') {
			const fetchData = async () => {
				const res = await getBlogs();
				setBlogItems(res.data);
			};
			fetchData();
		}
		if (activeSection === 'community' && show) {
			const fetchData = async () => {
				const res = await getFeed();
				setFeedItems(res.data);
			};
			fetchData();
		}
	}, [activeSection, show]);

	// Reset the active section to 'community' when the modal is closed
	useEffect(() => {
		if (!show) {
			setActiveSection('community');
		}
	}, [show]);

	return (
		<Modal
			show={show}
			onHide={handleClose}
			centered
			size={activeSection === 'blog' ? 'xl' : 'md'}
			className={`h-100 ${hideModal ? 'hideModal' : ''}`}
			scrollable
			backdropClassName="backdrop-blur"
		>
			<Modal.Header closeButton>
				<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0 1rem' }}>
					<Modal.Title>{getModalTitle()}</Modal.Title>
					{activeSection !== 'blog' && (
						<ThemeButton
							label="Patricia's blog"
							size="lg"
							color="blue"
							inverted
							onClick={() => handleSectionClick('blog')}
						/>
					)}
				</div>
			</Modal.Header>
			<ModalBody style={{ padding: 'unset' }}>
				{activeSection === 'community' && (
					<>
						<DailyThoughtContainer>
							<DailyThought />
						</DailyThoughtContainer>
						<CommunityModalBody>
							<FeedColumn>
								<div style={{ marginTop: '-1rem' }} />
								{feedItems.map((item, index) => (
									<FeedCard key={`feedcard-${index}`} image={item.image} text={item.post} />
								))}
							</FeedColumn>
						</CommunityModalBody>
					</>
				)}
				{activeSection === 'blog' && (
					<BlogSection
						onBackClick={() => setActiveSection('community')}
						hideSection={() => setActiveSection(null)}
						blogItems={blogItems}
					/>
				)}
			</ModalBody>
		</Modal>
	);
};

export default CommunityModal;

const ModalBody = styled(Modal.Body)`
	@media (max-width: 991px) {
		overflow-y: scroll;
	}
`;

const CommunityModalBody = styled.div`
	height: 65vh;
	padding: 0 3rem 2rem 3rem;
	justify-content: space-between;
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	gap: 1rem;
	overflow: hidden;
	@media (max-width: 991px) {
		height: 100%;
		overflow: auto;
		flex-direction: column-reverse;
		flex-wrap: nowrap;
	}
`;

const DailyThoughtContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 1rem 0;
`;

const FeedColumn = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding-right: 1rem;
	overflow-y: scroll;

	@media (max-width: 991px) {
		width: 100%;
		height: 100%;
		overflow-y: hidden;
		padding: unset;
	}
`;
