import { usePreference } from 'contexts/PreferenceContext';
import React, { useContext, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { green } from 'styles/colors';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';

import { statementsStateSelector } from '@note/atoms';
import { AuthContext } from '@note/contexts/AuthContext';

import StatementText from './input/StatementText';

export const PSO = ({
	statement,
	isListLinesSingle,
	editMode,
	statementText,
	setStatementText,
	filteredStems,
	toggleEdit,
}) => {
	const { isOldSafari } = useContext(AuthContext);
	const [selectedPSO, setSelectedPSO] = useState(statement.stem);
	const setStatement = useSetRecoilState(statementsStateSelector);
	const { globalPreferences } = usePreference();

	// Toggles the selected stem (part of PSO logic) and updates the statement state accordingly
	const toggleStatement = stemId => {
		stemId = selectedPSO === stemId ? null : stemId;
		// Plays a sound effect if enabled in global preferences
		!isOldSafari &&
			globalPreferences.SoundEffects === 'Enabled' &&
			soundPlayer(stemId === null ? soundKeys.TOGGLEOFF : soundKeys.TOGGLEON);
		setSelectedPSO(stemId);
		// Updates the statement with the new stem and selection state
		setStatement({ ...statement, isSelected: stemId === statement.id, stem: stemId });
	};

	// Checks if the current statement is selected based on its stem
	const isSelected = () => {
		return statement && statement.stem !== undefined && statement.stem !== null;
	};

	// Sorts the stems based on their order index for consistent rendering order
	const sortedStems = filteredStems.sort(function (a, b) {
		return a.order_index - b.order_index;
	});

	return (
		<StatementContainer>
			<KeyContainer>
				{sortedStems.map((stem, index) => (
					<KeyItem key={`${statement.id}+${stem.id}`}>
						<Btn key={index} isSelected={stem.id === statement.stem} onClick={() => toggleStatement(stem.id)}>
							{stem.key}
						</Btn>
					</KeyItem>
				))}
			</KeyContainer>
			<TextContainer className="pso-text-container">
				<StatementText
					statementText={statementText}
					setStatementText={setStatementText}
					statement={statement}
					isSelected={isSelected()}
					isSingleLine={isListLinesSingle}
					editMode={editMode}
					toggleEdit={toggleEdit}
				/>
			</TextContainer>
		</StatementContainer>
	);
};

const Btn = styled.button`
	width: 100%;
	border: none;
	margin-right: 5px;
	border-radius: 2px;
	background: ${props => (props.isSelected ? green : 'white')};
	color: ${props => (props.isSelected ? 'white' : 'black')};
	height: 30px;
	border: 1px solid #00000020;
`;

const StatementContainer = styled.div`
	height: 100%;
	display: flex;
`;

const TextContainer = styled.div`
	height: 100%;
	width: 100%;
	margin-left: 0.45rem;
`;

const KeyContainer = styled.div`
	display: flex;
	gap: 0.25rem;
`;

const KeyItem = styled.div`
	font-size: 0.8vw;
	width: 1.6vw;
	@media (max-width: 1462px) {
		width: 1.8vw;
	}
`;
