import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { ThemeButton } from 'components/ThemeButton';

// Search and replace strings feature used in Final Edit and AI Rewrite modals
const SearchReplace = ({ show, handleClose, text, setText, originalAiAnswer }) => {
	// State to hold the original value to be replaced
	const [originalValue, setOriginalValue] = useState('');
	// State to hold the replacement value
	const [replaceValue, setReplaceValue] = useState('');

	// Handle changes to the original value input field
	const handleOriginalValueChange = e => {
		const value = e.target.value;
		setOriginalValue(value);
	};

	// Handle changes to the replacement value input field
	const handleReplaceValueChange = e => {
		const value = e.target.value;
		setReplaceValue(value);
	};

	// Perform the search and replace operation
	const handleSearchAndReplace = () => {
		const regex = new RegExp(originalValue, 'gi');
		const replaceFunction = match => {
			if (match[0] === match[0].toUpperCase()) {
				return replaceValue.charAt(0).toUpperCase() + replaceValue.slice(1);
			}
			return replaceValue;
		};

		const newText = text.replace(regex, replaceFunction);

		setText(newText);
		handleClose();
	};

	// Undo changes and reset to original AI answer
	const handleUndo = () => {
		setText(originalAiAnswer);
		handleClose();
	};

	return (
		<Modal show={show} onHide={handleClose} centered size={'md'} scrollable backdropClassName="backdrop-blur">
			<Modal.Header closeButton>
				<Modal.Title>Search and Replace</Modal.Title>
			</Modal.Header>
			<ModalBody>
				<TextInput placeholder="Text to be replaced" value={originalValue} onChange={handleOriginalValueChange} />
				<TextInput placeholder="New text value" value={replaceValue} onChange={handleReplaceValueChange} />
			</ModalBody>
			<Modal.Footer>
				<ThemeButton label="Undo changes" size="lg" color="yellow" onClick={handleUndo} />
				<ThemeButton label="Close" size="lg" color="grey" onClick={handleClose} />
				<ThemeButton
					onClick={handleSearchAndReplace}
					label="Submit"
					size="lg"
					color="green"
					disabled={!originalValue || !replaceValue}
				/>
			</Modal.Footer>
		</Modal>
	);
};

export default SearchReplace;

const ModalBody = styled(Modal.Body)`
	display: flex;
	flex-direction: column;
	gap: 0.7rem;
	padding: 1rem;
	@media (max-width: 991px) {
		overflow-y: scroll;
	}
`;

const TextInput = styled.input`
	height: 2.5rem;
	width: 100%;
	padding: 0.5rem;
	outline: none;
	border: 1px solid #00000050;
	border-radius: 0.3rem;
`;
