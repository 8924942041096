import React from 'react';
import styled from 'styled-components';

const RadioInput = ({ value, handleChange, currentValue, name, label }) => {
	return (
		<Label>
			<Radio type="radio" value={value} checked={currentValue === value} onChange={handleChange} name={name} />
			{label}
		</Label>
	);
};

export default RadioInput;

const Radio = styled.input`
	margin-right: 5px;
`;

const Label = styled.label`
	margin-right: 10px;
	cursor: pointer;
`;
