export const getStatementObject = (statement) => {
    return {
        ...statement,
        isSelected: false,
        selectedType: null,
        content: '',   // For manual input widget
        selectedOption: null,
        editedPrefilledText: statement.prefilled_text,
        stem: null,
        editedName: statement.name
    }
}