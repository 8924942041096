import { InputType } from 'utils/enum';
import { fetch } from 'utils/fetch';

const prepareHeadingPayload = ({ order_index, name, type, note_type, options, placeholder }) => ({
	order_index,
	name,
	type,
	options: type === InputType.SELECT ? options : {},
	note_type,
	placeholder,
});

const getHeadingsByNoteId = id =>
	fetch({
		endpoint: `/notes/headings/?note_type=${id}`,
		method: 'get',
		errorLabel: 'getHeadingsByNoteId',
	});

const createHeading = heading =>
	fetch({
		endpoint: `/notes/headings/`,
		method: 'post',
		payload: prepareHeadingPayload(heading),
		errorLabel: 'createHeading',
	});

const updateHeading = heading =>
	fetch({
		endpoint: `/notes/headings/${heading.id}/`,
		method: 'patch',
		payload: prepareHeadingPayload(heading),
		errorLabel: 'updateHeading',
	});

const reorderHeadings = orders =>
	fetch({
		endpoint: `notes/headings/reorder/`,
		method: 'post',
		payload: orders,
		errorLabel: 'reorderHeadings',
	});

const deleteHeading = id =>
	fetch({
		endpoint: `/notes/headings/${id}/`,
		method: 'delete',
		errorLabel: 'deleteHeading',
	});

export { getHeadingsByNoteId, createHeading, updateHeading, reorderHeadings, deleteHeading };
