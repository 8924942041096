import DeleteConfirmationModal from 'modals/DeleteConfirmationModal';
import React, { useCallback, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { createSection, deleteSection, updateSectionName } from 'services/section';

import { isDesignPageState, sectionsState } from '@note/atoms';

import ReadMode from './ReadMode';
import SectionNameInput from './SectionNameInput';

const SectionName = ({ section, sectionHref, removeNewSection }) => {
	const isDesignPage = useRecoilValue(isDesignPageState);
	const [sections, setSections] = useRecoilState(sectionsState);

	const [isLoading, setIsLoading] = useState(false);
	const [editMode, setEditMode] = useState(section?.isNew ? true : false);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const openDeleteModal = useCallback(() => setShowDeleteModal(true), []);
	const closeDeleteModal = useCallback(() => setShowDeleteModal(false), []);

	const toggleEditMode = useCallback(() => {
		if (isDesignPage && section.name) {
			if (editMode) removeNewSection();
			setEditMode(!editMode);
		}
	}, [editMode, isDesignPage, removeNewSection, section]);

	// Function to update section name
	const updateName = useCallback(
		async name => {
			try {
				setIsLoading(true);
				const res = await updateSectionName(section.id, name);
				setSections(
					sections.map(sc => {
						return sc.id === section.id ? { ...sc, name: res.data.name } : sc; // Update section in state
					})
				);
				setIsLoading(false);
				setEditMode(false);
			} catch (error) {
				console.error('🚀 ~ file: SectionName.js ~ line 44 ~ SectionName ~ error', error);
			}
		},
		[section, sections, setSections]
	);

	// Function to create a new section
	const createNew = useCallback(
		async name => {
			try {
				setIsLoading(true);
				const res = await createSection({
					name: name,
					order_index: section.order_index,
					subtab: section.subtab,
				});
				setSections(
					[...sections.filter(sc => sc.id !== section.id), res.data].sort((a, b) => a.order_index - b.order_index) // Add new section and sort
				);
				setIsLoading(false); // Reset loading state
				setEditMode(false); // Exit edit mode
			} catch (error) {
				console.error('🚀 ~ file: SectionName.js ~ line 63 ~ SectionName ~ error', error);
			}
		},
		[section, sections, setSections]
	);

	const handleSave = useCallback(
		async name => {
			if (section?.isNew) {
				await createNew(name);
			} else {
				await updateName(name);
			}
		},
		[createNew, section, updateName]
	);

	const deleteSectionEntity = useCallback(async () => {
		try {
			await deleteSection(section.id);
			setEditMode(false);
			setSections(sections.filter(sc => sc.id !== section.id)); // Remove section from state
		} catch (error) {
			console.error('🚀 ~ file: SectionName.js ~ line 44 ~ deleteSection ~ error', error);
		}
	}, [section, sections, setSections]);

	return (
		<>
			{editMode ? (
				<SectionNameInput
					defaultValue={section.name}
					showDelete={!section?.isNew}
					handleDelete={openDeleteModal}
					handleSave={handleSave}
					placeholder="Type a name of this category here"
					toggleEditMode={toggleEditMode}
					isLoading={isLoading}
				/>
			) : (
				<ReadMode section={section} sectionHref={sectionHref} toggleEditMode={toggleEditMode} />
			)}
			{showDeleteModal && (
				<DeleteConfirmationModal
					entityType="section"
					deleteEntity={deleteSectionEntity}
					show={showDeleteModal}
					id={section.id}
					name={section.name}
					handleClose={closeDeleteModal}
				/>
			)}
		</>
	);
};

export default React.memo(SectionName);
