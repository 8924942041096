import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';

function ImageCropper({ imageToCrop, onImageCropped, setImageDataURL }) {
	const [cropConfig, setCropConfig] = useState({
		unit: '%', // Can be 'px' or '%'
		x: 25,
		y: 25,
		width: 50,
		height: 50,
	});

	const [imageRef, setImageRef] = useState();

	// Function to crop the image based on the crop configuration
	async function cropImage(crop) {
		if (imageRef && crop.width && crop.height) {
			const croppedImage = await getCroppedImage(
				imageRef,
				crop,
				'croppedImage.png' // destination filename
			);

			const dataurl = getBlob(
				imageRef,
				crop,
				'croppedImage.png' // destination filename
			);

			setImageDataURL(dataurl);

			// calling the props function to expose
			// croppedImage to the parent component
			onImageCropped(croppedImage);
		}
	}

	// Function to get the cropped image as a URL
	function getCroppedImage(sourceImage, cropConfig, fileName) {
		// Creating the cropped image from the source image
		const canvas = document.createElement('canvas');
		const scaleX = sourceImage.naturalWidth / sourceImage.width;
		const scaleY = sourceImage.naturalHeight / sourceImage.height;
		canvas.width = cropConfig.width;
		canvas.height = cropConfig.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			sourceImage,
			cropConfig.x * scaleX,
			cropConfig.y * scaleY,
			cropConfig.width * scaleX,
			cropConfig.height * scaleY,
			0,
			0,
			cropConfig.width,
			cropConfig.height
		);

		// Returning the cropped image as a Blob URL
		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				// returning an error if the Blob is empty
				if (!blob) {
					reject(new Error('Canvas is empty'));
					return;
				}

				setImageDataURL(blob);

				blob.name = fileName;
				// Creating an Object URL representing the Blob object given
				const croppedImageUrl = window.URL.createObjectURL(blob);

				resolve(croppedImageUrl);
			}, 'image/png');
		});
	}

	// Function to get the cropped image as a data URL
	function getBlob(sourceImage, cropConfig, fileName) {
		// Creating the cropped image from the source image
		const canvas = document.createElement('canvas');
		const scaleX = sourceImage.naturalWidth / sourceImage.width;
		const scaleY = sourceImage.naturalHeight / sourceImage.height;
		canvas.width = cropConfig.width;
		canvas.height = cropConfig.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			sourceImage,
			cropConfig.x * scaleX,
			cropConfig.y * scaleY,
			cropConfig.width * scaleX,
			cropConfig.height * scaleY,
			0,
			0,
			cropConfig.width,
			cropConfig.height
		);

		return canvas.toDataURL('image/png');
	}

	return (
		<ReactCrop
			src={imageToCrop}
			crop={cropConfig}
			ruleOfThirds
			onImageLoaded={imageRef => setImageRef(imageRef)}
			onComplete={cropConfig => cropImage(cropConfig)}
			onChange={cropConfig => setCropConfig(cropConfig)}
			crossorigin="anonymous" // to avoid CORS-related problems
		/>
	);
}

ImageCropper.defaultProps = {
	onImageCropped: () => {},
};

export default ImageCropper;
