import failure from 'assets/sounds/failure.mp3';
import harpAscending from 'assets/sounds/harpAscending.mp3';
import harpDescending from 'assets/sounds/harpDescending.mp3';
import harpFlourish from 'assets/sounds/harpFlourish.mp3';
import harpSound from 'assets/sounds/harpSound.mp3';
import NewNote from 'assets/sounds/new_note_sound.mp3';
import success from 'assets/sounds/success.mp3';
import toggleStatementOff from 'assets/sounds/toggleStatementOff.mp3';
import toggleStatementOn from 'assets/sounds/toggleStatementOn.mp3';

export const soundKeys = {
	FAILURE: 'FAILURE',
	HARP: 'HARP',
	HARPASCENDING: 'HARPASCENDING',
	HARPDESCENDING: 'HARPDESCENDING',
	HARPFLOURISH: 'HARPFLOURISH',
	NEWNOTE: 'NEWNOTE',
	SUCCESS: 'SUCCESS',
	TOGGLEOFF: 'TOGGLEOFF',
	TOGGLEON: 'TOGGLEON',
};

export const sounds = {
	FAILURE: failure,
	HARP: harpSound,
	HARPASCENDING: harpAscending,
	HARPDESCENDING: harpDescending,
	HARPFLOURISH: harpFlourish,
	NEWNOTE: NewNote,
	SUCCESS: success,
	TOGGLEOFF: toggleStatementOff,
	TOGGLEON: toggleStatementOn,
};

export const soundPlayer = soundKey => {
	const soundPath = sounds[soundKey];
	const audio = new Audio(soundPath);
	audio.play();
};
