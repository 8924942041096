import Search from 'components/Search';
import { ThemeButton } from 'components/ThemeButton';
import { usePreference } from 'contexts/PreferenceContext';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { useRecoilState, useRecoilValue } from 'recoil';
import { updateSubTab } from 'services/subTab';
import styled from 'styled-components';
import { Defaults, PreferenceTypes, WidgetType } from 'utils/enum';

import { isDesignPageState, sectionsState, stemsState } from '@note/atoms';
import Section from '@note/layouts/note-selector/section';

import MultipleChoiceOptions from './multiplechoice-options';
import SubTabSettings from './settings';
import Stem from './stem';

const SubTab = ({ subTab }) => {
	const [filteredSections, setFilteredSections] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [searchValue, setSearchValue] = useState();
	const { getPreferenceValueBySubTab } = usePreference();
	const [sections, setSections] = useRecoilState(sectionsState);
	const isDesignPage = useRecoilValue(isDesignPageState);
	const stems = useRecoilValue(stemsState);

	// Get the stems associated with the current sub-tab
	const stemsInTab = useMemo(() => {
		return stems.filter(stem => stem.subtab === subTab.id);
	}, [subTab, stems]);

	// Filter the sections based on the selected sub-tab
	useEffect(() => {
		setFilteredSections(sections.filter(section => section.subtab === subTab.id));
	}, [subTab, sections]);

	// Retrieve punctuation settings for the sub-tab
	const punctuation = useMemo(
		() => ({
			OnePhraseSuffix: getPreferenceValueBySubTab(PreferenceTypes.PUNCTUATION_ONE_PHRASE_STEM_SUFFIX, subTab),
			TwoPhraseSuffix: getPreferenceValueBySubTab(PreferenceTypes.PUNCTUATION_TWO_PHRASE_STEM_SUFFIX, subTab),
			TwoPhraseBeforeFinalItem: getPreferenceValueBySubTab(PreferenceTypes.PUNCTUATION_TWO_BEFORE_FINAL_ITEM, subTab),
			ThreePhraseSuffix: getPreferenceValueBySubTab(PreferenceTypes.PUNCTUATION_THREE_PHRASE_STEM_SUFFIX, subTab),
			ThreePhraseSeparation: getPreferenceValueBySubTab(PreferenceTypes.PUNCTUATION_THREE_PHRASE_SEPARATION, subTab),
			ThreePhraseBeforeFinalItem: getPreferenceValueBySubTab(
				PreferenceTypes.PUNCTUATION_THREE_BEFORE_FINAL_ITEM,
				subTab
			),
		}),
		[getPreferenceValueBySubTab, subTab]
	);

	// Handle adding a new section to the sub-tab
	const handleAddSection = () => {
		const sectionsWithoutCategorized = filteredSections.filter(sc => sc.name !== Defaults.UNCATEGORIZED_SECTION_NAME);
		// Calculate the order for the new section
		const order =
			sectionsWithoutCategorized.length > 0 ? Math.max(...sectionsWithoutCategorized.map(sc => sc.order_index)) + 1 : 0;
		setSections([
			...sections,
			{
				id: Math.random(),
				name: '',
				isNew: true,
				order_index: order,
				subtab: subTab.id,
			},
		]);
	};

	// Remove the new section if it exists
	const removeNewSection = useCallback(() => {
		setSections(sections.filter(section => !section?.isNew));
	}, [sections, setSections]);

	// Save the updated settings for the sub-tab
	const saveSettings = useCallback(
		async settings => {
			try {
				await updateSubTab(subTab.id, { settings });
			} catch (error) {
				console.error('🚀 ~ file: index.js ~ line 85 ~ saveSettings ~ error', error);
			}
		},
		[subTab]
	);

	// Handle search input to filter the sections
	const handleSearch = value => {
		setSearchValue(value);
	};

	return (
		<SubTabContainer isdiabled={isLoading}>
			<div className="innersection" style={{ marginRight: 10 }}>
				<SearchContainer>
					{isDesignPage ? (
						<>
							<Alert style={{ height: 'max-content' }}>
								Now you can edit the content below. Click on text to edit it; then use the green floppy-disk icon to
								save your changes. You can also adjust punctuation and other settings using the blue button to the right
								here.{' '}
								<b>
									Using these advanced customization features requires some practice! We strongly advise watching this{' '}
									<a href="https://www.youtube.com/watch?v=Oa709eKqbOk" target="_blank" rel="noreferrer">
										tutorial video.
									</a>
								</b>
							</Alert>
							<SubTabSettings subTab={subTab} punctuation={punctuation} saveSettings={saveSettings} />
						</>
					) : (
						<Search label="statements" handleSearch={handleSearch} useClick={true} />
					)}
				</SearchContainer>
				<div className="sub-tab-content">
					{(subTab.type === WidgetType.LIST_SELECT ||
						subTab.type === WidgetType.LIST_MULTI_SELECT ||
						subTab.type === WidgetType.PS ||
						subTab.type === WidgetType.PSO) && <Stem filteredStems={stemsInTab} subTab={subTab} />}
					{subTab.type === WidgetType.MULTIPLE_CHOICE && isDesignPage && <MultipleChoiceOptions subTab={subTab} />}
					<div>
						{filteredSections.map((section, index) => {
							return (
								<Suspense fallback={<div>Loading...</div>} key={index}>
									<Section
										key={section.id}
										section={section}
										isSectionNameVisible={filteredSections.length > 1}
										searchValue={searchValue}
										filteredStems={stemsInTab}
										punctuation={punctuation}
										subTabType={subTab.type}
										subTabSettings={subTab.settings}
										removeNewSection={removeNewSection}
										setIsLoading={setIsLoading}
									/>
								</Suspense>
							);
						})}
					</div>

					{!filteredSections.some(section => section?.isNew) && isDesignPage && (
						<ButtonContainer className="add-category-button">
							<ThemeButton onClick={handleAddSection} label="Add Category" color="darkgreen" icon={FaPlus} />
						</ButtonContainer>
					)}
				</div>
			</div>
		</SubTabContainer>
	);
};

export default React.memo(SubTab);

const SubTabContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
	${props =>
		props.isdiabled &&
		`
		pointer-events: none;
		opacity: 0.5;
	`}
	overflow: auto;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: 10px 0;
`;

const SearchContainer = styled.div`
	display: flex;
	flex-shrink: 0;
	gap: 5px;
`;
