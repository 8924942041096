import { Separator } from 'components/Separator';
import React, { useCallback } from 'react';
import styled from 'styled-components';

const LayoutSettings = ({ subTab, saveSettings, setSubTabs, subTabs }) => {
	// Function to save the updated settings for the subTab
	const saveSubTabSettings = useCallback(
		async settings => {
			// Update the specific subTab's settings within the subTabs state
			setSubTabs(subTabs.map(st => (st.id === subTab.id ? { ...st, settings: settings } : st)));
			// Save the settings via the provided saveSettings function
			await saveSettings(settings);
		},
		[saveSettings, setSubTabs, subTab, subTabs]
	);

	// Handle changes to the radio buttons, updating the corresponding settings
	const handleChange = useCallback(
		async (e, value) => {
			// Update the listSelect settings for the subTab
			const settings = { ...subTab?.settings, listSelect: { ...subTab.settings?.listSelect, [e.target.name]: value } };
			saveSubTabSettings(settings); // Save the updated settings
		},
		[saveSubTabSettings, subTab.settings]
	);

	return (
		<>
			<h6>How would you like your statements stacked?</h6>
			<SettingLabel>
				{/* Single stack option */}
				<SettingRadio
					onChange={event => handleChange(event, false)}
					checked={!subTab.settings?.listSelect?.isListDoubleStacked} // Checked if not double stacked
					name="isListDoubleStacked"
					type="radio"
				/>
				Single (best when your statements are longer)
			</SettingLabel>
			<SettingLabel>
				{/* Double stack option */}
				<SettingRadio
					type="radio"
					name="isListDoubleStacked"
					onChange={event => handleChange(event, true)}
					checked={subTab.settings?.listSelect?.isListDoubleStacked} // Checked if double stacked
				/>
				Double (best when your statements are just a word or two)
			</SettingLabel>
			<Separator />
			<h6>Statement Lines</h6>
			<SettingLabel>
				{/* Single line option */}
				<SettingRadio
					onChange={event => handleChange(event, true)}
					checked={subTab.settings?.listSelect?.isListLinesSingle} // Checked if single line
					name="isListLinesSingle"
					type="radio"
				/>
				Single Line (best when your statements are short)
			</SettingLabel>
			<SettingLabel>
				{/* Multi-line option */}
				<SettingRadio
					type="radio"
					name="isListLinesSingle"
					onChange={event => handleChange(event, false)}
					checked={!subTab.settings?.listSelect?.isListLinesSingle} // Checked if not single line
				/>
				Multi Line (best when your statements are longer)
			</SettingLabel>
			<Separator />
		</>
	);
};

export default LayoutSettings;

const SettingRadio = styled.input`
	margin-right: 5px;
`;

const SettingLabel = styled.label`
	cursor: pointer;
`;
