import React from 'react';
import styled from 'styled-components';

import { prefilledInputData } from '../../example-data';

const PrefilledTextPreview = () => {
	return (
		<>
			{prefilledInputData.map((item, index) => (
				<div key={index}>
					<TextInput rows="3" defaultValue={item.name} />
					<TextInput rows="5" defaultValue={item.prefilled_text} />
				</div>
			))}
		</>
	);
};

export default PrefilledTextPreview;

const TextInput = styled.textarea`
	width: 100%;
	outline: none;
	border: 1px solid #00000020;
`;
