import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import { ThemeButton } from 'components/ThemeButton';

import { useBulkStatements} from 'hooks';

import { updateStatement } from 'services/statement';
import {useRecoilState, useRecoilValue} from "recoil";
import {sectionsState, statementsState, subTabsState, tabsState} from "@note/atoms";

const MoveModal = ({ show, handleClose }) => {
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedSubTab, setSelectedSubTab] = useState(0);
	const [selectedSection, setSelectedSection] = useState(0);
	const { bulkStatements } = useBulkStatements();

	const tabs = useRecoilValue(tabsState);
	const subTabs = useRecoilValue(subTabsState);
	const sections = useRecoilValue(sectionsState);
	const [statements, setStatements] = useRecoilState(statementsState);
	const [isValid, setIsValid] = useState(true);

	const [isLoading, setIsLoading] = useState(false);

	const handleTabSelect = e => {
		setSelectedSubTab(null);
		setSelectedSection(null);
		setSelectedTab(parseInt(e.target.value));
	};

	const handleSubTabSelect = e => {
		setSelectedSection(null);
		setSelectedSubTab(parseInt(e.target.value));
	};

	const handleSectionSelect = e => {
		setSelectedSection(parseInt(e.target.value));
	};

	const handleSave = async () => {
		if (!selectedTab || !selectedSubTab || !selectedSection) {
			setIsValid(false);
			return;
		}
		setIsLoading(true);
		const sectionStatements = statements.filter(st => st.section === selectedSection);
		let order = sectionStatements.length > 0 ? Math.max(...sectionStatements.map(st => st.order_index)) : 0;
		const promises = bulkStatements.map(statement => {
			order++;
			return updateStatement({ ...statement, order_index: order, section: selectedSection });
		});
		try {
			const results = await Promise.all(promises);
			setStatements(
				statements.map(st => {
					const updatedStatement = results.find(res => res.data.id === st.id);
					if (updatedStatement && updatedStatement !== -1) {
						return { ...updatedStatement.data, isSelected: false, selectedType: null, content: '', selectedOption: null };
					}
					return st;
				})
			);
			setIsLoading(false);
			handleClose();
		} catch (error) {
			console.error('🚀 ~ file: MoveModal.js ~ line 67 ~ handleSave ~ error', error);
		}
	};

	return (
		<Modal show={show} onHide={handleClose} centered size="lg">
			<Modal.Header closeButton>
				<Modal.Title>Move</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h5 className="mb-3">Please select where you would like to move the selected statements</h5>
				<Form.Group className="mb-3">
					<Form.Label>Select Tab</Form.Label>
					<Form.Select onChange={handleTabSelect} isInvalid={!isValid && !selectedTab}>
						<option value={null}>No tabs to select</option>
						{tabs.map((tab, index) => (
							<option key={index} value={tab.id}>
								{tab.name}
							</option>
						))}
					</Form.Select>
				</Form.Group>
				{
					<Form.Group className="mb-3">
						<Form.Label>Select Subtab</Form.Label>
						<Form.Select onChange={handleSubTabSelect} isInvalid={!isValid && !selectedSubTab}>
							<option value={null}>No sub tabs to select</option>
							{selectedTab &&
								subTabs
									.filter(st => st.tab === selectedTab)
									.map((subtab, index) => (
										<option key={index} value={subtab.id} onClick={() => setSelectedSubTab(subtab)}>
											{subtab.name}
										</option>
									))}
						</Form.Select>
					</Form.Group>
				}
				{selectedSubTab && (
					<Form.Group className="mb-3">
						<Form.Label>Select sections</Form.Label>
						<Form.Select onChange={handleSectionSelect} isInvalid={!isValid && !selectedSection}>
							<option value={null}>No sections to select</option>
							{sections
								.filter(sc => sc.subtab === selectedSubTab)
								.map((section, index) => (
									<option key={index} value={section.id} onClick={() => setSelectedSection(section)}>
										{section.name}
									</option>
								))}
						</Form.Select>
					</Form.Group>
				)}
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label="Close" size="lg" color="grey" onClick={handleClose} />
				<ThemeButton onClick={handleSave} label={isLoading ? 'Moving...' : 'Move'} size="lg" color="green" />
			</Modal.Footer>
		</Modal>
	);
};

export default MoveModal;
