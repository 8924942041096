import React, { useEffect, useRef } from 'react';

import { TextArea, TextAreaTitle } from './styles';
import { TextModules, TitleModules } from './toolbarOptions';

const EditMode = ({ editMode, statementText, prefilledText, setStatementText, setPrefilledText }) => {
	const inputRefEditMode = useRef();

	// Focus on the title input field when the edit mode is activated
	useEffect(() => {
		if (editMode) inputRefEditMode.current.focus();
	}, [editMode]);

	// Handle changes to the title text
	// Ensures that the content is wrapped in <strong> tags for bold styling
	const handleTitleTextChange = content => {
		if (!content.startsWith('<p><strong>')) {
			content = content.replace(/<p>/g, '<p><strong>').replace(/<\/p>/g, '</strong></p>');
		}
		setStatementText(content); // Update the statement text state with the formatted content
	};

	// Handle changes to the prefilled text content
	const handleContentTextChange = content => {
		setPrefilledText(content);
	};

	return (
		<>
			<TextAreaTitle
				placeholder="Title"
				ref={inputRefEditMode}
				value={statementText}
				onChange={handleTitleTextChange}
				modules={TitleModules}
			/>
			<TextArea
				placeholder="Prefilled text"
				value={prefilledText}
				onChange={handleContentTextChange}
				modules={TextModules}
			/>
		</>
	);
};

export default EditMode;
