import React from 'react';
import { Modal, Alert } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { ThemeButton } from 'components/ThemeButton';

// Modal to confirm the hiding of a statement. Reversible via top-right menu -> filter -> Hidden statements
const HideStatementConfirmation = ({ show, handleClose, hideStatement, name }) => {
	const handleYes = async () => {
		await hideStatement();
		handleClose();
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Confirmation</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<b>Are you sure that you want to hide the following statement?</b>
				<p>{name}</p>
				<Alert>
					Note: You can view all your hidden statements - and retrieve them if you wish - by using the filter button at
					the top of your screen.
				</Alert>
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label="Yes" size="lg" color="red" onClick={handleYes} />
				<ThemeButton label="Cancel" icon={FaTimes} size="lg" color="grey" onClick={handleClose} />
			</Modal.Footer>
		</Modal>
	);
};

export default HideStatementConfirmation;
