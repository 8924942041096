import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { Tab } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

import { selectorActiveTabId, tabsState } from '@note/atoms';

import Headings from './heading/Headings';
import Images from './images/Images';
import AISettings from './aiSettings/AISettings';
import TabNav from './tab-nav/TabNav';

const NoteSelector = ({ tabComponent: TabComponent }) => {
	const tabs = useRecoilValue(tabsState);
	const [activeTabId, setActiveTab] = useRecoilState(selectorActiveTabId);

	useEffect(() => {
		setActiveTab('headings');
	}, []);

	return (
		<Tab.Container activeKey={activeTabId}>
			<TabNav setActiveTab={setActiveTab} defaultTab={'headings'} />
			<Tab.Content className="tab-content">
				<Tab.Pane className="heading-pane" eventKey="ai-settings">
					<AISettings />
				</Tab.Pane>
				<Tab.Pane className="heading-pane" eventKey="images">
					<Images />
				</Tab.Pane>
				<Tab.Pane className="heading-pane" eventKey="headings">
					<Headings />
				</Tab.Pane>
				<AnimatePresence mode="wait">
					{tabs?.length > 0 &&
						tabs.map(
							(tab, index) =>
								tab.id === activeTabId && (
									<motion.div
										style={{ height: '100%' }}
										key={index}
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										transition={{ duration: 0.2 }}
									>
										<Tab.Pane className="tab-pane" key={index} eventKey={tab.id}>
											<TabComponent tab={tab} />
										</Tab.Pane>
									</motion.div>
								)
						)}
				</AnimatePresence>
			</Tab.Content>
		</Tab.Container>
	);
};

export default React.memo(NoteSelector);
