import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { orange } from 'styles/colors';

export const ChatbotContainer = styled.div`
	border-radius: 10px;
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 1000;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
`;

export const HeaderContainer = styled.div`
	align-items: center;
	padding: 10px;
	background-color: ${orange};
	position: relative;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
`;

export const CloseButton = styled(FaTimes)`
	cursor: pointer;
	color: white;
	font-size: 1.5rem;
	position: absolute;
	margin-top: 3px;
`;

export const ChatbotHeaderText = styled.div`
	margin: 0 2rem;
	text-align: center;
`;

export const ChatbotHeaderTitle = styled.div`
	font-size: 1.25rem;
	font-weight: bold;
	color: white;
`;

export const ChatLogo = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 50%;
`;

export const ChatbotToggle = styled.div`
	color: ${orange};
	position: fixed;
	bottom: 2rem;
	right: 2rem;
	z-index: 900;
	cursor: pointer;
`;
