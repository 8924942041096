import { ThemeButton } from 'components/ThemeButton';
import { usePreference } from 'contexts/PreferenceContext';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { FaSave, FaTimes } from 'react-icons/fa';
import { createPreference, updatePreference } from 'services/preference';
import { PreferenceTypes } from 'utils/enum';
import { AuthContext } from '@note/contexts/AuthContext';
import { changeAIConsent } from '@note/services/user';
import styled from 'styled-components';

import { globalPreferenceGroups } from '../preference-data';
import Preference from './Preference';

// Preferences menu accessed via the top bar
const PreferenceModal = ({ show, handleClose }) => {
	const { user, setUser } = useContext(AuthContext); // Context for user authentication
	const [isLoading, setIsLoading] = useState(false); // State to manage loading state
	const { setPreferences, preferences } = usePreference(); // Context for managing preferences
	const [filteredPreferences, setFilteredPreferences] = useState(preferences); // State to manage filtered preferences

	// Update filtered preferences when preferences change
	useEffect(() => {
		setFilteredPreferences(preferences);
	}, [preferences]);

	// Get promises for creating or updating preferences
	const getPromises = useCallback(() => {
		const promises = [];
		filteredPreferences.forEach(preference => {
			if (preference.isNew) {
				promises.push(createPreference(preference)); // Create new preference if it's new
			} else if (preference.isChanged) {
				promises.push(updatePreference(preference)); // Update preference if it has changed
			}
		});
		return promises;
	}, [filteredPreferences]);

	// Handle discarding changes
	const handleDiscard = useCallback(() => {
		setFilteredPreferences(preferences);
		handleClose();
	}, [handleClose, preferences]);

	// Modify user consent for AI features
	const modifyUserConsent = consentValue => {
		if (consentValue === false) changeAIConsent(user.id, consentValue);
		setUser({ ...user, has_accepted_ai_terms: consentValue });
	};

	// Handle saving preferences
	const handleSave = useCallback(async () => {
		const promises = getPromises();
		let aiConsentChanged = false;
		let newAIConsentValue = false;

		if (promises.length > 0) {
			try {
				setIsLoading(true);
				const returnedPromises = await Promise.all(promises);
				setFilteredPreferences(
					filteredPreferences.map(fp => {
						if (fp.key === PreferenceTypes.AI_REWRITE && fp.isChanged) {
							aiConsentChanged = true;
							newAIConsentValue = fp.value === 'Enabled';
						}
						return { ...fp, isNew: false, isChanged: false };
					})
				);
				let newFilteredPreferences = [...filteredPreferences];
				let newPreferences = [...preferences];

				returnedPromises.forEach(promise => {
					const filteredIndex = newFilteredPreferences?.findIndex(fp => fp.key === promise?.data?.key);
					if (filteredIndex !== -1) {
						newFilteredPreferences[filteredIndex] = {
							...newFilteredPreferences[filteredIndex],
							...promise.data,
							isNew: false,
							isChanged: false,
						};
					} else {
						newFilteredPreferences.push({ ...promise.data, isNew: false, isChanged: false });
					}

					const index = newPreferences?.findIndex(fp => fp.key === promise?.data?.key);
					if (index !== -1) {
						newPreferences[index] = {
							...newPreferences[index],
							...promise.data,
							isNew: false,
							isChanged: false,
						};
					} else {
						newPreferences.push({ ...promise.data, isNew: false, isChanged: false });
					}
				});
				setFilteredPreferences(newFilteredPreferences);
				setPreferences(newPreferences);
				setIsLoading(false);
				handleClose();

				if (aiConsentChanged) {
					modifyUserConsent(newAIConsentValue);
				}
			} catch (error) {
				console.error('Error saving preferences:', error);
			}
		}
	}, [filteredPreferences, getPromises, handleClose, modifyUserConsent, setPreferences]);

	return (
		<FullHeightModal show={show} onHide={handleDiscard} centered size="lg" className="h-100" scrollable>
			<Modal.Header closeButton>
				<Modal.Title>Preferences and Advanced Options</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{globalPreferenceGroups.map((group, index) => (
					<div key={index}>
						<h6>{group.title}</h6>
						<p>{group.subTitle}</p>
						{group.preferences.map((pref, prefIndex) => (
							<Preference
								key={prefIndex}
								preferenceOptions={pref.options}
								filteredPreferences={filteredPreferences}
								setFilteredPreferences={setFilteredPreferences}
								preferenceKey={pref.key}
							/>
						))}
						{globalPreferenceGroups.length - 1 !== index && <hr />}
					</div>
				))}
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton
					label="Discard and close"
					icon={FaTimes}
					size="lg"
					color="grey"
					onClick={handleDiscard}
					tourAnchor="discard-button"
				/>
				<ThemeButton
					label={isLoading ? 'Saving...' : 'Save'}
					icon={FaSave}
					disabled={!filteredPreferences?.some(fp => fp.isNew || fp.isChanged)}
					size="lg"
					color="green"
					onClick={handleSave}
					tourAnchor="save-button"
				/>
			</Modal.Footer>
		</FullHeightModal>
	);
};

export default PreferenceModal;

const FullHeightModal = styled(Modal)`
	div.modal-content {
		height: 100%;
	}
`;
