import React from 'react';
import { Nav } from 'react-bootstrap';
import styled from 'styled-components';
import { navy } from 'styles/colors';

const NoteTypeTab = ({ noteTypes, setActiveNoteType }) => {
	return (
		<Nav variant="tabs">
			{noteTypes.map((notetype, index) => (
				<NavItem key={index}>
					<NavLink eventKey={notetype.id} onClick={() => setActiveNoteType(notetype.id)}>
						{notetype.name}
					</NavLink>
				</NavItem>
			))}
		</Nav>
	);
};

export default NoteTypeTab;

const NavItem = styled(Nav.Item)`
	.nav-link.active {
		background: ${navy};
		color: white;
	}
	.nav-link {
		color: black;
	}
`;

const NavLink = styled(Nav.Link)``;
