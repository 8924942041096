import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { statementsStateSelector } from '@note/atoms';

import 'react-quill/dist/quill.snow.css';

import ActionButtons from './ActionButtons';
import { TextArea, TextAreaTitle, TitleContainer } from './styles';
import { TextModules, TitleModules } from './toolbarOptions';

const ReadMode = ({ statement }) => {
	const setStatement = useSetRecoilState(statementsStateSelector);

	const [titleValue, setTitleValue] = useState('');
	const [textValue, setTextValue] = useState('');

	// Converts the title's text content into HTML paragraphs with strong tags if it doesn't already contain HTML
	const convertNewTitlelinesToParagraphs = text => {
		if (text?.includes('<p>')) {
			return text;
		}

		return text
			?.split(/\n+/)
			.filter(p => p)
			.map(p => `<p><strong>${p}</strong></p>`)
			.join('');
	};

	// Converts the main text content into HTML paragraphs if it doesn't already contain HTML
	const convertNewlinesToParagraphs = text => {
		if (text?.includes('<p>')) {
			return text;
		}

		return text
			?.split(/\n+/)
			.filter(p => p)
			.map(p => `<p>${p}</p>`)
			.join('<br/>');
	};

	// Initializes the title and text values when the component mounts or the statement changes
	useEffect(() => {
		setTitleValue(convertNewTitlelinesToParagraphs(statement.editedName));
		setTextValue(convertNewlinesToParagraphs(statement.editedPrefilledText));
	}, [statement]);

	// Updates the statement in the global state when the user stops editing
	const handleBlur = (value, name) => {
		setStatement({ ...statement, [name]: value });
	};

	// Updates the local state with the current content as the user types
	const handleTextChange = (htmlValue, name) => {
		if (name === 'editedName') {
			setTitleValue(htmlValue);
		} else if (name === 'editedPrefilledText') {
			setTextValue(htmlValue);
		}
	};

	// Resets the input fields to the original values if changes are discarded
	const resetInputs = () => {
		setTitleValue(convertNewTitlelinesToParagraphs(statement.editedName));
		setTextValue(convertNewlinesToParagraphs(statement.editedPrefilledText));
	};

	return (
		<form onSubmit={e => e.preventDefault()}>
			<TitleContainer>
				<TextAreaTitle
					theme="snow"
					value={titleValue}
					onChange={htmlValue => handleTextChange(htmlValue, 'editedName')}
					onBlur={() => handleBlur(titleValue, 'editedName')}
					modules={TitleModules}
				/>
				<ActionButtons
					resetInputs={resetInputs}
					statement={statement}
					updatedValues={{
						editedName: titleValue,
						editedPrefilledText: textValue,
					}}
				/>
			</TitleContainer>
			<TextArea
				theme="snow"
				value={textValue}
				onChange={htmlValue => handleTextChange(htmlValue, 'editedPrefilledText')}
				onBlur={() => handleBlur(textValue, 'editedPrefilledText')}
				modules={TextModules}
			/>
		</form>
	);
};

export default ReadMode;
