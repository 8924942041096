import { useNote } from 'contexts/NoteContext';
import { isNil } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { Alert, Card, ListGroup } from 'react-bootstrap';
import { AuthContext } from '@note/contexts/AuthContext';
import ArchiveNoteType from './ArchiveNoteType';
import DuplicateNoteType from './DuplicateNotetype';
import ListItem from './ListItem';
import RenameNoteType from './RenameNoteType';

const SelectedNoteType = ({ noteTypeId }) => {
	const { noteTypes } = useNote();
	const { user } = useContext(AuthContext);

	// Memoizing the selected note type based on the provided ID for efficiency
	const noteType = useMemo(() => {
		return noteTypes?.find(nt => nt.id === parseInt(noteTypeId));
	}, [noteTypeId, noteTypes]);

	// Determining if the selected note type belongs to the current user
	const isMyNoteType = useMemo(() => {
		if (user && noteType) {
			return user.id === noteType.user;
		}
		return false;
	}, [noteType, user]);

	// Handling case where no note type is selected
	if (isNil(noteTypeId)) {
		return (
			<Card className="h-100">
				<Card.Body className="d-flex align-items-center justify-content-center">
					<h4>Please select a note-type on the left</h4>
				</Card.Body>
			</Card>
		);
	}

	return (
		<>
			<Card className="h-100">
				<Card.Body>
					<Card.Title>{noteType && <h1>{noteType.name}</h1>}</Card.Title>
					{!isMyNoteType && (
						<Alert>
							This is one of Note Designer's default note-types; you can duplicate it, but not rename or delete it
						</Alert>
					)}

					<ListGroup variant="flush">
						{isMyNoteType && (
							<ListItem title="Rename note-type" subTitle="This will rename your selected note-type">
								<RenameNoteType noteType={noteType} />
							</ListItem>
						)}

						<ListItem title="Create a duplicate" subTitle="This will create a duplicate of the selected note-type">
							<DuplicateNoteType noteType={noteType} />
						</ListItem>
						{isMyNoteType && (
							<ListItem
								title="Archive note-type"
								subTitle="This will store the note-type in the archive; you can retrieve it here later."
							>
								<ArchiveNoteType noteType={noteType} />
							</ListItem>
						)}
					</ListGroup>
				</Card.Body>
			</Card>
		</>
	);
};

export default SelectedNoteType;
