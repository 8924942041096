import React from 'react';
import styled from 'styled-components';

// TextElement component to render text based on preview mode and provided styles
const TextElement = React.memo(({ value, placeholder, isPreview, style }) => {
	const renderContent = (value, placeholder) => {
		if (isPreview) return value ? value : placeholder; // Show placeholder if no value in preview mode
		return value ? value : ''; // Show nothing if no value in non-preview mode
	};

	return <p style={style}>{renderContent(value, placeholder)}</p>;
});

// Letterhead component to render a letterhead with fields or as HTML based on its type
const Letterhead = ({ letterhead, isPreview = false }) => {
	// Render letterhead fields in the desired order with specific styles
	const renderFieldsLetterhead = () => {
		const letterheadKeys = Object.keys(letterhead).filter(key => key !== 'type');
		const desiredOrder = ['name', 'companyName', 'address', 'phoneNumber', 'email'];
		const sortedKeys = letterheadKeys.sort((a, b) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b));

		return (
			<LetterheadConainer style={{ textAlign: 'center' }} isPreview={isPreview}>
				{sortedKeys.map(key => {
					const { value, font, isBold, isItalic, fontSize, placeholder } = letterhead[key];
					const style = {
						fontFamily: font,
						fontWeight: isBold ? 'bold' : 'normal',
						fontStyle: isItalic ? 'italic' : 'normal',
						fontSize: fontSize,
						marginBottom: 'unset',
						lineHeight: 1.3,
					};

					return <TextElement key={key} value={value} placeholder={placeholder} isPreview={isPreview} style={style} />;
				})}
			</LetterheadConainer>
		);
	};

	return (
		<>
			{letterhead.type === 'formfields' || !letterhead.type ? (
				renderFieldsLetterhead() // Render letterhead with form fields
			) : (
				<span style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: letterhead.value }} /> // Render letterhead as HTML
			)}
		</>
	);
};

export default Letterhead;

// Styled component for the letterhead container
const LetterheadConainer = styled.div`
	text-align: 'center';
	height: ${props => (props.isPreview ? '27vh' : 'unset')};
	padding: 2rem;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
