import React, { useState, useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa';
import styled from 'styled-components';
import VideosModal from '@note/features/videosModal/VideosModal';
import QuestionModal from '@note/modals/questionModal/QuestionModal';
import FAQModal from '@note/features/FAQModal/FAQModal';

import './SupportMenu.scss';

// Accessed via the "Help & Support" navbar button
const SupportMenu = (isBurgerMenu = false) => {
	const [showVideosModal, setVideosModal] = useState(false);
	const [showQuestion, setShowQuestion] = useState(false);
	const [showFAQModal, setFAQModal] = useState(false);

	const openVideos = useCallback(() => setVideosModal(true), []);
	const closeVideos = useCallback(() => setVideosModal(false), []);

	const openFAQ = useCallback(() => setFAQModal(true), []);
	const closeFAQ = useCallback(() => setFAQModal(false), []);

	const closeQuestion = () => {
		setShowQuestion(false);
	};

	const openQuestion = () => {
		setShowQuestion(true);
	};

	return (
		<>
			<Dropdown style={{ maxHeight: 23, transform: 'translateY(-5px)', zIndex: 100 }}>
				<DropdownToggle variant="success" id="dropdown-basic" drop="left">
					<NavItem>
						<MenuIcon>
							<FaQuestionCircle />
						</MenuIcon>
						<span>Help & Support</span>
					</NavItem>
				</DropdownToggle>
				<Menu>
					<Dropdown.Item href="https://notedesigner.com/app-help-menu/" target="_blank">
						Tips & Tutorials
					</Dropdown.Item>
					<Dropdown.Item onClick={() => openFAQ()}>FAQs</Dropdown.Item>
					<Dropdown.Item onClick={() => openVideos()}>Videos</Dropdown.Item>
					<Dropdown.Item onClick={() => openQuestion()}>Contact us</Dropdown.Item>
					<Dropdown.Divider />
					<Dropdown.Item href="https://notedesigner.com/license/" target="_blank">
						<TermsMessage>
							{`Content of this app is licensed from Patricia A. R. Csank, PhD, and is provided to authorized users to help in the preparation of their professional documentation. ©2013-${new Date().getFullYear()} Note Designer Inc. All rights reserved.(click here to see the full license agreement)`}
						</TermsMessage>
					</Dropdown.Item>
				</Menu>
			</Dropdown>
			{showVideosModal && <VideosModal show={showVideosModal} handleClose={closeVideos} />}
			{showFAQModal && <FAQModal show={showFAQModal} handleClose={closeFAQ} />}
			{showQuestion && <QuestionModal show={showQuestion} handleCloseQuestionModal={closeQuestion} />}
		</>
	);
};

export default SupportMenu;

const Menu = styled(Dropdown.Menu)`
	width: fit-content;
	@media (max-width: 991px) {
		position: absolute;
		transform: translate(-100%, -30px);
		left: auto !important;
	}
	@media (min-width: 992px) and (max-width: 1051px) {
		position: absolute;
		transform: translate(-50%, 10px);
		left: auto !important;
	}
`;

const DropdownToggle = styled(Dropdown.Toggle)`
	background: none !important;
	border: none;
	@media (max-width: 991px) {
		padding-left: unset;
	}
	&:after {
		display: none;
	}
`;

const NavItem = styled.span`
	color: #a7a8a9;
	position: relative;
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	padding-left: 20px;
	cursor: pointer;
	& > svg {
		font-size: 18px;
		margin-right: 5px;
	}
	&:hover {
		color: white;
	}
	@media (max-width: 991px) {
		color: #1e2125;
		&:hover {
			color: #1e2125;
			background: #e9ecef;
		}
	}
	@media (min-width: 992px) and (max-width: 1051px) {
		& > span {
			display: none;
		}
	}
`;

const MenuIcon = styled.div`
	margin-right: 5px;
	margin-top: -2px;
	@media (max-width: 991px) {
		display: none;
	}
`;

const TermsMessage = styled.div`
	width: 250px;
	white-space: Normal;
	font-size: 12px;
`;
