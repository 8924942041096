export const blankLetterhead = {
	type: 'formfields',
	name: {
		label: 'Name',
		value: '',
		font: 'Arial',
		fontSize: 24,
		isBold: true,
		isItalic: false,
		placeholder: 'Your Name, Credentials',
	},
	companyName: {
		label: 'Company name',
		value: '',
		font: 'Arial',
		fontSize: 12,
		isBold: true,
		isItalic: false,
		placeholder: 'Serenity Now Clinic, Anytown',
	},
	address: {
		label: 'Address',
		value: '',
		font: 'Arial',
		fontSize: 12,
		isBold: false,
		isItalic: false,
		placeholder: '101 Any Street, Anytown',
	},
	phoneNumber: {
		label: 'Phone number',
		value: '',
		font: 'Arial',
		fontSize: 12,
		isBold: false,
		isItalic: false,
		placeholder: '555-225-4078',
	},
	email: {
		label: 'Email',
		value: '',
		font: 'Arial',
		fontSize: 12,
		isBold: false,
		isItalic: false,
		placeholder: 'yourname@serenitynowanytown.com',
	},
};
