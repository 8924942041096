import { Spinner } from 'components/Spinner';
import { ThemeButton } from 'components/ThemeButton';
import { useNote } from 'contexts/NoteContext';
import { usePreference } from 'contexts/PreferenceContext';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FaDownload, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { getSectionsByNoteType } from 'services/section';
import { createStatement, getLibraryStatementsByNoteType } from 'services/statement';
import { getStemByNoteType } from 'services/stem';
import { getSubTabsByNoteType } from 'services/subTab';
import { getTabsByNoteId } from 'services/tab';
import styled from 'styled-components';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';

import { statementsState } from '@note/atoms';
import { AuthContext } from '@note/contexts/AuthContext';

import LibrarySelector from './LibrarySelector';

const LibraryModal = ({ show, handleClose }) => {
	const { isOldSafari } = useContext(AuthContext);
	const { activeNoteType } = useNote();
	const [statements, setStatements] = useRecoilState(statementsState);
	const [isSaving, setIsSaving] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [libraryTabs, setLibraryTabs] = useState([]);
	const [librarySubTabs, setLibrarySubTabs] = useState([]);
	const [librarySections, setLibrarySections] = useState([]);
	const [libraryStems, setLibraryStems] = useState([]);
	const [libraryStatements, setLibraryStatements] = useState([]);
	const { globalPreferences } = usePreference();

	// Load initial data when the component mounts
	useEffect(() => {
		setIsLoading(true);
		const id = activeNoteType.id;
		Promise.all([
			getTabsByNoteId(id),
			getSubTabsByNoteType(id),
			getSectionsByNoteType(id),
			getLibraryStatementsByNoteType(id),
			getStemByNoteType(id),
		])
			.then(([tabs, subTabs, sections, statements, stems]) => {
				setLibraryTabs(tabs.data.filter(menu => menu.note_type === parseInt(id)));
				setLibrarySubTabs(subTabs.data.filter(subTab => subTab?.type !== null));
				setLibrarySections(sections.data);
				setLibraryStatements(
					statements.data.map(statement => ({
						...statement,
						isSelected: false,
						selectedType: null,
						content: '',
						selectedOption: null,
						stem: null,
					}))
				);
				setLibraryStems(stems.data);
				setIsLoading(false);
			})
			.catch(error => {
				console.error('Error loading data:', error);
				setIsLoading(false);
			});
	}, [activeNoteType]);

	// Handle import action
	const handleImport = async () => {
		let importedStatements = libraryStatements.filter(st => st.isSelected);
		importedStatements = importedStatements.map((importedStatement, index) => {
			const filtered = statements.filter(st => st.section === importedStatement.section);
			const order = Math.max(...filtered.map(st => st.order_index)) + 1 + index;
			return {
				name: importedStatement.name,
				section: importedStatement.section,
				order_index: order,
				is_library: false,
			};
		});

		const promises = importedStatements.map(importedStatement => createStatement(importedStatement));

		try {
			setIsSaving(true);
			const response = await Promise.all(promises);
			const results = response.map(resItem => resItem.data);
			setStatements([...statements, ...results]);
			setIsSaving(false);
			!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.SUCCESS);
			toast.success('Imported statements successfully', { hideProgressBar: true });
			handleClose();
		} catch (error) {
			console.error('Error importing statements:', error);
		}
	};

	return (
		<CustomModal show={show} onHide={handleClose} centered scrollable size="xl">
			<Modal.Header closeButton>
				<Modal.Title>Library</Modal.Title>
			</Modal.Header>
			<ModalBody>
				{isLoading ? (
					<Spinner />
				) : (
					<LibrarySelector
						libraryTabs={libraryTabs}
						librarySubTabs={librarySubTabs}
						librarySections={librarySections}
						libraryStatements={libraryStatements}
						setLibraryStatements={setLibraryStatements}
						libraryStems={libraryStems}
						statements={statements}
					/>
				)}
			</ModalBody>
			<Modal.Footer>
				<ThemeButton label="Discard and close" icon={FaTimes} size="lg" color="grey" onClick={handleClose} />
				<ThemeButton
					onClick={handleImport}
					disabled={isSaving}
					label={isSaving ? 'Importing...' : 'Import'}
					icon={FaDownload}
					size="lg"
					color="green"
				/>
			</Modal.Footer>
		</CustomModal>
	);
};

export default LibraryModal;

const CustomModal = styled(Modal)`
	div.modal-content {
		height: 100%;
	}
`;

const ModalBody = styled(Modal.Body)`
	padding: 0;
`;
