const getBlogArticle = async url => {
	// Fetch the HTML content of the blog article from the provided URL
	const response = await fetch(url);
	const html = await response.text();

	// Parse the HTML content into a DOM object for manipulation
	const parser = new DOMParser();
	const doc = parser.parseFromString(html, 'text/html');

	// Select the main article element from the parsed document
	const article = doc.querySelector('article');

	// Remove any social media sections within the article if they exist
	const div = article.querySelector('.single-social-section');
	if (div) {
		div.remove();
	}

	// Ensure all links in the article open in a new tab
	const anchors = article.querySelectorAll('a');
	anchors.forEach(anchor => {
		anchor.target = '_blank';
	});

	// Adjust the size of all images within the article
	const images = article.querySelectorAll('img');
	for (let img of images) {
		// Create a new Image element to ensure each image is fully loaded before resizing
		let newImg = new Image();
		newImg.onload = () => {
			// Set the width and height to be 30% of their original (natural) size
			img.style.width = newImg.naturalWidth * 0.3 + 'px';
			img.style.height = newImg.naturalHeight * 0.3 + 'px';
		};
		newImg.src = img.src;
	}

	return article;
};

export { getBlogArticle };
