import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { ThemeButton } from 'components/ThemeButton';
import NotificationList from './NotificationList';
import SelectedNotification from './SelectedNotification';

// Accessed via the bell icon at the top menu
const NotificationModal = ({ show, handleClose, urgentNotification = null }) => {
	const [selectedNotification, setSelectedNotification] = useState(urgentNotification);

	const handleNotificationClick = notification => {
		setSelectedNotification(notification);
	};

	const handleBack = () => {
		setSelectedNotification(null);
	};

	return (
		<ModalContainer show={show} onHide={handleClose} centered size="lg">
			<Modal.Header closeButton>
				<Modal.Title>Notification</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{selectedNotification ? (
					<SelectedNotification handleBack={handleBack} selectedNotification={selectedNotification} />
				) : (
					<NotificationList handleNotificationClick={handleNotificationClick} />
				)}
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label="Close" color="grey" onClick={handleClose} />
			</Modal.Footer>
		</ModalContainer>
	);
};

export default NotificationModal;

const ModalContainer = styled(Modal)`
	div.modal-content {
		max-height: 90vh;
	}

	div.card-body {
		overflow-y: scroll;
		max-height: 65vh;
	}

	@media (min-width: 950px) {
		div.modal-dialog {
			max-width: 40vw;
		}
	}
`;
