import { ThemeButton } from 'components/ThemeButton';
import React, { useCallback, useRef } from 'react';
import { Badge, Form, ListGroup } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { useRecoilState } from 'recoil';
import { updateSubTabSettings } from 'services/subTab';
import styled from 'styled-components';

import { subTabsState } from '@note/atoms';

const EditMode = ({ options, setOptions, subTab, toggleEditMode }) => {
	const optionsRef = useRef();
	const [subTabs, setSubTabs] = useRecoilState(subTabsState);

	// Handle pressing "Enter" to add a new option
	const handleKeyPress = useCallback(
		e => {
			if (e.key === 'Enter') {
				setOptions([...options, e.target.value]); // Add the new option to the list
				optionsRef.current.value = ''; // Clear the input field
			}
		},
		[options, setOptions]
	);

	// Save the updated options to the subTab settings
	const handleSave = useCallback(async () => {
		try {
			const res = await updateSubTabSettings(subTab.id, { ...subTab.settings, multipleChoice: { options: options } });
			// Update the subTab settings with the new options
			setSubTabs(subTabs.map(st => (st.id === subTab.id ? { ...st, settings: res.data.settings } : st)));
			toggleEditMode(); // Exit edit mode
		} catch (error) {
			console.error('🚀 ~ file: EditMode.js ~ line 38 ~ error', error);
		}
	}, [options, setSubTabs, subTab.id, subTab.settings, subTabs, toggleEditMode]);

	// Handle the removal of an option
	const handleRemove = useCallback(
		removedOption => {
			// Filter out the removed option from the list
			setOptions(options.filter(option => option !== removedOption));
		},
		[options, setOptions]
	);

	// Cancel the edit mode and reset the options to the original state
	const handleCancel = useCallback(() => {
		setOptions(subTab.settings.multipleChoice.options); // Reset to original options
		toggleEditMode(); // Exit edit mode
	}, [setOptions, subTab.settings.multipleChoice.options, toggleEditMode]);

	return (
		<>
			<Form.Control
				type="text"
				ref={optionsRef}
				onKeyPress={handleKeyPress}
				placeholder="Type and *PRESS ENTER* to add a new dropdown option"
			/>
			<ListGroup className="mt-1" variant="flush">
				{options.map((option, index) => (
					<ListGroup.Item key={index} className="d-flex justify-content-between align-items-start">
						<div className="ms-2 me-auto">{option}</div>
						<Badge bg="danger" pill onClick={() => handleRemove(option)}>
							<FaTimes />
						</Badge>
					</ListGroup.Item>
				))}
				<ButtonContainer>
					<ThemeButton onClick={handleSave} label="Save" />
					<ThemeButton label="Cancel" color="grey" onClick={handleCancel} />
				</ButtonContainer>
			</ListGroup>
		</>
	);
};

export default EditMode;

const ButtonContainer = styled.div`
	display: inline-flex;
	column-gap: 5px;
	margin: 10px 0;
	justify-content: flex-end;
`;
