/* eslint-disable no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */
import { ThemeButton } from 'components/ThemeButton';
import React from 'react';
import styled from 'styled-components';
import { WidgetType } from 'utils/enum';

import ToggleSwitch from './ToggleSwitch';

export const StemFooter = ({ handleCancel, handleSave, subTab, toggleStem, disableSave }) => {
	return (
		<Footer>
			<ThemeButton label="Save" onClick={handleSave} disabled={disableSave} />
			{(subTab.type === WidgetType.LIST_SELECT || subTab.type === WidgetType.LIST_MULTI_SELECT) && (
				<ToggleSwitch subTab={subTab} toggleStem={toggleStem} />
			)}
			<ThemeButton onClick={handleCancel} label="Cancel" color="grey" />
		</Footer>
	);
};

export default StemFooter;

const Footer = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 5px;
`;

const ActionBtn = styled.button`
	position: relative;
	border: none;
	background: ${props => props.color};
	color: white;
	padding: 5px 10px;
	border-radius: 3px;
	margin-right: 5px;
	&:hover {
		transform: scale(1.1);
	}
`;

const ButtonContainer = styled.div`
	display: none;
	position: absolute;
	bottom: -30px;
	right: 0;
	z-index: 1;
`;
