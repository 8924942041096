import React, { useEffect, useRef, useState } from 'react';
import { FaSave, FaSpinner, FaTimes, FaTrashAlt } from 'react-icons/fa';
import styled from 'styled-components';

import { green, grey, red } from 'styles/colors';

const SectionNameInput = ({
	defaultValue,
	handleSave,
	toggleEditMode,
	isLoading = false,
	placeholder = '',
	showDelete = false,
	handleDelete,
}) => {
	const [textValue, setTextValue] = useState(defaultValue);

	const inputRef = useRef();

	// Automatically focus the input when edit mode is toggled on
	useEffect(() => {
		if (toggleEditMode) inputRef.current.focus();
	}, [toggleEditMode]);

	const handleCancel = () => {
		setTextValue(defaultValue);
		toggleEditMode();
	};

	return (
		<InputContainer>
			<NameInput
				ref={inputRef}
				valid={textValue && textValue.length > 0}
				placeholder={placeholder}
				type="text"
				onChange={e => setTextValue(e.target.value)}
				value={textValue}
			/>
			<ButtonContainer>
				{showDelete && (
					<ActionBtn valid={true} color={red} onClick={handleDelete}>
						<FaTrashAlt />
					</ActionBtn>
				)}
				<ActionBtn valid={textValue.length > 0} color={green} onClick={() => handleSave(textValue)}>
					{isLoading ? <FaSpinner className="spinner" /> : <FaSave />}
				</ActionBtn>
				<ActionBtn valid={true} color={grey} onClick={handleCancel}>
					<FaTimes />
				</ActionBtn>
			</ButtonContainer>
		</InputContainer>
	);
};

export default SectionNameInput;

const NameInput = styled.input`
	width: 100%;
	height: 35px;
	margin-top: 5px;
	margin-bottom: 15px;
	font-weight: bold;
	padding-left: 5px;
	border-radius: 5px;
	border: 1px solid ${props => (props.valid ? '#212529' : '#dc3545')};
	outline: none;
`;

const InputContainer = styled.div`
	position: relative;
`;

const ActionBtn = styled.button`
	position: relative;
	border: none;
	background: ${props => props.color};
	color: white;
	padding: 5px 10px;
	border-radius: 3px;
	margin-right: 5px;
	&:hover {
		transform: scale(1.1);
	}
	${props =>
		!props.valid &&
		`
          opacity: 0.6;
          pointer-events: none;
        `}
`;

const ButtonContainer = styled.div`
	position: absolute;
	bottom: -15px;
	right: 0;
	z-index: 1;
`;
