import React from 'react';
import styled from 'styled-components';

import SubTab from '../subtab';
import WidgetSelector from './WidgetSelector';
import PreviewScreen from './preview-screen';

const DesignerSubTab = ({ subTab }) => (
	<SubTabContainer>
		<MainScreenContainer>
			{subTab?.isNew ? <WidgetSelector subTab={subTab} /> : <SubTab subTab={subTab} />}
		</MainScreenContainer>
		<PreviewScreen subTab={subTab} />
	</SubTabContainer>
);

export default DesignerSubTab;

const SubTabContainer = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
`;

const MainScreenContainer = styled.div`
	width: 60%;
	height: 100%;
	display: inline-block;
	/* overflow-y: auto; */
	/* overflow-x: hidden; */
	padding: 10px 5px 10px 10px;
`;
