import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { ThemeButton } from 'components/ThemeButton';

// The modal of the note converterd to pdf format

const ExportModal = ({ show, handleClose, iframeURL }) => {
	return (
		<CustomModal show={show} onHide={handleClose} centered size="xl" scrollable>
			<Modal.Header closeButton>
				<Modal.Title>Export</Modal.Title>
			</Modal.Header>
			<ModalBody>
				<WarningMsg>
					Your note will appear temporarily below but <b>will NOT be printed or saved</b> anywhere unless you use a
					button in the window below to print or save the note.
				</WarningMsg>
				{iframeURL && <IFrame id="exportiframe" src={iframeURL}></IFrame>}
			</ModalBody>
			<Modal.Footer>
				<ThemeButton label="Close" color="grey" onClick={handleClose} />
			</Modal.Footer>
		</CustomModal>
	);
};

export default ExportModal;

const CustomModal = styled(Modal)`
	div.modal-content {
		height: 100%;
	}
`;

const WarningMsg = styled.p`
	padding: 7px 15px;
	margin: 0;
`;

const IFrame = styled.iframe`
	width: 100%;
	height: calc(100% - 38px);
`;

const ModalBody = styled(Modal.Body)`
	padding: 0;
	overflow: hidden !important;
`;
