import { convert } from 'html-to-text';
import SubTabBreak from 'layouts/note-output/SubTabBreak';
import SubTabTitle from 'layouts/note-output/SubTabTitle';
import { usePreference } from 'contexts/PreferenceContext';
import { flatten } from 'lodash';
import { replaceTerminologies } from 'utils/terminologyHelper';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { editOutputState, manualInputStatementState } from '@note/atoms';

import StatementLine from '../StatementLine';

const ManualInputNoteContent = ({ subTab, filteredSections, subTabStatements }) => {
	const { multiLevelPreferences } = usePreference();
	const editOutput = useRecoilValue(editOutputState);
	const manualInputs = useRecoilValue(manualInputStatementState);
	const [editedText, setEditedText] = useState({});

	// Filter statements based on manual inputs and sections
	const filteredStatements = useMemo(
		() =>
			flatten(
				filteredSections.map(section =>
					subTabStatements.filter(st => st.section === section.id && manualInputs.includes(st.id))
				)
			),
		[filteredSections, manualInputs, subTabStatements]
	);

	// Check if there are any non-empty statements
	const isNotEmpty = useMemo(
		() => filteredStatements.some(st => st?.content && st.content.length > 0),
		[filteredStatements]
	);

	// Handle changes in the editable content
	const handleChange = useCallback((event, statementId) => {
		setEditedText(prevState => ({ ...prevState, [statementId]: event.target.value }));
	}, []);

	// Filter statements again to ensure non-empty content
	const refilteredStatements = filteredStatements.filter(st => st?.content && st.content.length > 0);

	// Render text for the statement
	const renderText = statement => {
		const text =
			convert(editedText)[statement.id] || (statement?.name && statement?.name.length > 0 && statement.name + ' ');
		return editOutput ? (
			<Editable
				tagName="span"
				id={statement.id + '-manual'}
				html={text}
				onChange={event => handleChange(event, statement.id)}
				className="contentEditable"
			/>
		) : (
			<span id={statement.id + '-manual'}>{replaceTerminologies(text, multiLevelPreferences)}</span>
		);
	};

	return isNotEmpty ? (
		<>
			<SubTabTitle isVisible={isNotEmpty} title={subTab.name} />
			{refilteredStatements.map((statement, index, array) => (
				<Fragment key={index}>
					{renderText(statement)}
					<StatementLine key={index} hasPunctuations={false} statementName={statement.content} />
					{index === refilteredStatements.length - 1 && <SubTabBreak isVisible={isNotEmpty} />}
				</Fragment>
			))}
		</>
	) : null;
};

export default ManualInputNoteContent;

const Editable = styled(ContentEditable)`
	&:focus-visible {
		outline: 1px solid #37a4fa;
		border-radius: 3px;
	}
`;
