import React, { useState, useEffect, useContext } from 'react';
import { Card, Form } from 'react-bootstrap';
import Select, { components } from 'react-select';

import { AuthContext } from '@note/contexts/AuthContext';

const fontOptions = [
	{ value: 'Hurricane', label: 'Hurricane', fontFamily: '"Hurricane"' },
	{ value: 'Mrs Saint Delafield', label: 'Mrs Saint Delafield', fontFamily: '"Mrs Saint Delafield"' },
	{ value: 'Alex Brush', label: 'Alex Brush', fontFamily: '"Alex Brush"' },
	{ value: 'Dawning of a New Day', label: 'Dawning of a New Day', fontFamily: '"Dawning of a New Day"' },
	{ value: 'Homemade Apple', label: 'Homemade Apple', fontFamily: '"Homemade Apple"' },
	{ value: 'Nanum Brush Script', label: 'Nanum Brush Script', fontFamily: '"Nanum Brush Script"' },
	{ value: 'Rock Salt', label: 'Rock Salt', fontFamily: '"Rock salt"' },
	{ value: 'Zeyada', label: 'Zeyada', fontFamily: '"Zeyada"' },
];

const fontSizeOptions = [
	{ value: 12, label: '12' },
	{ value: 16, label: '16' },
	{ value: 20, label: '20' },
	{ value: 24, label: '24' },
	{ value: 28, label: '28' },
	{ value: 30, label: '30' },
];

const SignatureTyped = ({ setSignatureJson }) => {
	const { signatureTextObject } = useContext(AuthContext);
	const [signatureText, setSignatureText] = useState(signatureTextObject?.signatureText || '');
	const [selectedFontOption, setSelectedFontOption] = useState(
		fontOptions.find(option => option.value === signatureTextObject?.selectedFontOption) || null
	);
	const [selectedFontSizeOption, setSelectedFontSizeOption] = useState(
		fontSizeOptions.find(option => option.value === signatureTextObject?.selectedFontSizeOption) || null
	);

	// Custom option component to display font family in dropdown
	const CustomOption = props => (
		<components.Option {...props}>
			<div style={{ fontFamily: props.data.fontFamily }}>{props.data.label}</div>
		</components.Option>
	);

	// Custom option component to display font size in dropdown
	const CustomSizeOption = props => (
		<components.Option {...props}>
			<div style={{ fontSize: props.data.value }}>{props.data.label}</div>
		</components.Option>
	);

	// Custom styles for font family dropdown
	const customStyles = {
		singleValue: (provided, state) => ({
			...provided,
			fontFamily: state.data.fontFamily,
			fontSize: 20,
		}),
	};

	// Custom styles for font size dropdown
	const customSizeStyles = {
		singleValue: (provided, state) => ({
			...provided,
			fontSize: state.data.fontSize,
		}),
	};

	// Handle font family change
	const handleFontFamilyChange = selectedOption => {
		setSelectedFontOption(selectedOption);
	};

	// Handle font size change
	const handleFontSizeChange = selectedOption => {
		setSelectedFontSizeOption(selectedOption);
	};

	// Update selected font and size options on component mount
	useEffect(() => {
		if (signatureTextObject?.selectedFontOption) {
			const matchingOption = fontOptions.find(option => option.value === signatureTextObject.selectedFontOption);
			setSelectedFontOption(matchingOption || null);
		}
		if (signatureTextObject?.selectedFontSizeOption) {
			const matchingOption = fontSizeOptions.find(
				option => option.value === signatureTextObject.selectedFontSizeOption
			);
			setSelectedFontSizeOption(matchingOption || null);
		}
	}, [signatureTextObject?.selectedFontOption, signatureTextObject?.selectedFontSizeOption]);

	// Update signature JSON whenever signature text, font, or size changes
	useEffect(() => {
		setSignatureJson({
			signatureText,
			selectedFontOption: selectedFontOption ? selectedFontOption.value : null,
			selectedFontSizeOption: selectedFontSizeOption ? selectedFontSizeOption.value : null,
		});
	}, [signatureText, selectedFontOption, setSignatureJson, selectedFontSizeOption]);

	return (
		<div>
			<Card className="my-2">
				<Card.Header>Select a font type</Card.Header>
				<Card.Body>
					<Select
						options={fontOptions}
						components={{ Option: CustomOption }}
						styles={customStyles}
						onChange={handleFontFamilyChange}
						value={selectedFontOption}
					/>
				</Card.Body>
			</Card>
			<Card className="my-2">
				<Card.Header>Select a font size</Card.Header>
				<Card.Body>
					<Select
						options={fontSizeOptions}
						components={{ Option: CustomSizeOption }}
						styles={customSizeStyles}
						onChange={handleFontSizeChange}
						value={selectedFontSizeOption}
					/>
				</Card.Body>
			</Card>
			<Card className="my-2">
				<Card.Header>Type your signature here</Card.Header>
				<Card.Body>
					<Form onSubmit={() => {}}>
						<Form.Control
							type="text"
							placeholder="Enter signature"
							value={signatureText}
							onChange={e => {
								setSignatureText(e.target.value);
							}}
							required
						/>
					</Form>
				</Card.Body>
			</Card>
			<Card>
				<Card.Header>Preview of your new signature</Card.Header>
				<Card.Body>
					<span
						style={{
							fontFamily: selectedFontOption ? selectedFontOption.fontFamily : 'unset',
							fontSize: selectedFontSizeOption ? selectedFontSizeOption.value : 35,
						}}
					>
						{signatureText}
					</span>
				</Card.Body>
			</Card>
		</div>
	);
};

export default SignatureTyped;
