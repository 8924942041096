import React, { useState } from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap';
import { useNote } from 'contexts/NoteContext';
import { saveNoteType } from 'functions/save-as';

const DuplicateNoteType = ({ noteType }) => {
    const { noteTypes, setNoteTypes } = useNote();
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState('')
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            duplicate();
        }
        setValidated(true);
    };

    const duplicate = async () => {
        setIsLoading(true)
        const newNoteType = await saveNoteType(noteType.id, name);
        setIsLoading(false)
        setNoteTypes([...noteTypes, newNoteType])
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group >
                <InputGroup hasValidation className="mb-3">
                    <Form.Control
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter name for the duplicate note-type"
                    />
                    <Button type='submit' disabled={isLoading} variant="primary" id="button-addon2">
                        {isLoading ? 'Duplicating...' : 'Duplicate'}
                    </Button>
                    <Form.Control.Feedback type="invalid">
                        Please choose a note-type name.
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
        </Form>
    )
}

export default DuplicateNoteType