import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import { manualInputStatementState, statementsStateSelector } from '@note/atoms';

import InputLabel from './InputLabel';

export const ManualInput = ({
	statement,
	editMode,
	placeholderText,
	statementText,
	setPlaceholderText,
	setStatementText,
}) => {
	const setStatement = useSetRecoilState(statementsStateSelector);
	const [manualInputs, setManualInputs] = useRecoilState(manualInputStatementState);
	const [inputValue, setInputValue] = useState(statement.content);

	// Effect to clear input if statement ID is not in the manualInputs array
	useEffect(() => {
		if (!Array.isArray(manualInputs) || !manualInputs.includes(statement.id)) {
			setInputValue(''); // Reset input if it's not in manualInputs
		}
	}, [manualInputs, statement.id]);

	const handleChange = e => {
		const value = e.target.value;
		setInputValue(value);

		// Update manualInputs state based on the input value
		if (value !== null && value !== '') {
			setManualInputs(prevManualInputs => {
				if (!prevManualInputs.includes(statement.id)) {
					return [...prevManualInputs, statement.id]; // Add statement ID if it's not already in the array
				}
				return prevManualInputs;
			});
		} else if (value === null || value === '') {
			setManualInputs(prevManualInputs => prevManualInputs.filter(inputId => inputId !== statement.id)); // Remove statement ID if input is empty
		}
		setStatement({ ...statement, content: value }); // Update the statement content
	};

	// Handle changes in the placeholder text
	const handlePlaceHolderChange = e => {
		setStatement({ ...statement, placeholder: e.target.value }); // Update the statement placeholder
	};

	return (
		<InputContainer>
			<InputLabel
				statementText={statementText}
				setStatementText={setStatementText}
				placeholderText={placeholderText}
				setPlaceholderText={setPlaceholderText}
				handlePlaceHolderChange={handlePlaceHolderChange}
				editMode={editMode}
				statement={statement}
			/>
			{/* Render a text input if not in edit mode */}
			{!editMode && (
				<TextInput placeholder={statement.placeholder} value={inputValue} onChange={handleChange} rows={3} />
			)}
		</InputContainer>
	);
};

const TextInput = styled.textarea`
	height: 25vh;
	width: 100%;
	outline: none;
	border: 1px solid #00000020;
`;

const InputContainer = styled.div`
	width: 100%;
`;
