import React, { useState } from 'react';
import { FaSearch, FaPlus } from 'react-icons/fa';
import styled from 'styled-components';

import { blue, navy } from 'styles/colors';

// This is the search bar to refine the statements list
export const Search = ({ label, handleSearch, useClick = false }) => {
	const [inputValue, setInputValue] = useState('');
	const [showCancel, setShowCancel] = useState(false);

	// Handle input change and toggle cancel button visibility
	const handleChange = event => {
		const value = event.target.value;
		setInputValue(value);
		setShowCancel(value.length > 0);
	};

	// Handle form submission
	const handleSubmit = event => {
		event.preventDefault();
		handleSearch(inputValue);
	};

	// Render dynamic search bar
	const renderDynamicSearch = () => {
		return (
			<SearchContainer>
				<SearchInput onChange={handleChange} placeholder={`Search ` + label} value={inputValue} />
				{showCancel && (
					<CancelBtn
						type="button"
						onClick={() => {
							setInputValue('');
							setShowCancel(false);
						}}
					>
						<FaPlus />
					</CancelBtn>
				)}
			</SearchContainer>
		);
	};

	// Render search bar with submit button
	const RenderSearchClick = () => {
		const handleCancelClick = () => {
			setInputValue('');
			setShowCancel(false);
			handleSearch('');
		};

		return (
			<SearchContainer>
				<form onSubmit={handleSubmit} style={{ display: 'flex' }}>
					<InputContainer>
						<SearchInput
							placeholder={`Search ${label}`}
							value={inputValue}
							onChange={handleChange}
							style={{ flexGrow: 5 }}
						/>
						{showCancel && (
							<CancelBtn type="button" onClick={handleCancelClick}>
								<FaPlus />
							</CancelBtn>
						)}
					</InputContainer>
					<SearchBtn type="submit">
						<FaSearch />
					</SearchBtn>
				</form>
			</SearchContainer>
		);
	};

	// Render the appropriate search bar based on the useClick prop
	return useClick ? RenderSearchClick() : renderDynamicSearch();
};

export default Search;

// Styled components
const SearchInput = styled.input`
	position: absolute;
	width: 100%;
	padding: 0 10px;
	border: none;
	border-radius: 3px;
	height: 40px;
	border: 1px solid lightgrey;
`;

const SearchBtn = styled.button`
	border: none;
	background: ${blue};
	color: white;
	padding: 5px 15px;
	border-radius: 3px;
	height: 40px;
	margin-left: 5px;
	&:hover {
		background: ${navy};
	}
`;

const CancelBtn = styled.button`
	height: 35px;
	width: 35px;
	right: 5px;
	top: 5px;
	font-size: 1.5rem;
	padding: unset;
	border: none;
	background: transparent;
	position: absolute;
	z-index: 50;
	color: #e57373;
	& svg {
		line-height: 1.5rem;
		vertical-align: unset;
		transform: translateY(-0.25rem);
		transform: rotate(45deg);
	}
	&:hover {
		font-size: 1.85rem;
		top: 3px;
		& svg {
			border-radius: 50%;
			border: 3px solid #e57373;
			transform: rotate(45deg);
		}
	}
`;

const SearchContainer = styled.div`
	display: inline-block;
	width: 100%;
`;

const InputContainer = styled.div`
	position: relative;
	width: 100%;
`;
