import React from 'react';
import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';
import { green, navy } from 'styles/colors';

const FAQSection = ({ FAQItems, backdropBlur }) => {
	const renderFAQ = (faqItem, index) => {
		return (
			<Item key={index} eventKey={index}>
				<Header style={{ border: `2px solid ${navy}` }}>
					{index + 1}. {faqItem.question}
				</Header>
				<Accordion.Body style={{ border: `2px solid ${green}`, borderRadius: '6px', marginTop: '0.5rem' }}>
					{faqItem.answer}
				</Accordion.Body>
			</Item>
		);
	};

	return (
		<SectionContainer>
			<FAQList>{FAQItems.map((faqItem, index) => renderFAQ(faqItem, index, FAQItems.length))}</FAQList>
		</SectionContainer>
	);
};

export default FAQSection;

const SectionContainer = styled.div`
	position: relative;
	padding: 5rem;
`;

const FAQList = styled(Accordion)``;

const Item = styled(Accordion.Item)`
	border: unset;
	margin-bottom: 1rem;
`;

const Header = styled(Accordion.Header)`
	border-radius: 6px;
	button {
		border-radius: 6px;
	}
`;
