import { usePreference } from 'contexts/PreferenceContext';
import React, { useCallback, useContext } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { WidgetType } from 'utils/enum';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';

import { statementsState, statementsStateSelector } from '@note/atoms';
import { AuthContext } from '@note/contexts/AuthContext';

import StatementText from './input/StatementText';

export const ListSelection = ({
	statement,
	type,
	editMode,
	isListLinesSingle,
	statementText,
	setStatementText,
	filteredStems,
	toggleEdit,
}) => {
	const { isOldSafari } = useContext(AuthContext);
	const setStatement = useSetRecoilState(statementsStateSelector);
	const [statements] = useRecoilState(statementsState);
	const { globalPreferences } = usePreference();

	// Function to update other statements within the same sub-tab to ensure only one statement is selected at a time
	const setStatementsInSameSubTab = useCallback(
		updatedStatement => {
			statements.forEach(s => {
				if (s.section === updatedStatement.section && s.id !== updatedStatement.id) {
					setStatement({ ...s, isSelected: false, stem: null });
				}
			});
		},
		[setStatement, statements]
	);

	// Toggles the selected state of the statement, updating the stem and handling the selection logic based on the widget type
	const toggleStatement = useCallback(() => {
		const stem = statement.stem === filteredStems[0]?.id ? null : filteredStems[0]?.id;
		const newState = {
			...statement,
			isSelected: !statement.isSelected,
			stem,
		};

		if (type === WidgetType.LIST_SELECT) {
			if (newState.isSelected) {
				setStatementsInSameSubTab(newState);
			}
			setStatement(newState);
		} else {
			setStatement(newState);
		}
	}, [statement, filteredStems, type, setStatementsInSameSubTab, setStatement]);

	// Handles the click event, optionally playing a sound effect and toggling the statement's selected state
	const handleClick = () => {
		!isOldSafari &&
			globalPreferences.SoundEffects === 'Enabled' &&
			soundPlayer(statement.isSelected ? soundKeys.TOGGLEOFF : soundKeys.TOGGLEON);
		toggleStatement();
	};

	return (
		<StatementText
			editMode={editMode}
			statement={statement}
			statementText={statementText}
			setStatementText={setStatementText}
			toggleStatement={handleClick}
			isSelected={statement.isSelected}
			isSingleLine={isListLinesSingle}
			toggleEdit={toggleEdit}
		/>
	);
};
