import { ThemeButton } from 'components/ThemeButton';
import { usePreference } from 'contexts/PreferenceContext';
import React, { useCallback, useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { toast } from 'react-toastify';
import { postFeedback } from 'services/user';
import styled from 'styled-components';
import { green, red } from 'styles/colors';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';
import { AuthContext } from '@note/contexts/AuthContext';
import './feedback-modal.scss';

// Accessed via the Help and Support menu -> Contact us
const FeedbackModal = ({ show, handleCloseFeedbackModal }) => {
	const { user, isOldSafari } = useContext(AuthContext);
	const [error, setError] = useState();
	const [feedback, setFeedback] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [disableButton, setDisableButton] = useState(false);
	const [submitSuccess, setSubmitSuccess] = useState(false);
	const { globalPreferences } = usePreference();

	// Function to handle feedback submission
	const handleSubmitFeedback = useCallback(
		async event => {
			event.preventDefault();
			setIsLoading(true);
			setDisableButton(true);
			try {
				await postFeedback(user.id, feedback);
				setIsLoading(false);
				setSubmitSuccess(true);
				!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.SUCCESS);
				toast.success('Thank You For Your Feedback!', { hideProgressBar: true });
				setTimeout(() => {
					setDisableButton(false);
				}, 1000);
			} catch (error) {
				setError('Error, please try again later');
				setIsLoading(false);
			}
		},
		[feedback, globalPreferences.SoundEffects, isOldSafari, user.id]
	);

	return (
		<Modal size="lg" show={show} onHide={handleCloseFeedbackModal} backdropClassName="backdrop-blur">
			<Modal.Header closeButton>
				<Modal.Title>Send us your feedback</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ minHeight: '40vh', width: '90%', margin: 'auto', marginBottom: '1.5rem' }}>
				<div className="feedback-modal-container">
					<Form onSubmit={submitSuccess ? handleCloseFeedbackModal : handleSubmitFeedback}>
						<InputGroup>
							<Form.Control
								style={{ minHeight: '35vh', margin: 'auto', marginBottom: '1.5rem' }}
								as="textarea"
								aria-label="With textarea"
								value={feedback}
								onChange={e => setFeedback(e.target.value)}
							/>
						</InputGroup>
						{error && <ErrorMessage>{error}</ErrorMessage>}
						{submitSuccess && (
							<SuccessMessage>
								Thanks for your comment. Please note that if there is something urgent or if you require a response, you
								can contact us at info@notedesigner.com.
							</SuccessMessage>
						)}
						<ThemeButton
							disabled={disableButton}
							type="submit"
							fullWidth={true}
							label={submitSuccess ? 'Close window' : isLoading ? 'Submitting...' : 'Submit'}
							size="lg"
							color="navy"
						/>
					</Form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default FeedbackModal;

const ErrorMessage = styled.p`
	color: ${red};
	text-align: center;
	font-weight: 500;
`;

const SuccessMessage = styled.p`
	color: ${green};
	text-align: center;
	font-weight: 500;
`;
