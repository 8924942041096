import React from 'react';
import { Form } from 'react-bootstrap';
import { FaPencilAlt } from 'react-icons/fa';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { isDesignPageState } from '@note/atoms';

const HeadingLabel = ({ heading, toggleEditMode, isEditAllowed }) => {
	const isDesignPage = useRecoilValue(isDesignPageState);
	return (
		<LabelContainer>
			<Label>
				<span style={{ cursor: isDesignPage ? 'move' : 'unset' }}>{heading.name}</span>
			</Label>
			{isEditAllowed && (
				<Icons>
					<FaPencilAlt
						onClick={() => toggleEditMode(heading)}
						style={{ fontSize: 15, transform: 'translateY(-2px)', color: 'gray' }}
					/>
				</Icons>
			)}
		</LabelContainer>
	);
};

export default HeadingLabel;

const LabelContainer = styled.div`
	width: 100%;
	display: flex;
`;

const Label = styled(Form.Label)`
	margin-bottom: 3px;
	font-weight: 500;
`;

const Icons = styled.div`
	margin-left: 8px;
	& > svg {
		cursor: pointer;
		opacity: 1;
		&:hover {
			transform: scale(1.1);
		}
	}
`;
