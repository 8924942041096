import React from 'react';
import ContentEditable from 'react-contenteditable';
import styled from 'styled-components';

const Comment = ({ placeholder, value, setValue }) => (
	<Text
		className="intro-comments"
		data-placeholder={placeholder}
		html={value}
		onChange={event => setValue(event.target.value)}
		suppressContentEditableWarning
	/>
);

export default React.memo(Comment);

const Text = styled(ContentEditable)`
	margin: 0;
	&:focus-visible {
		outline: 1px solid #37a4fa;
		border-radius: 3px;
	}
	:empty:before {
		content: attr(data-placeholder);
		color: #888;
		font-style: italic;
	}
`;
