import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import StatementText from '../../note-selector/widgets/input/StatementText';

// Component representing a single statement in the library
const LibraryStatement = ({ statement, index, libraryStatements, setLibraryStatements, statements }) => {
	// Toggles the selection of a statement
	const handleClick = useCallback(() => {
		setLibraryStatements(
			libraryStatements.map(st => (st.id === statement.id ? { ...st, isSelected: !st.isSelected } : st))
		);
	}, [libraryStatements, setLibraryStatements, statement]);

	// Checks if the statement is already imported into the current note
	const isAlreadyImported = useMemo(() => {
		return statements?.some(st => st.original_id === statement.id && st.name.trim() === statement.name.trim());
	}, [statement, statements]);

	return (
		<>
			{statement && (
				<StatementWrapper key={index}>
					{isAlreadyImported ? (
						<input type="checkbox" disabled checked={true} />
					) : (
						<input type="checkbox" onChange={handleClick} checked={statement.isSelected} />
					)}
					<StatementText
						editMode={false}
						statement={statement}
						isSelected={isAlreadyImported}
						isSingleLine={true}
						isLibraryStatement={true}
					/>
				</StatementWrapper>
			)}
		</>
	);
};

export default React.memo(LibraryStatement);

const StatementWrapper = styled.label`
	display: flex;
	width: 100%;
	gap: 5px;
	align-items: center;
	margin-bottom: 1px;
	padding: 1px;
`;
