import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import Select from 'react-select';
import { getBlogArticle } from 'services/blogArticles';
import styled from 'styled-components';

const BlogSection = ({ onBackClick, hideSection, blogItems }) => {
	const [articleHtml, setArticleHtml] = useState('');
	const [selectedBlogIndex, setSelectedBlogIndex] = useState(0);

	useEffect(() => {
		if (blogItems.length > 0) {
			fetchArticle(blogItems[selectedBlogIndex].url);
		}
	}, [blogItems, selectedBlogIndex]);

	const fetchArticle = async url => {
		try {
			const article = await getBlogArticle(url);
			setArticleHtml(article.innerHTML);
		} catch (error) {
			console.error(error);
		}
	};

	const goBack = () => {
		hideSection();
		onBackClick();
	};

	const handleArticleSelect = selectedOption => {
		setSelectedBlogIndex(selectedOption.index);
		fetchArticle(selectedOption.value);
	};

	const options = blogItems.map((item, index) => ({ index, value: item.url, label: item.title }));

	return (
		<SectionContainer>
			<FaArrowLeft
				onClick={() => goBack()}
				style={{ position: 'absolute', top: '1rem', left: '2rem' }}
				data-testid="back-arrow"
			/>
			<DropdownContainer>
				<Select options={options} onChange={handleArticleSelect} value={options[selectedBlogIndex]} />
			</DropdownContainer>
			<ArticleFrame
				className="articleIframe"
				title="Article"
				srcDoc={`<!DOCTYPE html><html><body>${articleHtml}</body></html>`}
			/>
		</SectionContainer>
	);
};

export default BlogSection;

const SectionContainer = styled.div`
	position: relative;
	height: 75vh;
	padding: 5rem 2rem 2rem 2rem;
`;

const DropdownContainer = styled.div`
	position: absolute;
	width: 40%;
	top: 1rem;
	right: 2rem;
`;

const ArticleFrame = styled.iframe`
	width: 100%;
	height: 100%;
`;
