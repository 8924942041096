import { ThemeButton } from 'components/ThemeButton';
import { useNote } from 'contexts/NoteContext';
import ManageNoteTypeModal from 'layouts/note-type/manage-notetype/ManageNoteTypeModal';
import React, { useCallback, useState, useContext, useMemo } from 'react';
import { Navbar } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '@note/contexts/AuthContext';
import styled from 'styled-components';
import DesignNoteLogo from './DesignNoteLogo';
import NoteDesignerMenu from './NoteDesignerMenu';

import './MainAppNav.scss';

import NewNoteMainPageModal from '../../../note-type/NewNoteMainPageModal';
import NavMenu from '../../nav-menu/NavMenu';
import NavLinks from '../../NavLinks';
import { DesignNoteMessageModal } from '../DesignNoteMessageModal';
import { DesignNoteConfirm } from '../DesignNoteConfirm';

// Top nav bar
const MainAppNav = ({ isLoading, clearNote, toggleCustomizations, showCustomizations }) => {
	const navigate = useNavigate();
	const { isAdmin, user } = useContext(AuthContext);
	const { isCustomizable, activeNoteType, noteTypes } = useNote();
	const [searchParams] = useSearchParams();
	const [showDesignMessage, setShowDesignMessage] = useState(false);
	const [showDesignConfirm, setShowDesignConfirm] = useState(false);
	const [showNewNoteMainPageModal, setShowNewNoteMainPageModal] = useState(false);
	const [showManageNoteTypeModal, setShowManageNoteTypeModal] = useState(false);

	const openDesignMessage = useCallback(() => setShowDesignMessage(true), []);
	const closeDesignMessage = useCallback(() => setShowDesignMessage(false), []);

	const openDesignConfirm = useCallback(() => setShowDesignConfirm(true), []);
	const closeDesignConfirm = useCallback(() => setShowDesignConfirm(false), []);
	const openNewNoteMainPageModal = useCallback(() => {
		setShowNewNoteMainPageModal(true);
	}, []);
	const closeNewNoteMainPageModal = useCallback(() => setShowNewNoteMainPageModal(false), []);
	const openManageNoteTypeModal = useCallback(() => setShowManageNoteTypeModal(true), []);
	const closeManageNoteTypeModal = useCallback(() => setShowManageNoteTypeModal(false), []);

	const isAINote = activeNoteType?.is_ai_note || activeNoteType?.is_ai_plus_note;
	const isDesignSpaceUnavailable = !isAdmin && isAINote && user?.site?.id === 1;

	const handleCustomizeClick = useCallback(() => {
		const id = searchParams.get('id');
		if (activeNoteType?.is_tour) {
			navigate('/design-note?id=' + id);
			return;
		}

		if (!isCustomizable) {
			openDesignMessage();

			return;
		}
		openDesignConfirm();
	}, [activeNoteType?.is_tour, isCustomizable, navigate, openDesignConfirm, openDesignMessage, searchParams]);

	const activeNoteTypeName = useMemo(() => {
		const id = searchParams.get('id');
		if (id && noteTypes && noteTypes.length > 0) {
			const notetype = noteTypes.find(nt => nt.id === parseInt(id));
			return notetype?.name ? notetype.name : 'Tab';
		}
		return '';
	}, [noteTypes, searchParams]);

	return (
		<NavigationBar className="px-3 shadow" collapseOnSelect expand="lg" bg="dark" variant="dark">
			<NoteDesignerMenu />

			{!isLoading && (
				<>
					<HeaderMenu>
						<ButtonContainer>
							<ThemeButton size="sm" onClick={openNewNoteMainPageModal} label="New Note" icon={FaPlus} />
							<ThemeButton
								size="sm"
								onClick={handleCustomizeClick}
								color={!isDesignSpaceUnavailable ? 'green' : 'grey'}
								label="Customize This Note"
								icon={DesignNoteLogo}
								disabled={isDesignSpaceUnavailable}
								tourAnchor="tour-design"
								inverted
							/>
							{isAdmin && <p className="animated-text-component">ADMIN!!</p>}
							{isDesignSpaceUnavailable && <HeaderText>Design space is unavailable in AI Notes</HeaderText>}
						</ButtonContainer>
						<div style={{ display: 'flex' }}>
							<div className="main-nav-navlinks">
								<NavLinks noteTypeName={activeNoteTypeName} />
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
								<NavMenu
									showCustomizations={showCustomizations}
									toggleCustomizations={toggleCustomizations}
									noteTypeName={activeNoteTypeName}
								/>
							</div>
						</div>
					</HeaderMenu>
					<DesignNoteMessageModal show={showDesignMessage} handleClose={closeDesignMessage} />
					<DesignNoteConfirm show={showDesignConfirm} handleClose={closeDesignConfirm} />
					<NewNoteMainPageModal
						clearNote={clearNote}
						openManageNoteTypeModal={openManageNoteTypeModal}
						show={showNewNoteMainPageModal}
						handleClose={closeNewNoteMainPageModal}
					/>
					<ManageNoteTypeModal show={showManageNoteTypeModal} handleClose={closeManageNoteTypeModal} />
				</>
			)}
		</NavigationBar>
	);
};

export default React.memo(MainAppNav);

const NavigationBar = styled(Navbar)`
	width: 100%;
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 5px;
	max-height: 55px;
`;

const HeaderMenu = styled.div`
	display: flex;
	@media (min-width: 992px) {
		width: 100%;
		justify-content: space-between;
	}
`;

const HeaderText = styled.span`
	margin-left: 10px;
	color: grey;
`;
