export const threeChoiceStems = [
	{
		key: 'A',
		value: 'For the appetizer the customer chose',
	},
	{
		key: 'M',
		value: 'For the main course the customer chose',
	},
	{
		key: 'D',
		value: 'For the Dessert the customer chose',
	},
];

export const twoChoiceStems = [
	{
		key: 'A',
		value: 'For the appetizer the customer chose',
	},
	{
		key: 'M',
		value: 'For the main course the customer chose',
	},
];

export const listSelectStems = [
	{
		key: '',
		value: 'After consulting the menu, the customer',
	},
];

export const listSelectData = [
	{
		name: 'fish and chips'
	},
	{
		name: 'chicken and salad'
	},
	{
		name: 'vegetarian pasta'
	}
];

export const multiChoiceOptionsData = ['not at all', 'somewhat', 'moderately', 'very'];

export const multiChoiceData = [
	{
		name: 'The customer found mango salad$tasty'
	},
	{
		name: 'The customer was$pleased with the main course'
	}
];

export const psoData = [
	{
		name: 'Green Salad'
	},
	{
		name: 'Vegetable Soup'
	},
	{
		name: 'Fresh fish'
	},
	{
		name: 'Cheese Plate'
	},
	{
		name: 'Pie'
	},
	{
		name: 'Chicken Fingers'
	},
	{
		name: 'Nut Cake'
	}
];


export const manualInputData = [{ name: 'Write the stem for your textbox here, if desired; e.g., "Appetizers:" or "The customer ordered the following appetizers:' }];

export const prefilledInputData = [
	{
		name:
			`Type any title you wish here, e.g.,
TODAY'S MENU
John Dough, C.E.C.,Chef`,
		prefilled_text: `Soup of the Day: Tomato
Appetizer of the Day: Mango Salad
Main Course of the Day: Ravioli
Dessert of the Day: Ice Cream with Chocolate Sauce`
	}
];




