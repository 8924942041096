import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import React, { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { reorderSectionOrder } from 'services/section';
import styled from 'styled-components';
import { updateAndGetNewOrder } from 'utils/dragDropHelper';
import { Defaults } from 'utils/enum';

import { isDesignPageState, sectionsState } from '@note/atoms';

const SectionList = ({
	subTab,
	filteredSections,
	setFilteredSections,
	setActiveSubTab,
	activeSection,
	setActiveSection,
}) => {
	const isDesignPage = useRecoilValue(isDesignPageState);
	const [sections, setSections] = useRecoilState(sectionsState);

	// Handles section click to set active subtab and section
	const handleSectionClick = useCallback(
		section => {
			setActiveSubTab(subTab);
			setActiveSection(section);
		},
		[setActiveSection, setActiveSubTab, subTab]
	);

	// Determines if dragging should be disabled for a section
	const isDragDisabled = section => {
		if (isDesignPage) {
			// Disable dragging for the "Uncategorized" section
			if (section.name === Defaults.UNCATEGORIZED_SECTION_NAME) {
				return true;
			}
			return false;
		}
		return true;
	};

	// Saves the new order of sections after dragging
	const saveOrder = useCallback(
		async changedSections => {
			if (changedSections.length === 0) return;
			try {
				await reorderSectionOrder({ orders: changedSections });
				setSections(
					sections
						.map(sec => {
							const updatedSection = changedSections.find(changedSec => changedSec.id === sec.id);
							return updatedSection && updatedSection !== -1 ? { ...sec, order_index: updatedSection.order } : sec;
						})
						.sort((a, b) => a.order_index - b.order_index) // Sort sections by order_index after updating
				);
			} catch (error) {
				console.error('🚀 ~ file: SubTabNavItem.js ~ line 46 ~ SubTabNavItem ~ error', error);
			}
		},
		[sections, setSections]
	);

	// Handles the end of a drag-and-drop operation
	const handleDragEnd = useCallback(
		result => {
			if (result) {
				const destination = filteredSections[result?.destination?.index];
				// Prevent dragging to the "Uncategorized" section
				if (destination?.name !== Defaults.UNCATEGORIZED_SECTION_NAME) {
					const changedSections = updateAndGetNewOrder({
						values: filteredSections,
						setValues: setFilteredSections,
						result,
					});
					saveOrder(changedSections);
				}
			}
		},
		[filteredSections, saveOrder, setFilteredSections]
	);

	// Renders individual section items
	const renderSectionItem = (section, index, provided) => {
		return (
			<SectionItem
				key={index}
				title={section.name}
				ref={provided?.innerRef}
				{...provided?.draggableProps}
				{...provided?.dragHandleProps}
			>
				<SectionLink
					selected={activeSection?.id === section.id}
					title={section.name}
					href={'#section' + section.id}
					onClick={() => handleSectionClick(section)}
				>
					{section.name}
				</SectionLink>
			</SectionItem>
		);
	};

	return (
		<List>
			{isDesignPage ? (
				<DragDropContext onDragEnd={handleDragEnd}>
					<Droppable droppableId="droppable" direction="vertical">
						{(provided, snapshot) => (
							<span ref={provided.innerRef} {...provided.droppableProps}>
								{filteredSections.length > 1 &&
									filteredSections.map((section, index) => (
										<Draggable
											key={section.id}
											draggableId={section.id.toString()}
											index={index}
											isDragDisabled={isDragDisabled(section)} // Disable dragging for certain sections
										>
											{(provided, snapshot) => renderSectionItem(section, index, provided)}
										</Draggable>
									))}
								{provided.placeholder}
							</span>
						)}
					</Droppable>
				</DragDropContext>
			) : (
				// Renders section items without drag-and-drop functionality if not in design mode
				filteredSections.length > 1 && filteredSections.map((section, index) => renderSectionItem(section, index))
			)}
		</List>
	);
};

export default React.memo(SectionList);

const List = styled.ul`
	padding: 0;
	margin: 5px 0;
	list-style: none;
	background: white;
`;
const SectionItem = styled.li`
	margin-top: 5px;
`;
const SectionLink = styled.a`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
	text-decoration: none;
	color: black;
	font-weight: 400;
	font-size: 14px;
	padding-left: 13px;
	margin-left: 7px;
	${props =>
		props.selected &&
		`
        border-left: 5px solid black;
        font-weight: bold;
        opacity: 0.9;
        background: #f3f4f7;
      `}
`;
