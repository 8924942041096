import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FaSave, FaTimes } from 'react-icons/fa';
import { ThemeButton } from 'components/ThemeButton';
import { usePreference } from 'contexts/PreferenceContext';
import { createPreference, updatePreference, deletePreference } from 'services/preference';
import { notePreferenceGroups } from '../preference-data';
import NotePreference from './NotePreference';

// Modal component for managing note-specific preferences
// Accessed via Note Settings in the customize mode
const NotePreferenceModal = ({ show, handleClose }) => {
	const [isLoading, setIsLoading] = useState(false); // State to handle loading status
	const { setPreferences, preferences } = usePreference(); // Context for managing preferences
	const [filteredPreferences, setFilteredPreferences] = useState(preferences); // State for filtered preferences

	// Update filtered preferences when preferences change
	useEffect(() => {
		setFilteredPreferences(preferences);
	}, [preferences]);

	// Generate promises for creating or updating preferences
	const getPromises = () => {
		const promises = [];
		filteredPreferences.forEach(preference => {
			if (preference.isNew) {
				promises.push(createPreference(preference));
			} else if (preference.isChanged) {
				promises.push(updatePreference(preference));
			}
		});
		return promises;
	};

	// Handle discarding changes
	const handleDiscard = () => {
		setFilteredPreferences(preferences);
		handleClose();
	};

	// Handle saving preferences
	const handleSave = async () => {
		const promises = getPromises();
		if (promises.length > 0) {
			try {
				setIsLoading(true);
				const results = await Promise.all(promises);
				let updatedPreferences = Array.from(preferences);
				let created = results.filter(r => r.config.method === 'post');
				let updated = results.filter(r => r.config.method === 'patch');

				if (created.length > 0) {
					updatedPreferences = [...updatedPreferences, ...created.map(c => c.data)];
				}
				if (updated.length > 0) {
					updated = updated.map(c => c.data);
					updatedPreferences = updatedPreferences.map(p => {
						const pref = updated.find(up => p.id === up.id);
						return pref && pref !== -1 ? pref : p;
					});
				}
				setPreferences(updatedPreferences);
				setIsLoading(false);
				handleClose();
			} catch (error) {
				console.error('🚀 ~ file: NotePreferenceModal.js ~ line 58 ~ handleSave ~ error', error);
			}
		}
	};

	// Handle resetting a preference
	const handleReset = async selectedPreference => {
		if (selectedPreference?.isNew) {
			setFilteredPreferences(filteredPreferences.filter(pref => pref.id !== selectedPreference?.id));
		} else {
			await deletePreference(selectedPreference?.id);
			setPreferences(preferences.filter(pref => pref.id !== selectedPreference?.id));
		}
	};

	return (
		<FullHeightModal show={show} onHide={handleDiscard} centered size="lg" className="h-100" scrollable>
			<Modal.Header closeButton>
				<Modal.Title>Note Settings (i.e. Settings for this note-type)</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{notePreferenceGroups.map((group, index) => (
					<div key={index}>
						<h6>{group.title}</h6>
						<p>{group.subTitle}</p>
						{group.preferences.map((pref, prefIndex) => (
							<NotePreference
								key={prefIndex}
								preferenceOptions={pref.options}
								isInputType={pref?.isInputType}
								filteredPreferences={filteredPreferences}
								setFilteredPreferences={setFilteredPreferences}
								preferenceKey={pref.key}
								handleReset={handleReset}
							/>
						))}
						{notePreferenceGroups.length - 1 !== index && <hr />}
					</div>
				))}
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label="Discard and close" icon={FaTimes} size="lg" color="grey" onClick={handleDiscard} />
				<ThemeButton
					label={isLoading ? 'Saving...' : 'Save'}
					icon={FaSave}
					disabled={!filteredPreferences?.some(fp => fp.isNew || fp.isChanged)}
					size="lg"
					color="green"
					onClick={handleSave}
				/>
			</Modal.Footer>
		</FullHeightModal>
	);
};

export default NotePreferenceModal;

const FullHeightModal = styled(Modal)`
	z-index: 9001;
	div.modal-content {
		height: 100%;
	}
`;
