import { ThemeButton } from 'components/ThemeButton';
import { usePreference } from 'contexts/PreferenceContext';
import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';

import { AuthContext } from '@note/contexts/AuthContext';

const ClearConfirmationModal = ({ show, handleClose, clearNote }) => {
	const { globalPreferences } = usePreference();
	const { isOldSafari } = useContext(AuthContext);

	const handleYes = () => {
		!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.HARP);
		setTimeout(() => {
			clearNote();
			handleClose();
		}, 700);
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Confirmation</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Are you sure you want to clear this note AND all selections plus any additional text you have input? Choose
				'CANCEL' if you haven't exported or copied the note yet and you want to; or YES to clear it now.
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label="Yes" size="lg" color="red" onClick={handleYes} tourAnchor="clear-confirm" />
				<ThemeButton label="Cancel" icon={FaTimes} size="lg" color="grey" onClick={handleClose} />
			</Modal.Footer>
		</Modal>
	);
};

export default ClearConfirmationModal;
