import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import styled from 'styled-components';

// Spinner animation called in various components to show a request being processed
export const Spinner = ({ size }) => {
	return (
		<SpinnerContainer>
			<FaSpinner
				className="spinner"
				style={{
					fontSize: size ? `${size * 10}px` : '30px',
					height: size && `${size * 10}px`,
					width: size && `${size * 10}px`,
				}}
			/>
		</SpinnerContainer>
	);
};

const SpinnerContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
