/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Tab } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { subTabsState } from '@note/atoms';

import DesignerSubTab from '../designer-subtab/DesignerSubTab';
import SubTabNavPane from '../subtab/subtab-nav/SubTabNavPane';

const DesignerTab = ({ tab }) => {
	const [filteredSubTabs, setFilteredSubTabs] = useState([]);
	const [activeSubTab, setActiveSubTab] = useState();
	const [activeSection, setActiveSection] = useState();

	const subTabs = useRecoilValue(subTabsState);

	// Filter sub-tabs based on the selected tab and set the first sub-tab as active if none is selected
	useEffect(() => {
		const filtered = subTabs.filter(st => st.tab === tab.id);
		setFilteredSubTabs(filtered);
		if (!activeSubTab) {
			setActiveSubTab(filtered[0]); // Set the first sub-tab as the active one
		}
	}, [tab, subTabs]);
	return (
		<>
			<Tab.Container activeKey={activeSubTab ? activeSubTab.id : ''}>
				<SubTabContainer>
					<SubTabNavPane
						activeSubTab={activeSubTab}
						activeSection={activeSection}
						setActiveSubTab={setActiveSubTab}
						setActiveSection={setActiveSection}
						tab={tab}
					/>
					<TabContent>
						{filteredSubTabs.length > 0 ? (
							filteredSubTabs.map(
								(subTab, index) =>
									activeSubTab.id === subTab.id && (
										<TabPane key={index} eventKey={subTab.id}>
											<DesignerSubTab subTab={subTab} />
										</TabPane>
									)
							)
						) : (
							<WelcomNoteWrapper>
								<WelcomNote>
									<h1>Welcome!</h1>
									<p>You have no subtabs as of now</p>
									<p>
										Click on the green add-subtab button at the left of your screen to start adding content to this tab
									</p>
								</WelcomNote>
							</WelcomNoteWrapper>
						)}
					</TabContent>
				</SubTabContainer>
			</Tab.Container>
		</>
	);
};

export default DesignerTab;

const SubTabContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	gap: 10px;
`;

const TabContent = styled(Tab.Content)`
	width: calc(100% - 225px);
	display: inline-block;
	height: 100%;
	background: #f9f9f9;
	border: 0.5px solid #00000005;
	border-radius: 10px;
	padding: 5px;
`;

const TabPane = styled(Tab.Pane)`
	height: 100%;
`;

const WelcomNote = styled.div`
	display: inline-block;
	text-align: center;
	& > button {
		display: inline-block;
	}
`;

const WelcomNoteWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	height: 100%;
`;
