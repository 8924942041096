import Logo from 'assets/logo-med.png';
import { Separator } from 'components/Separator';
import { ThemeButton } from 'components/ThemeButton';
import LoginForm from 'layouts/login/login-form';
import React from 'react';
import { Alert, Card } from 'react-bootstrap';
import styled from 'styled-components';

const Login = () => (
	<FormContainer>
		<Card style={{ width: '450px' }}>
			<div className="text-center">
				<img className="mt-3" src={Logo} alt="NoteDesigner logo" />
			</div>
			<Card.Body className="mb-3">
				<Alert>
					As we continue to introduce new features, it will occasionally be necessary for you to log in separately to
					the program and to the dashboard. Please log in now. If you forget your password click Forgot/Dont have
					password below
				</Alert>
				<LoginForm showDashboardLink={true} />
				<div className="my-2 text-center">
					<a href={`${process.env.REACT_APP_OLD_APP_URL}/no-password`}>Forgot/Don't have password?</a>
				</div>
				<Separator />
				<a href="https://notedesigner.com/pricing/" style={{ textDecoration: 'none' }}>
					<ThemeButton
						type="submit"
						fullWidth={true}
						label="Purchase a copy of NoteDesigner"
						size="lg"
						color="yellow"
					/>
				</a>
			</Card.Body>
		</Card>
	</FormContainer>
);

export default Login;

const FormContainer = styled.div`
	background: #212529;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;
