import { ThemeButton } from 'components/ThemeButton';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { red } from 'styles/colors';

import { AuthContext } from '@note/contexts/AuthContext';
import { login } from '@note/services/user';

const LoginForm = ({ handleCloseLoginModal, buttonLabel = 'Login to app', localUser, showDashboardLink }) => {
	const navigate = useNavigate();
	const { user, setUser } = useContext(AuthContext);
	const [error, setError] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const domain = window.location.origin;

	// Function to handle form submission for logging in
	const handleSubmit = useCallback(
		async event => {
			event.preventDefault();
			setIsLoading(true);
			try {
				const res = await login(email, password);
				if (!localUser) {
					localStorage.setItem('user', JSON.stringify(res.data));
					setUser(res.data);
				}
				setIsLoading(false);
				handleCloseLoginModal?.(true);
				if (!handleCloseLoginModal) {
					navigate('/');
				}
			} catch (error) {
				setError('Email or password are incorrect');
				setIsLoading(false);
			}
		},
		[email, password, handleCloseLoginModal, navigate, setUser, localUser]
	);

	// Function to handle form submission for logging in and redirecting to the dashboard
	const handleDashboardSubmit = useCallback(
		async event => {
			event.preventDefault();
			setIsLoading(true);
			try {
				const res = await login(email, password);
				if (!localUser) {
					localStorage.setItem('user', JSON.stringify(res.data));
					setUser(res.data);
				}
				setIsLoading(false);
				window.location.replace(`${domain}/dashboard/`);
			} catch (error) {
				setError('Email or password are incorrect');
				setIsLoading(false);
			}
		},
		[email, password, localUser, domain, setUser]
	);

	// Pre-fill email field if user information is available
	useEffect(() => {
		if (localUser) {
			setEmail(JSON.parse(localUser).email);
		} else if (user) {
			setEmail(user.email);
		}
	}, [localUser, user]);

	return (
		<Form onSubmit={handleSubmit}>
			<Form.Group className="mb-3" controlId="formBasicEmail">
				<Form.Control
					type="email"
					placeholder="Enter email"
					disabled={localUser}
					value={email && email}
					onChange={e => setEmail(e.target.value)}
					required
				/>
			</Form.Group>
			<Form.Group className="mb-3" controlId="formBasicPassword">
				<Form.Control
					type="password"
					placeholder="Password"
					value={password}
					onChange={e => setPassword(e.target.value)}
					required
				/>
			</Form.Group>
			{error && <ErrorMessage>{error}</ErrorMessage>}
			<ThemeButton
				disabled={!email || !password || isLoading}
				type="submit"
				fullWidth={true}
				label={isLoading ? 'Login...' : buttonLabel}
				size="lg"
				color="loginGreen"
			/>
			{showDashboardLink && (
				<>
					<br />
					<ThemeButton
						disabled={!email || !password || isLoading}
						onClick={handleDashboardSubmit}
						fullWidth={true}
						label="Login directly to your dashboard"
						size="lg"
						color="grey"
					/>
				</>
			)}
		</Form>
	);
};

export default LoginForm;

const ErrorMessage = styled.p`
	color: ${red};
	text-align: center;
	font-weight: 500;
`;
