import { Spinner } from 'components/Spinner';
import { useNote } from 'contexts/NoteContext';
import { usePreference } from 'contexts/PreferenceContext';
import ExportItemModal from 'features/exportItem/exportItemModal';
import DesignerNav from 'layouts/nav/DesignerNav';
import NoteSelector from 'layouts/note-selector/NoteSelector';
import DesignerTab from 'layouts/note-selector/tab/DesignerTab';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { getHeadingsByNoteId } from 'services/heading';
import { getNoteTypeById } from 'services/noteType';
import { getAdminPreferences, getUserPreferences } from 'services/preference';
import { getSectionsByNoteType } from 'services/section';
import { getStatementsByNoteType } from 'services/statement';
import { getStemByNoteType } from 'services/stem';
import { getSubTabsByNoteType } from 'services/subTab';
import { getTabsByNoteId } from 'services/tab';
import { getStatementObject } from 'utils/helper';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';
import DesignPageOpeningMessage from '../features/tour/DesignPageOpeningMessage';

import {
	filteredStatementState,
	headingsState,
	isDesignPageState,
	sectionsState,
	statementsState,
	stemsState,
	subTabsState,
	tabsState,
} from '@note/atoms';
import { AuthContext } from '@note/contexts/AuthContext';
import TourManager from 'features/tour/TourManager';
import designTourSteps from 'features/tour/designTourSteps';

const DesignNote = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [loadedTabs, setLoadedTabs] = useState([]); // Tracks which tabs have been loaded
	const [isLoading, setIsLoading] = useState(true); // Tracks the loading state of the component

	const setStems = useSetRecoilState(stemsState);
	const { user, isAdmin, showCloneModal, isOldSafari } = useContext(AuthContext);
	const setTabs = useSetRecoilState(tabsState);
	const setSubTabs = useSetRecoilState(subTabsState);
	const setHeadings = useSetRecoilState(headingsState);
	const setSections = useSetRecoilState(sectionsState);
	const setIsDesignPage = useSetRecoilState(isDesignPageState);
	const { setPreferences, globalPreferences } = usePreference();
	const { setActiveNoteType, isCustomizable, activeNoteType } = useNote();
	const [statements, setStatements] = useRecoilState(statementsState);
	const setFilteredStatements = useSetRecoilState(filteredStatementState);

	useEffect(() => {
		// Get the user data from localStorage if not present in context
		const localUser = localStorage.getItem('user');
		const userObject = user ? user : localUser;

		if (user || localUser) {
			setIsLoading(true);
			setIsDesignPage(true);
			const id = searchParams.get('id');
			Promise.all([
				// Fetching necessary data related to the note type
				getTabsByNoteId(id),
				getHeadingsByNoteId(id),
				getUserPreferences(),
				getAdminPreferences(id),
				getNoteTypeById(id),
				getSubTabsByNoteType(id),
				getSectionsByNoteType(id),
				getStatementsByNoteType(id),
				getStemByNoteType(id),
			])
				.then(([tabs, headings, userPrefs, adminPrefs, noteTypes, subTabs, sections, statements, stems]) => {
					// Set the fetched data into the corresponding Recoil states
					setTabs(tabs.data);
					setHeadings(
						headings.data.length > 0
							? headings.data
									.map(heading => ({ ...heading, content: '' }))
									.sort((a, b) => a.order_index - b.order_index)
							: []
					);
					setActiveNoteType(noteTypes.data);

					// Use user preferences if the note type belongs to the user; otherwise, use admin preferences
					if (noteTypes.data.user === userObject.id) {
						setPreferences(userPrefs.data);
					} else {
						setPreferences([
							...adminPrefs.data.filter(pref => pref.note_type !== null || pref.subtab !== null),
							...userPrefs.data,
						]);
					}

					// Set other related data
					setSubTabs(subTabs.data.map(subtab => ({ ...subtab, isNew: subtab?.type === null })));
					setSections(sections.data);
					setStatements(statements.data.map(statement => getStatementObject(statement)));
					setStems(stems.data.map(st => ({ ...st, editedValue: st.value })));
					setIsLoading(false);

					!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.HARPASCENDING);
				})
				.catch(error => {
					console.error('🚀 ~ file: NoteDesigner.js ~ line 87 ~ useEffect ~ error', error);
					setIsLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		searchParams,
		setActiveNoteType,
		setHeadings,
		setIsDesignPage,
		setPreferences,
		setSections,
		setStatements,
		setStems,
		setSubTabs,
		setTabs,
	]);

	// Check if the user has access to the design page based on their role and note type
	const hasAccess = isAdmin || isCustomizable || activeNoteType?.is_tour;

	useEffect(() => {
		// Warn the user if they try to leave the page while still loading or if they have access
		window.onbeforeunload = !isLoading && hasAccess ? () => 'Are you sure you want to leave?' : null;
	}, [hasAccess, isLoading]);

	useEffect(() => {
		// Set filtered statements based on the current statements
		setFilteredStatements(statements);
	}, [statements, setFilteredStatements]);

	if (!isLoading && !hasAccess) {
		return (
			<div className="no-access-info">
				<p>You don't have access to this page.</p>
				<Button onClick={() => navigate(-1)}>Go back</Button>
			</div>
		);
	}

	return (
		<TourManager steps={designTourSteps}>
			<div className="designer-container">
				<DesignPageOpeningMessage isTourPage={activeNoteType?.is_tour} />
				<DesignerNav />
				<div className="creator-body">
					{!isLoading ? (
						<div className="creator-body-panel">
							<NoteSelector loadedTabs={loadedTabs} setLoadedTabs={setLoadedTabs} tabComponent={DesignerTab} />
						</div>
					) : (
						<Spinner />
					)}
				</div>
				<ExportItemModal
					show={showCloneModal.show}
					isSubTab={showCloneModal.isSubTab}
					exportItemId={showCloneModal.id}
				/>
			</div>
		</TourManager>
	);
};

export default DesignNote;
