import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Dropdown, Navbar } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';
import styled from 'styled-components';
import { AuthContext } from '@note/contexts/AuthContext';
import ProfilePictureModal from './ProfilePictureModal';

import './NoteDesignerMenu.scss';

const NoteDesignerMenu = () => {
	const { user } = useContext(AuthContext);
	const [showProfilePictureModal, setProfilePictureModal] = useState(false);
	const [avatarUrl, setAvatarUrl] = useState('');

	const openProfilePicture = useCallback(() => setProfilePictureModal(true), []);
	const closeProfilePicture = useCallback(() => setProfilePictureModal(false), []);

	useEffect(() => {
		setAvatarUrl(user?.avatar ? user.avatar : undefined);
	}, [user]);

	return (
		<div style={{ display: 'inline-flex' }}>
			<CustomDropdown>
				<DropdownToggle variant="success" id="dropdown-basic">
					{avatarUrl ? (
						<ProfilePicture src={avatarUrl} alt={'profile picture'} />
					) : (
						<FaUser style={{ color: 'white', fontSize: 25, marginTop: -5 }} />
					)}
					<NavItem>
						<NavbarBrand>
							<span>{user?.site?.name}</span>
						</NavbarBrand>
					</NavItem>
				</DropdownToggle>
				<Dropdown.Menu align="end">
					<Dropdown.Item href={'/dashboard/'} target="_blank">
						Go to your profile dashboard
					</Dropdown.Item>
					<Dropdown.Item onClick={() => openProfilePicture()}>Edit your profile image</Dropdown.Item>
				</Dropdown.Menu>
			</CustomDropdown>
			{showProfilePictureModal && (
				<ProfilePictureModal show={showProfilePictureModal} handleClose={closeProfilePicture} />
			)}
		</div>
	);
};

export default NoteDesignerMenu;

const CustomDropdown = styled(Dropdown)`
	& > .dropdown-menu {
		right: unset;
	}
`;

const DropdownToggle = styled(Dropdown.Toggle)`
	background: none !important;
	padding: unset;
	border: none;
	&:after {
		display: none;
	}
	& > span {
		padding-left: 10px;
	}
`;

const ProfilePicture = styled.img`
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	margin-top: -5px;
`;

const NavItem = styled.span`
	color: #a7a8a9;
	position: relative;
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	padding-left: 20px;
	cursor: pointer;
	& > svg {
		font-size: 18px;
		margin-right: 5px;
	}
	&:hover {
		color: white;
	}
	@media (max-width: 991px) {
		color: #1e2125;
		&:hover {
			color: #1e2125;
			background: #e9ecef;
		}
	}
`;

const NavbarBrand = styled(Navbar.Brand)`
	display: inline-flex;
	align-items: left;
	& > span {
		font-size: 20px;
		font-weight: 500;
	}
	& > svg {
		font-size: 22px;
		margin-right: 5px;
	}
`;
