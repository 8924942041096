import { toast } from 'react-toastify';

// Determine the base URL for the WebSocket connection, accounting for the current protocol (HTTP or HTTPS)
const baseUrl = (window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host;

// Function to initialize a WebSocket connection to a specified path
const initializeWebSocket = path => new WebSocket(`${baseUrl}/ws${path}`);

// Function to make a WebSocket request to a Zephyr AI service endpoint
const callZephyrWebSocketEndpoint = (path, data) => {
	return new Promise((resolve, reject) => {
		let attempts = 0; // Tracks the number of connection attempts
		const maxRetries = 3; // Maximum number of retries allowed for connection
		let shouldRetry = false; // Flag to determine if the connection should be retried

		// Function to attempt establishing a WebSocket connection
		const attemptConnection = () => {
			const ws = initializeWebSocket(path);

			// Handle the WebSocket connection open event
			ws.onopen = () => ws.send(JSON.stringify(data)); // Send the data when the connection opens

			// Handle incoming messages from the WebSocket
			ws.onmessage = event => {
				try {
					resolve(JSON.parse(event.data)); // Resolve the promise with the parsed message data
				} catch (error) {
					reject(error); // Reject the promise if parsing fails
				} finally {
					ws.close(); // Close the WebSocket connection
				}
			};

			// Handle WebSocket errors
			ws.onerror = error => {
				ws.close(); // Close the WebSocket on error
				shouldRetry = true; // Set the retry flag to true
			};

			// Handle WebSocket closure
			ws.onclose = event => {
				if (shouldRetry && ++attempts < maxRetries) {
					// If an error occurred and retries are available, retry the connection after a delay
					setTimeout(attemptConnection, 5000);
					shouldRetry = false; // Reset the retry flag
				} else if (!event.wasClean) {
					// If the connection closed unexpectedly without a clean exit, show an error message and reject
					toast.error('The AI service is having issues, please try again later');
					reject(new Error('WebSocket closed unexpectedly without a successful response.'));
				}
			};
		};

		attemptConnection(); // Start the connection attempt
	});
};

// Function to request a modified note from the Zephyr AI service
export const getZephyrModifiedNote = (messages, useBulletPoints, language, length) => {
	const data = {
		messages: messages,
		useBulletPoints: useBulletPoints,
		language: language === 'English' ? undefined : language, // Only include language if it's not English
		length: length,
	};
	return callZephyrWebSocketEndpoint('/zephyr/asst/', data); // Make the WebSocket request with the specified data
};
