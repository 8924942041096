import { ThemeButton } from 'components/ThemeButton';
import { usePreference } from 'contexts/PreferenceContext';
import Signature from 'layouts/note-output/signature';
import CopyModal from 'modals/CopyModal';
import React, { useContext, useState, useEffect } from 'react';
import { Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { FaCopy, FaPrint, FaSearch } from 'react-icons/fa';
import styled from 'styled-components';
import { copyToClipboard, copyTextToClipboard } from 'utils/copyToClipboard';
import { exportPdf } from 'utils/exportPdf';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';
import SearchReplace from 'features/searchReplace/searchReplace';
import { AuthContext } from '@note/contexts/AuthContext';
import Letterhead from '@note/layouts/note-output/letterhead/Letterhead';

import { TextArea } from './styles';

import ExportModal from './ExportModal';

// A modal where you can edit the note output via an RTF editor and then either export or copy it
const FinalEditModal = ({ show, fontSize, fontStyle, openFinalNoteConfirmationModal }) => {
	const { showLetterhead, user, isOldSafari } = useContext(AuthContext);
	const { globalPreferences } = usePreference();
	const { multiLevelPreferences } = usePreference();
	const { tabBreak, subtabBreak } = multiLevelPreferences;
	const [showSearchReplace, setShowSearchReplace] = useState();
	const [originalText, setOriginalText] = useState(null);
	const [showExport, setShowExport] = useState();
	const [rtfValue, setRtfValue] = useState();
	const [iframeURL, setIframeURL] = useState();
	const [showCopy, setShowCopy] = useState(false);

	// Function to close the export modal
	const closeExport = () => setShowExport(false);

	// Function to close the copy modal
	const closeCopy = () => setShowCopy(false);

	// Function to open the copy modal
	const openCopy = () => setShowCopy(true);

	// Function to close the search and replace modal
	const closeSearchReplace = () => setShowSearchReplace(false);

	// Function to open the search and replace modal
	const openSearchReplace = () => setShowSearchReplace(true);

	useEffect(() => {
		let noteContent = document.querySelector('.note-content-body');
		if (noteContent) {
			let aiNoteBody = noteContent.querySelector('.ai-note-body');
			if (aiNoteBody) {
				aiNoteBody.remove();
			}

			const replacedFontWeight = noteContent.innerHTML.replaceAll('style="font-weight: 600', 'style="font-weight: 700');

			let modifiedOutput;

			if (tabBreak === 'Show breaks' && subtabBreak !== 'Show breaks') {
				const emptySpanRegex = /<span id="[^"]*"><\/span><div><br><\/div>/g;

				modifiedOutput = replacedFontWeight.replaceAll(emptySpanRegex, '<span></span><div><br></div><div><br></div>');
			}

			setOriginalText(
				tabBreak === 'Show breaks' && subtabBreak !== 'Show breaks' ? modifiedOutput : replacedFontWeight
			);
			setRtfValue(tabBreak === 'Show breaks' && subtabBreak !== 'Show breaks' ? modifiedOutput : replacedFontWeight);
		}
	}, []);

	// Handle change in RTF value
	const handleRtfValueChange = content => {
		setRtfValue(content);
	};

	// Handle copy action
	const handleCopy = isSimpletext => {
		if (!navigator.userAgent.includes('Firefox')) {
			try {
				isSimpletext
					? copyTextToClipboard(document.getElementById('finalNote'), 'signature-controls')
					: copyToClipboard(document.getElementById('finalNote'), 'signature-controls');
				!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.SUCCESS);
			} catch (error) {
				openCopy();
			}
		} else {
			openCopy();
		}
	};

	// Handle export action
	const handleExportClick = () => exportPdf({ id: 'finalNote', fontSize, fontStyle, setIframeURL, setShowExport });

	const imagesElement = document.querySelector('#note .images-output');
	const imagesDiv = imagesElement ? imagesElement.innerHTML : undefined;

	const TextModules = {
		toolbar: [
			[{ header: [1, 2, 3, false] }],
			['bold', 'italic', 'underline'],
			[{ list: 'ordered' }, { list: 'bullet' }],
		],
	};

	return (
		<>
			<CustomModal show={show} onHide={openFinalNoteConfirmationModal} centered size="xl" scrollable>
				<Modal.Header closeButton>
					<Modal.Title>Final edit</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container className="h-100 text-left align-items-left">
						<Row className="h-100">
							<Col md={4}>
								<p>
									This page allows you to do some final editing before EXPORTING your note or COPYING it to be pasted
									into a word processor or other program.
								</p>
							</Col>
							<Col md={8}>
								<Card className="h-100">
									<Card.Header className="justify-content-end d-flex gap-2">
										<ThemeButton
											onClick={openSearchReplace}
											label="Search / Replace"
											icon={FaSearch}
											color="blue"
											disabled={!rtfValue}
										/>
										<ThemeButton onClick={handleExportClick} label="Export" icon={FaPrint} color="blue" />
										<ThemeButton onClick={() => handleCopy(false)} label="Copy" icon={FaCopy} color="blue" />
										<ThemeButton onClick={() => handleCopy(true)} label="Copy Text Only" icon={FaCopy} color="blue" />
									</Card.Header>
									<Card.Body id="finalNote">
										{showLetterhead && user.letterhead !== null && <Letterhead letterhead={user.letterhead} />}
										<TextArea
											theme="snow"
											value={rtfValue || ''}
											onChange={handleRtfValueChange}
											modules={TextModules}
										/>
										{<Signature style={{ height: 'unset', display: 'unset' }} innerHTML={imagesDiv} />}
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Container>
				</Modal.Body>
			</CustomModal>
			{showSearchReplace && (
				<SearchReplace
					show={showSearchReplace}
					handleClose={closeSearchReplace}
					text={rtfValue}
					setText={setRtfValue}
					originalAiAnswer={originalText}
				/>
			)}
			{showExport && <ExportModal show={showExport} handleClose={closeExport} iframeURL={iframeURL} />}
			{showCopy && <CopyModal show={showCopy} handleClose={closeCopy} />}
		</>
	);
};

export default FinalEditModal;

const CustomModal = styled(Modal)`
	div.modal-content {
		height: 100%;
	}
`;
