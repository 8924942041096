import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const InputLabel = ({ statement, statementText, setStatementText, editMode, setPlaceholderText, placeholderText }) => {

	const inputRef = useRef();

	useEffect(() => {
		if (editMode) inputRef.current.focus();
	}, [editMode]);

	return (
		<Text>
			{editMode ? (
				<>
					<StatementTextInput
						placeholder='Edit the stem for your textbox here, if desired; e.g., "Appetizers:" or "The customer ordered the following appetizers:'
						ref={inputRef}
						value={statementText}
						onChange={e => setStatementText(e.target.value)}
						type="text"
					/>
					<PlaceholderInput
						placeholder="Add a prompt here if you wish"
						value={placeholderText}
						onChange={e => setPlaceholderText(e.target.value)}
						type="text"
					/>
				</>

			) : (
				<span>{statement.name}</span>
			)}
		</Text>
	);
};

export default React.memo(InputLabel);

const Text = styled.div`
	width: 100%;
	padding: 5px 0px;
`;

const PlaceholderInput = styled.textarea`
	height: 25vh;
	width: 100%;
	border-radius: 5px;
	margin-top: 5px;
	outline: none;
	border: 1px solid grey;
`
const StatementTextInput = styled.input`
	width: 100%;
	border-radius: 5px;
	outline: none;
	border: 1px solid grey;
`;