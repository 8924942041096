import React, { useState, useContext, useRef } from 'react';
import { toast } from 'react-toastify';
import { FaTimes } from 'react-icons/fa';
import { ThemeButton } from 'components/ThemeButton';
import { Modal } from 'react-bootstrap';
import ImageCropper from 'components/ImageCropper';
import { deleteAvatar, createAvatar } from 'services/user';
import { AuthContext } from '@note/contexts/AuthContext';


const ProfilePictureModal = ({ show, handleClose, isDesignPage }) => {
	const { user, setUser } = useContext(AuthContext);
	const [imageToCrop, setImageToCrop] = useState(undefined);
	const [imageDataURI, setImageDataURL] = useState();
	const [croppedImage, setCroppedImage] = useState(undefined);
	const fileInputRef = useRef();
	const [hasInvalidFiles, setHasInvalidFiles] = useState(false);

	const onUploadFile = event => {
		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				const image = reader.result;

				setImageToCrop(image);
			});

			reader.readAsDataURL(event.target.files[0]);
		}
	};

	const clear = () => {
		setImageToCrop(undefined);
		setCroppedImage(undefined);
		setImageDataURL(undefined)
		fileInputRef.current.value = null;
	};

	const closeModal = () => {
		handleClose();
		setHasInvalidFiles(false);
	};

	const deleteProfilePicture = async() => {
		try {
			await deleteAvatar(user)
			setUser({...user, avatar: null})
			setImageToCrop(undefined);
		setCroppedImage(undefined);
		setImageDataURL(undefined)
			toast.success(`Profile image deleted successfully`, { hideProgressBar: true });
		} catch {
			toast.error(`Error uploading profile picture`, { hideProgressBar: true });
		}
	}

	const saveProfilePicture = async () => {
		try {
		  let file;
	  
		  if (croppedImage instanceof Blob) {
			file = new File([croppedImage], "profile-picture.png", { type: "image/png" });
		  } else if (typeof croppedImage === 'string' || croppedImage instanceof String) {
			const response = await fetch(croppedImage);
			const blob = await response.blob();
			file = new File([blob], "profile-picture.png", { type: "image/png" });
		  } else {
			throw new Error("Invalid image data");
		  }
	  
		  const response = await createAvatar(user, file);
		  setUser({ ...user, avatar: response?.data?.avatar });
	  
		  toast.success(`Profile picture uploaded successfully`, { hideProgressBar: true });
		} catch (error) {
		  toast.error(`Error uploading profile picture: ${error.message || error.toString()}`, { hideProgressBar: true });
		}
	  };
	  

	return (
		<Modal show={show} onHide={handleClose} size="md" scrollable centered backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>Upload your profile picture</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			<input type="file" accept="image/*" ref={fileInputRef} onChange={onUploadFile} />
					<div className="mt-2">
						<ImageCropper
							imageToCrop={imageToCrop}
							setImageDataURL={setImageDataURL}
							onImageCropped={croppedImage => setCroppedImage(croppedImage)}
						/>
					</div>
			</Modal.Body>
			<Modal.Footer>
				{user?.avatar && <ThemeButton label="Delete my profile picture" size="lg" color="red" onClick={() => deleteProfilePicture()} />}
				{imageDataURI && (
					<>
						<ThemeButton label="Upload" size="lg" color="green" onClick={saveProfilePicture} disabled={hasInvalidFiles} />
						<ThemeButton label="Clear the above image" size="lg" color="red" onClick={clear} />
					</>
				)}
				<ThemeButton label="Close" icon={FaTimes} size="lg" color="grey" onClick={closeModal} />
			</Modal.Footer>
		</Modal>
	);
};

export default ProfilePictureModal;