import React from 'react';
import styled from 'styled-components';
import { Form, InputGroup, Button } from 'react-bootstrap';

const PreferenceInput = ({ handleChange, handleReset, selectedPreference, hasReset = false }) => {

    return (
        <InputGroup className="mb-3">
            <Form.Control
                type="text"
                value={selectedPreference?.value ? selectedPreference?.value : ''}
                placeholder="Enter custom note title"
                onChange={handleChange}
            />
            {
                hasReset && selectedPreference &&
                <ResetButton variant="danger" onClick={() => handleReset(selectedPreference)}>
                    Reset
                </ResetButton>
            }

        </InputGroup>
    )
}

export default PreferenceInput;

const ResetButton = styled(Button)`
	border-top-left-radius: 0.25rem !important;
	border-bottom-left-radius: 0.25rem !important;
	margin-left: 5px !important;
`;
