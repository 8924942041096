import { usePreference } from 'contexts/PreferenceContext';
import React, { useContext, useEffect, useState } from 'react';
import { Form, ListGroup } from 'react-bootstrap';
import { FaCopy } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getUserStatementsForDefaultNoteType, getUserStatementsForUserNoteType } from 'services/statement';
import styled from 'styled-components';
import { navy } from 'styles/colors';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';

import { AuthContext } from '@note/contexts/AuthContext';

const StatementList = ({ noteType, isMyNoteType }) => {
	const { isOldSafari } = useContext(AuthContext);
	const { id } = noteType;
	const { globalPreferences } = usePreference();

	// Fetch user statements based on note type
	const {
		isLoading,
		isError,
		data: statements,
		error,
	} = useQuery(['statements' + id, id], () =>
		isMyNoteType ? getUserStatementsForUserNoteType(id) : getUserStatementsForDefaultNoteType(id)
	);

	const [filteredStatements, setFilteredStatements] = useState([]);
	const [searchValue, setSearchValue] = useState('');

	// Filter statements based on search value
	useEffect(() => {
		if (statements && statements?.data.length > 0) {
			if (searchValue.length > 0) {
				setFilteredStatements(
					statements.data.filter(statement => statement.name.toLowerCase().includes(searchValue.toLowerCase()))
				);
			} else {
				setFilteredStatements(statements.data);
			}
		}
	}, [statements, searchValue]);

	if (isError) {
		console.error(error);
	}

	// Function to copy statement to clipboard
	function copyToClipBoard(text) {
		navigator.clipboard.writeText(text);
		!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.SUCCESS);
		toast.success('Copied successfully!', { hideProgressBar: true });
	}

	return isLoading ? (
		<div style={{ textAlign: 'center' }}>Loading</div>
	) : (
		<>
			<Form.Control
				value={searchValue}
				className="my-2"
				onChange={e => setSearchValue(e.target.value)}
				type="text"
				placeholder="Search statements"
			/>
			<ListGroup variant="flush">
				{filteredStatements.length > 0 ? (
					filteredStatements.map((statement, index) => (
						<ListGroup.Item as="li" key={index} action>
							<StatementContainer onClick={() => copyToClipBoard(statement.name)}>
								{statement.name}
								<CopyButtonContainer className="copy-customization-btn">
									Click to copy
									<FaCopy />
								</CopyButtonContainer>
							</StatementContainer>
						</ListGroup.Item>
					))
				) : (
					<div style={{ textAlign: 'center' }}>No statements found.</div>
				)}
			</ListGroup>
		</>
	);
};

export default StatementList;

const StatementContainer = styled.div`
	cursor: pointer;
	&:hover {
		div.copy-customization-btn {
			display: flex;
		}
	}
`;

const CopyButtonContainer = styled.div`
	display: none;
	position: absolute;
	right: 4px;
	top: 4px;
	height: 30px;
	background: ${navy};
	color: white;
	border-radius: 5px;
	padding: 5px 10px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;
