import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getVideoMetadata } from 'services/videos';
import styled from 'styled-components';

// Component to display a section with a list of videos
const VideosSection = ({ videoIds }) => {
	// State to track the index of the currently selected video
	const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
	// State to store the metadata of the videos
	const [videos, setVideos] = useState([]);

	// Function to fetch and populate video metadata
	const populateVideos = async ids => {
		const videoMetadata = await Promise.all(ids.map(getVideoMetadata));
		setVideos(videoMetadata);
	};

	// Effect to fetch video metadata when the component mounts or videoIds change
	useEffect(() => {
		const idList = videoIds.map(item => item.id);
		populateVideos(idList);
	}, [videoIds]);

	// Handler for clicking on a thumbnail to select a video
	const handleThumbnailClick = index => {
		setSelectedVideoIndex(index);
	};

	// Options for the video select dropdown
	const options = videos?.map((video, index) => ({ value: index, label: video.title }));

	return (
		<SectionContainer>
			<VideosContainer>
				<MainVideoContainer>
					{videos && (
						<iframe
							width="100%"
							height="100%"
							src={`https://www.youtube-nocookie.com/embed/${videos[selectedVideoIndex]?.id}`}
							title={videos[selectedVideoIndex]?.title}
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowFullScreen
							alt={videos[selectedVideoIndex]?.title}
						/>
					)}
				</MainVideoContainer>
				<ThumbnailListContainer>
					<Select
						options={options}
						onChange={selectedOption => handleThumbnailClick(selectedOption.value)}
						value={options.find(option => option.value === selectedVideoIndex)}
					/>
					{videos?.map((video, index) => (
						<ThumbnailContainer
							key={video.id}
							onClick={() => handleThumbnailClick(index)}
							isActive={index === selectedVideoIndex}
						>
							<b>{video.title}</b>
							<img src={video.thumbnail} alt={video.title} />
						</ThumbnailContainer>
					))}
				</ThumbnailListContainer>
			</VideosContainer>
		</SectionContainer>
	);
};

export default VideosSection;

// Styled components for layout and styling
const SectionContainer = styled.div`
	position: relative;
	padding: 2rem 3rem;
`;

const VideosContainer = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	max-width: 97%;
	height: 65vh;
	margin: auto;
`;

const MainVideoContainer = styled.div`
	flex-basis: 70%;
`;

const ThumbnailListContainer = styled.div`
	flex-basis: 30%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem;
	overflow-y: auto;
	background-color: #f1f1f1;
`;

const ThumbnailContainer = styled.div`
	cursor: pointer;
	padding: 0.5rem;
	border-radius: 5px;
	background-color: #ffffff;
	img {
		margin-top: 0.5rem;
		opacity: ${props => (props.isActive ? 1 : 0.5)};
		transition: opacity 0.2s ease-in-out;
		width: 100%;
		border-radius: 5px;
	}
`;
