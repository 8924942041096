import React, { useCallback, useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';

import Letterhead from './Letterhead';
import LetterheadLine from './letterhead-line/letterhead-line';

const LetterheadForm = ({ letterhead, setLetterhead }) => {
	const fonts = ['Arial', 'Verdana', 'Courier New', 'Times New Roman', 'DejaVu'];
	const fontSizes = [10, 12, 16, 18, 20, 24, 36];

	const [previewLetterhead, setPreviewLetterhead] = useState(letterhead);

	const handleLetterheadUpdate = useCallback(() => {
		setPreviewLetterhead({ ...letterhead });
	}, [letterhead]);

	useEffect(() => {
		if (letterhead) {
			setPreviewLetterhead(letterhead);
		}
	}, [letterhead]);

	const handleChange = useCallback(
		(event, field) => {
			const newLetterhead = { ...letterhead, type: 'formfields' };
			newLetterhead[field].value = event.target.value;
			setLetterhead(newLetterhead);
			handleLetterheadUpdate();
		},
		[letterhead, setLetterhead, handleLetterheadUpdate]
	);

	const handleFontChange = useCallback(
		(font, field) => {
			const newLetterhead = { ...letterhead, type: 'formfields' };
			newLetterhead[field].font = font;
			setLetterhead(newLetterhead);
			handleLetterheadUpdate();
		},
		[letterhead, setLetterhead, handleLetterheadUpdate]
	);

	const handleFontSizeChange = useCallback(
		(fontSize, field) => {
			const newLetterhead = { ...letterhead, type: 'formfields' };
			newLetterhead[field].fontSize = fontSize;
			setLetterhead(newLetterhead);
			handleLetterheadUpdate();
		},
		[letterhead, setLetterhead, handleLetterheadUpdate]
	);

	const handleBoldToggle = useCallback(
		(isBold, field) => {
			const newLetterhead = { ...letterhead, type: 'formfields' };
			newLetterhead[field].isBold = isBold;
			setLetterhead(newLetterhead);
			handleLetterheadUpdate();
		},
		[letterhead, setLetterhead, handleLetterheadUpdate]
	);

	const handleItalicToggle = useCallback(
		(isItalic, field) => {
			const newLetterhead = { ...letterhead, type: 'formfields' };
			newLetterhead[field].isItalic = isItalic;
			setLetterhead(newLetterhead);
			handleLetterheadUpdate();
		},
		[letterhead, setLetterhead, handleLetterheadUpdate]
	);

	const letterheadKeys = Object.keys(letterhead).filter(key => key !== 'type');
	const desiredOrder = ['name', 'companyName', 'address', 'phoneNumber', 'email'];
	const sortedKeys = letterheadKeys.sort((a, b) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b));

	return (
		<div>
			<Card className="mt-2">
				<Card.Body>
					<Letterhead letterhead={previewLetterhead} isPreview />
				</Card.Body>
			</Card>
			<Card>
				<Card.Body>
					<Form>
						{sortedKeys.map(key => {
							const field = letterhead[key];
							return (
								<LetterheadLine
									key={key}
									label={field.label}
									value={field.value}
									font={field.font}
									fontSize={field.fontSize}
									fonts={fonts}
									fontSizes={fontSizes}
									isBold={field.isBold}
									isItalic={field.isItalic}
									placeholder={field.placeholder}
									onValueChange={event => handleChange(event, key)}
									onFontChange={font => handleFontChange(font, key)}
									onBoldToggle={isBold => handleBoldToggle(isBold, key)}
									onItalicToggle={isItalic => handleItalicToggle(isItalic, key)}
									onFontSizeChange={fontSize => handleFontSizeChange(fontSize, key)}
								/>
							);
						})}
					</Form>
				</Card.Body>
			</Card>
		</div>
	);
};

export default LetterheadForm;
