import React from 'react';
import { Modal } from 'react-bootstrap';
import { ThemeButton } from '@note/components/ThemeButton';

import './modal.scss';

const PrintInfoModal = ({ show, handleClose, handlePrint }) => {
	return (
		<Modal
			show={show}
			onHide={handleClose}
			backdrop="static"
			scrollable
			centered
			size="md"
			backdropClassName="backdrop-blur"
		>
			<Modal.Body>
				<p>
					Some browsers may not Export longer notes correctly. In such a case you can use this special Browser-Export
					function instead.
				</p>
				<p>NOTE: If your browser adds text to the margins you can adjust this with margin settings</p>
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label="Continue" size="lg" color="green" onClick={handlePrint} />
				<ThemeButton label="Close" size="lg" color="green" onClick={handleClose} />
			</Modal.Footer>
		</Modal>
	);
};

export default PrintInfoModal;
