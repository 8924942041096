import { ThemeButton } from 'components/ThemeButton';
import { usePreference } from 'contexts/PreferenceContext';
import SignatureDeleteConfirmationModal from 'modals/SignatureDeleteConfirmationModal';
import React, { useContext, useState, useEffect } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { FaSave, FaTimes, FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { createSignature, deleteSignature, getSignature } from 'services/user';
import styled from 'styled-components';
import { blue } from 'styles/colors';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';

import { AuthContext } from '@note/contexts/AuthContext';

import SignatureTyped from './SignatureTyped';
import SignatureCanvas from './SignatureCanvas';
import SignatureUpload from './SignatureUpload';

const SignatureModal = ({ show, handleClose }) => {
	const [imageDataURI, setImageDataURL] = useState();
	const [activeKey, setActiveKey] = useState('upload');
	const [signatureJson, setSignatureJson] = useState();
	const { user, setSignatureImage, signatureImage, isOldSafari, setSignatureTextObject, signatureTextObject } =
		useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const { globalPreferences } = usePreference();

	const openDelete = () => setShowDelete(true);
	const closeDelete = () => setShowDelete(false);

	// Reset active tab to 'upload' when the modal is opened
	useEffect(() => {
		setActiveKey('upload');
	}, []);

	// Function to close the modal and reset states
	const closeModal = () => {
		handleClose();
		setActiveKey('upload');
		setImageDataURL(null);
	};

	// Function to handle saving the signature
	const handleSave = async () => {
		setIsLoading(true);
		try {
			if ((activeKey === 'upload' || activeKey === 'sign') && imageDataURI) {
				const blob = await (await fetch(imageDataURI)).blob();
				const fileName = `${user.email}-${Date.now()}.png`;
				const file = new File([blob], fileName, { type: 'image/png', lastModified: new Date() });
				await createSignature(user?.id, file, null);
			} else if (activeKey === 'Typed' && signatureJson) {
				await createSignature(user?.id, null, signatureJson);
			} else {
				throw new Error('No valid signature data provided.');
			}

			// Retrieve and set the updated signature
			const res = await getSignature(user?.id);
			if (activeKey === 'Typed') {
				setSignatureImage(null);
				setSignatureTextObject(res.data?.signature_json);
			} else {
				setSignatureImage(res.data?.signature_image);
				setSignatureTextObject(null);
			}
			setImageDataURL(null);
			toast.success('Signature was updated', { hideProgressBar: true });
		} catch (error) {
			console.error(error);
			toast.error('Error updating signature', { hideProgressBar: true });
		} finally {
			setIsLoading(false);
			handleClose();
			setActiveKey('upload');
			setImageDataURL(null);
		}

		if (!isOldSafari && globalPreferences.SoundEffects === 'Enabled') {
			soundPlayer(soundKeys.SUCCESS);
		}
	};

	// Function to handle signature deletion
	const onDeleteSignature = async () => {
		try {
			await deleteSignature(user.id);
			setSignatureImage(null);
			setSignatureTextObject(null);
			!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.SUCCESS);
			toast.success('Signature was deleted', { hideProgressBar: true });
			setActiveKey('upload');
		} catch (error) {
			toast.error('Error deleting signature', { hideProgressBar: true });
		}
	};

	return showDelete ? (
		<SignatureDeleteConfirmationModal deleteSignature={onDeleteSignature} show={showDelete} handleClose={closeDelete} />
	) : (
		<FullHeightModal show={show} onHide={() => closeModal()} size="lg" scrollable centered backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>Manage signature</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<CustomTabs defaultActiveKey="upload" className="mb-3" justify onSelect={k => setActiveKey(k)}>
					<FullHeightTab eventKey="upload" title="Upload">
						<SignatureUpload setImageDataURL={setImageDataURL} />
					</FullHeightTab>
					<FullHeightTab eventKey="sign" title="Draw">
						<SignatureCanvas setImageDataURL={setImageDataURL} />
					</FullHeightTab>
					<FullHeightTab eventKey="Typed" title="Typed">
						<SignatureTyped setSignatureJson={setSignatureJson} />
					</FullHeightTab>
					<FullHeightTab eventKey="delete" title="Delete">
						{signatureImage || signatureTextObject ? (
							<>
								<p>This will delete your signature permanently.</p>
								<ThemeButton onClick={openDelete} icon={FaTrashAlt} label="Delete signature" color="red" />
							</>
						) : (
							<p>No signature found.</p>
						)}
					</FullHeightTab>
				</CustomTabs>
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label="Close" icon={FaTimes} size="lg" color="grey" onClick={() => closeModal()} />
				<ThemeButton
					label={isLoading ? 'Saving...' : 'Save this as your signature'}
					disabled={
						isLoading ||
						activeKey === 'delete' ||
						((activeKey === 'upload' || activeKey === 'sign') && !imageDataURI) ||
						(activeKey === 'Typed' && !signatureJson.signatureText.length > 0)
					}
					icon={FaSave}
					size="lg"
					onClick={handleSave}
				/>
			</Modal.Footer>
		</FullHeightModal>
	);
};

export default SignatureModal;

const FullHeightModal = styled(Modal)`
	div.modal-content,
	div.tab-content {
		height: 100%;
	}
	& .tab-content {
		padding: 0;
	}
`;

const FullHeightTab = styled(Tab)`
	height: 100%;
`;

const CustomTabs = styled(Tabs)`
	flex-direction: row;
	& > li > button {
		padding-right: 10px !important;
		padding-left: 10px !important;
		color: black !important;
	}
	& > li button.active {
		background: ${blue} !important;
		color: white !important;
	}
`;
