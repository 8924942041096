import ReactQuill from 'react-quill';
import styled from 'styled-components';

export const StyledForm = styled.form`
	width: 100%;
	display: block;
`;

export const TitleContainer = styled.div`
	width: 100%;
	max-width: 100%;
	padding: 5px 0px;
	margin-right: 5px;
	display: flex;
	align-items: flex-start;
	overflow: hidden;
`;

export const PlaceholderInput = styled.textarea`
	width: 100%;
	border-radius: 5px;
	margin-top: 5px;
	outline: none;
	border: 1px solid grey;
`;

export const ActionBtn = styled.button`
	position: relative;
	border: none;
	background: ${props => props.color};
	color: white;
	padding: 5px 10px;
	margin-left: 5px;
	border-radius: 3px;
`;

export const TextArea = styled(ReactQuill)`
	width: 100%;
	margin-top: 1rem;
	.ql-container {
		width: 100%;
		height: 20vh;
		border: none;
	}
	.ql-editor {
		padding: 1rem !important;
		background-color: white;
		width: 100%;
		padding: 5px;
		border-radius: 5px;
		border: 2px solid grey;
		${props => props.isbold && 'font-weight: bold'}
	}
	.ql-toolbar {
		background-color: white;
		border-radius: 5px;
		border: 2px solid grey;
		margin-bottom: 0.5rem;
	}
`;

export const TextAreaTitle = styled(TextArea)`
	margin-top: 1rem;
	flex-shrink: 1;
	box-sizing: border-box;
	max-width: 100%;
	width: 100%;
	overflow: auto;
	.ql-container {
		height: 15vh !important;
	}
	.ql-editor {
		overflow: hidden;
		word-wrap: break-word;
		white-space: pre-wrap;
	}
`;

export const TextAreaTitleBold = styled(TextAreaTitle)`
	margin-top: 1rem;
	width: 100%;
	.ql-editor {
		font-weight: 800;
	}
`;
