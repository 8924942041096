export const updateAndGetNewOrder = ({ values, setValues, result, isStatementsList }) => {
	const { destination, source } = result; // Extract the destination and source from the result object.
	const changed = []; // Array to store items whose order has changed.

	// If there's no destination or the item is dropped in the same position, return an empty array.
	if (!destination) return changed;
	if (destination.droppableId === source.droppableId && destination.index === source.index) {
		return changed;
	}

	// Create a copy of the values array to avoid mutating the original array.
	const newValues = Array.from(values);

	// Get the item that was dragged from the source index.
	const sourceValue = values[source.index];

	// Remove the item from its original position.
	newValues.splice(source.index, 1);

	// Insert the item into its new position.
	newValues.splice(destination.index, 0, sourceValue);

	// Update the order of items and store the changes.
	setValues(
		newValues.map((val, index) => {
			// Check if the item's order has changed and update it.
			if (val.order_index !== index) {
				changed.push(
					isStatementsList
						? { ...val, order: index, order_index: index } // For statements list, update both `order` and `order_index`.
						: { id: val.id, order: index } // For other items, update only `order`.
				);
			}
			return { ...val, order_index: index, index }; // Update the item's order_index and index.
		})
	);

	return changed; // Return the array of items whose order has changed.
};
