import React, { useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { RiSettings3Line } from 'react-icons/ri';
import styled from 'styled-components';
import PunctuationModal from 'features/punctuation/PunctuationModal';
import { useRecoilState } from 'recoil';
import { subTabsState } from '@note/atoms';
import LayoutSettings from './LayoutSettings';

const SubTabSettings = ({ subTab, saveSettings, punctuation }) => {
	const [subTabs, setSubTabs] = useRecoilState(subTabsState);

	const [showPunctuationModal, setShowPunctuationModal] = useState(false);

	const openPunctuationModal = () => {
		document.body.click(); // to close the popover since it close on blur
		setShowPunctuationModal(true);
	};
	const closePunctuationModal = () => setShowPunctuationModal(false);

	const handlePunctuationClick = () => {
		openPunctuationModal();
	};

	return (
		<>
			<OverlayTrigger
				trigger="click"
				rootClose
				placement="bottom"
				overlay={
					<Popover style={{ maxWidth: '410px' }}>
						<Popover.Body style={{ maxHeight: '65vh', overflowX: 'scroll' }}>
							<LayoutSettings subTab={subTab} saveSettings={saveSettings} subTabs={subTabs} setSubTabs={setSubTabs} />

							<div className="text-center">
								<PunctuationLink onClick={handlePunctuationClick}>Punctuation settings</PunctuationLink>
							</div>
						</Popover.Body>
					</Popover>
				}
			>
				<SettingButton title="Settings">
					<RiSettings3Line style={{ transform: 'translateY(-3px)' }} />
				</SettingButton>
			</OverlayTrigger>
			<PunctuationModal
				subTab={subTab}
				punctuation={punctuation}
				show={showPunctuationModal}
				handleClose={closePunctuationModal}
			/>
		</>
	);
};

export default SubTabSettings;

const SettingButton = styled(Button)`
	height: 40px;
	padding: 0 10px;
	font-size: 24px;
`;

const PunctuationLink = styled.a`
	cursor: pointer;
`;
