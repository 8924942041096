import React, { useState } from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap';
import { updateName } from 'services/noteType';
import { useNote } from 'contexts/NoteContext';

const RenameNoteType = ({ noteType }) => {
    const { noteTypes, setNoteTypes } = useNote();
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState('')
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            rename();
        }
        setValidated(true);
    };

    const rename = async () => {
        try {
            setIsLoading(false)
            const res = await updateName(noteType.id, name);
            setNoteTypes(noteTypes.map(nt => {
                return nt.id === noteType.id ? { ...nt, name: res.data.name } : nt;
            }))
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            console.error(err);
        }
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group >
                <InputGroup hasValidation className="mb-3">
                    <Form.Control
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter new name for the note-type"
                    />
                    <Button type='submit' variant="primary" id="button-addon2">
                        {isLoading ? 'Renaming...' : 'Rename'}
                    </Button>
                    <Form.Control.Feedback type="invalid">
                        Please choose a note-type name.
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
        </Form>
    )
}

export default RenameNoteType