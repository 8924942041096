import Cookies from 'js-cookie';
import React, { useCallback, useState } from 'react';

import { logout as fetchLogout } from '@note/services/user';
import { endSupport, endAIPlus } from '../services/aiEdit';

export const AuthContext = React.createContext();

// Context used for user-related data
export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(); // User information fetched from the database
	const [isOldSafari, setIsOldSafari] = useState(false); // If isOldSafari = true then sound effects cannot be used
	const [isAdmin, setIsAdmin] = useState(); // Admin value fetched from the backend
	const [signature, setSignature] = useState(); // Signature state shown in the note output
	const [signatureImage, setSignatureImage] = useState(); // Drawn signature
	const [signatureTextObject, setSignatureTextObject] = useState(); // Typed signature with chosen font
	const [letterheadPreview, setLetterheadPreview] = useState(); // Object to preview the letterhead in the corresponding modal
	const [showSignature, setShowSignature] = useState(false); // Toggle to show/hide signature
	const [showLetterhead, setShowLetterhead] = useState(false); // Toggle to show/hide letterhead
	const [showCloneModal, setShowCloneModal] = useState({ show: false, id: null, isSubTab: false }); // Toggle the modal to clone tab or sub-tabs to other notes

	const hostname = window.location.origin;
	const prodApp = window.location.origin.includes('app2.notedesigner');

	// Close WebSocket connections
	const closeWebsockets = noteId => {
		endSupport();
		noteId && endAIPlus(noteId);
	};

	// Logout function
	const logout = useCallback(
		async noteId => {
			try {
				await fetchLogout();
				window.location.href = `${hostname}/login`;
				closeWebsockets(noteId);
				Cookies.remove('csrftoken');
			} catch (error) {
				console.error('🚀 ~ file: AuthContext.js ~ line 21 ~ logout ~ error', error);
			}
		},
		[hostname]
	);

	// Logout from the modal that prompts when a 401 error is received
	const logoutFromModal = useCallback(
		async noteId => {
			try {
				await fetchLogout();
				if (prodApp) {
					const img = document.createElement('img');
					img.src = 'https://app.notedesigner.com/logout';
					img.style = 'display:none';
					document.body.append(img);
				}
				closeWebsockets(noteId);
				localStorage.removeItem('user');
				window.location.href = `${hostname}/login`;
				Cookies.remove('csrftoken');
			} catch (error) {
				console.error('🚀 ~ file: AuthContext.js ~ line 21 ~ logout ~ error', error);
			}
		},
		[hostname, prodApp]
	);

	// Clear storage and logout
	const logoutClearStorage = useCallback(
		async noteId => {
			try {
				await fetchLogout();
				if (prodApp) {
					const img = document.createElement('img');
					img.src = 'https://app.notedesigner.com/logout';
					img.style = 'display:none';
					document.body.append(img);
				} // To keep until app1 is decommissioned
				setTimeout(
					() => {
						localStorage.removeItem('user');
						window.location.href = `${hostname}/login`;
						Cookies.remove('csrftoken');
						closeWebsockets(noteId);
					},
					prodApp ? 1000 : 0
				);
			} catch (error) {
				console.error('🚀 ~ file: AuthContext.js ~ line 21 ~ logout ~ error', error);
			}
		},
		[hostname, prodApp]
	);

	return (
		<AuthContext.Provider
			value={{
				user,
				isAdmin,
				signature,
				showSignature,
				setIsAdmin,
				setUser,
				logout,
				logoutFromModal,
				logoutClearStorage,
				setSignature,
				signatureImage,
				setSignatureImage,
				setShowSignature,
				showLetterhead,
				setShowLetterhead,
				letterheadPreview,
				setLetterheadPreview,
				showCloneModal,
				setShowCloneModal,
				setIsOldSafari,
				isOldSafari,
				signatureTextObject,
				setSignatureTextObject,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
