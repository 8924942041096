import { ThemeButton } from 'components/ThemeButton';
import { useNote } from 'contexts/NoteContext';
import { usePreference } from 'contexts/PreferenceContext';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { duplicateSubtab } from 'services/subTab';
import { duplicateTab, getTabsByNoteId } from 'services/tab';
import styled from 'styled-components';
import { green, blue, red } from 'styles/colors';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';

import { AuthContext } from '@note/contexts/AuthContext';

// This modal is used to clone Tabs and/or Subtabs from the current note to the user's other notes
const ExportItemModal = ({ show, exportItemId, isSubTab }) => {
	const { noteTypes, activeNoteType } = useNote();
	const { user, setShowCloneModal, isOldSafari, isAdmin } = useContext(AuthContext);

	const [error, setError] = useState(); // State to handle errors
	const [submitSuccess, setSubmitSuccess] = useState(false); // State to handle success message
	const [selectedCurrent, setSelectedCurrent] = useState(false); // State to check if the current note is selected
	const [noteIds, setNoteIds] = useState([]); // State to store selected note IDs
	const [selectedId, setSelectedId] = useState(null); // State to store the selected note ID
	const [selectedNoteId, setSelectedNoteId] = useState(null); // State to store the selected note ID for subtabs
	const [tabs, setTabs] = useState([]); // State to store tabs of the selected note
	const [selectTabIds, setSelectTabIds] = useState([]); // State to store selected tab IDs
	const { globalPreferences } = usePreference();

	const tabInstructions = 'Clone This Tab to Duplicate it into One or More of Your Custom Notes';
	const subtabInstructions = 'Clone This Subtab to Duplicate it into One or More Tabs in a Custom Note';

	useEffect(() => {
		if (activeNoteType) {
			if (isSubTab) {
				setSelectedCurrent(selectedNoteId === activeNoteType.id);
			} else {
				setSelectedCurrent(noteIds.includes(activeNoteType.id));
			}
		}
	}, [noteIds, activeNoteType, isSubTab, selectedNoteId]);

	const myNoteTypes = useMemo(() => {
		if (noteTypes && noteTypes?.length > 0) {
			return noteTypes.filter(nt => {
				return isAdmin ? !nt.is_archived : nt?.user === user?.id && !nt.is_archived;
			});
		}
		return [];
	}, [noteTypes, user]);

	const fetchTabsByNoteIds = useCallback(async noteId => {
		try {
			const res = await getTabsByNoteId(noteId);
			setTabs(res.data);
		} catch (error) {
			console.error('Subtabs fetch error', error);
		}
	}, []);

	const handleNoteCheckChange = (event, id) => {
		if (isSubTab) {
			if (event.target.checked) {
				setSelectedId(id);
				setSelectedNoteId(id);
				setNoteIds(prevNoteIds => [...prevNoteIds, id]);
				fetchTabsByNoteIds(id);
			} else {
				let newNoteIds = noteIds.filter(noteId => noteId !== id);
				setNoteIds(newNoteIds);

				if (selectedNoteId === id) {
					setSelectedNoteId(null);
				}

				if (newNoteIds.length === 0 || selectedNoteId === id) {
					setSelectedId(null);
					setSelectTabIds([]);
					setTabs([]);
				}
			}
		} else if (event.target.checked) {
			setSelectedNoteId(id);
			setNoteIds(prevNoteIds => [...prevNoteIds, id]);
		} else {
			let newNoteIds = noteIds.filter(noteId => noteId !== id);
			setNoteIds(newNoteIds);

			if (id === selectedNoteId) {
				setSelectedNoteId(null);
				setSelectTabIds([]);
			}
		}
	};

	const handleTabCheckChange = (event, id) => {
		if (event.target.checked) {
			setSelectTabIds(prevSelectTabIds => {
				const newTabIds = [...prevSelectTabIds, id];
				return newTabIds;
			});
		} else {
			setSelectTabIds(prevSelectTabIds => {
				const newTabIds = prevSelectTabIds.filter(selectTabId => selectTabId !== id);
				return newTabIds;
			});
		}
	};

	const handleSubmit = useCallback(
		async event => {
			event.preventDefault();
			try {
				if (isSubTab) {
					await duplicateSubtab(exportItemId, selectTabIds);
				} else {
					await duplicateTab(exportItemId, noteIds);
				}
				setError(null);
				setSubmitSuccess(true);
				!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.SUCCESS);
				toast.success(`${isSubTab ? 'SubTab' : 'Tab'} cloned successfully`, { hideProgressBar: true });
			} catch (error) {
				setSubmitSuccess(false);
				setError('Error, please try again later');
			}
		},
		[exportItemId, globalPreferences.SoundEffects, isOldSafari, isSubTab, noteIds, selectTabIds]
	);

	const handleClose = () => {
		setSelectedCurrent(false);
		setNoteIds([]);
		setSelectedId(null);
		setSelectedNoteId(null);
		setTabs([]);
		setSelectTabIds([]);
		setSubmitSuccess(false);
		setShowCloneModal({ show: false, id: null, isSubTab: false });
	};

	const isDisabled = () => {
		if (isSubTab && !(selectTabIds?.length === 0 || selectTabIds === null)) {
			return false;
		} else if (!isSubTab && !(selectedNoteId?.length === 0 || selectedNoteId === null)) {
			return false;
		}
		return true;
	};

	return (
		<Modal
			show={show}
			onHide={() => handleClose()}
			centered
			size="lg"
			className={`h-100`}
			scrollable
			backdropClassName="backdrop-blur"
		>
			<Modal.Header closeButton>
				<Modal.Title>Clone {isSubTab ? 'SubTab' : 'Tab'} to Notes</Modal.Title>
			</Modal.Header>
			<Instructions>{isSubTab ? subtabInstructions : tabInstructions}</Instructions>
			<Modal.Body style={{ padding: 'unset' }}>
				<ModalForm onSubmit={handleSubmit}>
					<FormRow isSubTab={isSubTab}>
						<Panel>
							<ColumnTitle>To Which Note(s)?</ColumnTitle>
							<ItemsList>
								{myNoteTypes.map((item, index) => {
									return (
										<Form.Check
											label={item.name}
											key={`tabitem-${index}`}
											name="group1"
											type="checkbox"
											id={`note-${index}`}
											onChange={e => handleNoteCheckChange(e, item.id)}
											checked={isSubTab ? item.id === selectedId : noteIds.includes(item.id)}
										/>
									);
								})}
							</ItemsList>
						</Panel>
						<Panel>
							{isSubTab && <ColumnTitle>To Which Tab(s)?</ColumnTitle>}
							{isSubTab && (
								<ItemsList>
									{tabs.length > 0 &&
										tabs.map((item, index) => {
											return (
												<Form.Check
													label={item.name}
													key={`tabitem-${index}`}
													name="group1"
													type="checkbox"
													id={`tab-${index}`}
													onChange={e => handleTabCheckChange(e, item.id)}
													checked={selectTabIds.includes(item.id)}
												/>
											);
										})}
								</ItemsList>
							)}
						</Panel>
					</FormRow>
					{selectedCurrent && (
						<ModalAlert variant="primary">
							{isSubTab ? (
								<>
									You have selected the current active Note. Cloning here will create a copy of the Subtab. In order to
									view the changes, the page will need to be refreshed.
								</>
							) : (
								<>
									You have selected the current active note. Cloning here will create a copy of the Tab. In order to
									view the changes, the page will need to be refreshed.
								</>
							)}
						</ModalAlert>
					)}
					{error && <ErrorMessage>{error}</ErrorMessage>}
					{submitSuccess && <SuccessMessage>Done! You can now close this window.</SuccessMessage>}
					<ThemeButton disabled={isDisabled()} type="submit" fullWidth={true} label={'Submit'} size="lg" color="blue" />
				</ModalForm>
			</Modal.Body>
		</Modal>
	);
};

export default ExportItemModal;

// Styled components for various elements
const ModalAlert = styled(Alert)`
	height: fit-content;
`;

const Instructions = styled.div`
	margin-top: 1rem;
	text-align: center;
`;

const ModalForm = styled(Form)`
	padding: 1rem 4rem 2rem 4rem;
	display: flex;
	flex-direction: column;
	height: calc(100% - 4rem);
`;

const FormRow = styled.div`
	display: grid;
	grid-template-columns: ${props => (props.isSubTab ? 'repeat(2, 1fr)' : 'unset')};
	gap: 1.5rem;
	padding-bottom: 0.5rem;
	height: calc(100% - 5rem);
`;

const Panel = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const ItemsList = styled.div`
	overflow-y: auto;
	border: 2px solid lightgray;
	border-radius: 0.375rem;
	padding: 2rem;
	max-height: 50vh;
	flex-grow: 1;
`;

const ErrorMessage = styled.p`
	color: ${red};
	text-align: center;
	font-weight: 500;
`;

const SuccessMessage = styled.p`
	color: ${green};
	text-align: center;
	font-weight: 500;
`;

const ColumnTitle = styled.p`
	color: ${blue};
	font-size: 18px;
	text-align: center;
	font-weight: 700;
`;
