import { fetch } from 'utils/fetch';

const getStemByNoteType = id =>
	fetch({
		endpoint: `/notes/stems/?note_type=${id}`,
		method: 'get',
		errorLabel: 'getStemByNoteType',
	});

const updateStem = (id, stem) =>
	fetch({
		endpoint: `/notes/stems/${id}/`,
		method: 'patch',
		payload: stem,
		errorLabel: 'updateStem',
	});

const deleteStem = id =>
	fetch({
		endpoint: `/notes/stems/${id}/`,
		method: 'delete',
		errorLabel: 'deleteStem',
	});

const createStem = stems =>
	fetch({
		endpoint: `/notes/stems/`,
		method: 'post',
		payload: stems,
		errorLabel: 'createStem',
	});

export { getStemByNoteType, updateStem, createStem, deleteStem };
