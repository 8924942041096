import React from 'react';
import { useNote } from 'contexts/NoteContext';
import { Modal } from 'react-bootstrap';

const TermsOfServiceModal = ({ show }) => {
	const { setShowTermsOfServiceModal } = useNote();

	const onClose = () => {
		setShowTermsOfServiceModal(false);
	};

	return (
		<Modal
			show={show}
			onHide={onClose}
			backdrop="static"
			scrollable
			centered
			size="xl"
			backdropClassName="backdrop-blur"
		>
			<Modal.Header closeButton>
				<Modal.Title>Terms and Conditions</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div style={{ padding: '1rem' }}>
					<h2>Below are the Terms of Service for using Note Designer</h2>
					<br />
					<p>
						<ul>
							<li>Note Designer: End User License Agreement</li>
							<li>TERMS OF USE OF AI FUNCTIONALITY (general)</li>
							<li>TERMS OF USE OF AI FUNCTIONALITY (specific to AI NOTE)</li>
						</ul>
					</p>
					<br />
					<h2>Note Designer: End User License Agreement</h2>
					<br />
					<p>This is a copy of the Note Designer End-User License Agreement (EULA)</p>
					<p>
						Please read the following terms and conditions carefully before using this SOFTWARE PRODUCT. This End-User
						License Agreement (EULA) is a legal agreement between you and NOTE DESIGNER INC. (previously MINDHABITS,
						Inc.), the distributor of NOTE DESIGNER, which governs your use of NOTE DESIGNER. Your use or installation
						of this copy of NOTE DESIGNER indicates your acceptance of this License.
					</p>
					<p>
						SOFTWARE PRODUCT here means Software, image files, web pages, all accompanying files, data and materials
						(including the psychotherapy-related statements and phrases) received with your order of NOTE DESIGNER. If
						you do not agree to any of the terms of this License, then do not install or use the SOFTWARE PRODUCT. If
						you have purchased a copy from NOTE DESIGNER, INC. or an authorized distributor or authorized retail
						channel, you may return it, within thirty (30) days after purchase, for a refund of your payment.
					</p>
					<p>
						This SOFTWARE PRODUCT is for use only by one individual, the authorized user, in conjunction with an
						authorization code and/or user account based on the user’s name and/or email address, and provided by NOTE
						DESIGNER INC. It may be used on more than one computer by the authorized user, but it may not be distributed
						to others. SOFTWARE PRODUCT may be used by more than one individual only in accordance with a group/site
						license agreement. All components accompanying the software are copyrighted by NOTE DESIGNER, INC. and may
						not be taken apart, modified, used or published with other software or means except with the SOFTWARE
						PRODUCT software and may not be distributed or copied in any manner.
					</p>
					<p>
						To the maximum extent permitted under applicable law, this SOFTWARE PRODUCT, all accompanying files, data
						and materials, are distributed AS IS and with no warranties of any kind, whether express or implied. The
						user must assume all risk of using the program. This disclaimer of warranty constitutes an essential part of
						the agreement.
					</p>
					<p>
						YOU AGREE THAT THE USE OF THE SOFTWARE PRODUCT IS AT YOUR SOLE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE,
						ACCURACY AND EFFORT. NOTE DESIGNER INC. does NOT warrant that the functions contained in the Software
						Product will meet your requirements or that the operation of the Software Product will be uninterrupted or
						error free or that such errors will be corrected. Computer software is inherently subject to bugs and
						potential incompatibility with other computer software and hardware. You should not use the Software Product
						for any application in which failure could cause any significant damage or injury to persons or tangible or
						intangible property. To the maximum extent permitted by applicable law, in no event shall NOTE DESIGNER INC.
						or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever
						(including, but not limited to, damages for loss of profits, for loss of data or other information, for
						business interruption, for personal injury, for loss of privacy arising out of or in any way related to the
						use of or inability to use the software product, third party software and/or third party hardware used with
						the software product, or otherwise in connection with any provision of this EULA), even if NOTE DESIGNER
						INC. or any supplier has been advised of the possibility of such damages and even if the remedy fails of its
						essential purpose. You acknowledge that maintenance of proper clinical notes and the confidentiality of
						information is your sole responsibility. NOTE DESIGNER INC. does NOT warrant that the functions and content
						contained in the Software Product will meet your requirements or the requirements of any professional
						organization or regulatory body. NOTE DESIGNER INC. cannot guarantee that database functions that may be
						contained in the software are completely secure from access or decoding by third parties. Accordingly, NOTE
						DESIGNER INC. will not be responsible for any losses or damages whatsoever resulting from third party
						decoding of, or access to, your files. The software may contain open source materials (e.g., any software
						subject to open source, copyleft, GNU general public license, library general public license, lesser general
						public license, Mozilla license, Berkeley software distribution license, open source initiative license,
						MIT, Apache, or public domain licenses, or similar license). NOTE DESIGNER INC. makes no warranties, and
						shall have no liability, direct or indirect, whatsoever with respect to open source materials contained in
						the software. Any liability of NOTE DESIGNER, INC. will be limited exclusively to refund of purchase price.
						In addition, in no event shall NOTE DESIGNER, INC., or its principals, shareholders, officers, employees,
						affiliates, contractors, subsidiaries, or parent organizations, be liable for any incidental, consequential,
						punitive or any other damages whatsoever relating to the use of SOFTWARE PRODUCT. You agree to indemnify and
						hold NOTE DESIGNER, INC. harmless from any claims resulting from the use of this SOFTWARE PRODUCT, which may
						damage any other party.
					</p>
					<p>
						NOTE DESIGNER, INC. holds the rights to this SOFTWARE PRODUCT, and all of its derivatives, title and
						accompanying materials. NOTE DESIGNER grants to the licensee the right to use the psychotherapy-related
						phrases and statements in the SOFTWARE PRODUCT for the intended purpose of preparing their own
						psychotherapy-related notes. All rights of any kind, which are not expressly granted in this License, are
						entirely and exclusively reserved to and by NOTE DESIGNER, INC. You may not rent, lease, transfer, modify,
						distribute, sublicense, translate, reverse engineer, de-compile, disassemble or create derivative works
						based on this SOFTWARE PRODUCT or the content therein. You may not disclose to other persons the data or
						techniques relating to this SOFTWARE PRODUCT that you know or should know that it is a trade secret of NOTE
						DESIGNER, INC. in any manner that will cause damage to NOTE DESIGNER, INC. If you breach these restrictions,
						you may be subject to prosecution and damages.
					</p>
					<p>
						This Agreement constitutes the entire statement of the Agreement between the parties on the subject matter,
						and merges and supersedes all other or prior understandings, purchase orders, agreements and arrangements.
						This Agreement shall be governed by the laws of the Province of Quebec. This EULA is effective unless
						terminated or rejected. This EULA will also terminate immediately and without additional notice in the event
						you breach this EULA and/or fail to comply with any term or condition of this EULA.
					</p>
					<br />
					<br />
					<h2>TERMS OF USE OF AI FUNCTIONALITY </h2>
					<h2>(general)</h2>
					<br />
					<p>Consent for Using Note Designer’s AI Functions</p>
					<p>
						As we add AI functionality to all note-types, including your highly customized templates, it is important
						for maintaining HIPAA compliance that we emphasize and remind you of your responsibility to not enter
						identifying Private Health Information into fields that are sent to the AI system.
					</p>
					<br />
					<b>Please Note the Following Capabilities and Potential Risks</b>
					<br />
					<br />
					<p>
						<ul>
							<li>
								<b>{'You’re in Control:'}</b> Note Designer will not send any information you input to the AI system
								without your instruction (e.g., pressing the “AI REWRITE” button).
							</li>
							<li>
								<b>{'AI isn’t Perfect:'}</b> Note Designer will not send any information you input to the AI system
								without your instruction (e.g., pressing the “AI REWRITE” button).
							</li>
							<li>
								<b>Potential Security Issues:</b> Though Note Designer does not send a user’s headings (i.e., client
								name, date of session, CPT codes etc.) or therapist signature/signature line to the AI system, other
								information outside of the Headings tab and signature line as entered by the user does get transmitted,
								in an encrypted form, over the internet to the AI system. Though unlikely, there are always possible
								risks associated with using any digital technology, including potential data breaches. This is why we
								emphasize not submiting identifying information to the AI Rewrite.
							</li>
						</ul>
					</p>
					<p>
						<b>Your Responsibilities regarding Data Privacy and Ethical Practice</b>
					</p>
					<br />
					<ul>
						<li>
							<li>
								<b>No PHI Entries:</b> Do not enter any identifying Protected Health Information (PHI) into the AI
								system (e.g., text field inputs).
							</li>
						</li>
						<li>
							<li>
								<b>Review and Oversight:</b> Be sure to thoroughly review and edit all AI- generated notes to ensure
								they accurately reflect the therapy session and adhere to professional standards.
							</li>
						</li>
						<li>
							<li>
								<b>Compliance with Privacy Laws:</b> t is your responsibility to comply with all applicable privacy laws
								and ethical guidelines in the use of AI for psychotherapy documentation.
							</li>
						</li>
					</ul>
					<br />
					<br />
					<p>
						<h2>TERMS OF USE OF AI FUNCTIONALITY </h2>
						<h2>(specific to AI NOTE)</h2>
					</p>
					<p>
						<div>Consent for Using Note Designer’s “AI Note”</div>
						This consent form outlines the terms under which Note Designer uses Artificial Intelligence (AI) technology
						in “AI Note” to re-write psychotherapy notes submitted by our users. By consenting to this document, you
						acknowledge understanding and agreeing to the use of the AI system in processing your psychotherapy notes
						under the specified conditions.
					</p>
					<p>
						Please note that the AI technology used for the “AI Note” is distinct from the AI system employed in
						processing the other Note Types (i.e., our fully integrated AI). Specifically, the “AI Note” leverages a
						third-party (OpenAI) AI system, while for all other note-types, our private AI system processes the inputs.
						In either case, only generic, non-identified information should be entered into note content that will be
						rewritten using the AI feature. As always all identifying information included in the Headings tab is never
						sent to any AI system.
					</p>
					<p>
						It is important for maintaining HIPAA compliance that we emphasize and remind you of your responsibility to
						not enter identifying Private Health Information into fields that are sent to either AI system.
					</p>
					<p>
						<b>Please Note the Following Capabilities and Potential Risks</b>
						<br />
						<br />
						<p>
							<ul>
								<li>
									<b>{'You’re in Control:'}</b> Note Designer will not send any information you input to the AI system
									without your instruction (e.g., pressing the “AI REWRITE” button).
								</li>
								<li>
									<b>{'AI isn’t Perfect:'}</b> AI technology has limitations and may not always accurately interpret or
									represent the nuances of therapy sessions.
								</li>
								<li>
									<b>Potential Security Issues:</b> Though Note Designer does not send a user’s headings (i.e., client
									name, date of session, CPT codes etc.) or therapist signature/signature line to the AI system, other
									information outside of the Headings tab and signature line as entered by the user does get
									transmitted, in an encrypted form, over the internet to the AI system. Though unlikely, there are
									always possible risks associated with using any digital technology, including potential data breaches.
									This is why we emphasize not submitting identifying information to the AI Rewrite.
								</li>
							</ul>
						</p>
					</p>
					<p>
						<b>Your Responsibilities regarding Data Privacy and Ethical Practice</b>
						<br />
						<br />
						<p>
							<ul>
								<li>
									<b>No PHI Entries:</b> Do not enter any identifying Protected Health Information (PHI) into the AI
									system (e.g., text field inputs).
								</li>
								<li>
									<b>Review and Oversight:</b> Be sure to thoroughly review and edit all AI- generated notes to ensure
									they accurately reflect the therapy session and adhere to professional standards.
								</li>
								<li>
									<b>Compliance with Privacy Laws:</b> It is your responsibility to comply with all applicable privacy
									laws and ethical guidelines in the use of AI for psychotherapy documentation.
								</li>
							</ul>
						</p>
					</p>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default TermsOfServiceModal;
