import React from 'react';
import styled from 'styled-components';

import { useBulkStatements } from 'hooks';

const BulkCheckBox = ({ statement }) => {
	const { addStatementToBulk, removeBulkStatement } = useBulkStatements();

	const handleCheck = (e, statement) => {
		if (e.target.checked) addStatementToBulk(statement);
		else removeBulkStatement(statement);
	};

	return (
		<Label>
			<CheckBox onChange={e => handleCheck(e, statement)} type="checkbox" />
		</Label>
	);
};

export default BulkCheckBox;

const CheckBox = styled.input`
	cursor: pointer;
`;
const Label = styled.label`
	height: 100%;
	display: flex;
	align-items: center;
	cursor: pointer;
`;
