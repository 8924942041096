import React from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';

// Card to display and item in the feed of the community modal
const FeedCard = ({ image, text }) => {
	return (
		<StyledCard>
			<CardHeader>
				<ImageDiv image={image}></ImageDiv>
			</CardHeader>
			<CardBody>{text}</CardBody>
		</StyledCard>
	);
};

export default FeedCard;

const CardHeader = styled(Card.Header)`
	padding: unset;
	aspect-ratio: 1 / 0.6;
	height: fit-content;
	border: unset;
`;

const CardBody = styled(Card.Body)`
	border: 1px solid lightgray;
	border-top: none;
	border-bottom-right-radius: 0.375rem;
	border-bottom-left-radius: 0.375rem;
`;

const ImageDiv = styled.div`
	background-image: url(${props => props.image});
	background-size: cover;
	background-repeat: no-repeat;
	aspect-ratio: 1/0.6;
	border-top-right-radius: 0.375rem;
	border-top-left-radius: 0.375rem;
`;

const StyledCard = styled(Card)`
	border: unset;
`;
