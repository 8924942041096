import React from 'react';
import styled from 'styled-components';

import { WidgetType } from 'utils/enum';

import ListSelectionPreview from './ListSelectionPreview';
import ManualInputPreview from './ManualInputPreview';
import MultipleChoicePreview from './MultipleChoicePreview';
import PSOPreview from './PSOPreview';
import PrefilledTextPreview from './PrefilledTextPreview';

const WidgetTypePreview = ({ subTab }) => {
	const getPreview = () => {
		switch (subTab.type) {
			case WidgetType.MANUAL_INPUT:
				return <ManualInputPreview subTab={subTab} />;
			case WidgetType.LIST_MULTI_SELECT:
				return <ListSelectionPreview subTab={subTab} />;
			case WidgetType.LIST_SELECT:
				return <ListSelectionPreview subTab={subTab} />;
			case WidgetType.PSO:
				return <PSOPreview subTab={subTab} />;
			case WidgetType.PS:
				return <PSOPreview subTab={subTab} />;
			case WidgetType.MULTIPLE_CHOICE:
				return <MultipleChoicePreview subTab={subTab} />;
			case WidgetType.PREFILLED_INPUT:
				return <PrefilledTextPreview subTab={subTab} />;
			default:
				return <div>Please Select a widget type</div>;
		}
	};

	return <div>{subTab && <PreviewContainer>{getPreview(subTab.type)}</PreviewContainer>}</div>;
};

export default WidgetTypePreview;

const PreviewContainer = styled.div`
	padding: 10px;
`;
