import { ThemeButton } from 'components/ThemeButton';
import React, { useState } from 'react';

import DisableStemModal from './DisableStemModal';

const ToggleSwitch = ({ subTab, toggleStem }) => {
	const [showModal, setShowModal] = useState(false);

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

	return (
		<>
			<ThemeButton
				onClick={subTab.is_stem_disabled ? toggleStem : openModal}
				color="yellow"
				label={subTab.is_stem_disabled ? 'Enable stem' : 'Disable stem'}
			/>
			{showModal && <DisableStemModal show={showModal} handleClose={closeModal} toggleStem={toggleStem} />}
		</>
	);
};

export default ToggleSwitch;
