import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import Tippy from '@tippyjs/react';
import { ThemeButton } from 'components/ThemeButton';
import { usePreference } from 'contexts/PreferenceContext';
import React, { useCallback, useContext, useState } from 'react';
import { Container, Nav } from 'react-bootstrap';
import { FaFileExport, FaPencilAlt, FaPlus } from 'react-icons/fa';
import { useRecoilState, useRecoilValue } from 'recoil';
import { reorderTabs } from 'services/tab';
import styled from 'styled-components';
import { blue, violet, purple } from 'styles/colors';
import { updateAndGetNewOrder } from 'utils/dragDropHelper';
import { isDesignPageState, selectorActiveTabId, tabsState, designTourState } from '@note/atoms';
import { useNote } from 'contexts/NoteContext';
import { AuthContext } from '@note/contexts/AuthContext';

import NewTabModal from './NewTabModal';

const TabNav = ({ defaultTab }) => {
	const { activeNoteType } = useNote();
	const { globalPreferences } = usePreference();
	const { useImages } = globalPreferences;
	const isDesignPage = useRecoilValue(isDesignPageState);
	const isDesignTourState = useRecoilValue(designTourState);
	const [tabs, setTabs] = useRecoilState(tabsState);
	const [showModal, setShowModal] = useState(false);
	const [selectedTab, setSelectedTab] = useState(false);
	const [activeTabId, setActiveTabId] = useRecoilState(selectorActiveTabId);

	const { setShowCloneModal } = useContext(AuthContext);

	// Function to open the modal for adding or editing a tab
	const openModal = useCallback(() => setShowModal(true), []);

	// Function to close the modal and reset the selected tab state
	const closeModal = useCallback(() => {
		setSelectedTab(null);
		setShowModal(false);
	}, []);

	const defaultTabColor = globalPreferences.TabsColors !== 'Mixed';
	const colors = ['#4DB6AC', '#9575CD', '#e57373', '#7986CB', '#81C784'];

	// Function to get a color for the tab based on its index
	const getColor = i => (i > 4 ? colors[i % 5] : colors[i]);

	// Function to handle the click event for adding a new tab
	const handleTabAddClick = useCallback(
		tab => {
			if (!isDesignTourState) {
				setSelectedTab(tab);
				openModal();
			}
		},
		[isDesignTourState, openModal]
	);

	// Function to handle the cloning of a tab
	const handleTabClone = tab => {
		!isDesignTourState && setShowCloneModal({ show: true, id: tab.id, isSubTab: false });
	};

	// Function to set the active tab
	const handleActiveTab = tab => {
		setActiveTabId(tab ? tab.id : defaultTab);
	};

	// Function to save the order of tabs after dragging and dropping
	const saveOrder = useCallback(
		async changedTabs => {
			if (changedTabs.length === 0) return;
			try {
				await reorderTabs({ orders: changedTabs });
				setTabs(
					tabs
						?.map(tab => {
							const updatedTab = changedTabs.find(changed => changed.id === tab.id);
							return updatedTab && updatedTab !== -1 ? { ...tab, order_index: updatedTab.order } : tab;
						})
						.sort((a, b) => a.order_index - b.order_index)
				);
			} catch (error) {
				console.error('🚀 ~ file: TabNav.js ~ line 60 ~ saveOrder ~ error', error);
			}
		},
		[setTabs, tabs]
	);

	// Function to handle the drag end event and update the order of tabs
	const handleDragEnd = useCallback(
		result => {
			const changedTabs = updateAndGetNewOrder({ values: tabs, setValues: setTabs, result });
			saveOrder(changedTabs);
		},
		[saveOrder, setTabs, tabs]
	);

	// Function to determine the color of the tab based on whether it is active
	const tabColor = id => (id === activeTabId ? purple : violet);

	// Function to render each tab item
	const renderTabItem = (tab, index, provided) => {
		return (
			<TabItem
				key={index}
				title={tab.name}
				ref={provided?.innerRef}
				defaultColor={defaultTabColor}
				bgcolor={getColor(index)}
				{...provided?.draggableProps}
				{...provided?.dragHandleProps}
				className={
					tab.name.toLowerCase() === 'subjective'
						? 'subjective-tab'
						: tab.name.toLowerCase() === 'new tab'
						? 'new-tab'
						: index === 0
						? 'first-tab-nav'
						: ''
				}
			>
				<TabLink
					bgcolor={defaultTabColor ? tabColor(tab.id) : getColor(index)}
					defaultColor={defaultTabColor}
					onClick={() => handleActiveTab(tab)}
					eventKey={tab.id}
					title=""
				>
					{tab.name}
				</TabLink>
				{!(activeNoteType?.is_ai_note || activeNoteType?.is_ai_plus_note) && (
					<TabIcons className="tabIcons" isDesign={isDesignPage && !activeNoteType.is_tour}>
						{isDesignPage && !activeNoteType.is_tour && (
							<Tippy content="Edit">
								<IconWrapper style={{ marginRight: '10px' }} onClick={() => handleTabAddClick(tab)}>
									<FaPencilAlt />
								</IconWrapper>
							</Tippy>
						)}
						<Tippy content="Click here if you want to clone this tab and duplicate it into another location">
							<IconWrapper onClick={() => handleTabClone(tab)}>
								<FaFileExport />
							</IconWrapper>
						</Tippy>
					</TabIcons>
				)}
			</TabItem>
		);
	};

	return (
		<Container fluid className="navtabs">
			<CustomTab variant="tabs" className="tab-nav">
				<TabItem defaultColor={defaultTabColor} bgcolor={getColor('headings')}>
					<TabLink
						onClick={() => handleActiveTab({ name: 'headings', id: 'headings' })}
						bgcolor={tabColor('headings')}
						defaultColor={defaultTabColor}
						eventKey="headings"
						className="headings-tab"
					>
						Headings
					</TabLink>
				</TabItem>
				{activeNoteType?.is_ai_note && (
					<TabItem defaultColor={defaultTabColor} bgcolor={getColor('ai-settings')}>
						<TabLink
							onClick={() => handleActiveTab({ name: 'ai-settings', id: 'ai-settings' })}
							bgcolor={tabColor('ai-settings')}
							defaultColor={defaultTabColor}
							eventKey="ai-settings"
						>
							AI settings
						</TabLink>
					</TabItem>
				)}
				{isDesignPage ? (
					<>
						<DragDropContext onDragEnd={handleDragEnd}>
							<Droppable droppableId="tabdroppable" direction="horizontal">
								{(provided, snapshot) => (
									<span style={{ display: 'flex' }} ref={provided.innerRef} {...provided.droppableProps}>
										{tabs?.map((tab, index) => (
											<Draggable
												key={tab.id}
												draggableId={tab.id.toString()}
												index={index}
												isDragDisabled={!isDesignPage}
											>
												{(provided, snapshot) => renderTabItem(tab, index, provided)}
											</Draggable>
										))}
										{provided.placeholder}
									</span>
								)}
							</Droppable>
						</DragDropContext>
						<>
							<AddBtn onClick={() => handleTabAddClick(null)} className="add-tab-btn">
								<ThemeButton label="Add tab" icon={FaPlus} />
							</AddBtn>
							{showModal && (
								<NewTabModal
									show={showModal}
									handleClose={closeModal}
									selectedTab={selectedTab}
									tabs={tabs}
									setActiveTabId={setActiveTabId}
									setTabs={setTabs}
								/>
							)}
						</>
					</>
				) : (
					<span style={{ display: 'flex' }}>{tabs?.map((tab, index) => renderTabItem(tab, index))}</span>
				)}
				{useImages === 'Show images' && (
					<TabItem defaultColor={defaultTabColor} bgcolor={getColor('images')}>
						<TabLink
							onClick={() => handleActiveTab({ name: 'images', id: 'images' })}
							bgcolor={tabColor('images')}
							defaultColor={defaultTabColor}
							eventKey="images"
						>
							Images
						</TabLink>
					</TabItem>
				)}
			</CustomTab>
		</Container>
	);
};

export default React.memo(TabNav);

const CustomTab = styled(Nav)`
	padding: 0 10px;
	height: 65px;
	display: flex;
	align-items: flex-end;
	overflow-x: auto;
	overflow-y: hidden;
	flex-wrap: nowrap;
`;

const TabItem = styled(Nav.Item)`
	position: relative;
	display: flex;
	align-items: flex-end;
	cursor: pointer;
	& > a.active {
		height: 50px;
		align-items: flex-start;
		transition: 0.3s all;
		color: white !important;
	}
	&:hover {
		color: white;
		& > a {
			background-color: ${props => (props.defaultColor ? purple : props.bgcolor)};
			color: white;
		}
		& .tabIcons {
			background-color: rgba(255, 255, 255, 0.6);
		}
		& > div {
			& > div {
				display: flex;
			}
		}
	}
`;

const AddBtn = styled(Nav.Item)`
	margin-left: 10px;
	margin-bottom: 3px;
`;

const TabLink = styled(Nav.Link)`
	height: 35px;
	display: flex;
	align-items: center;
	font-weight: bold;
	margin-left: 5px;
	background-color: ${props => props.bgcolor};
	color: white;
	position: relative;
	white-space: nowrap;
	&.active,
	&:active {
		background-color: ${props => (props.defaultColor ? `${purple} !important` : `${props.bgcolor} !important`)};
		color: white !important;
	}
`;

const TabIcons = styled.div`
	position: absolute;
	top: 2;
	right: ${props => (props.isDesign ? '-50px' : '-15px')};
	z-index: 20;
	display: flex;
	flex-direction: row;
	padding: 3px;
	border-radius: 1rem;
`;

const IconWrapper = styled.div`
	background: white;
	color: gray;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	cursor: pointer;
	display: none;
	border: 1px solid gray;
	&:hover {
		border: 1px solid ${blue};
		color: ${blue};
	}
`;
