import { ThemeButton } from 'components/ThemeButton';
import MoveModal from 'features/bulk-action/MoveModal';
import SaveAsModal from 'features/save-as/SaveAsModal';
import { useBulkStatements } from 'hooks';
import React, { useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { FaArrowLeft, FaHome } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import NavMenu from './nav-menu/NavMenu';
import NavLinks from './NavLinks';

//Navbar of the design mode of a note
const DesignerNav = () => {
	const navigate = useNavigate();
	const { toggleBulkMode } = useBulkStatements();

	const [searchParams] = useSearchParams();
	const [showModeModal, setShowMoveModal] = useState(false);
	const [showSaveAsModal, setShowSaveAsModal] = useState(false);

	const openMoveModal = () => setShowMoveModal(true);
	const closeMoveModal = () => setShowMoveModal(false);

	const openSaveAsModal = () => setShowSaveAsModal(true);
	const closeSaveAsModal = () => setShowSaveAsModal(false);

	const handleBackClick = () => {
		const id = searchParams.get('id');
		navigate('/main-app?id=' + id);
	};

	return (
		<NavigationBar className="px-2 shadow" collapseOnSelect expand="lg" bg="dark" variant="dark">
			<NavbarBrand>
				<FaHome />
				<span>Note Designer</span>
			</NavbarBrand>

			<ButtonContainer>
				<ThemeButton
					onClick={handleBackClick}
					size="sm"
					label="Leave this design page and go back to note"
					icon={FaArrowLeft}
					tourAnchor="leave-design-btn"
				/>
			</ButtonContainer>
			<NavbarBrand style={{ marginLeft: '1rem' }}>
				<span>DESIGN SPACE</span>
			</NavbarBrand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse className="justify-content-end">
				<NavLinks />
			</Navbar.Collapse>
			<NavMenu
				designMode={true}
				openSaveAsModal={openSaveAsModal}
				openMoveModal={openMoveModal}
				toggleBulkMode={toggleBulkMode}
			/>
			<MoveModal show={showModeModal} handleClose={closeMoveModal} />
			<SaveAsModal show={showSaveAsModal} handleClose={closeSaveAsModal} />
		</NavigationBar>
	);
};

export default DesignerNav;

const NavigationBar = styled(Navbar)`
	width: 100%;
	height: 45px;
	background-color: rgb(33, 37, 41) !important;
`;

const NavbarBrand = styled(Navbar.Brand)`
	display: inline-flex;
	align-items: center;
	& > span {
		font-size: 20px;
		font-weight: 500;
	}
	& > svg {
		font-size: 22px;
		margin-right: 5px;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 5px;
`;
