import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

const DEFAULT_OPTION = 'choose one or more options';

const HeadingSelect = ({ heading, onChange }) => {
	const { options } = heading;
	const [showMenu, setShowMenu] = useState(false);

	// Handle option click: add, remove, or deselect all options
	const handleClick = (e, option) => {
		let value = '';
		if (option !== DEFAULT_OPTION) {
			let content = heading?.content ? heading.content.split(', ') : [];
			if (content.length === 0) {
				value = option;
			} else if (content.includes(option)) {
				value = content.filter(c => c !== option).join(', ');
			} else {
				content.push(option);
				value = content.join(', ');
			}
		} else {
			setShowMenu(false);
		}
		onChange(value);
	};

	// Check if an option is already selected
	const isChecked = useCallback(
		option => {
			const contentArray = heading?.content ? heading.content.split(', ') : [];
			return contentArray.includes(option);
		},
		[heading]
	);

	return (
		<>
			<CustomDropDown className="headings-dropdown">
				<Dropdown.Toggle onClick={() => setShowMenu(!showMenu)} className="headings-dropdown-toggle">
					{heading?.content ? heading?.content : DEFAULT_OPTION}
				</Dropdown.Toggle>
				{showMenu && (
					<Menu tabIndex="0" onBlur={() => setShowMenu(false)} className="headings-dropdown-menu">
						<MenuItem onClick={e => handleClick(e, DEFAULT_OPTION)}>Deselect all</MenuItem>
						{options?.length > 0 &&
							options?.map((option, index) => (
								<MenuItem
									active={isChecked(option)}
									onClick={e => handleClick(e, option)}
									key={index}
									className="heading-option"
								>
									{option}
								</MenuItem>
							))}
					</Menu>
				)}
			</CustomDropDown>
		</>
	);
};

export default HeadingSelect;

const CustomDropDown = styled(Dropdown)`
	position: relative;
	button {
		width: 100%;
		text-align: left;
		display: block;
		padding: 0.375rem 1.25rem 0.375rem 0.75rem;
		-moz-padding-start: calc(0.75rem - 3px);
		font-size: 1rem;
		font-weight: 400;
		color: #212529 !important;
		background-color: #fff !important;
		border: 1px solid #ced4da !important;
		border-radius: 0.25rem;
	}

	.btn-primary:hover {
		color: #212529 !important;
	}

	button::after {
		float: right;
		margin-top: 10px;
	}
`;

const Menu = styled.div`
	width: 100%;
	position: absolute;
	z-index: 1000;
	background: white;
	top: 42px;
	border: 1px solid #ced4da !important;
	border-radius: 5px;
	padding: 5px 0px;
`;

const MenuItem = styled.div`
	width: 100%;
	padding: 5px 10px;
	cursor: pointer;
	margin-bottom: 3px;
	&:hover {
		background: ${props => (props?.active ? '#0d6efd' : '#e9ecef')};
	}
	${props =>
		props?.active &&
		`
		background: #0d6efd;
		color: white;
	`}
`;
