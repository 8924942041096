import { fetch } from 'utils/fetch';

const getNotifications = () =>
	fetch({
		endpoint: `/notifications/`,
		method: 'get',
		errorLabel: 'getNotifications',
	});


const readNotification = id =>
	fetch({
		endpoint: `/notifications/${id}/read/`,
		method: 'post',
		errorLabel: 'readNotification',
	});

export {
	getNotifications,
	readNotification,
};
