import { Separator } from 'components/Separator';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import SectionName from '../../note-selector/section/section-name/SectionName';
import LibraryStatement from '../statement/LibraryStatement';

const LibrarySection = ({
	subTab,
	section,
	filteredSections,
	searchValue,
	libraryStatements,
	setLibraryStatements,
	filteredStems,
	isSectionNameVisible,
	statements,
}) => {
	const [filteredStatements, setFilteredStatements] = useState([]);

	// Update the filtered statements based on the search value or section id
	useEffect(() => {
		if (searchValue) {
			setFilteredStatements(
				libraryStatements.filter(
					statement =>
						statement.section === section.id && statement.name.toLowerCase().includes(searchValue.toLowerCase())
				)
			);
		} else {
			setFilteredStatements(libraryStatements.filter(statement => statement.section === section.id));
		}
	}, [section, libraryStatements, searchValue]);

	return (
		<>
			{isSectionNameVisible && <SectionName section={section} sectionHref="library-section" />}
			<Stack isDoubleStacked={subTab.settings?.listSelect?.isListDoubleStacked}>
				{filteredStatements.length > 0 ? (
					filteredStatements.map((statement, index) => (
						<LibraryStatement
							key={index}
							statement={statement}
							filteredStems={filteredStems}
							index={index}
							subTab={subTab}
							filteredSections={filteredSections}
							libraryStatements={libraryStatements}
							setLibraryStatements={setLibraryStatements}
							statements={statements}
						/>
					))
				) : (
					<NoStatementFound>No Statements Found for this Section</NoStatementFound>
				)}
			</Stack>
			<Separator />
		</>
	);
};

export default React.memo(LibrarySection);

// Styled components for layout
const Stack = styled.div`
	display: grid;
	grid-template-columns: ${props => (props.isDoubleStacked ? '50% 50%' : '100%')};
	column-gap: 5px;
`;

const NoStatementFound = styled.div`
	text-align: center;
`;
