import { useNote } from 'contexts/NoteContext';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import React, { useContext, useEffect, useRef } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { FilePond, registerPlugin } from 'react-filepond';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { saveImage } from 'services/images';
import { imagesState } from '@note/atoms';
import { AuthContext } from '@note/contexts/AuthContext';

// Registering FilePond plugins
registerPlugin(FilePondPluginImagePreview, FilePondPluginImageExifOrientation, FilePondPluginFileValidateType);

const ImagesUpload = ({ updateClearAll, updateUploadAll, isDesignPage, onInvalidFileType }) => {
	const { user } = useContext(AuthContext);
	const { noteImagesUpload, setNoteImagesUpload } = useNote();
	const [images, setImages] = useRecoilState(imagesState);
	const pond = useRef(null);
	const acceptedTypes = [
		'image/jpeg',
		'image/png',
		'image/gif',
		'image/bmp',
		'image/tiff',
		'image/avif',
		'image/svg+xml',
	];

	const savedImagesQty = images.filter(img => img.isSaved === true && !img.default).length; // Count saved images

	// Function to clear all images from the FilePond instance
	const clearAll = () => {
		if (pond.current) {
			pond.current.removeFiles();
			setNoteImagesUpload([]);
		}
	};

	// Ref to keep track of the current state of noteImagesUpload
	const noteImagesUploadRef = useRef(noteImagesUpload);
	noteImagesUploadRef.current = noteImagesUpload; // Update the ref to the current noteImagesUpload state

	// Calculate the total number of uploaded files
	const filesUploasdQty = noteImagesUploadRef?.current?.length + savedImagesQty;

	// Convert object URL to Base64 string
	async function convertObjectURLToBase64(fileUrl) {
		const response = await fetch(fileUrl);
		const blob = await response.blob();

		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = function (e) {
				resolve(e.target.result);
			};

			reader.onerror = function (e) {
				reject(new Error('Failed to convert blob to base64'));
			};

			reader.readAsDataURL(blob);
		});
	}

	// Function to upload all images
	const uploadAll = async () => {
		const maxOrder = Math.max(...images?.map(img => img.order), 0);
		let currentOrderValue = maxOrder + 1;
		const newImages = [];

		for (let fileItem of noteImagesUploadRef.current) {
			if (isDesignPage) {
				const res = await saveImage(fileItem, user.id);
				const item = {
					...res.data,
					isSaved: true,
					order: currentOrderValue,
				};
				newImages.push(item);
			} else {
				const fileUrl = URL.createObjectURL(fileItem);
				const base64URL = await convertObjectURLToBase64(fileUrl);

				const item = {
					...fileItem,
					file: base64URL,
					id: Math.random(),
					isLocal: true,
					order: currentOrderValue,
				};
				newImages.push(item);
			}

			toast.success(`${fileItem.name} uploaded successfully`, { hideProgressBar: true });
			currentOrderValue++;
			clearAll();
		}

		setImages(prevImages => [...prevImages, ...newImages]);
	};

	// Store selected images in the state
	const storeItems = fileItems => {
		const invalidFiles = fileItems.some(item => !acceptedTypes.includes(item.file.type));

		onInvalidFileType(invalidFiles);
		setNoteImagesUpload(fileItems.map(item => item.file));
	};

	// Update functions for clear and upload actions
	useEffect(() => {
		updateClearAll(clearAll);
		updateUploadAll(uploadAll);
	}, []);

	return (
		<div>
			<Card className="mt-2">
				{isDesignPage && filesUploasdQty >= 5 && (
					<Alert>You cannot have more than 5 images saved in your account.</Alert>
				)}
				<Card.Body>
					<FilePond
						ref={pond}
						allowMultiple={true}
						onupdatefiles={storeItems}
						allowReorder={true}
						instantUpload={false}
						allowRevert={false}
						allowProcess={false}
						accepted-file-types={acceptedTypes}
						maxFiles={isDesignPage ? 5 - savedImagesQty : null}
						disabled={isDesignPage ? savedImagesQty >= 5 : false}
					/>
				</Card.Body>
			</Card>
		</div>
	);
};

export default ImagesUpload;
