import React, { useCallback, useContext, useState } from 'react';
import { useNote } from 'contexts/NoteContext';
import FilterModal from 'features/filters/FilterModal';
import { Dropdown, Form } from 'react-bootstrap';
import { FaBars, FaUserCircle } from 'react-icons/fa';
import styled from 'styled-components';
import { AuthContext } from '@note/contexts/AuthContext';
import PrintInfoModal from 'modals/PrintInfoModal';
import { useRecoilValue } from 'recoil';
import { isDesignPageState } from '@note/atoms';
import LibraryComingSoonModal from '@note/layouts/library/design-page/LibraryComingSoonModal';
import LibraryModal from '@note/layouts//library/main-page/LibraryModal';
import NavLinks from '../NavLinks';
import './NavMenu.scss';

const handleDashboardClick = () => {
	window.open('/dashboard/', '_blank');
};

//Bruger menu at the end of the navbar
const NavMenu = ({ openSaveAsModal, designMode = false, toggleCustomizations, showCustomizations, noteTypeName }) => {
	const { activeNoteType, isCustomizable, setShowTermsOfServiceModal } = useNote();
	const { user, logoutClearStorage, isAdmin } = useContext(AuthContext);
	const [showFilter, setShowFilter] = useState(false);
	const [showPrintNote, setShowPrintNote] = useState(false);
	const [showMainPageLibrary, setShowMainPageLibrary] = useState(false);
	const [showDesignPageLibrary, setShowDesignPageLibrary] = useState(false);
	const isDesignPage = useRecoilValue(isDesignPageState);
	const handleLogout = useCallback(async () => await logoutClearStorage(activeNoteType?.id), [logoutClearStorage]);

	const openPrintNote = useCallback(() => setShowPrintNote(true), []);
	const closePrintNote = useCallback(() => setShowPrintNote(false), []);

	const openMainLibraryModal = () => setShowMainPageLibrary(true);
	const closeMainLibraryModal = () => setShowMainPageLibrary(false);

	const openLibraryDesignModal = () => setShowDesignPageLibrary(true);
	const closeLibraryDesignModal = () => setShowDesignPageLibrary(false);
	const openFilterModal = () => setShowFilter(true);
	const closeFilterModal = () => setShowFilter(false);

	const toggleCustom = useCallback(() => {
		toggleCustomizations(!showCustomizations);
	}, [toggleCustomizations, showCustomizations]);

	const handleLibraryOpen = () => {
		if (isCustomizable) {
			openLibraryDesignModal();
		} else {
			openMainLibraryModal();
		}
	};

	const handlePrint = () => {
		closePrintNote();
		setTimeout(() => {
			window.print();
		}, 100);
	};

	return (
		<Dropdown>
			<DropdownToggle variant="success" id="dropdown-basic">
				<FaBars className="mainapp-nav-menu" />
			</DropdownToggle>
			<Dropdown.Menu align="end">
				<Dropdown.Header>
					<FaUserCircle /> {user && user.email}
				</Dropdown.Header>
				<Dropdown.Divider />
				{!designMode && (
					<>
						<Form.Check
							type="switch"
							checked={showCustomizations}
							label="Show my customizations"
							onChange={toggleCustom}
							style={{ marginLeft: '1rem' }}
						/>
					</>
				)}
				<Dropdown.Item onClick={() => openPrintNote()}>Export long form using your browser</Dropdown.Item>
				<Dropdown.Item onClick={() => openFilterModal()}> {!isDesignPage && <div>Filter</div>}</Dropdown.Item>
				{!isDesignPage && !isAdmin && (
					<Dropdown.Item onClick={handleLibraryOpen}> {!isDesignPage && <div>Import</div>}</Dropdown.Item>
				)}
				<Dropdown.Divider />
				<div className="nav-menu-navlinks">
					<div className="navlinks-title">{noteTypeName}</div>
					<NavLinks />
					<Dropdown.Divider />
				</div>
				{designMode && (
					<>
						<Dropdown.Item onClick={openSaveAsModal}>Save as..</Dropdown.Item>
						<Dropdown.Divider />
					</>
				)}
				<Dropdown.Item onClick={handleDashboardClick}>Go to dashboard</Dropdown.Item>
				<Dropdown.Item onClick={() => setShowTermsOfServiceModal(true)}>Terms and conditions</Dropdown.Item>
				<Dropdown.Item
					onClick={() =>
						window.confirm('Are you sure you want to log out?') && ((window.onbeforeunload = null), handleLogout())
					}
				>
					Log out
				</Dropdown.Item>
			</Dropdown.Menu>
			<FilterModal show={showFilter} handleClose={closeFilterModal} />
			{showMainPageLibrary && <LibraryModal show={showMainPageLibrary} handleClose={closeMainLibraryModal} />}
			{showDesignPageLibrary && (
				<LibraryComingSoonModal show={showDesignPageLibrary} handleClose={closeLibraryDesignModal} />
			)}
			{showPrintNote && <PrintInfoModal show={showPrintNote} handleClose={closePrintNote} handlePrint={handlePrint} />}
		</Dropdown>
	);
};

export default NavMenu;

const DropdownToggle = styled(Dropdown.Toggle)`
	background: none !important;
	border: none;
	&:after {
		display: none;
	}
`;
