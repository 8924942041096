import React from 'react';

const MessageParser = ({ children, actions }) => {
  // Function to parse the message and handle it
  const parse = async (message) => {
    if (message && message !== '') {
      try {
        // Call the handleMessage action if the message is not empty
        actions.handleMessage(message);
      } catch (error) {
        // Log any errors that occur
        console.error("Error in MessageParser:", error);
      }
    }
  };

  return (
    <div>
      {/* Clone the children elements to pass down the parse function and an empty actions object */}
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions: {},
        });
      })}
    </div>
  );
};

export default MessageParser;
