import Tippy from '@tippyjs/react';
import { Spinner } from 'components/Spinner';
import { ThemeButton } from 'components/ThemeButton';
import { useNote } from 'contexts/NoteContext';
import { usePreference } from 'contexts/PreferenceContext';
import Signature from 'layouts/note-output/signature';
import CopyModal from 'modals/CopyModal';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { FaCopy, FaEdit, FaLock, FaPrint, FaSearch } from 'react-icons/fa';
import { getModifiedNote, postAIPlus } from 'services/aiEdit';
import { getZephyrModifiedNote } from 'services/zephyrAiEdit';
import { copyToClipboard, copyTextToClipboard } from 'utils/copyToClipboard';
import { exportPdf } from 'utils/exportPdf';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';
import AiSettingsModal from '@note/modals/AiSettingsModal';

import { AuthContext } from '@note/contexts/AuthContext';
import Letterhead from '@note/layouts/note-output/letterhead/Letterhead';
import SearchReplace from 'features/searchReplace/searchReplace';
import { useRecoilState } from 'recoil';
import { closingCommentsState, introCommentsState } from '@note/atoms';
import Comment from '../../layouts/note-output/Comment';
import { revertTags, replaceTags, formatText } from './aiRewriteUtils';
import {
	InstructionsPannel,
	CustomModal,
	SpinnerContainer,
	SettingsContainer,
	AiContentContainer,
	ExportContainer,
	TextArea,
	InstructionElement,
} from './styles';

import FeedbackModal from '../../layouts/feedback/FeedbackModal';
import HeadingOutput from '../../layouts/note-output/HeadingOutput';
import ExportModal from '../export/ExportModal';

const AiEditModal = ({ show, fontSize, fontStyle, openAIEditConfirmationModal, handleClose, showAISettings }) => {
	const [closingComment, setClosingComment] = useRecoilState(closingCommentsState);
	const [introComment, setIntroComment] = useRecoilState(introCommentsState);
	const { showLetterhead, user, isOldSafari } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [showExport, setShowExport] = useState();
	const [showFeedback, setShowFeedback] = useState();
	const [showSearchReplace, setShowSearchReplace] = useState();
	const [iframeURL, setIframeURL] = useState();
	const [showCopy, setShowCopy] = useState(false);
	const [aiAnswer, setAiAnswer] = useState(null);
	const [originalAiAnswer, setOriginalAiAnswer] = useState(null);
	const documentData = document?.querySelector('#note-body');
	const prefilledContent = documentData.querySelectorAll('.prefilled-content');
	prefilledContent.forEach(prefilled => prefilled.remove());
	const originalText = documentData?.innerHTML;
	const formattedOriginaText = originalText?.replaceAll('<div><br /></div>', '<br />');
	const prefilledText = document?.querySelector('#sub-prefilled')?.innerHTML;
	const [showConsentModal, setShowConsentModal] = useState(false);
	const { filledPrompt, activeNoteType, globalAIPrompt } = useNote();
	const { globalPreferences } = usePreference();

	const closeAISettingsModal = () => setShowConsentModal(false);
	const closeExport = () => setShowExport(false);
	const closeFeedback = () => setShowFeedback(false);
	const openFeedback = () => setShowFeedback(true);
	const closeCopy = () => setShowCopy(false);
	const openCopy = () => setShowCopy(true);
	const closeSearchReplace = () => setShowSearchReplace(false);
	const openSearchReplace = () => setShowSearchReplace(true);

	const isGlobalAI = !activeNoteType?.is_ai_plus_note && !activeNoteType?.is_ai_note;

	const handleColorChange = color => {
		document.getElementById('promtableContent').style.backgroundColor = color;
	};

	const handleSubmitCalled = useRef(false);

	// Handle submit when component mounts
	useEffect(() => {
		if (activeNoteType?.is_ai_note && !handleSubmitCalled.current) {
			handleSubmit();
			handleSubmitCalled.current = true;
		}
	}, []);

	// Handle accept action for AI settings modal
	const handleAccept = async () => {
		try {
			handleSubmit();
			closeAISettingsModal();
		} catch (error) {
			console.error(error);
		}
	};

	// Handle cancel action for AI settings modal
	const handleCancel = () => {
		handleClose();
	};

	// Handle copy action
	const handleCopy = isSimpletext => {
		if (!navigator.userAgent.includes('Firefox')) {
			try {
				handleColorChange('transparent');
				isSimpletext
					? copyTextToClipboard(document.getElementById('aiNote'), 'signature-controls')
					: copyToClipboard(document.getElementById('aiNote'), 'signature-controls');
				!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.SUCCESS);
				handleColorChange('#ededed');
			} catch (error) {
				openCopy();
			}
		} else {
			openCopy();
		}
	};

	// Handle submit action for AI note
	const handleSubmit = async () => {
		const cleanedText = formatText(formattedOriginaText);

		const query = `${isGlobalAI ? globalAIPrompt.messages : filledPrompt}${
			isGlobalAI ? replaceTags(cleanedText) : cleanedText
		}`;

		// A different AI endpoint for the ai_plus_note than the global AI as well as different prompt builders are used to build the prompt being sent to each endpoint
		try {
			setIsLoading(true);
			const response = activeNoteType?.is_ai_plus_note
				? activeNoteType?.id && (await postAIPlus(activeNoteType.id, query))
				: activeNoteType?.is_ai_note
				? await getModifiedNote(query)
				: await getZephyrModifiedNote(
						`${query} </s>`,
						globalAIPrompt.useBulletPoints,
						globalAIPrompt.language,
						globalAIPrompt.length
				  );
			const answer = isGlobalAI
				? revertTags(response.answer)
				: activeNoteType?.is_ai_plus_note
				? response[response.length - 1].message
				: response.answer;
			let processedResponse = answer
				?.replace(/\n\n?/g, '<br />')
				.replace(/<br \/><strong>/g, '<br /><br /><strong>')
				.replace(/<\/strong><br \/><br \/><br \/>/g, '</strong><br /><br />')
				.replace(/(<div><br\s*\/?><\/div>)/g, '<br /><br />');
			setAiAnswer(processedResponse);
			setOriginalAiAnswer(processedResponse);
		} catch (error) {
			console.error('Error fetching modified note:', error);
		} finally {
			setIsLoading(false);
		}
	};

	// Handle export action
	const handleExportClick = () => {
		handleColorChange('transparent');
		exportPdf({ id: 'aiNote', fontSize, fontStyle, setIframeURL, setShowExport });
		handleColorChange('#ededed');
	};

	// Handle component mount for AI notes
	useEffect(() => {
		if (activeNoteType?.is_ai_plus_note || activeNoteType?.is_ai_note) {
			if (!handleSubmitCalled.current) {
				handleSubmit();
				handleSubmitCalled.current = true;
			}
		} else setShowConsentModal(true);
	}, []);

	// Handle AI answer change for rich text editor
	const handleRtfAIAnswerChange = content => {
		setAiAnswer(content);
	};

	const TextModules = {
		toolbar: [
			[{ header: [1, 2, 3, false] }],
			['bold', 'italic', 'underline'],
			[{ list: 'ordered' }, { list: 'bullet' }],
		],
	};

	return (
		<>
			<CustomModal
				show={show}
				onHide={openAIEditConfirmationModal}
				centered
				size="xl"
				scrollable
				blurredbackground={showConsentModal && activeNoteType?.is_ai_plus_note ? true : false}
				backdropClassName="backdrop-blur"
			>
				<Modal.Header closeButton>
					<Modal.Title>Intelligent Note Editing with AI</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ overflow: 'hidden' }}>
					<Container className="h-100 text-left align-items-left">
						<Row className="h-100">
							<Col md={4} style={{ height: '100%' }}>
								<SettingsContainer>
									<div
										style={{
											overflowY: 'auto',
											height: '100%',
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'space-between',
										}}
									>
										<InstructionsPannel>
											<h3>Tips:</h3>
											<br />
											<ul>
												<InstructionElement>
													Choose client/patient/etc, using your Preferences menu at the top right of your screen.
												</InstructionElement>
												<InstructionElement>
													Turn tab titles and breaks to “show” and subtab titles and breaks to “hide” in your
													preferences menu.
												</InstructionElement>
												<InstructionElement>A moderate amount of content is best.</InstructionElement>
												<InstructionElement>
													If you don’t like the re-written note, you can edit it on the screen or close this window and
													click AI-Rewrite again to resubmit.
												</InstructionElement>
												<InstructionElement>
													This AI function is currently offered as a beta test, as we fine-tune the AI system to work
													with all possible note-types including your customized content. For the most reliable results,
													please use the AI Note note-type.
												</InstructionElement>
											</ul>
										</InstructionsPannel>
										<ThemeButton
											onClick={openFeedback}
											label="Please click here to tell us what you think!"
											icon={FaEdit}
											size="lg"
											fontSize="lg"
											color="violet"
										/>
									</div>
								</SettingsContainer>
							</Col>
							<Col md={8} style={{ height: '100%' }}>
								<Card className="h-100">
									<Card.Header className="justify-content-end d-flex gap-2">
										<div style={{ display: 'flex' }}>
											<ThemeButton
												onClick={openSearchReplace}
												label="Search / Replace"
												icon={FaSearch}
												color="blue"
												disabled={!aiAnswer}
											/>
											<ExportContainer>
												<ThemeButton
													onClick={handleExportClick}
													label="Export"
													icon={FaPrint}
													color="blue"
													disabled={!aiAnswer}
												/>
											</ExportContainer>
											<ThemeButton
												onClick={() => handleCopy(false)}
												label="Copy"
												icon={FaCopy}
												color="blue"
												disabled={!aiAnswer}
											/>
											<ThemeButton
												onClick={() => handleCopy(true)}
												label="Copy Text Only"
												icon={FaCopy}
												color="blue"
												disabled={!aiAnswer}
											/>
										</div>
									</Card.Header>
									<div style={{ overflowY: 'auto', position: 'relative' }}>
										<Tippy content="Only your generic selections, in the gray area, are sent to the artificial intelligence tool.">
											<i style={{ position: 'absolute', top: '1rem', right: '1rem' }}>
												<FaLock data-tippy-content="lock-tooltip" />
											</i>
										</Tippy>
										<div id="aiNote">
											<Card.Body style={{ overflowY: 'auto' }}>
												<AiContentContainer>
													{showLetterhead && user.letterhead !== null && <Letterhead letterhead={user.letterhead} />}
													<HeadingOutput />
													{!activeNoteType.is_ai_note && (
														<>
															<Comment
																placeholder="[Add Introductory comments here if desired]"
																value={introComment}
																setValue={setIntroComment}
															/>
															<br />
														</>
													)}
													<div
														id="promtableContent"
														style={{
															padding: '3px',
															backgroundColor: aiAnswer ? '#ededed' : '#ffffff',
															borderRadius: '5px',
														}}
													>
														{isLoading ? (
															<SpinnerContainer>
																<Spinner size={5} />
																Generating new note: This may take about 20 seconds, depending on the length of the note
																and the time of day.
															</SpinnerContainer>
														) : aiAnswer ? (
															<TextArea
																theme="snow"
																value={aiAnswer || ''}
																onChange={handleRtfAIAnswerChange}
																modules={TextModules}
															/>
														) : null}
													</div>
													{prefilledText && <TextArea theme="snow" value={prefilledText || ''} modules={TextModules} />}
													{!activeNoteType.is_ai_note && (
														<>
															<br />
															<Comment
																placeholder="[Add Closing comments here if desired]"
																value={closingComment}
																setValue={setClosingComment}
															/>
														</>
													)}
												</AiContentContainer>
											</Card.Body>
											<div style={{ padding: '1rem' }}>
												<Signature />
											</div>
										</div>
									</div>
								</Card>
							</Col>
						</Row>
					</Container>
				</Modal.Body>
			</CustomModal>
			{showConsentModal && !activeNoteType?.is_ai_note && showAISettings && (
				<AiSettingsModal
					show={showConsentModal}
					handleClose={closeAISettingsModal}
					onSubmit={handleAccept}
					onCancel={handleCancel}
					useShortForm
				/>
			)}
			{showSearchReplace && (
				<SearchReplace
					show={showSearchReplace}
					handleClose={closeSearchReplace}
					text={aiAnswer}
					setText={setAiAnswer}
					originalAiAnswer={originalAiAnswer}
				/>
			)}
			{showFeedback && <FeedbackModal show={showFeedback} handleCloseFeedbackModal={closeFeedback} />}
			{showExport && <ExportModal show={showExport} handleClose={closeExport} iframeURL={iframeURL} />}
			{showCopy && <CopyModal show={showCopy} handleClose={closeCopy} />}
		</>
	);
};

export default AiEditModal;
