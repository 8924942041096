import React, { useCallback, useEffect, useState } from 'react';
import { getQuote } from 'services/user';
import styled from 'styled-components';

// Fetches and renders a quote from the backend

const DailyThought = () => {
	const [quote, setQuote] = useState();

	const fetchQuote = useCallback(async () => {
		const quote = await getQuote();
		setQuote(quote.data);
	}, []);

	useEffect(() => {
		fetchQuote();
	}, [fetchQuote]);

	return (
		<QuoteContainer>
			<q>{quote?.quote}</q>
			<br />
			<QuoteAuthor>- {quote?.person}</QuoteAuthor>
		</QuoteContainer>
	);
};

export default DailyThought;

const QuoteContainer = styled.div`
	max-width: 70%;
`;

const QuoteAuthor = styled.p`
	display: flex;
	justify-content: end;
`;
