import { ThemeButton } from 'components/ThemeButton';
import React, { useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { BiCommentError } from 'react-icons/bi';

import './login-modal.scss';

import LoginForm from './login-form';

const LoginModal = ({ show, handleCloseLoginModal, modalLogout }) => {
	const user = localStorage.getItem('user');
	const [showAlert, setShowAlert] = useState(false);

	let parsedUser;
	try {
		parsedUser = JSON.parse(user);
	} catch (e) {
		console.error('Failed to parse user data:', e);
	}

	const AlertMessage = () => {
		return (
			<>
				<Alert show={showAlert} variant="primary">
					<Alert.Heading>Redirection</Alert.Heading>
					<p>
						Are you certain that you wish to be redirected to the login page? If you have work currently in progress, it
						will be lost.
					</p>
					<hr />
					<div className="d-flex justify-content-end">
						<ThemeButton
							type="button"
							label="Logout and redirect"
							size="md"
							color="navy"
							onClick={() => modalLogout()}
						/>
					</div>
				</Alert>

				{!showAlert && (
					<ThemeButton type="button" label="Back to login" size="md" color="navy" onClick={() => setShowAlert(true)} />
				)}
			</>
		);
	};

	return (
		<Modal
			size="lg"
			show={show}
			onHide={user ? handleCloseLoginModal : () => {}}
			backdropClassName="backdrop-blur"
			backdrop={'static'}
			style={{ zIndex: 9001 }}
		>
			<Modal.Header>
				<Modal.Title>Confirmation</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ maxWidth: '90%', margin: 'auto', marginBottom: '1.5rem' }}>
				<div className="login-modal-container">
					<span className="icon-container">
						<BiCommentError />
					</span>
					<h4>Seems like you have been logged out</h4>
					{parsedUser && (
						<p>
							If you want to avoid losing your work, and stay logged in as
							<b> {parsedUser.email}, </b>
							please enter your password
						</p>
					)}
				</div>
				<LoginForm handleCloseLoginModal={handleCloseLoginModal} localUser={user} />
				<div className="login-redirect">
					<p>If you don't need to resume this work, click here to log in with a different account.</p>
					<AlertMessage />
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default LoginModal;
