import SubTabBreak from 'layouts/note-output/SubTabBreak';
import React, { useCallback, useState, useEffect } from 'react';
import ContentEditable from 'react-contenteditable';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { editOutputState } from '@note/atoms';

const PrefilledStatement = ({ statement, isNotEmpty }) => {
	const editOutput = useRecoilValue(editOutputState);
	const [editedTitle, setEditedTitle] = useState(statement.editedName);
	const [editedText, setEditedText] = useState(statement.editedPrefilledText);

	// Replace specific break patterns in the text on mount
	useEffect(() => {
		const replaceFirstBreak = text => {
			const firstBreakPattern = /<p><br><\/p>/;
			return text.replace(firstBreakPattern, '<br>');
		};

		const replaceSubsequentBreaks = text => {
			const subsequentBreakPattern = /<p><br><\/p>/g;
			return text.replace(subsequentBreakPattern, '<br>');
		};

		let replacedText = replaceFirstBreak(editedText);
		replacedText = replaceSubsequentBreaks(replacedText);

		const replacedTitle = editedTitle.replace(/<p><strong>/g, '<p style="text-align: center;"><strong>');

		setEditedTitle(replacedTitle);
		setEditedText(replacedText);
	}, [editedText, editedTitle]);

	// Handle changes to the title
	const handleTitleChange = useCallback(event => {
		let newValue = event.target.value;
		setEditedTitle(newValue);
	}, []);

	// Handle changes to the text
	const handleTextChange = useCallback(event => {
		let newValue = event.target.value;
		setEditedText(newValue);
	}, []);

	// Render the title, allowing for editing if in edit mode
	const renderTitle = () => {
		return editOutput ? (
			<Text
				tagName="span"
				style={{ fontWeight: '700 !important' }}
				id={statement?.id + '-title'}
				className="prefilled-title"
				html={editedTitle}
				onChange={handleTitleChange}
			/>
		) : (
			<span
				id={statement.id + '-title'}
				style={{ fontWeight: '700 !important', whiteSpace: 'pre-wrap' }}
				dangerouslySetInnerHTML={{ __html: editedTitle }}
			/>
		);
	};

	// Render the text, allowing for editing if in edit mode
	const renderText = statement => {
		return editOutput ? (
			<Text
				tagName="span"
				id={statement?.id + '-text'}
				className="prefilled-body"
				html={editedText}
				onChange={handleTextChange}
			/>
		) : (
			<span
				id={statement?.id + '-text'}
				style={{ whiteSpace: 'pre-wrap' }}
				dangerouslySetInnerHTML={{ __html: editedText }}
			/>
		);
	};

	return (
		<>
			{statement?.editedName && statement?.editedName.length > 0 && (
				<>
					<p style={{ textAlign: 'center', whiteSpace: 'pre-wrap', marginBottom: 'unset' }}>{renderTitle()}</p>
					<br />
				</>
			)}
			{renderText()}
			<SubTabBreak isVisible={isNotEmpty} />
		</>
	);
};

export default PrefilledStatement;

const Editable = styled(ContentEditable)`
	&:focus-visible {
		outline: 1px solid #37a4fa;
		border-radius: 3px;
	}
`;

const Text = styled(Editable)`
	white-space: pre-wrap;
`;
