import VideoModal from 'modals/VideoModal';
import React, { useCallback, useState } from 'react';
import { blue } from 'styles/colors';
import styled from 'styled-components';

import { ThemeButton } from 'components/ThemeButton';

// To toggle on the videos modal
const VideoLinkButton = ({ size = 'md', fullWidth = true, label, videoType = 'welcome', useLinkStyle = false }) => {
	const [showVideoModal, setShowVideoModal] = useState();
	const closeVideoModal = useCallback(() => setShowVideoModal(false), []);
	const openVideoModal = useCallback(() => setShowVideoModal(true), []);

	return (
		<>
			{useLinkStyle ? (
				<>
					<VideoButton onClick={openVideoModal}>{label}</VideoButton>
					<VideoModal videoType={videoType} show={showVideoModal} handleClose={closeVideoModal} />
				</>
			) : (
				<>
					<ThemeButton size={size} label={label} fullWidth={fullWidth} color="blue" onClick={openVideoModal} />
					<VideoModal videoType={videoType} show={showVideoModal} handleClose={closeVideoModal} />
				</>
			)}
		</>
	);
};

export default VideoLinkButton;

const VideoButton = styled.button`
	background-color: transparent;
	border: none;
	text-decoration: underline;
	color: ${blue};
`;
