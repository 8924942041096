import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNote } from 'contexts/NoteContext';
import styled from 'styled-components';

import { ThemeButton } from '@note/components/ThemeButton';

const AINoteIntroModal = () => {
	const [showModal, setShowModal] = useState(true);
	const { activeNoteType } = useNote();

	const renderAiPlusIntro = () => {
		return (
			<>
				<p>
					Welcome to our new Simple AI Note which harnesses advances in artificial intelligence (AI) to create a flowing
					and natural sounding note derived from just a few words or simple phrases you enter about the session.
				</p>
				<b>Things to know:</b>
				<br />
				<br />
				<ul>
					<li>
						The Private Health Information (PHI) you enter in the Headings tab is never sent to the AI system for
						rewriting and never leaves your computer browser – thus your note remains HIPAA complaint.
					</li>
					<li>
						You are advised to only enter brief generic and non-identifying information about the therapy session or
						interview into the AI-text fields. It is your responsibility to ensure that you do not enter any PHI or
						other identifying information about your clients. The first time you submit your note to AI for rewriting,
						you will be asked to confirm that you agree to not include any PHI in your inputs.
					</li>
					<li>
						When prompted for your text-inputs, you only need to write a few words or simple phrases. You also do not
						have to put an entry in every text field – which fields you use is totally up to you.
					</li>
				</ul>
				<b>Instructions:</b>
				<br />
				<br />
				1) Use the AI Settings tab to tailor your note specifications and format. <br />
				2) Enter the confidential (PHI) information and demographics in the separate and protected Headings tab. <br />
				3) Enter a few simple words and/or phrases into any applicable the text-input fields. <br />
				4) click the <b>AI rewrite</b> button above the note and follow the instructions.
				<br />
			</>
		);
	};

	return (
		<StyledModal
			style={{ border: '2px solid grey' }}
			show={showModal}
			onHide={() => setShowModal(false)}
			backdrop="static"
			scrollable
			centered
			size="lg"
			backdropClassName="backdrop-blur"
		>
			{activeNoteType?.is_ai_plus_note && (
				<Modal.Header>
					<Modal.Title>Try our latest Simple AI Note!</Modal.Title>
				</Modal.Header>
			)}
			<Modal.Body>{activeNoteType?.is_ai_plus_note && renderAiPlusIntro()}</Modal.Body>

			<Modal.Footer>
				<ThemeButton label="Close" size="lg" color="green" onClick={() => setShowModal(false)} />
			</Modal.Footer>
		</StyledModal>
	);
};

export default AINoteIntroModal;

const StyledModal = styled(Modal)`
	& .modal-content {
		border: 2px solid gray;
	}
`;
