import * as markerjs2 from 'markerjs2';
import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { editImageState } from '@note/atoms';

import '../Images.scss';

// A component to draw and save images from within the app
const ImageEditModal = ({ handleClose, show, handleEdit }) => {
	const editImage = useRecoilValue(editImageState);
	const imageRef = useRef();
	const containerRef = useRef();

	useEffect(() => {
		markerjs2.Activator.addKey(process.env.REACT_APP_MARKERJS_KEY);
		if (show && imageRef.current && containerRef.current) {
			const markerArea = new markerjs2.MarkerArea(imageRef.current); // Create a new MarkerArea for the image
			markerArea.targetRoot = containerRef.current; // Set the container element as the target root
			markerArea.renderAtNaturalSize = true; // Render the MarkerArea at the natural size of the image
			markerArea.addEventListener('render', event => {
				handleEdit(event.dataUrl); // Handle the edit event by passing the edited image data URL
			});
			markerArea.addEventListener('close', () => {
				handleClose();
			});
			markerArea.show();
		}
	}, [show, imageRef, handleClose, handleEdit]);

	return show ? (
		<Backdrop>
			<ModalContainer>
				<StyledBody>
					<Container ref={containerRef}>
						<StyledImage id="myimg" src={editImage.file} alt="Your edit" ref={imageRef} />
					</Container>
				</StyledBody>
			</ModalContainer>
		</Backdrop>
	) : null;
};

export default ImageEditModal;

const Backdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(5px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1000;
`;

const ModalContainer = styled.div`
	background-color: white;
	max-height: 90vh;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	overflow: hidden;
	box-shadow:
		0 4px 6px rgba(0, 0, 0, 0.1),
		0 1px 3px rgba(0, 0, 0, 0.1);
`;

const StyledBody = styled.div`
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`;

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledImage = styled.img`
	width: auto;
	max-height: 80vh;
	min-width: 400px;
	max-width: 100%;
	object-fit: contain;
`;
