import React from 'react';
import App from 'App';
import axios from 'axios';
import Cookies from 'js-cookie';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { AuthProvider } from 'contexts/AuthContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'styles/index.css';
import 'tippy.js/dist/tippy.css';

export const axiosClient = axios.create({
	baseURL: '/api',
	headers: {
		ContentType: 'application/json',
		'Cache-Control': 'no-cache, no-store, must-revalidate',
		Vary: '*',
		'X-CSRFToken': Cookies.get('csrftoken'),
		Pragma: 'no-cache',
		Expires: 0,
	},
	responseType: 'json',
	withCredentials: true,
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
	<React.StrictMode>
		<AuthProvider>
			<RecoilRoot>
				<App />
			</RecoilRoot>
		</AuthProvider>
	</React.StrictMode>
);
