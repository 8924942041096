/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

import { Separator } from 'components/Separator';

import RadioInput from '@note/components/RadioInput';

const PunctuationGroup = ({ punctuation, handleChange }) => {
	return (
		<div>
			<h5>{punctuation.name}</h5>
			{punctuation.settings.map((puntuationItem, index) => (
				<Fragment key={index}>
					<Form.Group>
						<PuntuationTitle>{puntuationItem.title}</PuntuationTitle>
						{puntuationItem.options.map((option, index) => (
							<RadioInput
								key={index}
								value={option.value}
								label={option.label}
								currentValue={puntuationItem.currentValue}
								name={puntuationItem.name}
								handleChange={handleChange}
							/>
						))}
					</Form.Group>
					{index !== punctuation.settings.length - 1 && <Separator />}
				</Fragment>
			))}
		</div>
	);
};

export default PunctuationGroup;

const PuntuationTitle = styled.p`
	padding: 0;
	margin-bottom: 5px;
`;
