import React from 'react';
import styled from 'styled-components';
import { blue, white } from 'styles/colors';
import { FaInfoCircle } from 'react-icons/fa';

const DesignerInfo = ({ toggleInfo }) => {
	return (
		<InfoButton onClick={toggleInfo}>
			<FaInfoCircle style={{ marginRight: '5px' }} />
			Info
		</InfoButton>
	);
};

export default DesignerInfo;

const InfoButton = styled.button`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 2px 12px;
	border-radius: 20px;
	border: 2px solid ${blue};
	background-color: ${white};
	color: ${blue};
	cursor: pointer;
	font-size: 16px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
	&:focus {
		outline: none;
	}
	svg {
		margin-right: 5px;
	}
`;
