import { useNote } from 'contexts/NoteContext';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

import RadioInput from '../RadioInput';

// Prompt builder to send to the AI service for the AIPlusNote

// Options for the prompt builder
const promptOptionsList = {
	formatoptions: {
		name: 'formatoptions',
		label: 'Note format',
		values: [
			{
				key: 'narrative',
				text: 'narrative',
			},
			{
				key: 'bullet-point',
				text: 'bullet-point',
			},
		],
	},
	style: {
		name: 'style',
		label: 'Note style',
		values: [
			{
				key: 'SOAP',
				text: 'SOAP',
			},
			{
				key: 'BIRP',
				text: 'BIRP',
			},
			{
				key: 'DAP',
				text: 'DAP',
			},
		],
	},
	pronouns: {
		name: 'pronouns',
		label: 'Pronouns',
		values: [
			{
				key: 'they / them',
				text: 'they / them',
			},
			{
				key: 'she / her',
				text: 'she / her',
			},
			{
				key: 'he / him',
				text: 'he / him',
			},
		],
	},
	agerange: {
		name: 'agerange',
		label: 'Age Range',
		values: [
			{
				key: 'child',
				text: 'child',
			},
			{
				key: 'adolescent',
				text: 'adolescent',
			},
			{
				key: 'young adult',
				text: 'young adult',
			},
			{
				key: 'adult',
				text: 'adult',
			},
			{
				key: 'middle aged',
				text: 'middle aged',
			},
			{
				key: 'senior',
				text: 'senior',
			},
			{
				key: 'elderly',
				text: 'elderly',
			},
		],
	},
	length: {
		name: 'length',
		label: 'Note length',
		values: [
			{
				key: 'short / concise',
				text: 'short / concise',
			},
			{
				key: 'medium',
				text: 'medium',
			},
			{
				key: 'long / comprehensive',
				text: 'long / comprehensive',
			},
		],
	},
};

export const AiPlusPromptBuilder = () => {
	const { setFilledPrompt } = useNote();

	// State to manage the selected prompt options
	const [promptOptions, setPromptOptions] = useState({
		formatoptions: { value: 'narrative' },
		style: { value: 'SOAP' },
		pronouns: { value: 'they / them' },
		agerange: { value: 'child' },
		length: { value: 'short / concise' },
	});

	// Extract the selected values into strings
	const formatString = promptOptions?.formatoptions?.value ? promptOptions.formatoptions.value : '';
	const styleString = promptOptions?.style?.value ? promptOptions.style.value : '';
	const pronounString = promptOptions?.pronouns?.value ? promptOptions.pronouns.value : '';
	const agerangeString = promptOptions?.agerange?.value ? promptOptions.agerange.value : '';
	const lengthString = promptOptions?.length?.value ? promptOptions.length.value : '';

	// Handle changes to the prompt options
	const handleChange = (param, value) => {
		setPromptOptions(prevOptions => ({
			...prevOptions,
			[param]: { value },
		}));
	};

	// Update the filled prompt whenever any of the selected values change
	useEffect(() => {
		const newFilledPrompt = `Note format: ${formatString}. Note type: ${styleString}. Pronouns: ${pronounString}. Age Range: ${agerangeString}. Note length: ${lengthString}.`;

		setFilledPrompt(newFilledPrompt);
	}, [styleString, pronounString, setFilledPrompt, formatString, agerangeString, lengthString]);

	// Render the choices for a given parameter
	const renderChoices = param => {
		const { label, values } = promptOptionsList[param];
		return (
			<Form.Group style={{ marginBottom: '1rem' }}>
				<h5>{label}</h5>
				{values.map(option => (
					<RadioInput
						key={option.key}
						value={option.text}
						label={option.key}
						currentValue={promptOptions[param]?.value}
						name={option.key}
						handleChange={() => handleChange(param, option.text)}
						checked={promptOptions[param]?.value === option.text}
					/>
				))}
			</Form.Group>
		);
	};

	return (
		<AiPlusPromptBuilderContainer>
			<Form>
				{renderChoices('formatoptions')}
				{renderChoices('style')}
				{renderChoices('pronouns')}
				{renderChoices('agerange')}
				{renderChoices('length')}
			</Form>
		</AiPlusPromptBuilderContainer>
	);
};

export default AiPlusPromptBuilder;

// Styled component for the prompt builder container
const AiPlusPromptBuilderContainer = styled.div`
	overflow-y: auto;
`;
