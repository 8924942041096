import { useNote } from 'contexts/NoteContext';
import { usePreference } from 'contexts/PreferenceContext';
import { flatten, isNil } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { WidgetType } from 'utils/enum';
import { replaceTerminologies } from 'utils/terminologyHelper';
import { outputStatementSelector, sectionsState, stemsState } from '@note/atoms';
import { AuthContext } from '@note/contexts/AuthContext';
import ListNoteContent from './widget-note-content/list-note-content';
import ManualInputNoteContent from './widget-note-content/manual-input';
import MultipleChoiceNoteContent from './widget-note-content/MultipleChoiceNoteContent';
import PrefilledInputNoteContent from './widget-note-content/prefilled-input';

const SubTabNoteContent = ({ subTab }) => {
	const { user } = useContext(AuthContext);
	const stems = useRecoilValue(stemsState);
	const sections = useRecoilValue(sectionsState);
	const { isCustomizable } = useNote();
	const { multiLevelPreferences } = usePreference();
	const outputStatements = useRecoilValue(outputStatementSelector);

	// Filters stems and sections based on the current subTab
	const filteredStems = useMemo(() => stems.filter(stem => stem.subtab === subTab.id), [subTab, stems]);
	const filteredSections = useMemo(() => sections.filter(section => section.subtab === subTab.id), [subTab, sections]);

	// Combines statements from filtered sections
	const subTabStatements = useMemo(() => {
		return flatten(filteredSections.map(section => outputStatements.filter(st => st.section === section.id)));
	}, [filteredSections, outputStatements]);

	// Retrieves the content of a stem with applied terminologies
	const getStemContent = useCallback(
		stem => {
			const isChanged = stem?.editedValue !== stem?.value;
			const stemval = isChanged && stem?.editedValue ? stem?.editedValue : stem?.value;
			if (!isCustomizable) {
				return replaceTerminologies(stemval, multiLevelPreferences);
			} else if (stem?.was_default) {
				return replaceTerminologies(stemval, multiLevelPreferences);
			}
			return stemval;
		},
		[isCustomizable, multiLevelPreferences]
	);

	// Retrieves the name of a statement with applied terminologies
	const getStatementName = useCallback(
		statement => {
			if (!isCustomizable) {
				if (statement?.user !== user.id || !isNil(statement.original_id)) {
					return replaceTerminologies(statement.name, multiLevelPreferences);
				}
			} else if (statement?.was_default) {
				return replaceTerminologies(statement.name, multiLevelPreferences);
			}
			return statement.name;
		},
		[isCustomizable, multiLevelPreferences, user]
	);

	return (
		<>
			{subTabStatements.length > 0 && (
				<>
					{subTab.type === WidgetType.MANUAL_INPUT && (
						<ManualInputNoteContent
							subTab={subTab}
							filteredSections={filteredSections}
							subTabStatements={subTabStatements}
						/>
					)}
					{(subTab.type === WidgetType.LIST_SELECT ||
						subTab.type === WidgetType.LIST_MULTI_SELECT ||
						subTab.type === WidgetType.PS ||
						subTab.type === WidgetType.PSO) && (
						<ListNoteContent
							subTab={subTab}
							filteredStems={filteredStems}
							filteredSections={filteredSections}
							subTabStatements={subTabStatements}
							getStemContent={getStemContent}
							getStatementName={getStatementName}
						/>
					)}
					{subTab.type === WidgetType.MULTIPLE_CHOICE && (
						<MultipleChoiceNoteContent
							subTab={subTab}
							filteredSections={filteredSections}
							subTabStatements={subTabStatements}
							getStemContent={getStemContent}
							getStatementName={getStatementName}
						/>
					)}
					{subTab.type === WidgetType.PREFILLED_INPUT && (
						<PrefilledInputNoteContent
							subTab={subTab}
							filteredSections={filteredSections}
							subTabStatements={subTabStatements}
						/>
					)}
				</>
			)}
		</>
	);
};

export default React.memo(SubTabNoteContent);
