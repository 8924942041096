import React from 'react';
import { FaSave, FaTimes, FaTrashAlt } from 'react-icons/fa';
import styled from 'styled-components';

import Tips from 'features/tips/Tips';

const DesignerTips = ({ subTab }) => {
	const getTips = () => {
		if (subTab.isNew) {
			return <Tips content={NewSubTab} />;
		}
		return <Tips content={WidgetEditing} />;
	};

	return <TipsContainer>{getTips()}</TipsContainer>;
};

export default DesignerTips;

const NewSubTab = () => (
	<div>
		<p>
			When you add a new Subtab, you first need to choose the kind of “widget” that will be used to display your phrases
			and collect your choices. For example, you can use Simple Text Boxes if you just want spaces to type into; or you
			can use a Select-From-A-List widget if you want to have a list of phrases to pick from.
		</p>
		<p>
			Check out the preview of each widget type by clicking on it, then once you know which you want to use, pick NEXT
			and you can fill in the stems, options, and so forth to make it the way you want it.
		</p>
	</div>
);

const WidgetEditing = () => (
	<div>
		<p>
			Now you can edit and reorganize the content with the wording you wish to have (e.g., if you’ve started a new
			subtab you can edit the sample text to replace the restaurant example). Hover over the words and look for the
			pencil icon to start editing, then the green floppy-disk (
			{
				<IconWrapper>
					<FaSave />
				</IconWrapper>
			}
			) icon to save your changes. Or you can use the grey X (
			{
				<IconWrapper>
					<FaTimes />
				</IconWrapper>
			}
			) to discard your changes. Or, the red trashcan(
			{
				<IconWrapper>
					<FaTrashAlt />
				</IconWrapper>
			}
			) to delete the entire item.
		</p>
		<p>
			<b>
				Keep in mind that when you do edit a sentence stem or a phrase option, you need to click the floppy-disk SAVE
				icon(
				{
					<IconWrapper>
						<FaSave />
					</IconWrapper>
				}
				) to save it.
			</b>
		</p>
	</div>
);

const TipsContainer = styled.div``;

const IconWrapper = styled.span`
	font-size: 12px;
	opacity: 0.9;
`;
