import React from 'react';
import { Modal } from 'react-bootstrap';

import './callToSubscribe.scss';

const CallToSubscribe = ({ show, handleCloseCallToSubscribe }) => {
	return (
		<Modal
			size="lg"
			centered
			show={show}
			onHide={handleCloseCallToSubscribe}
			backdropClassName="backdrop-blur"
			backdrop={'static'}
			style={{ zIndex: 9001 }}
		>
			<Modal.Header closeButton />

			<Modal.Body style={{ maxWidth: '90%', margin: 'auto', marginBottom: '1.5rem' }} closeButton>
				<div className="subscibe-modal-container">
					<h4>Oops! It looks like there’s a problem with the subscription linked to this account.</h4>
					<br />
					<p>
						Possibly you’ve logged in with a different email address or maybe your subscription has been canceled or has
						expired.
					</p>
					<p>
						To keep enjoying our services without interruption, please start a new subscription by clicking the link
						below (then in just a few minutes you’ll be able to get back to your notes).
					</p>
					<div className="subscribe-link">
						<a href="https://notedesigner.com/pricing" target="_blank" rel="noreferrer">
							https://notedesigner.com/pricing
						</a>
					</div>
					<br />
					<b>*Please be sure to use the same email address for billing as you use for logging in to the program.</b>
					<h5 style={{ marginTop: '3rem' }}>Why am I getting this notice?</h5>
					<p style={{ marginTop: '1rem' }}>
						There are two main reasons you may be receiving this notice. It is possible that the email you used to
						purchase your subscription is different from the one you’ve been using to login to the program. (You can
						check the logged in account by looking to the menu at the top right of your screen.) We detected an error
						that was mistakenly allowing users to do this but it is prone to a number of difficulties and we needed to
						change that. Another possibility is that your subscription had either been canceled or expired but you were
						able to continue using the program due to an error in our system that we have now fixed. We apologize for
						any inconvenience but we did have to correct this error in order for the program to continue its work
						effectively.
					</p>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default CallToSubscribe;
