import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FaSave, FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import { ThemeButton } from 'components/ThemeButton';
import { usePreference } from 'contexts/PreferenceContext';
import { createPreference, updatePreference } from 'services/preference';
import Preference from './SignaturePreference';
import { signaturePreferenceGroups } from '../preference-data';

// Modal component for managing signature format preferences
const SignaturePreferenceModal = ({ show, handleClose }) => {
	const [isLoading, setIsLoading] = useState(false); // State to handle loading status
	const { setPreferences, preferences } = usePreference(); // Access preferences from context
	const [filteredPreferences, setFilteredPreferences] = useState(preferences); // State for filtered preferences

	useEffect(() => {
		setFilteredPreferences(preferences); // Update filtered preferences when preferences change
	}, [preferences]);

	// Function to create promises for saving preferences
	const getPromises = useCallback(() => {
		const promises = [];
		filteredPreferences.forEach(preference => {
			if (preference.isNew) {
				promises.push(createPreference(preference)); // Create new preference
			} else if (preference.isChanged) {
				promises.push(updatePreference(preference)); // Update existing preference
			}
		});
		return promises;
	}, [filteredPreferences]);

	// Function to handle discarding changes and closing the modal
	const handleDiscard = useCallback(() => {
		setFilteredPreferences(preferences); // Reset filtered preferences
		handleClose(); // Close the modal
	}, [handleClose, preferences]);

	// Function to handle saving preferences
	const handleSave = async () => {
		const promises = getPromises(); // Get promises for saving preferences
		if (promises.length > 0) {
			try {
				setIsLoading(true); // Set loading state to true
				const results = await Promise.all(promises); // Wait for all promises to resolve
				let created = results.filter(r => r.config.method === 'post'); // Get created preferences
				let updated = results.filter(r => r.config.method === 'patch'); // Get updated preferences
				updated = updated?.map(c => c.data); // Map updated preferences to data

				// Update preferences state with created and updated preferences
				setPreferences(prev => {
					return [
						...prev?.map(p => {
							const pref = updated.find(up => p.id === up.id);
							return pref && pref !== -1 ? pref : p;
						}),
						...created?.map(c => c.data),
					];
				});
				setIsLoading(false); // Set loading state to false
				handleClose(); // Close the modal
			} catch (error) {
				console.error('🚀 ~ file: NotePreferenceModal.js ~ line 58 ~ handleSave ~ error', error);
			}
		}
	};

	return (
		<FullHeightModal show={show} onHide={handleDiscard} centered size="lg" className="h-100" scrollable>
			<Modal.Header closeButton>
				<Modal.Title>Signature Format</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{signaturePreferenceGroups?.map((group, index) => (
					<div key={index}>
						<h6>{group.title}</h6>
						<p>{group.subTitle}</p>
						{group.preferences?.map((pref, prefIndex) => (
							<Preference
								key={prefIndex}
								isInputType={pref?.isInputType} // Flag to determine if preference is input type
								preferenceOptions={pref.options} // Options for preferences
								filteredPreferences={filteredPreferences} // Filtered preferences
								setFilteredPreferences={setFilteredPreferences} // Function to set filtered preferences
								preferenceKey={pref.key} // Key to identify the preference
							/>
						))}
						{signaturePreferenceGroups.length - 1 !== index && <hr />}{' '}
						{/* Render horizontal rule except for the last group */}
					</div>
				))}
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label="Discard and close" icon={FaTimes} size="lg" color="grey" onClick={handleDiscard} />
				<ThemeButton
					label={isLoading ? 'Saving...' : 'Save'} // Change button label based on loading state
					icon={FaSave}
					disabled={!filteredPreferences?.some(fp => fp.isNew || fp.isChanged)} // Disable button if no changes
					size="lg"
					color="green"
					onClick={handleSave}
				/>
			</Modal.Footer>
		</FullHeightModal>
	);
};

export default SignaturePreferenceModal;

const FullHeightModal = styled(Modal)`
	div.modal-content {
		height: 100%;
	}
`;
