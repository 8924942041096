import React, { useCallback, useMemo } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';

import { ThemeButton } from 'components/ThemeButton';

import { useNote } from 'contexts/NoteContext';
import { usePreference } from 'contexts/PreferenceContext';

import {
	createPreference as fetchCreatePreference,
	updatePreference as fetchUpdatePreference,
} from 'services/preference';

import { PreferenceTypes } from 'utils/enum';

import PunctuationGroup from './PunctuationGroup';

// Accessed via the cogwheel button in certain design space tabs
const getPunctuations = punctuation => [
	{
		name: 'When one phrase is selected',
		settings: [
			{
				title: 'Insert a colon (“:”) after the stem?',
				name: PreferenceTypes.PUNCTUATION_ONE_PHRASE_STEM_SUFFIX,
				currentValue: punctuation.OnePhraseSuffix,
				options: [
					{
						value: ':',
						label: 'Yes',
					},
					{
						value: '',
						label: 'No',
					},
				],
			},
		],
	},
	{
		name: 'When two phrases are selected',
		settings: [
			{
				title: 'Insert a colon (“:”) after the stem?',
				name: PreferenceTypes.PUNCTUATION_TWO_PHRASE_STEM_SUFFIX,
				currentValue: punctuation.TwoPhraseSuffix,
				options: [
					{
						value: ':',
						label: 'Yes',
					},
					{
						value: '',
						label: 'No',
					},
				],
			},
			{
				title: 'Before the final phrase, insert',
				name: PreferenceTypes.PUNCTUATION_TWO_BEFORE_FINAL_ITEM,
				currentValue: punctuation.TwoPhraseBeforeFinalItem,
				options: [
					{
						value: 'and',
						label: 'and',
					},
					{
						value: ', and',
						label: ', and',
					},
					{
						value: '; and',
						label: '; and',
					},
					{
						value: ',',
						label: ',',
					},
					{
						value: ';',
						label: ';',
					},
				],
			},
		],
	},
	{
		name: 'When three or more phrases are selected',
		settings: [
			{
				title: 'Insert a colon (“:”) after the stem?',
				name: PreferenceTypes.PUNCTUATION_THREE_PHRASE_STEM_SUFFIX,
				currentValue: punctuation.ThreePhraseSuffix,
				options: [
					{
						value: ':',
						label: 'Yes',
					},
					{
						value: '',
						label: 'No',
					},
				],
			},
			{
				title: 'Separate the phrases with',
				name: PreferenceTypes.PUNCTUATION_THREE_PHRASE_SEPARATION,
				currentValue: punctuation.ThreePhraseSeparation,
				options: [
					{
						value: ',',
						label: 'Commas (“,”)',
					},
					{
						value: ';',
						label: 'Semicolons  (“;”)',
					},
				],
			},
			{
				title: 'Before the final phrase, insert',
				name: PreferenceTypes.PUNCTUATION_THREE_BEFORE_FINAL_ITEM,
				currentValue: punctuation.ThreePhraseBeforeFinalItem,
				options: [
					{
						value: 'and',
						label: 'and',
					},
					{
						value: ', and',
						label: ', and',
					},
					{
						value: '; and',
						label: '; and',
					},
					{
						value: ',',
						label: ',',
					},
					{
						value: ';',
						label: ';',
					},
				],
			},
		],
	},
];

// Punctuation settings modal component
const PunctuationModal = ({ show, handleClose, punctuation, subTab }) => {
	const { preferences, setPreferences } = usePreference();
	const { activeNoteType } = useNote();
	const punctuations = useMemo(() => getPunctuations(punctuation), [punctuation]);

	// Handle preference change
	const handleChange = e => {
		const changedPreference = preferences?.find(pref => pref.key === e.target.name && pref.subtab === subTab.id);
		if (changedPreference && changedPreference !== -1) {
			setPreferences(
				preferences.map(pref => {
					if (pref.id === changedPreference.id) {
						updatePreference({ ...pref, value: e.target.value });
						return { ...pref, value: e.target.value };
					}
					return pref;
				})
			);
		} else {
			const newPreference = {
				id: Math.random(),
				key: e.target.name,
				value: e.target.value,
				subtab: subTab.id,
				note_type: activeNoteType.id,
			};
			createPreference(newPreference);
		}
	};

	// Create a new preference
	const createPreference = useCallback(
		async preference => {
			try {
				const response = await fetchCreatePreference(preference);
				setPreferences([...preferences, response.data]);
			} catch (error) {
				console.error('🚀 ~ file: PunctuationModal.js ~ line 177 ~ createNewPreference ~ error', error);
			}
		},
		[preferences, setPreferences]
	);

	// Update an existing preference
	const updatePreference = useCallback(async preference => {
		try {
			await fetchUpdatePreference(preference);
		} catch (error) {
			console.error('🚀 ~ file: PunctuationModal.js ~ line 183 ~ updatePreference ~ error', error);
		}
	}, []);

	return (
		<Modal show={show} onHide={handleClose} centered size="xl">
			<Modal.Header closeButton>
				<Modal.Title>Punctuation Settings</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container>
					<Row>
						{punctuations.map((punctuation, index) => (
							<Col key={index}>
								<PunctuationGroup punctuation={punctuation} handleChange={handleChange} />
							</Col>
						))}
					</Row>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton onClick={handleClose} label="Close" color="grey" />
			</Modal.Footer>
		</Modal>
	);
};

export default PunctuationModal;
