import { Resizable } from 're-resizable';
import React, { useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import debounce from 'utils/debounce';

import { imagesState } from '@note/atoms';

const ImageWithCaption = ({ image, dragHandleProps, onCaptionChange, onTitleChange }) => {
	const [isHovered, setIsHovered] = useState(false);
	const [images, setImagesState] = useRecoilState(imagesState);

	const handleTitleChange = e => {
		const content = e.target.value.trim();
		onTitleChange(image.id, content === '' ? null : content);
	};

	const handleCaptionChange = e => {
		const content = e.target.value.trim();
		onCaptionChange(image.id, content === '' ? null : content);
	};

	const debouncedSetImageSize = debounce((size, id) => {
		const updatedImages = images.map(img => {
			if (img.id === id) {
				return { ...img, ...size };
			}
			return img;
		});
		setImagesState(updatedImages);
	}, 1000);

	const handleResizeStop = ref => {
		const size = {
			width: ref.style.width,
			height: ref.style.height,
		};
		debouncedSetImageSize(size, image.id);
	};

	return (
		<div style={{ width: 'fit-content', position: 'relative' }} className="image-element">
			<Text
				data-placeholder="[Add a title here if desired]"
				html={image.title || ''}
				onChange={handleTitleChange}
				className="image-caption"
			/>
			{
				<ResizableDiv onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
					<div {...dragHandleProps}>
						<DragHandle />
					</div>

					<Resizable
						defaultSize={{
							width: image.width ? image.width : 'auto',
							height: image.height ? image.height : 200,
						}}
						minHeight={100}
						lockAspectRatio={true}
						boundsByDirection={true}
						onResizeStop={(_, __, ref) => handleResizeStop(ref)}
						handleStyles={{
							top: { display: 'none' },
							right: { display: 'none' },
							bottom: { display: 'none' },
							left: { display: 'none' },
							topRight: { display: 'none' },
							bottomRight: {
								display: isHovered ? 'block' : 'none',
								background: 'blue',
								width: '10px',
								height: '10px',
								cursor: 'se-resize',
								right: '-5px',
								bottom: '-5px',
								backgroundColor: 'transparent',
								borderBottom: '3px solid blue',
								borderRight: '3px solid blue',
							},
							bottomLeft: { display: 'none' },
							topLeft: { display: 'none' },
						}}
					>
						<ImageElement src={image.file} alt="Image with caption" />
					</Resizable>
				</ResizableDiv>
			}
			<Text
				data-placeholder="[Add a caption here if desired]"
				html={image.caption || ''}
				onChange={handleCaptionChange}
				className="image-caption"
			/>
		</div>
	);
};
export default ImageWithCaption;

const Text = styled(ContentEditable)`
	margin: 0;
	width: fit-content;
	&:focus-visible {
		outline: 1px solid #37a4fa;
		border-radius: 3px;
	}
	:empty:before {
		content: attr(data-placeholder);
		color: #888;
		font-style: italic;
	}
`;
const ImageElement = styled.img`
	width: 100%;
	height: 100%;
	border: 1px solid black;
`;

const ResizableDiv = styled.div`
	width: fit-content;
	position: relative;
	padding: 10px;
`;

const DragHandle = styled.div`
	z-index: 200;
	position: absolute;
	inset: 20px;
`;
