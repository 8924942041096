import { usePreference } from 'contexts/PreferenceContext';
import dayjs from 'dayjs';
import SignaturePreferenceModal from 'features/preferences/signature-preferences/SignaturePreferenceModal';
import React, { useContext, useState } from 'react';
import { FaCalendarAlt, FaCog, FaFileSignature } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { getSignature } from 'services/user';
import styled from 'styled-components';
import { blue } from 'styles/colors';
import { DateFormats } from 'utils/enum';

import { AuthContext } from '@note/contexts/AuthContext';

import './Signature.scss';

import Images from '../images/Images';
import LetterheadModal from '../letterhead/LetterheadModal';
import SignatureImagePreview from './SignatureImagePreview';
import SignatureModal from './SignatureModal';

const Signature = isFinalNote => {
	const {
		signature,
		signatureImage,
		showSignature,
		setShowSignature,
		showLetterhead,
		setShowLetterhead,
		setSignatureImage,
		signatureTextObject,
		setSignatureTextObject,
		user,
		innerHtml,
	} = useContext(AuthContext);
	const { globalPreferences } = usePreference();
	const { useImages } = globalPreferences;

	const { SignatureDate, SignatureTime, SignatureDateFormat, SignatureStartLine, SignatureEndLine } = globalPreferences;

	const [showSignatureModal, setShowSignatureModal] = useState(false);
	const [showLetterheadModal, setShowLetterheadModal] = useState(false);
	const [showFormatModal, setShowFormatDateModal] = useState(false);

	const openSignatureModal = () => setShowSignatureModal(true);
	const closeSignatureModal = () => setShowSignatureModal(false);

	const openLetterheadModal = () => setShowLetterheadModal(true);
	const closeLetterheadModal = () => setShowLetterheadModal(false);

	const openFormatDateModal = () => setShowFormatDateModal(true);
	const closeFormatDateModal = () => setShowFormatDateModal(false);

	// Toggle the visibility of the signature
	const toggleSignature = async () => {
		if (!signatureImage && !signatureTextObject) {
			setShowSignature(false);
			return toast.info('No Signature was found', { hideProgressBar: true });
		}
		if (showSignature === false) {
			await loadSignature();
		}
		setShowSignature(!showSignature);
	};

	// Toggle the visibility of the letterhead
	const toggleLetterhead = async () => {
		if (user.letterhead === null) {
			setShowLetterhead(false);

			return toast.info('No Letterhead was found', { hideProgressBar: true });
		}
		setShowLetterhead(!showLetterhead);
	};

	// Load the signature data
	const loadSignature = async () => {
		try {
			const res = await getSignature();
			setSignatureImage(res.data?.signature_image);
			setSignatureTextObject(res.data?.signature_json);
		} catch (error) {
			console.error('🚀 ~ file: App.js ~ line 47 ~ useAsyncEffect ~ error', error);
		}
	};

	return (
		<>
			<div className={!isFinalNote ? 'signature-container' : ''}>
				<div className="signature-area">
					<>
						<br />
						<br />
						<br />
					</>
					<div style={{ display: 'flex' }}>
						{showSignature && (signatureImage || signatureTextObject) && (
							<>
								{signatureImage && (
									<div style={{ borderBottom: showSignature ? '1px solid black' : 'none', paddingBottom: '0.5rem' }}>
										<SignatureImagePreview />
									</div>
								)}
								{signatureTextObject && (
									<div
										style={{
											borderBottom: showSignature ? '1px solid black' : 'none',
											fontFamily: signatureTextObject.selectedFontOption,
											lineHeight: '25px',
											marginBottom: 15,
											fontSize: signatureTextObject.selectedFontSizeOption,
											transform: 'translateY(15px)',
											marginRight: '1rem',
										}}
									>
										{signatureTextObject.signatureText}
									</div>
								)}
								{SignatureDate === 'Show date' && (
									<FormattedDate>
										{SignatureStartLine}
										<br />
										{SignatureTime === 'Show time' && dayjs(new Date()).format('h:mm A') + ', '}
										{dayjs(new Date()).format(DateFormats[SignatureDateFormat])}
										<br />
										{SignatureEndLine}
									</FormattedDate>
								)}
							</>
						)}
					</div>

					<div id="signature" style={{ whiteSpace: 'pre' }}>
						{!showSignature && <p style={{ margin: '0', lineHeight: '1' }}>‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾</p>}
						{signature && signature}
					</div>
				</div>
				{innerHtml ? (
					<div style={{ padding: '3px' }} dangerouslySetInnerHTML={{ __html: innerHtml }} />
				) : (
					useImages === 'Show images' && <Images id="imagesOutput" />
				)}

				<FormatContainer className="signature-controls">
					<ButtonsContainer>
						<SignatureButton className="signature-button" onClick={toggleSignature}>
							<FaFileSignature />
							{showSignature ? 'Unsign' : 'Sign'}
						</SignatureButton>
						<CreateElementButton className="signature-button" onClick={openSignatureModal}>
							<FaCog />
							Create signature
						</CreateElementButton>
						<FormatDateButton className="signature-button" onClick={openFormatDateModal}>
							<FaCalendarAlt />
							Format Date
						</FormatDateButton>
					</ButtonsContainer>
					<ButtonsContainer>
						<SignatureButton className="signature-button" onClick={toggleLetterhead}>
							<FaFileSignature />
							{showLetterhead ? 'Remove Letterhead' : 'Add Letterhead'}
						</SignatureButton>
						<CreateElementButton className="signature-button" onClick={openLetterheadModal}>
							<FaCog />
							Format Letterhead
						</CreateElementButton>
					</ButtonsContainer>
				</FormatContainer>
			</div>
			<LetterheadModal show={showLetterheadModal} handleClose={closeLetterheadModal} />
			<SignatureModal show={showSignatureModal} handleClose={closeSignatureModal} />
			<SignaturePreferenceModal show={showFormatModal} handleClose={closeFormatDateModal} />
		</>
	);
};

export default React.memo(Signature);

const FormatContainer = styled.div`
	margin-bottom: 20px;
`;

const SignatureButton = styled.button`
	&:not(:first-child) {
		margin-left: 1rem;
	}
	border: none;
	margin-top: 10px;
	padding: 4px 12px;
	font-size: 14px;
	border-radius: 5px;
	color: white;
	display: flex;
	align-items: center;
	gap: 5px;
	background: ${blue};
	svg {
		font-size: 16px;
	}
`;

const ButtonsContainer = styled.div`
	display: flex;
`;

const CreateElementButton = styled(SignatureButton)`
	background: ${blue};
`;

const FormatDateButton = styled(SignatureButton)`
	background: ${blue};
`;

const FormattedDate = styled.p`
	margin-bottom: 0;
	align-self: end;
	opacity: 0.7;
	font-size: 13px;
`;
