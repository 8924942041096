import React from 'react';

const DesignNoteLogo = (props) => (
  <svg width="18" height="18" viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.514282 12.0476H12.0571V0.428574H0.514282V12.0476ZM3.4 3.33334H9.17142V9.14286H3.4V3.33334Z"/>
    <path d="M14.9429 0.428574V12.0476H26.4857V0.428574H14.9429ZM23.6 9.14286H17.8286V3.33334H23.6V9.14286Z"/>
    <path d="M0.514282 26.5714H12.0571V14.9524H0.514282V26.5714ZM3.4 17.8571H9.17142V23.6667H3.4V17.8571Z"/>
    <path d="M22.1571 14.9524H19.2714V19.3095H14.9429V22.2143H19.2714V26.5714H22.1571V22.2143H26.4857V19.3095H22.1571V14.9524Z"/>
  </svg>
);

export default DesignNoteLogo;
