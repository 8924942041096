import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { ThemeButton } from '@note/components/ThemeButton';

const AIPlusConsentModal = ({ show, handleClose, onConsentCancel, onConsentAccept }) => {
	const handleConsentCancel = () => {
		onConsentCancel();
		handleClose();
	};

	const handleConsentAccept = () => {
		onConsentAccept();
		handleClose();
	};
	const renderModalBody = () => {
		return (
			<>
				This consent form outlines the terms under which Note Designer uses Artificial Intelligence (AI) technology in
				“AI Note” to re-write psychotherapy notes submitted by our users. By consenting to this document, you
				acknowledge understanding and agreeing to the use of the AI system in processing your psychotherapy notes under
				the specified conditions.
				<br />
				<br />
				Please note that the AI technology used for the “AI Note” is distinct from the AI system employed in processing
				the other Note Types (i.e., our fully integrated AI). Specifically, the “AI Note” leverages a third-party
				(OpenAI) AI system, while for all other note-types, our private AI system processes the inputs. In either case,
				only generic, non-identified information should be entered into note content that will be rewritten using the AI
				feature. As always all identifying information included in the Headings tab is never sent to any AI system.
				<br />
				<br />
				It is important for maintaining HIPAA compliance that we emphasize and remind you of your responsibility to not
				enter identifying Private Health Information into fields that are sent to either AI system.
				<br />
				<br />
				<b>Please Note the Following Capabilities and Potential Risks</b>
				<br />
				<br />
				<ul>
					<li>
						<b>You’re in Control: </b>Note Designer will not send any information you input to the AI system without
						your instruction (e.g., pressing the “AI REWRITE” button).
					</li>
					<li>
						<b>AI isn’t Perfect: </b>AI technology has limitations and may not always accurately interpret or represent
						the nuances of therapy sessions.
					</li>
					<li>
						<b>Potential Security Issues: </b>Though Note Designer does not send a user’s headings (i.e., client name,
						date of session, CPT codes etc.) or therapist signature/signature line to the AI system, other information
						outside of the Headings tab and signature line as entered by the user does get transmitted, in an encrypted
						form, over the internet to the AI system. Though unlikely, there are always possible risks associated with
						using any digital technology, including potential data breaches. This is why we emphasize not submitting
						identifying information to the AI Rewrite.
					</li>
				</ul>
				<br />
				<b>Your Responsibilities regarding Data Privacy and Ethical Practice</b>
				<br />
				<br />
				<ul>
					<li>
						<b>No PHI Entries: </b>Do not enter any identifying Protected Health Information (PHI) into the AI system
						(e.g., text field inputs).
					</li>
					<li>
						<b>Review and Oversight: </b>Be sure to thoroughly review and edit all AI- generated notes to ensure they
						accurately reflect the therapy session and adhere to professional standards.
					</li>
					<li>
						<b>Compliance with Privacy Laws: </b>It is your responsibility to comply with all applicable privacy laws
						and ethical guidelines in the use of AI for psychotherapy documentation.
					</li>
				</ul>
				<br />
				<b>Consent:</b>
				<br />
				By clicking the “agree” button below I consent to the use of AI under the outlined conditions and acknowledge
				the associated risks. I affirm my responsibility to protect client confidentiality and to adhere to the ethical
				standards of my profession in the use of this technology.
			</>
		);
	};

	return (
		<StyledModal
			style={{ border: '2px solid grey', zIndex: '9000' }}
			show={show}
			onHide={() => handleClose(true)}
			backdrop="static"
			scrollable
			centered
			size="lg"
			backdropClassName="backdrop-blur"
			keyboard={false}
		>
			<Modal.Header>
				<Modal.Title>Consent for Using Note Designer’s “AI Note”</Modal.Title>
			</Modal.Header>
			<Modal.Body>{renderModalBody()}</Modal.Body>
			<Modal.Footer>
				<ConsentButtons>
					<ThemeButton
						label={`I have read the above and consent to using the AI functions offered in "AI Note"`}
						size="lg"
						color="green"
						onClick={handleConsentAccept}
					/>
					<CancelButton>
						<ThemeButton label="Cancel" size="lg" color="red" onClick={handleConsentCancel} />
					</CancelButton>
				</ConsentButtons>
			</Modal.Footer>
		</StyledModal>
	);
};

export default AIPlusConsentModal;

const StyledModal = styled(Modal)`
	& .modal-content {
		border: 2px solid gray;
	}
`;

const CancelButton = styled('div')`
	margin-left: 1rem;
`;

const ConsentButtons = styled('div')`
	display: flex;
	flex-direction: row;
`;
