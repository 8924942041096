import React from 'react';
import styled from 'styled-components';

import SubTabNoteContent from '../../../note-output/SubTabNoteContent';

const NotePreview = ({ subTab }) => {
	return (
		<PreviewContainer>
			<p>
				<b>
					When you make a selection on the left it will be previewed here. Keep in mind you are now editing your template; when you are
					ready to write an actual note you can press the green button at the top left of the screen to return to writing a note.
				</b>
			</p>
			<SubTabNoteContent subTab={subTab} />
		</PreviewContainer>
	);
};

export default NotePreview;

const PreviewContainer = styled.div`
	width: 100%;
	height: 100%;
	background: white;
	padding: 10px;
`;
