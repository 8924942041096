import { useNote } from 'contexts/NoteContext';
import { usePreference } from 'contexts/PreferenceContext';
import React, { useMemo } from 'react';
import { PreferenceTypes } from 'utils/enum';

const NoteTitle = () => {
	const { activeNoteType } = useNote();
	const { getPreferenceValueByNoteType } = usePreference();

	// Memoized function to get the note title preference based on the active note type
	const noteTitle = useMemo(() => {
		if (!activeNoteType) {
			return 'Hide title';
		}
		return getPreferenceValueByNoteType(PreferenceTypes.NOTE_TITLE, activeNoteType.id);
	}, [activeNoteType, getPreferenceValueByNoteType]);

	// Memoized function to get the note title value based on the active note type
	const noteTitleValue = useMemo(() => {
		if (!activeNoteType) {
			return '';
		}
		return getPreferenceValueByNoteType(PreferenceTypes.NOTE_TITLE_VALUE, activeNoteType.id);
	}, [activeNoteType, getPreferenceValueByNoteType]);

	// Render the note title if the preference is not to hide it
	if (activeNoteType && noteTitle !== 'Hide title') {
		return <h3 style={{ textAlign: 'center' }}>{noteTitleValue ? noteTitleValue : activeNoteType.name}</h3>;
	}

	return null;
};

export default React.memo(NoteTitle);
