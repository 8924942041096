import React from 'react';
import { Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { useNote } from 'contexts/NoteContext';
import { endAIPlus } from 'services/aiEdit';

import { ThemeButton } from 'components/ThemeButton';

export const AIEditConfirmationModal = ({ show, handleClose, closeAIEditModal }) => {
	const { activeNoteType } = useNote();

	const handleConfirm = () => {
		activeNoteType?.id && endAIPlus(activeNoteType.id);
		closeAIEditModal();
		handleClose();
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Note</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				If you close this AI Edit space without exporting or copying, any changes you made will not be saved. CANCEL and
				return to AI Edit space or CLOSE now?
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label="Close" size="lg" color="yellow" onClick={handleConfirm} />
				<ThemeButton label="Cancel" icon={FaTimes} size="lg" color="grey" onClick={handleClose} />
			</Modal.Footer>
		</Modal>
	);
};
