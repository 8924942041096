import React, { useState } from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { ThemeButton } from 'components/ThemeButton';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import SelectedNoteType from './SelectedNoteType';
import NoteTypeList from '../note-type-list';

const ManageNoteTypeModal = ({ show, handleClose }) => {
    const [selectedNoteType, setSelectedNoteType] = useState();

    return (
        <Modal show={show} onHide={handleClose} fullscreen centered>
            <Modal.Header closeButton>
                <Modal.Title>Manage your note-type list</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className='h-100'>
                    <Row className='h-100'>
                        <Col className='h-100'>
                            <ScrollableContainer>
                                <NoteTypeList
                                    selectedNoteType={selectedNoteType}
                                    isArchivedVisible={true}
                                    isManage
                                    setSelectedNoteType={setSelectedNoteType} />
                            </ScrollableContainer>
                        </Col>
                        <Col>
                            <AnimatePresence mode='wait'>
                                <motion.div
                                    key={selectedNoteType}
                                    style={{ height: '100%' }}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.15 }}
                                >
                                    <SelectedNoteType noteTypeId={selectedNoteType} />
                                </motion.div>
                            </AnimatePresence>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <ThemeButton label="Close" size="lg" color="grey" onClick={handleClose} />
            </Modal.Footer>
        </Modal>
    );
};

export default ManageNoteTypeModal;


const ScrollableContainer = styled.div`
    height: 100%;
    overflow: auto;
`
