import React, { useState, useRef } from 'react';
import { Card, Alert } from 'react-bootstrap';
import SignaturePad from 'react-signature-canvas';
import styled from 'styled-components';

import { ThemeButton } from 'components/ThemeButton';
import SignatureImagePreview from './SignatureImagePreview';

const SignatureCanvas = ({ setImageDataURL }) => {
	// State to hold the trimmed data URL of the signature
	const [trimmedDataURL, setTrimmedDataURL] = useState(null);
	// Reference to the SignaturePad component
	let sigPad = useRef();

	// Function to clear the signature pad and reset state
	const clear = () => {
		sigPad.clear();
		setTrimmedDataURL(null);
		setImageDataURL(null);
	};

	// Function to trim the signature and set the data URL
	const trim = () => {
		const trimmed = sigPad.getTrimmedCanvas().toDataURL('image/png');
		setTrimmedDataURL(trimmed);
		setImageDataURL(trimmed);
	};

	return (
		<Container>
			<Alert>
				To draw your signature:
				<ol className="mb-0">
					<li>Use your cursor to draw your signature in the box below.</li>
					<li>Scroll down to see a preview.</li>
					<li>Click Save.</li>
				</ol>
			</Alert>
			<Card className="mt-2">
				<Card.Body>
					<SigContainer>
						<SignaturePad
							onEnd={trim}
							canvasProps={{
								height: '300px',
								width: '600px',
								className: 'signature-canvas',
							}}
							ref={ref => {
								sigPad = ref;
							}}
						/>
					</SigContainer>
				</Card.Body>
				{trimmedDataURL && (
					<Card.Footer className="d-flex justify-content-end">
						<ThemeButton label="Clear above canvas" size="lg" color="red" onClick={clear} />
					</Card.Footer>
				)}
			</Card>
			<Card className="mt-2">
				<Card.Header>Current signature</Card.Header>
				<Card.Body>
					<SignatureImagePreview />
				</Card.Body>
			</Card>
			<Card className="my-2">
				<Card.Header>Preview of new signature</Card.Header>
				<Card.Body>
					{trimmedDataURL ? (
						<img className="sigImage" alt="signature" src={trimmedDataURL} />
					) : (
						<p style={{ textAlign: 'center', margin: '0' }}>Draw your signature above to see the preview</p>
					)}
				</Card.Body>
			</Card>
		</Container>
	);
};

export default SignatureCanvas;

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const SigContainer = styled.div`
	width: 100%;
	min-height: 300px;
	background-color: #fff;
	text-align: center;
`;
