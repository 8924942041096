import React, { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import styled from 'styled-components';

import EditMode from './EditMode';
import ReadMode from './ReadMode';

const MultipleChoiceOptions = ({ subTab }) => {
	const [options, setOptions] = useState(subTab.settings.multipleChoice.options);
	const [editMode, setEditMode] = useState(false);

	const toggleEditMode = useCallback(() => {
		setEditMode(editMode => !editMode);
	}, []);

	return (
		<OptionsContainer>
			<LabelContainer>
				<Label>
					Options (Here you can edit the possible selections for this section, e.g., mild, moderate, severe):
				</Label>
				<Icons onClick={toggleEditMode}>
					<FaEdit />
				</Icons>
			</LabelContainer>
			{editMode ? (
				<EditMode subTab={subTab} options={options} setOptions={setOptions} toggleEditMode={toggleEditMode} />
			) : (
				<ReadMode options={options} />
			)}
		</OptionsContainer>
	);
};

export default MultipleChoiceOptions;

const OptionsContainer = styled.div`
	background: white;
	padding: 5px 10px;
	margin-bottom: 10px;
`;

const LabelContainer = styled.div`
	width: 100%;
	display: flex;
`;

const Label = styled(Form.Label)`
	width: calc(100% - 100px);
	margin-bottom: 3px;
	font-weight: 500;
`;

const Icons = styled.div`
	text-align: right;
	width: 100px;
	padding-right: 5px;
	& > svg {
		cursor: pointer;
		opacity: 0.6;
		&:hover {
			opacity: 1;
		}
	}
`;
