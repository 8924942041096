import React from 'react';
import styled from 'styled-components';
import { navy } from 'styles/colors';
import { WidgetType } from 'utils/enum';

import { listSelectData } from '../../example-data';

const ListSelectionPreview = ({ subTab }) => {
	const isSelected = index => {
		if (subTab.type === WidgetType.LIST_MULTI_SELECT) {
			return index === 0 || index === 2 ? true : false;
		}
		return index === 1 ? true : false;
	};

	return (
		<>
			<Stem>After Consulting the menu, the customer ordered:</Stem>
			<StatementContainer>
				{listSelectData.map((item, index) => (
					<Statement key={index} selected={isSelected(index)}>
						{item.name}
					</Statement>
				))}
			</StatementContainer>
		</>
	);
};

export default ListSelectionPreview;

const Stem = styled.div`
	padding: 5px 10px;
	width: 100%;
	margin-bottom: 10px;
	font-weight: bold;
`;

const StatementContainer = styled.div`
	display: grid;
	column-gap: 10px;
`;

const Statement = styled.div`
	width: 100%;
	background: ${props => (props.selected ? 'rgba(141, 184, 252, .1)' : '#eee')};
	padding: 5px 10px;
	border: 1px solid ${props => (props.selected ? navy + '70' : 'white')};
	color: ${props => (props.selected ? navy : 'black')};
	border-radius: 5px;
	margin-bottom: 5px;
`;
