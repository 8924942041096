import React, { useCallback, useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { ThemeButton } from 'components/ThemeButton';
import DeleteConfirmationModal from 'modals/DeleteConfirmationModal';

import {
	createTab as fetchCreateTab,
	deleteTab as fetchDeleteTab,
	updateTabName as fetchUpdateTabName,
} from 'services/tab';
import { useRecoilState } from 'recoil';
import { tabsState } from '@note/atoms';

const NewTabModal = ({ show, handleClose, selectedTab = null, setActiveTabId }) => {
	const [tabs, setTabs] = useRecoilState(tabsState);
	const [searchParams] = useSearchParams();

	const [name, setName] = useState('');
	const [isValid, setIsValid] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// Function to open the delete confirmation modal
	const openDeleteModal = useCallback(() => setShowDeleteModal(true), []);

	// Function to close the delete confirmation modal
	const closeDeleteModal = useCallback(() => setShowDeleteModal(false), []);

	// Function to handle delete action
	const handleDelete = useCallback(() => openDeleteModal(), [openDeleteModal]);

	// Effect to set the initial state of the modal based on whether a tab is selected or not
	useEffect(() => {
		setIsValid(true);
		if (selectedTab) {
			setName(selectedTab.name);
		} else {
			setName('');
		}
	}, [selectedTab, show]);

	// Function to update the name of an existing tab
	const updateTabName = useCallback(async () => {
		setIsLoading(true);
		const response = await fetchUpdateTabName(selectedTab.id, name);
		setTabs(tabs.map(tab => (tab.id === selectedTab.id ? { ...tab, name: response.data.name } : tab)));
		setActiveTabId(response.data.id);
		setIsLoading(false);
		handleClose();
	}, [handleClose, name, selectedTab, setTabs, tabs, setActiveTabId]);

	// Function to create a new tab
	const createTab = useCallback(async () => {
		setIsLoading(true);
		const response = await fetchCreateTab({
			order_index: tabs.length > 0 ? Math.max(...tabs.map(tab => tab.order_index)) + 1 : 0, // Determine the order index for the new tab
			name: name,
			note_type: parseInt(searchParams.get('id')),
		});

		setTabs([...tabs, response.data]);
		setActiveTabId(response.data.id);
		setIsLoading(false);
		handleClose();
	}, [handleClose, name, searchParams, setActiveTabId, setTabs, tabs]);

	// Function to either update an existing tab or create a new one based on the state
	const saveTab = useCallback(async () => {
		if (name.length === 0) {
			setIsValid(false);
			return;
		}

		selectedTab ? await updateTabName() : await createTab();
	}, [createTab, name, selectedTab, updateTabName]);

	// Function to delete the selected tab
	const deleteTab = useCallback(async () => {
		await fetchDeleteTab(selectedTab.id);
		setTabs(tabs.filter(tab => tab.id !== selectedTab.id));
		if (tabs && tabs.length > 0) {
			setActiveTabId(tabs[0].id); // Set the first tab as the active tab after deletion
		}
		handleClose();
	}, [handleClose, selectedTab, setTabs, tabs, setActiveTabId]);

	// Conditionally render the delete confirmation modal or the tab creation/editing modal
	return showDeleteModal ? (
		selectedTab && (
			<DeleteConfirmationModal
				entityType="tab"
				deleteEntity={deleteTab}
				show={showDeleteModal}
				id={selectedTab.id}
				name={selectedTab.name}
				handleClose={closeDeleteModal}
			/>
		)
	) : (
		<Modal show={show} onHide={handleClose} centered>
			<Modal.Header closeButton>
				<Modal.Title>{selectedTab ? 'Edit' : 'New'} Tab</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3" controlId="formGroupEmail">
						<Form.Label>Name your tab</Form.Label>
						<Form.Control
							value={name}
							required
							type="text"
							isInvalid={!isValid}
							placeholder="Type a Name for your tab here (you can change it later)"
							onChange={e => setName(e.target.value)}
							onKeyDown={e => {
								if (e.key === 'Enter') e.preventDefault();
							}}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{selectedTab && <ThemeButton label="Delete" size="lg" color="red" onClick={handleDelete} />}
				<ThemeButton
					label={isLoading ? 'Saving...' : selectedTab ? 'Update' : 'Create'}
					size="lg"
					color="blue"
					onClick={saveTab}
					tourAnchor="create-button"
				/>
				<ThemeButton label="Close" icon={FaTimes} size="lg" color="grey" onClick={handleClose} />
			</Modal.Footer>
		</Modal>
	);
};

export default NewTabModal;
