import React from 'react';
import { ListGroup } from 'react-bootstrap';
import styled from 'styled-components';

const ListItem = ({ children, title, subTitle }) => {
    return (
        <ListGroupItem>
            <Title> {title} </Title>
            <SubTitle>{subTitle}</SubTitle>
            {children}
        </ListGroupItem>
    )
}

export default ListItem;


const Title = styled.h5`
    margin-bottom: 3px;
`

const SubTitle = styled.p`
    margin-bottom: 5px;
    opacity: 0.7;
`

const ListGroupItem = styled(ListGroup.Item)`
    padding 10px 0;
`