import React, { useState, useCallback } from 'react';
import PTLogo from 'assets/pt-logo.png';
import { Alert, Card, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ThemeButton } from 'components/ThemeButton';
import styled from 'styled-components';
import { signup } from '../services/signup';

const SignMeUp = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');

	const handleSubmit = useCallback(
		async event => {
			event.preventDefault();
			if (email && name) {
				try {
					await signup(email, name);
					toast.success(
						"Thanks for your interest in Note Designer: Physical Therapy. We'll send you an email to get set up within 24 hours. Feel free to contact us anytime at info@notedesigner.com",
						{ hideProgressBar: true }
					);
				} catch (error) {
					toast.error('Error submitting form. Please try again later', { hideProgressBar: true });
				}
			}
		},
		[email, name]
	);

	return (
		<PageContainer>
			<Card style={{ width: '450px' }}>
				<div className="text-center">
					<img className="mt-3" src={PTLogo} alt="NoteDesigner logo" />
				</div>
				<Card.Body className="mb-3">
					<Alert>
						No credit card required! Just fill out the form below and we'll get you set up for Note Designer: Physical
						Therapy. First 3 months free -- Cancel anytime.
					</Alert>
					<Form onSubmit={handleSubmit}>
						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Group className="mb-3" controlId="formBasicPassword">
								<Form.Control
									type="text"
									placeholder="Your Name"
									value={name}
									onChange={e => setName(e.target.value)}
									required
								/>
							</Form.Group>
							<Form.Control
								type="email"
								placeholder="Enter email"
								value={email}
								onChange={e => setEmail(e.target.value)}
								required
							/>
						</Form.Group>
						<ThemeButton
							disabled={!email || !name}
							type="submit"
							fullWidth={true}
							label="Submit"
							size="lg"
							color="loginGreen"
						/>
					</Form>
				</Card.Body>
			</Card>
		</PageContainer>
	);
};

export default SignMeUp;

const PageContainer = styled.div`
	background: #212529;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;
