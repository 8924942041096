import React from 'react';
import styled from 'styled-components';

const ReadMode = ({ options }) => {
	return (
		<Options>
			{options.map((option, index) => (
				<OptionItem key={index}>{option}</OptionItem>
			))}
		</Options>
	);
};

export default ReadMode;

const Options = styled.ul`
	list-style: none;
	padding: 0;
`;

const OptionItem = styled.li`
	padding: 3px 12px;
	background: #eee;
	color: #000;
	margin-right: 5px;
	border-radius: 5px;
	display: inline-flex;
	margin-bottom: 5px;
	align-items: center;
	& > svg {
		margin-left: 5px;
	}
`;
