/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { isNull } from 'lodash';
import { useNote } from 'contexts/NoteContext';
import PreferenceOptions from './PreferenceOptions';
import PreferenceInput from './PreferenceInput';

// Component for managing note-specific preferences
const NotePreference = ({
	preferenceOptions, // Options for preferences
	preferenceKey, // Key to identify the preference
	filteredPreferences, // Array of filtered preferences
	setFilteredPreferences, // Function to update filtered preferences
	isInputType = false, // Flag to determine if preference is input type
	handleReset, // Function to handle resetting preference
}) => {
	const { activeNoteType } = useNote(); // Context for active note type

	// Memoized function to get the selected preference
	const selectedPreference = useMemo(() => {
		const preference = filteredPreferences?.find(
			pref =>
				pref.key === preferenceKey &&
				pref?.note_type === activeNoteType.id &&
				isNull(pref?.subtab) &&
				isNull(pref?.section) &&
				isNull(pref?.tab)
		);
		return preference && preference !== -1 ? preference : null;
	}, [filteredPreferences, preferenceKey, activeNoteType?.id]);

	// Function to handle clicking a preference option
	const handleClick = prefOption => {
		updatePreferenceValue(prefOption);
	};

	// Function to handle changing preference value for input type
	const handleChange = event => {
		const value = event.target.value;
		if (value === '') {
			setFilteredPreferences(filteredPreferences =>
				filteredPreferences.filter(
					({ key, isNew, note_type }) => !(key === preferenceKey && isNew && note_type === activeNoteType.id)
				)
			);
		}
		updatePreferenceValue(value);
	};

	// Function to update the preference value
	const updatePreferenceValue = value => {
		if (selectedPreference) {
			setFilteredPreferences(
				filteredPreferences.map(pref =>
					pref.key === preferenceKey &&
					pref.note_type === activeNoteType?.id &&
					pref.tab === null &&
					pref.subtab === null &&
					pref.section === null
						? { ...pref, value, isChanged: !selectedPreference.isNew ? true : false }
						: pref
				)
			);
		} else {
			setFilteredPreferences([
				...filteredPreferences,
				{
					id: Math.random(), // Temporary ID for new preference
					key: preferenceKey,
					value,
					note_type: activeNoteType?.id,
					tab: null,
					subtab: null,
					section: null,
					isNew: true, // Mark as new preference
				},
			]);
		}
	};

	// Render input type or options based on isInputType flag
	return isInputType ? (
		<PreferenceInput
			selectedPreference={selectedPreference}
			handleChange={handleChange}
			hasReset={true}
			handleReset={handleReset}
		/>
	) : (
		<PreferenceOptions
			preferenceOptions={preferenceOptions}
			selectedPreference={selectedPreference}
			handleClick={handleClick}
			hasReset={true}
			handleReset={handleReset}
		/>
	);
};

export default NotePreference;
