import { ThemeButton } from 'components/ThemeButton';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

const DeleteConfirmationModal = ({ show, handleClose, deleteEntity, message = null, entityType, id, name }) => {
	const [isDeleting, setIsDeleting] = useState(false);
	const handleYes = async () => {
		setIsDeleting(true);
		await deleteEntity(id);
		handleClose();
		setIsDeleting(false);
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Confirmation</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					<b>
						Are you sure you want to delete this {entityType}?&nbsp;
						{entityType !== 'statement' && (
							<>
								This will also delete ALL content (including all statements) currently contained within the {entityType}
								.&nbsp;
							</>
						)}
						This deletion is permanent and cannot be undone. OK to delete?
					</b>
				</p>
				<p>{name}</p>
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton
					label={isDeleting ? '...Deleting' : 'Yes'}
					size="lg"
					color="red"
					onClick={handleYes}
					disabled={isDeleting}
				/>
				<ThemeButton label="Cancel" icon={FaTimes} size="lg" color="grey" onClick={handleClose} />
			</Modal.Footer>
		</Modal>
	);
};

export default DeleteConfirmationModal;
