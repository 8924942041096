export const sortedFilteredStems = filteredStems => {
	return (
		filteredStems
			// Filter out stems that do not have a parent
			.filter(stem => stem.parent === null || stem.parent === undefined)
			// Sort the stems based on their order_index
			.sort((a, b) => {
				if (a.order_index === null && b.order_index === null) return 0; // If both have null order_index, keep original order
				if (a.order_index === null) return -1; // If `a` has null order_index, move it above `b`
				if (b.order_index === null) return 1; // If `b` has null order_index, move it above `a`
				return a.order_index - b.order_index; // Otherwise, sort by order_index in ascending order
			})
	);
};
