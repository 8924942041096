import { usePreference } from 'contexts/PreferenceContext';
import { convert } from 'html-to-text';
import React, { useCallback, useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { editOutputState } from '@note/atoms';

const SubTabTitle = ({ isVisible, title }) => {
	const editOutput = useRecoilValue(editOutputState);
	const [editedText, setEditedText] = useState('');

	const { multiLevelPreferences } = usePreference();
	const { subTabTitle } = multiLevelPreferences;

	// Update the edited text when the visibility or title changes
	useEffect(() => {
		isVisible && setEditedText(title);
	}, [isVisible, title]);

	// Handle changes to the edited text
	const handleChange = useCallback(event => setEditedText(event.target.value), []);

	// Render the subtitle, allowing for editing if editOutput is enabled
	const renderSubtitle = () => {
		return editOutput ? (
			<Editable
				tagName="span"
				style={{ fontWeight: '600', fontStyle: 'italic' }}
				id={title}
				html={editedText}
				onChange={handleChange}
				className="contentEditable"
			/>
		) : (
			<span style={{ fontWeight: '600', fontStyle: 'italic' }} id={title}>
				{convert(editedText)}
			</span>
		);
	};

	// Conditionally render the subtitle based on visibility and preference settings
	return (
		<>
			{isVisible && subTabTitle === 'Show title' && (
				<>
					{renderSubtitle()}
					<span>: </span>
				</>
			)}
		</>
	);
};

export default SubTabTitle;

const Editable = styled(ContentEditable)`
	&:focus-visible {
		outline: 1px solid #37a4fa;
		border-radius: 3px;
	}
`;
