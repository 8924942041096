import React, { useContext, useEffect, useState } from 'react';
import { getNoteTypes, getNoteTypeGroups } from 'services/noteType';

import { AuthContext } from '@note/contexts/AuthContext';

const NoteContext = React.createContext();

export const useNote = () => useContext(NoteContext);

export const NoteProvider = ({ children }) => {
	const { user, isAdmin } = useContext(AuthContext);
	const [noteTypes, setNoteTypes] = useState(); // Note types associated with the user account
	const [noteTypeGroups, setNoteTypeGroups] = useState(); // Grouped note types
	const [activeNoteType, setActiveNoteType] = useState(); // Note type currently open in the app
	const [isCustomizable, setIsCustomizable] = useState(false); // If the current note can be customized
	const [filledPrompt, setFilledPrompt] = useState(''); // Prompt sent to the AI backend from the AI Note
	const [globalAIPrompt, setGlobalAIPrompt] = useState({}); // Prompt sent to the AI backend from the Global AI rewrite modal
	const [selectedStatements, setSelectedStatements] = useState([]); // List of selected statements to render in the Note output
	const [showTermsOfServiceModal, setShowTermsOfServiceModal] = useState(false);
	const [noteImagesUpload, setNoteImagesUpload] = useState([]); // Selected images to upload in the database

	// Check if the current note type is customizable based on the user's role and note ownership
	useEffect(() => {
		if (isAdmin) {
			setIsCustomizable(true);
		} else if (user && activeNoteType) {
			setIsCustomizable(activeNoteType.user === user.id);
		}
	}, [activeNoteType, user, isAdmin]);

	// Fetch note types and note type groups when the user is set
	useEffect(() => {
		(async () => {
			if (user) {
				const fetchedNoteTypes = await getNoteTypes();
				setNoteTypes(fetchedNoteTypes?.data);
				const fetchedNoteTypeGroups = await getNoteTypeGroups();
				setNoteTypeGroups(fetchedNoteTypeGroups?.data);
			}
		})();
	}, [user]);

	return (
		<NoteContext.Provider
			value={{
				activeNoteType,
				noteTypes,
				noteTypeGroups,
				filledPrompt,
				isCustomizable,
				setActiveNoteType,
				setNoteTypes,
				setFilledPrompt,
				selectedStatements,
				setSelectedStatements,
				noteImagesUpload,
				setNoteImagesUpload,
				globalAIPrompt,
				setGlobalAIPrompt,
				showTermsOfServiceModal,
				setShowTermsOfServiceModal,
			}}
		>
			{children}
		</NoteContext.Provider>
	);
};
