import React from 'react';
import { Container, Nav } from 'react-bootstrap';
import styled from 'styled-components';

const colors = ['#4DB6AC', '#9575CD', '#e57373', '#7986CB', '#81C784'];

const LibraryTabNav = ({ tabs, setActiveTab }) => {
	const getColor = i => {
		return i > 4 ? colors[i % 5] : colors[i];
	};

	return (
		<Container fluid>
			<CustomTab variant="tabs">
				<span style={{ display: 'flex' }}>
					{tabs.map((tab, index) => (
						<TabItem key={index} title={tab.name}>
							<TabLink bgcolor={getColor(index)} onClick={() => setActiveTab(tab)} eventKey={tab.id}>
								{tab.name}
							</TabLink>
						</TabItem>
					))}
				</span>
			</CustomTab>
		</Container>
	);
};

export default LibraryTabNav;

const CustomTab = styled(Nav)`
	height: 55px;
	padding: 0 10px;
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	flex-wrap: nowrap;
	flex-direction: row;
`;

const TabItem = styled(Nav.Item)`
	display: flex;
	align-items: flex-end;
	cursor: pointer;
	& > a.active {
		height: 50px;
		align-items: flex-start;
		transition: 0.3s all;
		color: white !important;
	}
`;

const TabLink = styled(Nav.Link)`
	height: 35px;
	padding: 0.5rem 1rem !important;
	display: flex;
	align-items: center;
	font-weight: bold;
	margin-left: 5px;
	background-color: ${props => props.bgcolor} !important;
	color: white;
	position: relative;
	white-space: nowrap;
	&:hover {
		color: white;
		& > div {
			display: flex;
		}
	}
`;
