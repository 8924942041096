import Tippy from '@tippyjs/react';
import React, { useCallback, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { FaSave, FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import { ThemeButton } from 'components/ThemeButton';
import { useNote } from 'contexts/NoteContext';
import { saveNoteType } from 'functions/save-as';

const SaveAs = ({ handleClose, setNewNote: setNewNoteProp, setShowModal: setShowModalProp, saveLabel }) => {
	const [name, setName] = useState('');
	const [isValid, setIsValid] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const { activeNoteType, noteTypes, setNoteTypes } = useNote();

	const openModal = useCallback(() => {
		setShowModalProp?.(true);
	}, [setShowModalProp]);

	const handleSave = useCallback(async () => {
		if (name.length === 0) {
			setIsValid(false);
			return;
		}
		try {
			setIsLoading(true);
			const newNoteType = await saveNoteType(activeNoteType.id, name);
			setNewNoteProp?.(newNoteType);
			setNoteTypes([...noteTypes, newNoteType]);
			setIsLoading(false);
			openModal();
			handleClose();
		} catch (error) {
			console.error('🚀 ~ file: SaveAs.js ~ line 38 ~ handleSave ~ error', error);
		}
	}, [name, activeNoteType, setNewNoteProp, setNoteTypes, noteTypes, openModal, handleClose]);

	return (
		<>
			<Form>
				<Form.Group className="mb-3">
					<Form.Label>New note-type name</Form.Label>
					<Form.Control
						isInvalid={!isValid}
						value={name}
						onChange={e => setName(e.target.value)}
						type="text"
						placeholder="Note name"
						onKeyDown={e => {
							if (e.key === 'Enter') e.preventDefault();
						}}
					/>
				</Form.Group>
			</Form>
			{isLoading && (
				<Alert variant="warning">
					Note: Please wait! this might take a while depending upon the size of the note you are saving.
				</Alert>
			)}
			<ButtonFooter>
				<ThemeButton
					label={!isLoading && saveLabel ? saveLabel : !isLoading ? 'Save' : 'Saving...'}
					size="lg"
					color="green"
					icon={FaSave}
					disabled={isLoading}
					onClick={handleSave}
				/>
				{isLoading ? (
					<Tippy content="Keep in mind that the cancel button is disabled while we're copying your data to a new note!">
						<ThemeButton label="Cancel" icon={FaTimes} size="lg" color="grey" />
					</Tippy>
				) : (
					<ThemeButton label="Cancel" icon={FaTimes} size="lg" color="grey" onClick={handleClose} />
				)}
			</ButtonFooter>
		</>
	);
};

export default SaveAs;

const ButtonFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	border-top: 1px solid #00000010;
	padding-top: 10px;
	gap: 5px;
`;
