import Tippy from '@tippyjs/react';
import { ArrowCircleRight } from '@phosphor-icons/react';
import { useNote } from 'contexts/NoteContext';
import { usePreference } from 'contexts/PreferenceContext';
import { Modal } from 'react-bootstrap';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { FaEye, FaEyeSlash, FaPencilAlt, FaSave, FaSpinner, FaTimes, FaTrashAlt } from 'react-icons/fa';
import { IoMdHeart, IoMdHeartDislike } from 'react-icons/io';
import { toast } from 'react-toastify';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { hideStatement, likeStatement } from 'services/statement';
import styled from 'styled-components';
import { green, grey, blue, red, black } from 'styles/colors';
import { WidgetType } from 'utils/enum';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';
import { v4 as uuid } from 'uuid';

import { isDesignPageState, statementsStateSelector } from '@note/atoms';
import { AuthContext } from '@note/contexts/AuthContext';
import { ListSelection, ManualInput, MultipleChoice, PrefilledInput, PSO } from '@note/layouts/note-selector/widgets';

import HideStatementConfirmation from './HideStatementConfirmation';
import { ThemeButton } from '@note/components/ThemeButton';

// This handles all of the actions that can be done on a stattement
const WidgetWrapper = ({
	deleteStatement,
	statement,
	filteredSections,
	saveStatement,
	subTabType,
	subTabSettings,
	filteredStems,
}) => {
	const { isCustomizable } = useNote();
	const { user, isAdmin, isOldSafari } = useContext(AuthContext);
	const { globalPreferences } = usePreference();
	const isDesignPage = useRecoilValue(isDesignPageState);

	const [resetKey, setResetKey] = useState(0);
	const [isHovered, setIsHovered] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showEditAlert, setShowEditAlert] = useState(false);
	const [editMode, setEditMode] = useState(!!statement?.isNew);
	const [statementText, setStatementText] = useState(statement.name);
	const [placeholderText, setPlaceholderText] = useState(statement.placeholder);
	const [prefilledText, setPrefilledText] = useState(statement?.prefilled_text);
	const [showHideConfirmation, setShowHideConfirmation] = useState(false);
	const setStatement = useSetRecoilState(statementsStateSelector);
	const openHideConfirmation = () => setShowHideConfirmation(true);
	const closeHideConfirmation = () => setShowHideConfirmation(false);

	const openEditAlert = () => setShowEditAlert(true);
	const closeEditAlert = () => setShowEditAlert(false);

	// Check if the widget is not a list type
	const isNotList =
		subTabType !== WidgetType.LIST_SELECT &&
		subTabType !== WidgetType.LIST_MULTI_SELECT &&
		subTabType !== WidgetType.PSO &&
		subTabType !== WidgetType.PS;

	// Mapping of widget types to their respective components
	const WIDGET_COMPONENTS = {
		[WidgetType.MANUAL_INPUT]: props => <ManualInput {...props} />,
		[WidgetType.LIST_SELECT]: props => <ListSelection {...props} toggleEdit={toggleEditMode} />,

		[WidgetType.LIST_MULTI_SELECT]: props => <ListSelection {...props} toggleEdit={toggleEditMode} />,
		[WidgetType.PSO]: props => <PSO {...props} toggleEdit={toggleEditMode} />,
		[WidgetType.PS]: props => <PSO {...props} toggleEdit={toggleEditMode} />,
		[WidgetType.MULTIPLE_CHOICE]: props => <MultipleChoice {...props} key={resetKey} />,
		[WidgetType.PREFILLED_INPUT]: props => <PrefilledInput {...props} />,
	};

	const toggleEditMode = useCallback(event => {
		setIsHovered(true);
		setEditMode(editMode => !editMode);
	}, []);

	// Function to discard changes and reset state
	const discardChanges = event => {
		setResetKey(uuid());
		setStatementText(statement.name);
		setPlaceholderText(statement.placeholder);
		(!statement?.id || statement.isNew) && removeNewStatement();
		setEditMode(false);
	};

	const handleCancelClick = event => {
		event.stopPropagation();
		discardChanges();
	};
	const handleSave = event => {
		event.stopPropagation();
		setIsLoading(true);
		saveStatement({
			...statement,
			name: statementText,
			placeholder: placeholderText,
			prefilled_text: prefilledText,
		});
		setIsLoading(false);
		setEditMode(false);
	};

	// Function to toggle like status of the statement
	const toggleLike = async event => {
		event.stopPropagation();
		try {
			await likeStatement(statement.id);
			setStatement({ ...statement, likes: !statement.likes });
		} catch (err) {
			console.error(err);
		}
	};

	// Function to toggle hide status of the statement
	const toggleHide = async event => {
		try {
			await hideStatement(statement.id);
			setStatement({ ...statement, hidden: !statement.hidden });
			!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.SUCCESS);
			toast.success(`Statement was ${statement.hidden ? 'unhidden' : 'hidden'}`, { hideProgressBar: true });
		} catch (err) {
			console.error(err);
		}
	};

	// Function to check if delete is allowed for the statement
	const isStatementDeleteAllowed = useCallback(
		statement => {
			if (isAdmin) return true;
			else if (user && statement) return statement.user === user.id;
		},
		[isAdmin, user]
	);

	// Determine if delete action is allowed
	const isDeleteAllowed = useMemo(
		() => () => (isCustomizable ? isCustomizable : isStatementDeleteAllowed(statement)),
		[isCustomizable, isStatementDeleteAllowed, statement]
	);

	const handleHideClick = event => {
		event.stopPropagation();
		if (statement.hidden) {
			toggleHide();
		} else {
			openHideConfirmation();
		}
	};

	const handleDelete = event => {
		event.stopPropagation();
		deleteStatement();
	};

	const removeTemporaryStatement = event => {
		event.stopPropagation();
		setStatement({ remove: true, id: statement.id });
	};

	// Opens an alert modal if the user tries to permanently edit a Prefilled statement while not in designer mode
	const EditAlert = () => {
		return (
			<Modal show={showEditAlert} onHide={() => closeEditAlert()} centered size="md" backdropClassName="backdrop-blur">
				<Modal.Body>
					You can make temporary modifications by simply typing in the box; for permanent edits go to "Customize This
					Note."
				</Modal.Body>
				<Modal.Footer>
					<ThemeButton size="lg" fontSize="lg" color="green" onClick={() => closeEditAlert()} label="ok" />
				</Modal.Footer>
			</Modal>
		);
	};

	const handleEdit = event => {
		event.stopPropagation();
		if (subTabType === WidgetType.PREFILLED_INPUT && !isDesignPage) {
			openEditAlert();
			return;
		}
		toggleEditMode();
	};

	const isSmall = useMemo(() => {
		return globalPreferences.ButtonSize === 'Smaller';
	}, [globalPreferences.ButtonSize]);

	const removeNewStatement = useCallback(() => {
		setStatement({ remove: true, ...statement });
	}, [statement, setStatement]);

	// Props to pass to widget components
	const props = {
		statement: statement,
		statementText: statementText,
		placeholderText: placeholderText,
		setStatementText: setStatementText,
		setPlaceholderText: setPlaceholderText,
		prefilledText: prefilledText,
		setPrefilledText: setPrefilledText,
		editMode: editMode,
		filteredStems: filteredStems,
		filteredSections: filteredSections,
		type: subTabType,
		isListLinesSingle: subTabSettings?.listSelect?.isListLinesSingle,
		settings: subTabSettings,
	};

	return (
		<Wrapper isHovered={isHovered} subTabType={subTabType}>
			<span style={{ opacity: statement.hidden ? '0.4' : '1' }}>
				{(subTabType === WidgetType.PREFILLED_INPUT || subTabType === WidgetType.MANUAL_INPUT) && isDesignPage && (
					<>
						<WarningMessage className="text-widget-warning-message">
							Be sure to start with pencil icon if you want to save your changes
						</WarningMessage>
						<div className="warning-placeholder">
							<br />
						</div>
					</>
				)}
				{WIDGET_COMPONENTS[subTabType](props)}
			</span>

			{isNotList && statement.likes && (
				<LikeContainer>
					<IoMdHeart />
				</LikeContainer>
			)}
			<ButtonContainer
				isSmall={isSmall}
				editMode={editMode}
				onMouseLeave={() => !editMode && setIsHovered(false)}
				onMouseEnter={() => setIsHovered(true)}
				className="btn-container"
			>
				{editMode ? (
					<>
						{!isNotList && (
							<>
								{isDeleteAllowed() && (
									<Tippy content="Delete statement">
										<ActionBtn
											isSmall={isSmall}
											valid={true}
											color={red}
											onClick={statement.isTemporary ? removeTemporaryStatement : handleDelete}
										>
											<FaTrashAlt />
										</ActionBtn>
									</Tippy>
								)}
								{!statement.isTemporary && (
									<Tippy content="Hide/Unhide statement">
										<ActionBtn isSmall={isSmall} valid={true} color={black} onClick={handleHideClick}>
											{statement.hidden ? <FaEye /> : <FaEyeSlash />}
										</ActionBtn>
									</Tippy>
								)}
							</>
						)}
						<Tippy content="Save statement">
							<ActionBtn
								isSmall={isSmall || statement.isTemporary}
								valid={statementText.length > 0 || subTabType === WidgetType.MANUAL_INPUT}
								color={green}
								onClick={handleSave}
							>
								{isLoading ? (
									<FaSpinner className="spinner" />
								) : statement.isTemporary ? (
									<ArrowCircleRight size={25} />
								) : (
									<FaSave />
								)}
							</ActionBtn>
						</Tippy>
						<Tippy content="Discard changes">
							<ActionBtn isSmall={isSmall} valid={true} color={grey} onClick={handleCancelClick}>
								<FaTimes />
							</ActionBtn>
						</Tippy>
					</>
				) : (
					isNotList && (
						<>
							{isDeleteAllowed() && (
								<Tippy content="Delete statement">
									<ActionBtn
										isSmall={isSmall}
										valid={true}
										color={red}
										onClick={statement.isTemporary ? removeTemporaryStatement : handleDelete}
									>
										<FaTrashAlt />
									</ActionBtn>
								</Tippy>
							)}
							{!statement.isTemporary && (
								<>
									<Tippy content="Hide/Unhide statement">
										<ActionBtn isSmall={isSmall} valid={true} color={grey} onClick={handleHideClick}>
											{statement.hidden ? <FaEye /> : <FaEyeSlash />}
										</ActionBtn>
									</Tippy>
									<Tippy content="Like/Unlike statement">
										<ActionBtnLike isSmall={isSmall} valid={true} color={red} onClick={toggleLike}>
											{statement.likes ? <IoMdHeartDislike /> : <IoMdHeart />}
										</ActionBtnLike>
									</Tippy>
									<Tippy content="Edit statement">
										<ActionBtn isSmall={isSmall} valid={true} color={blue} onClick={handleEdit}>
											<FaPencilAlt />
										</ActionBtn>
									</Tippy>
								</>
							)}
						</>
					)
				)}
			</ButtonContainer>
			{showEditAlert && <EditAlert />}
			{showHideConfirmation && (
				<HideStatementConfirmation
					show={showHideConfirmation}
					handleClose={closeHideConfirmation}
					hideStatement={toggleHide}
					name={statement.name}
				/>
			)}
		</Wrapper>
	);
};

export default WidgetWrapper;

const Wrapper = styled.div`
	width: 100%;
	position: relative;
	${props => (props.subTabType !== WidgetType.PREFILLED_INPUT ? 'cursor: pointer;' : '')}
	border: 1px solid transparent;

	&:hover div.btn-container {
		display: block;
	}

	&:hover span.text-widget-warning-message {
		display: inline;
	}
	&:hover div.warning-placeholder {
		display: none;
	}
	${props => (props.editMode ? 'border: 1px solid navy;' : '')}

	&:hover {
		${props =>
			props.subTabType !== WidgetType.PREFILLED_INPUT &&
			props.subTabType !== WidgetType.MULTIPLE_CHOICE &&
			props.subTabType !== WidgetType.PSO &&
			props.subTabType !== WidgetType.PS
				? 'border: 1px solid navy;'
				: ''}
	}
`;

const ButtonContainer = styled.div`
	display: ${props => (props.editMode ? 'block' : 'none')};
	position: absolute;
	bottom: ${props => (props.isSmall ? '-27px' : '-30px')};
	right: 0;
	z-index: 1;
`;

const WarningMessage = styled.span`
	display: none;
	font-size: 12px;
	background: ${red};
	color: white;
	padding: 1px 3px;
	border-radius: 10px;
`;

const ActionBtn = styled.button`
	position: relative;
	border: none;
	background: ${props => props.color};
	color: white;
	padding: ${props => (props.isSmall ? '2px 7px 3px 7px' : '3px 10px 6px')};
	margin-right: ${props => (props.isSmall ? '3px' : '5px')};
	border-radius: 3px;

	&:hover {
		transform: scale(1.1);
	}

	${props =>
		!props.valid &&
		`
		opacity: 0.6;
		pointer-events: none;
    `}
`;

const ActionBtnLike = styled(ActionBtn)`
	color: #dc3545;
	background: #ffe496;
`;

const LikeContainer = styled.div`
	position: absolute;
	right: 3px;
	bottom: 0;
	height: 20px;
	width: 20px;
	background: #f3f4f7;
	color: red;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
`;
