import React, { useCallback, useMemo } from 'react';
import { Nav } from 'react-bootstrap';
import styled from 'styled-components';
import { navy } from 'styles/colors';
import LibrarySectionList from './LibrarySectionList';

const LibrarySubTabNavItem = ({ subTab, librarySections, index, setActiveSubTab, activeLibrarySection, setActiveLibrarySection }) => {
	const filteredSections = useMemo(() => librarySections.filter(section => section.subtab === subTab.id), [subTab, librarySections]);

	const handleSubTabClick = useCallback(() => {
		setActiveSubTab(subTab);
		setActiveLibrarySection(filteredSections[0]);
	}, [filteredSections, setActiveLibrarySection, setActiveSubTab, subTab]);

	return (
		<NavItem key={index} title={subTab.name}>
			<NavLink eventKey={subTab.id} onClick={handleSubTabClick}>
				{subTab.name}
			</NavLink>
			<LibrarySectionList
				subTab={subTab}
				filteredSections={filteredSections}
				setActiveSubTab={setActiveSubTab}
				activeLibrarySection={activeLibrarySection}
				setActiveLibrarySection={setActiveLibrarySection}
			/>
		</NavItem>
	);
};

export default LibrarySubTabNavItem;

const NavItem = styled(Nav.Item)`
	height: auto !important;
	& > a.active {
		background: ${navy} !important;
	}
`;

const NavLink = styled(Nav.Link)`
	position: relative;
	color: black;
	cursor: pointer;
	font-weight: 600;
	font-size: 15px;
	padding: 0.5rem 1rem !important;
	&:hover {
		color: black;
		background: #f9f9f9;
		& > div {
			display: flex;
		}
	}
`;
