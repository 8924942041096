const FontChoice = ({ fonts, selectedFont, onFontChange }) => {
  const handleFontChange = (font) => {
    onFontChange(font);
  };

  return (
    <div className="choice-format-fonts">
      {fonts.map((font) => (
        <button
          key={font}
          className={selectedFont === font ? "active" : ""}
          type="button"
          onClick={() => handleFontChange(font)}
        >
          {font}
        </button>
      ))}
    </div>
  );
};

export default FontChoice;

