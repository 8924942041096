import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { Alert, Tab } from 'react-bootstrap';
import styled from 'styled-components';

import LibraryTabNav from '../../library-selector/tab-nav/LibraryTabNav';
import LibraryTab from '../../library-selector/tab/LibraryTab';

const LibrarySelector = ({
	libraryTabs,
	librarySubTabs,
	librarySections,
	libraryStatements,
	setLibraryStatements,
	libraryStems,
	statements,
}) => {
	const [activeTab, setActiveTab] = useState(libraryTabs[0]);

	if (libraryTabs.length === 0) {
		return null;
	}

	return (
		<LeftPane>
			<Alert>
				For some sections, we have additional phrases you can import into your current note-type. Select phrases you
				want to use, click the IMPORT button, and they will be added.
			</Alert>
			<LeftPaneWrapper>
				<Tab.Container activeKey={activeTab ? activeTab.id : libraryTabs[0].id}>
					<LibraryTabNav tabs={libraryTabs} setActiveTab={setActiveTab} />
					<TabContent>
						<AnimatePresence mode="wait">
							{libraryTabs.map(
								(tab, index) =>
									tab.id === activeTab.id && (
										<motion.div
											style={{ height: '100%' }}
											key={index}
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											exit={{ opacity: 0 }}
											transition={{ duration: 0.2 }}
										>
											<TabPane key={index} eventKey={tab.id}>
												<LibraryTab
													tab={tab}
													librarySubTabs={librarySubTabs}
													librarySections={librarySections}
													setLibraryStatements={setLibraryStatements}
													libraryStatements={libraryStatements}
													libraryStems={libraryStems}
													statements={statements}
												/>
											</TabPane>
										</motion.div>
									)
							)}
						</AnimatePresence>
					</TabContent>
				</Tab.Container>
			</LeftPaneWrapper>
		</LeftPane>
	);
};

export default LibrarySelector;

const LeftPane = styled.div`
	width: 100%;
	height: 100%;
	background: #f1f1f1;
	padding: 15px 7px 15px 15px;
`;

const LeftPaneWrapper = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 10px;
	background: white;
`;

const TabPane = styled(Tab.Pane)`
	height: 100%;
`;

const TabContent = styled(Tab.Content)`
	padding: 20px;
	height: calc(100% - 55px);
`;
