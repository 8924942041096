import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

// The innermost element of the statemnt component
const StatementInput = ({ isSingleLine = true, statementText, setStatementText, editMode }) => {
	const inputRef = useRef();

	useEffect(() => {
		if (editMode) inputRef.current.focus();
	}, [editMode]);

	return isSingleLine ? (
		<StatementTextInput
			ref={inputRef}
			valid={statementText.length > 0}
			value={statementText}
			onClick={e => e.stopPropagation()}
			onChange={e => setStatementText(e.target.value)}
		/>
	) : (
		<StatementTextArea
			ref={inputRef}
			valid={statementText.length > 0}
			value={statementText}
			onClick={e => e.stopPropagation()}
			onChange={e => setStatementText(e.target.value)}
		/>
	);
};

export default StatementInput;

const StatementTextInput = styled.input`
	width: 100%;
	border-radius: 5px;
	border: 1px solid ${props => (props.valid ? '#212529' : '#dc3545')};
	outline: none;
`;

const StatementTextArea = styled.textarea`
	width: 100%;
	border-radius: 5px;
	border: 1px solid ${props => (props.valid ? '#212529' : '#dc3545')};
	outline: none;
`;
