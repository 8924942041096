import React from 'react';
import styled from 'styled-components';

import { navy } from 'styles/colors';

import { multiChoiceData, multiChoiceOptionsData } from '../../example-data';

const MultipleChoicePreview = () => {
	return (
		<>
			{multiChoiceData.map((item, index) => (
				<StatementContainer key={index}>
					<Statement width="100%"> {item.name.split('$')[0]}</Statement>
					<List>
						{multiChoiceOptionsData.map((option, optionindex) => (
							<ListItem key={optionindex} isSelected={index === optionindex}>
								{option}
							</ListItem>
						))}
					</List>
					<Statement>{item.name.split('$')[1]}</Statement>
				</StatementContainer>
			))}
		</>
	);
};

export default MultipleChoicePreview;

const Statement = styled.div`
	font-weight: bold;
`;

const StatementContainer = styled.div`
	width: 100%;
	margin-bottom: 15px;
	padding: 10px;
	width: 100%;
	position: relative;
	background: #f1f1f1;
`;

const ListItem = styled.li`
	padding: 7px 15px;
	display: inline-block;
	background: ${props => (props.isSelected ? navy : '#fff')};
	color: ${props => (props.isSelected ? '#fff' : '#000')};
	margin-right: 5px;
	margin-bottom: 5px;
	border-radius: 5px;
	cursor: pointer;
	&:hover {
		background: ${navy};
		color: #fff;
	}
`;

const List = styled.ul`
	list-style: none;
	padding: 0;
	margin: 7px 0;
`;
