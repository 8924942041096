import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { ThemeButton } from '@note/components/ThemeButton';
import AISettings from '../layouts/note-selector/aiSettings/AISettings';

const AiSettingsModal = ({ show, handleClose, onCancel, onSubmit }) => {
	const handleCancel = () => {
		onCancel();
		handleClose();
	};

	const handleAccept = () => {
		onSubmit();
	};

	const renderAiSettingsForm = () => {
		return (
			<div style={{ padding: '0 1rem' }}>
				<AISettings />
				<div style={{ marginTop: '1rem' }}>
					<b>Consent for using Note Designer's AI functions.</b>
					<p style={{ marginTop: 10 }}>
						I confirm that identifying PHI appears only in the Headings tab not in any other customized or text fields.
					</p>
				</div>
			</div>
		);
	};

	const renderShortForm = () => {
		return renderAiSettingsForm();
	};

	return (
		<StyledModal
			style={{ border: '2px solid grey', zIndex: '9000' }}
			show={show}
			onHide={() => handleClose(true)}
			backdrop="static"
			scrollable
			centered
			size="lg"
			backdropClassName="backdrop-blur"
			keyboard={false}
		>
			<Modal.Header>
				<Modal.Title>AI settings</Modal.Title>
			</Modal.Header>
			<Modal.Body>{renderShortForm()}</Modal.Body>
			<Modal.Footer>
				<ConsentButtons>
					<ThemeButton label={'Confirm and submit to AI'} size="lg" color="green" onClick={handleAccept} />

					<CancelButton>
						<ThemeButton label={'Close'} size="lg" color="red" onClick={handleCancel} />
					</CancelButton>
				</ConsentButtons>
			</Modal.Footer>
		</StyledModal>
	);
};

export default AiSettingsModal;

const StyledModal = styled(Modal)`
	& .modal-content {
		border: 2px solid gray;
	}
`;

const CancelButton = styled('div')`
	margin-left: 1rem;
`;

const ConsentButtons = styled('div')`
	display: flex;
	flex-direction: row;
`;
