import React, { useState, useEffect } from 'react';
import { TourProvider } from '@reactour/tour';
import { FaTimes } from 'react-icons/fa';
import { useSetRecoilState } from 'recoil';
import { designTourState } from '@note/atoms';
import styled from 'styled-components';

// Tour accessed via the "Start tour" link in the new note modal
const TourManager = ({ steps, children, isDesignTour }) => {
	// Recoil state to manage the design tour state
	const setisDesignTourState = useSetRecoilState(designTourState);
	const [currentStep, setCurrentStep] = useState(0);

	// Determine specific tour steps
	const tourStepTwelve = currentStep !== 12 && !isDesignTour;
	const tourStepNinteen = currentStep !== 19 && !isDesignTour;

	// Effect to manage tour steps and event listeners
	useEffect(() => {
		const advanceStep = () => {
			setCurrentStep(prev => prev + 1);
		};

		const eventListener = e => {
			// Handle click events for specific tour steps
			if (steps[currentStep]?.type === 'click' && e.target.matches(steps[currentStep].selector)) {
				e.target.__typingTimeout = setTimeout(advanceStep, 300);
			}

			if (
				steps[currentStep]?.type === 'click' &&
				(e.target.closest('.first-tab-nav') ||
					e.target.closest('.create-button') ||
					e.target.closest('.subjective-tab') ||
					e.target.closest('.add-tab-btn') ||
					e.target.closest('.new-tab') ||
					e.target.closest('.add-subtab'))
			) {
				e.target.__typingTimeout = setTimeout(advanceStep, 300);
			}

			// Handle click events for common elements
			if (
				e.target.closest('.ras-subtab') ||
				e.target.closest('.discard-button') ||
				e.target.closest('.save-button') ||
				e.target.closest('.clear-confirm')
			) {
				advanceStep();
			}

			// Handle input events for specific tour steps
			if (steps[currentStep]?.type === 'input' && e.target.matches(steps[currentStep].selector)) {
				clearTimeout(e.target.__typingTimeout);
				e.target.__typingTimeout = setTimeout(advanceStep, 1000);
			}

			// Handle select events for specific tour steps
			if (steps[currentStep]?.type === 'select' && e.target.closest('.heading-option')) {
				advanceStep();
			}
		};

		document.addEventListener('click', eventListener);
		document.addEventListener('input', eventListener);

		return () => {
			document.removeEventListener('click', eventListener);
			document.removeEventListener('input', eventListener);
		};
	}, [currentStep, setCurrentStep, steps]);

	// Custom close button for the tour
	const CloseTourButton = ({ onClick }) => (
		<CloseButton style={{ cursor: 'pointer', position: 'absolute', right: 0, top: 0 }} onClick={onClick}>
			<>
				End Tour
				<FaTimes style={{ marginTop: -2, marginLeft: 5 }} />
			</>
		</CloseButton>
	);

	return (
		<TourProvider
			steps={steps}
			currentStep={currentStep}
			onClickClose={({ setIsOpen }) => {
				setisDesignTourState(false);
				setIsOpen(false);
			}}
			setCurrentStep={setCurrentStep}
			showPrevNextButtons={steps[currentStep]?.type === 'click' || !steps[currentStep]?.type}
			disableKeyboardNavigation={!!steps[currentStep]?.type}
			showDots={false}
			badgeContent={props => {
				const { currentStep, totalSteps } = props;
				return (
					<>
						{currentStep + 1} / {totalSteps}
					</>
				);
			}}
			padding={{ mask: 10 }}
			components={{ CloseTourButton }}
			styles={{
				badge: base => ({
					...base,
					bottom: '-0.8125em',
					top: 'unset',
					left: 'unset',
					right: '50%',
					transform: 'translateX(50%)',
				}),
				mask: base => ({ ...base, opacity: 0.4 }),
				popover: base => ({
					...base,
					top: 0,
					right: 0,
					minWidth: steps[currentStep]?.customControl === true ? 550 : 'unset',
				}),
			}}
			onClickMask={() => {}}
			disableInteraction={steps[currentStep]?.disableInteraction}
			nextButton={props => {
				const { setCurrentStep, stepsLength, setIsOpen } = props;
				if (steps[currentStep]?.customControl === true) {
					return <PopoverButton onClick={() => setIsOpen(false)}>End the tour</PopoverButton>;
				} else if (steps[currentStep]?.type === 'click') {
					return null;
				} else if (steps[currentStep]?.tourStart === true) {
					return <PopoverButton onClick={() => setCurrentStep(prev => prev + 1)}>Start My Tour!</PopoverButton>;
				} else if (currentStep === stepsLength - 1) {
					return <PopoverButton onClick={() => setIsOpen(false)}>End the tour</PopoverButton>;
				}
				return <PopoverButton onClick={() => setCurrentStep(prev => prev + 1)}>Next</PopoverButton>;
			}}
			prevButton={({ setCurrentStep, currentStep }) => {
				if (steps[currentStep]?.customControl === true) {
					return (
						<PopoverButton onClick={() => setCurrentStep(currentStep + 1)}>
							Continue to Advanced Customization Tour
						</PopoverButton>
					);
				}
				return currentStep === 0 ? (
					<span></span>
				) : currentStep > 0 && tourStepTwelve && tourStepNinteen ? (
					<PopoverButton onClick={() => setCurrentStep(currentStep - 1)}>Previous</PopoverButton>
				) : (
					<span></span>
				);
			}}
		>
			{children}
		</TourProvider>
	);
};

const CloseButton = styled.button`
	color: white;
	background: grey;
	border: none;
	border-top-right-radius: 6px;
	border-bottom-left-radius: 6px;
`;

const PopoverButton = styled.button`
	background: none;
	border: none;
`;

export default TourManager;
