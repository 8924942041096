import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTour } from '@reactour/tour';
import styled from 'styled-components';
import { Textbox, PlusCircle, Cursor } from '@phosphor-icons/react';
import VideoLinkButton from 'layouts/nav/VideoLinkButton';
import { useSetRecoilState } from 'recoil';
import { designTourState } from '@note/atoms';

import { ThemeButton } from '@note/components/ThemeButton';

const DesignPageOpeningMessage = ({ isTourPage }) => {
	const setisDesignTourState = useSetRecoilState(designTourState);
	const [showModal, setShowModal] = useState(true);
	const { setIsOpen } = useTour();

	const handleButtonCLick = () => {
		setIsOpen(true);
		setisDesignTourState(true);
		setShowModal(false);
	};

	const ActionItem = ({ icon: Icon, title, text }) => {
		return (
			<ActionItemContainer>
				<Icon size={'3rem'} />
				<b style={{ marginTop: '1rem', marginBottom: '1rem' }}>{title}</b>
				<div style={{ textAlign: 'center' }}>{text}</div>
			</ActionItemContainer>
		);
	};

	return (
		<StyledModal
			style={{ border: '2px solid grey' }}
			backdrop="static"
			scrollable
			centered
			size="lg"
			backdropClassName="backdrop-blur"
			show={showModal}
			onHide={() => setShowModal(false)}
		>
			<ModalBody>
				<h3 style={{ marginBottom: '1rem' }}>Customize your note</h3>
				This is the Design Space where you can make major changes to your note-type templates to fit your unique needs.
				<div>
					<ItemsList>
						<ItemsList>
							<ActionItem icon={Textbox} title="Rename" text="Edit the names of Tabs and Subtabs" />
							<ActionItem icon={PlusCircle} title="Create" text="Add new Tabs, Subtabs, or Categories" />
							<ActionItem icon={Cursor} title="Drag & Drop" text="Move and reorganize anything you need" />
						</ItemsList>
					</ItemsList>
					<b>
						To learn about all our advanced customization features, we recommend watching our detailed{' '}
						<VideoLinkButton videoType="intro" label="video tutorial" useLinkStyle />
					</b>
				</div>
			</ModalBody>

			<Modal.Footer>
				{isTourPage && (
					<ThemeButton label="Take a Design Space Tour" size="lg" color="green" onClick={() => handleButtonCLick()} />
				)}
				<ButtonContainer>
					<ThemeButton label="Start customizing" size="lg" color="blue" onClick={() => setShowModal(false)} />
				</ButtonContainer>
			</Modal.Footer>
		</StyledModal>
	);
};

export default DesignPageOpeningMessage;

const ModalBody = styled(Modal.Body)`
	padding: 2rem;
	overflow: hidden !important;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const StyledModal = styled(Modal)`
	& .modal-content {
		border: 2px solid gray;
	}
`;

const ItemsList = styled.div`
	margin: 1.5rem 0.5rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const ActionItemContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
