import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTour } from '@reactour/tour';
import styled from 'styled-components';

import { ThemeButton } from '@note/components/ThemeButton';

const TourModal = () => {
	const [showModal, setShowModal] = useState(true);
	const { setIsOpen } = useTour();

	const handleButtonCLick = () => {
		setIsOpen(true);
		setShowModal(false);
	};

	return (
		<StyledModal
			style={{ border: '2px solid grey' }}
			show={showModal}
			onHide={() => setShowModal(false)}
			backdrop="static"
			scrollable
			centered
			size="lg"
			backdropClassName="backdrop-blur"
		>
			<Modal.Body>
				Welcome to our "Take A Tour" note, which will give a quick overview of how to complete a typical note.
			</Modal.Body>

			<Modal.Footer>
				<ThemeButton label="Start my Tour!" size="lg" color="green" onClick={() => handleButtonCLick()} />
			</Modal.Footer>
		</StyledModal>
	);
};

export default TourModal;

const StyledModal = styled(Modal)`
	& .modal-content {
		border: 2px solid gray;
	}
`;
