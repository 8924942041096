import React from 'react';
import { usePreference } from 'contexts/PreferenceContext';

const OpeningHeading = () => {
    const { multiLevelPreferences } = usePreference();
    const { openingHeading } = multiLevelPreferences;
    return (
        <>
            {openingHeading !== 'no heading' && (
                <>
                    <p style={{ margin: 0, lineHeight: 1 }}>{openingHeading}</p>
                    <br />
                </>
            )}
        </>
    )
}

export default React.memo(OpeningHeading);