/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { defaultGlobalPreferences } from 'constants';
import PreferenceInput from '../note-preferences/PreferenceInput';
import PreferenceOptions from '../note-preferences/PreferenceOptions';

// Component to handle individual preference setting
const Preference = ({
	isInputType = false, // Determines if the preference is an input type
	preferenceOptions, // Options for the preference
	preferenceKey, // Key to identify the preference
	filteredPreferences, // Current list of filtered preferences
	setFilteredPreferences, // Function to update the filtered preferences
}) => {
	// Memoized function to get the preference value
	const preferenceValue = useMemo(() => {
		const preference = filteredPreferences?.find(
			pref =>
				pref.key === preferenceKey &&
				pref.note_type === null &&
				pref.subtab === null &&
				pref.section === null &&
				pref.tab === null
		);
		return preference && preference !== -1 ? preference.value : defaultGlobalPreferences[preferenceKey];
	}, [filteredPreferences, preferenceKey]);

	// Handle option click
	const handleClick = prefOption => {
		updatePreferenceValue(prefOption);
	};

	// Handle input change
	const handleChange = event => {
		const value = event.target.value;
		if (value === '') {
			setFilteredPreferences(filteredPreferences =>
				filteredPreferences.filter(
					({ key, isNew, note_type }) => !(key === preferenceKey && isNew && note_type === null)
				)
			);
		}
		updatePreferenceValue(value);
	};

	// Update preference value
	const updatePreferenceValue = value => {
		const changedPreference = filteredPreferences?.find(
			pref =>
				pref.key === preferenceKey &&
				pref.note_type === null &&
				pref.subtab === null &&
				pref.section === null &&
				pref.tab === null
		);
		if (changedPreference && changedPreference !== -1) {
			if (!changedPreference.isNew) {
				setFilteredPreferences(
					filteredPreferences.map(pref =>
						pref.key === preferenceKey &&
						pref.note_type === null &&
						pref.tab === null &&
						pref.subtab === null &&
						pref.section === null
							? { ...pref, value: value, isChanged: true }
							: pref
					)
				);
			} else {
				setFilteredPreferences(
					filteredPreferences.map(pref =>
						pref.key === preferenceKey &&
						pref.note_type === null &&
						pref.tab === null &&
						pref.subtab === null &&
						pref.section === null
							? { ...pref, value: value }
							: pref
					)
				);
			}
		} else {
			setFilteredPreferences([
				...filteredPreferences,
				{
					key: preferenceKey,
					value: value,
					note_type: null,
					tab: null,
					subtab: null,
					section: null,
					isNew: true,
				},
			]);
		}
	};

	return isInputType ? (
		<PreferenceInput
			selectedPreference={{ value: preferenceValue }}
			handleChange={handleChange}
			handleReset={() => {}}
		/>
	) : (
		<PreferenceOptions
			preferenceOptions={preferenceOptions}
			selectedPreference={{ value: preferenceValue }}
			handleClick={handleClick}
			handleReset={() => {}}
		/>
	);
};

export default Preference;
