import React, { useCallback, useState } from 'react';
import { Modal, Alert } from 'react-bootstrap';
import NoteReadyModal from 'features/save-as/NoteReadyModal';
import SaveAs from 'features/save-as/SaveAs';

// Confirm modal shown when clicking "Customize this note" on a default note
export const DesignNoteMessageModal = ({ show, handleClose }) => {
	const [newNote, setNewNote] = useState(null);
	const [showModal, setShowModal] = useState();

	const closeModal = useCallback(() => setShowModal(false), []);

	return (
		<>
			<Modal show={show} onHide={handleClose} backdropClassName="backdrop-blur">
				<Modal.Header closeButton>
					<Modal.Title>Customize This Note</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>To start editing your note template, you must first save a copy with a new name (e.g., My Basic Note).</p>
					<Alert variant="warning" style={{ borderWidth: 2 }}>
						<p>CAUTION</p>
						<p>
							If you proceed, you will lose any inputs and statement selections you have made in your current note.
							[Click "Cancel" to return to your current note instead]
						</p>
					</Alert>
					<SaveAs
						handleClose={handleClose}
						setNewNote={setNewNote}
						setShowModal={setShowModal}
						saveLabel="Create custom note"
					/>
				</Modal.Body>
			</Modal>
			{showModal && <NoteReadyModal newNote={newNote} show={showModal} handleClose={closeModal} />}
		</>
	);
};
