import React from 'react';
import { Modal } from 'react-bootstrap';
import { ThemeButton } from 'components/ThemeButton';

const CopyModal = ({ show, handleClose }) => {

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Copy</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>In your particular browser, you copy a note by FIRST highlighting the text.</p>
				<p>SECOND, you use your usual browser commands for copying. Depending on your browser,
					you then could use the Edit/Copy menu, for example. Or on Windows you might use
					ctrl-c (pressing the 'control' key and c key at the same time), or right-click on
					the highlighted area with the mouse and choose Copy. On Macs you could use command-c
					and in iPad you press the note and choose Copy.</p>
				<p>Then you can paste the note into a word processor document, much as you would paste
					anything you have copied.</p>
			</Modal.Body>
			<Modal.Footer>
				<ThemeButton label='Close' color='grey' onClick={handleClose} />
			</Modal.Footer>
		</Modal>
	);
};

export default CopyModal;
