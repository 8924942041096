import { fetch } from 'utils/fetch';

const getAdminPreferences = note_type =>
	fetch({
		endpoint: `notes/preferences/?note_type=${note_type}`,
		method: 'get',
		errorLabel: 'getAdminPreferences',
	});

const getUserPreferences = () =>
	fetch({
		endpoint: `/notes/preferences/`,
		method: 'get',
		errorLabel: 'getUserPreferences',
	});

const createPreference = preference =>
	fetch({
		endpoint: `/notes/preferences/`,
		method: 'post',
		payload: preference,
		errorLabel: 'createPreference',
	});

const updatePreference = ({ id, value }) =>
	fetch({
		endpoint: `/notes/preferences/${id}/`,
		method: 'patch',
		payload: { value },
		errorLabel: 'updatePreference',
	});

const deletePreference = id =>
	fetch({
		endpoint: `/notes/preferences/${id}/`,
		method: 'delete',
		errorLabel: 'deletePreference',
	});

export { getAdminPreferences, getUserPreferences, createPreference, updatePreference, deletePreference };
