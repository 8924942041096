import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { imagesState } from '@note/atoms';
import ImageWithCaption from './ImageWithCaption';

const Images = (useSizes = false) => {
	const [images, setImagesState] = useRecoilState(imagesState);
	const [base64Images, setBase64Images] = useState([]);

	// Function to convert a Blob object to a base64 encoded string
	async function convertBlobToBase64(blob) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			// On successful read, resolve the promise with the base64 string
			reader.onload = function (e) {
				resolve(e.target.result);
			};

			// On read error, reject the promise with an error message
			reader.onerror = function (e) {
				reject(new Error('Failed to convert blob to base64'));
			};

			// Start reading the Blob as a Data URL (base64)
			reader.readAsDataURL(blob);
		});
	}

	// Effect to convert image files to base64 format if they are Blob URLs
	useEffect(() => {
		async function convertImages() {
			// Map through all images and convert Blob URLs to base64
			const promises = images.map(async image => {
				if (typeof image.file === 'string' && image.file.startsWith('blob:')) {
					const response = await fetch(image.file); // Fetch the Blob from the URL
					const blob = await response.blob(); // Convert response to Blob
					const base64 = await convertBlobToBase64(blob); // Convert Blob to base64
					return { ...image, file: base64 }; // Return the image with the base64 string
				}
				return image; // If not a Blob URL, return the image as is
			});

			// Wait for all conversions to complete and update state
			const convertedImages = await Promise.all(promises);
			setBase64Images(convertedImages);
		}

		convertImages();
	}, [images]);

	// Handle drag and drop end event to reorder images
	const handleOnDragEnd = result => {
		const { destination, source } = result;

		// If dropped outside the list or dropped in the same position, do nothing
		if (!destination) {
			return;
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		// Reorder images based on drag and drop result
		const imagesCopy = [...images];
		const [reorderedItem] = imagesCopy.splice(source.index, 1);
		imagesCopy.splice(destination.index, 0, reorderedItem);
		setImagesState(imagesCopy);
	};

	// Handle title change for an image
	const handleTitleChange = (imageId, title) => {
		setImagesState(prevImages => {
			return prevImages.map(img => (img.id === imageId ? { ...img, title } : img));
		});
	};

	// Handle caption change for an image
	const handleCaptionChange = (imageId, caption) => {
		setImagesState(prevImages => {
			return prevImages.map(img => (img.id === imageId ? { ...img, caption } : img));
		});
	};

	return (
		<DragDropContext onDragEnd={handleOnDragEnd}>
			<Droppable droppableId="imageDroppable">
				{provided => (
					<div
						{...provided.droppableProps}
						ref={provided.innerRef}
						style={{ width: 'fit-content' }}
						className="images-output"
					>
						{base64Images?.map((image, index) => {
							if (image.isSelected) {
								return (
									<Draggable key={image.id} draggableId={image.id.toString()} index={index}>
										{(provided, snapshot) => (
											<div ref={provided.innerRef} {...provided.draggableProps}>
												<ImageWithCaption
													image={image}
													dragHandleProps={provided.dragHandleProps}
													onCaptionChange={handleCaptionChange}
													onTitleChange={handleTitleChange}
												/>
												{index < images.length - 1 && (
													<div>
														<br />
													</div>
												)}
											</div>
										)}
									</Draggable>
								);
							}
							return null;
						})}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default Images;
